import {
	call, put, takeEvery
} from 'redux-saga/effects';
import * as types from './types';
import { outletManager } from '@/services/api';

export const triggers = {};

triggers.activeListByRole = () => ({
	type: types.FETCH_ACTIVE_LIST_BY_ROLE
});

triggers.listByDealers = (payload) => ({
	type: types.FETCH_OUTLETS_BY_DEALERS,
	payload
});

triggers.clearListByDealers = () => ({
	type: types.CLEAR_OUTLETS_BY_DEALERS
});

function* activeListByRoleWorker () {
	try {
		const response = yield call(outletManager.listByRole);
		yield put({
			type: types.SET_ACTIVE_LIST_BY_ROLE,
			payload: response.data
		});
	} catch (e) {
		yield call(console.warn, e);
	}
}

function* listByDealers ({ payload }) {
	try {
		const response = yield call(outletManager.listByDealers, payload);
		yield put({
			type: types.SET_OUTLETS_BY_DEALERS,
			payload: response.data
		});
	} catch (e) {
		yield call(console.warn, e);
	}
}

export function* saga () {
	yield takeEvery(types.FETCH_ACTIVE_LIST_BY_ROLE, activeListByRoleWorker);
	yield takeEvery(types.FETCH_OUTLETS_BY_DEALERS, listByDealers);
}
