export const PSS_CREATE = '@/pss/PSS_CREATE';

export const FETCH_PSS_CURRENT = '@/pss/FETCH_PSS_CURRENT';
export const SET_PSS_CURRENT = '@/pss/SET_PSS_CURRENT';

export const FETCH_PSS_LIST = '@/pss/FETCH_PSS_LIST';
export const SET_PSS_LIST = '@/pss/SET_PSS_LIST';

export const SET_PSS_CHANGE_STATUS = '@/pss/SET_PSS_CHANGE_STATUS';
export const FETCH_CHANGE_PSS_STATUS = '@/pss/FETCH_CHANGE_PSS_STATUS';

export const UPDATE_PSS = '@/pss/UPDATE_PSS';
export const CHANGE_PSS_LOGIN_AND_PASSWORD = '@/pss/CHANGE_PSS_LOGIN_AND_PASSWORD';

export const FETCH_PSS_ACTIVE_LIST = '@/pss/FETCH_PSS_ACTIVE_LIST';
export const PUT_PSS_ACTIVE_LIST = '@/pss/PUT_PSS_ACTIVE_LIST';
