import * as types from './types';
import {
	call,
	put,
	takeEvery,
} from 'redux-saga/effects';

import { lostToCompanies } from '@/services/api';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { noty } from '@/utilities/notifications';
import t from '@/utilities/translate';
import { store } from '@/redux/store';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.getData = (payload) => ({
	type: types.FETCH_ALL_LIST_DATA,
	payload,
});

triggers.getFullData = (payload) => ({
	type: types.FETCH_FULL_DATA,
	payload,
});

triggers.changeCompany = (payload) => ({
	type: types.CHANGE_COMPANY,
	payload,
});

triggers.changeCompanyStatus = (payload) => ({
	type: types.CHANGE_COMPANY_STATUS,
	payload,
});

triggers.changeCompanyActualSales = (payload) => ({
	type: types.CHANGE_COMPANY_ACTUAL_SALES,
	payload,
});

triggers.deleteCompany = (payload) => ({
	type: types.DELETE_COMPANY,
	payload,
});

triggers.getCompany = (payload) => ({
	type: types.GET_COMPANY_INFO,
	payload,
});

triggers.saveCompany = (payload) => ({
	type: types.SAVE_COMPANY_INFO,
	payload,
});

triggers.createCompany = (payload) => ({
	type: types.CREATE_COMPANY,
	payload,
});

triggers.setFilter = (payload) => ({
	type: types.SET_FILTER,
	payload,
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* getData({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield put(triggers.setFilter(payload));
		const { data } = yield call(lostToCompanies.getData, payload);
		yield put({
			type: types.SAVE_ALL_LIST_DATA,
			payload: data,
		});
	} finally {
		yield put(removePreloader(type));
	}
}

export function* getFullData({ type, payload }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(lostToCompanies.getFullData, payload);
		yield put({
			type: types.SAVE_FULL_DATA,
			payload: data,
		});
	} finally {
		yield put(removePreloader(type));
	}
}

export function* createCompany({ payload }) {
	try {
		yield call(lostToCompanies.create, payload);
		yield call(noty, 'success', t('lost-to-companies/create/success'));
		const filter = store.getState().lostToCompanies.get('filter');
		yield put({
			type: types.FETCH_ALL_LIST_DATA,
			payload: { ...filter },
		});
	} catch (err) {
		throw err;
	}
}

export function* changeCompanyStatus({ payload }) {
	try {
		yield call(lostToCompanies.change_status, payload);
		yield call(noty, 'success', t('lost-to-companies/changeStatus/success'));
		const filter = store.getState().lostToCompanies.get('filter');
		yield put({
			type: types.FETCH_ALL_LIST_DATA,
			payload: { ...filter },
		});
	} catch (err) {
		throw err;
	}
}

export function* changeCompanyActualSales({ payload }) {
	try {
		yield call(lostToCompanies.changeActualSales, payload);
		yield call(noty, 'success', t('manufacture/change-actual-sales/success'));
		const filter = store.getState().lostToCompanies.get('filter');
		yield put({
			type: types.FETCH_ALL_LIST_DATA,
			payload: { ...filter },
		});
	} catch (err) {
		throw err;
	}
}

export function* deleteCompany({ payload }) {
	try {
		yield call(lostToCompanies.delete, payload);
		yield call(noty, 'success', t('lost-to-companies/delete/success'));
		const filter = store.getState().lostToCompanies.get('filter');
		yield put({
			type: types.FETCH_ALL_LIST_DATA,
			payload: { ...filter },
		});
	} catch (err) {
		throw err;
	}
}

export function* changeCompany({ payload }) {
	const { id, name, status, tax_code, actual_sales } = payload;
	try {
		yield call(lostToCompanies.edit, id, { name, status, tax_code, actual_sales });
		yield call(noty, 'success', t('lost-to-companies/change/success'));
		const filter = store.getState().lostToCompanies.get('filter');
		yield put({
			type: types.FETCH_ALL_LIST_DATA,
			payload: { ...filter },
		});
	} catch (err) {
		throw err;
	}
}

export function* getCompany({ payload }) {
	try {
		const response = yield call(lostToCompanies.current, payload);
		yield put({
			type: types.SAVE_COMPANY_INFO,
			payload: response.data,
		});
	} catch (err) {
		throw err;
	}
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_ALL_LIST_DATA, getData);
	yield takeEvery(types.FETCH_FULL_DATA, getFullData);
	yield takeEvery(types.GET_COMPANY_INFO, getCompany);
	yield takeEvery(types.CHANGE_COMPANY, changeCompany);
	yield takeEvery(types.DELETE_COMPANY, deleteCompany);
	yield takeEvery(types.CHANGE_COMPANY_STATUS, changeCompanyStatus);
	yield takeEvery(types.CHANGE_COMPANY_ACTUAL_SALES, changeCompanyActualSales);
	yield takeEvery(types.CREATE_COMPANY, createCompany);
}
