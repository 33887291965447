export const FETCH_DASHBOARD_LIST = '@/DASHBOARD/FETCH_DASHBOARD_LIST';

export const FETCH_DASHBOARD_SALES_ACTIVITY = '@/DASHBOARD/FETCH_DASHBOARD_SALES_ACTIVITY';
export const SET_DASHBOARD_SALES_ACTIVITY = '@/DASHBOARD/SET_DASHBOARD_SALES_ACTIVITY';

export const FETCH_DASHBOARD_SALES_TOTAL = '@/DASHBOARD/FETCH_DASHBOARD_SALES_TOTAL';
export const SET_DASHBOARD_SALES_TOTAL = '@/DASHBOARD/SET_DASHBOARD_SALES_TOTAL';

export const FETCH_DASHBOARD_PRODUCT_MIX = '@/DASHBOARD/FETCH_DASHBOARD_PRODUCT_MIX';
export const SET_DASHBOARD_PRODUCT_MIX = '@/DASHBOARD/SET_DASHBOARD_PRODUCT_MIX';

export const SET_FILTER_VALUE = '@/DASHBOARD/SET_FILTER_VALUE';
export const SET_UPDATE_TIME = '@/DASHBOARD/SET_UPDATE_TIME';
