export const CREATE_PLATFORM = '@/platform/CREATE_PLATFORM';
export const CREATE_EQ = '@/platform/CREATE_EQ';

export const PLATFROM_LIST_FOR_EQ = '@/platform/PLATFROM_LIST_FOR_EQ';
export const SET_PLATFROM_LIST_FOR_EQ = '@/platform/SET_PLATFROM_LIST_FOR_EQ';

export const PLATFORM_AND_EQ_LIST = '@/platform/PLATFORM_AND_EQ_LIST';
export const SET_PLATFORM_AND_EQ_LIST = '@/platform/SET_PLATFORM_AND_EQ_LIST';

export const CHANGE_STATUS_PLATFORM_AND_EQ = '@/platform/CHANGE_STATUS_PLATFORM_AND_EQ';
export const FETCH_CHANGE_STATUS_PLATFORM_OR_EQ = '@/platform/FETCH_CHANGE_STATUS_PLATFORM_OR_EQ';

export const CURRENT_PLATFORM_OR_EQ = '@/platform/CURRENT_PLATFORM_OR_EQ';
export const SET_CURRENT_PLATFORM_OR_EQ = '@/platform/SET_CURRENT_PLATFORM_OR_EQ';

export const PLATFORM_OR_EQ_EDIT = '@/platform/PLATFORM_OR_EQ_EDIT';

export const FETCH_EQ_BY_PLATFROM_ID = '@/platform/FETCH_EQ_BY_PLATFROM_ID';
export const SET_EQ_BY_PLATFROM_ID = '@/platform/SET_EQ_BY_PLATFROM_ID';

export const EQUIPMENT_GROUP_LIST = '@/platform/EQUIPMENT_GROUP_LIST';
export const SET_EQUIPMENT_GROUP_LIST = '@/platform/SET_EQUIPMENT_GROUP_LIST';

export const FETCH_SIZE_CLASS_LIST = '@/platform/FETCH_SIZE_CLASS_LIST';
export const SET_SIZE_CLASS_LIST = '@/platform/SET_SIZE_CLASS_LIST';

export const FETCH_PLATFORM_LIST_BY_MANUFACTURE = '@/platform/FETCH_PLATFORM_LIST_BY_MANUFACTURER';
export const SET_PLATFORM_LIST_BY_MANUFACTURE = '@/platform/SET_PLATFORM_LIST_BY_MANUFACTURER';
