import { all, call, put, takeEvery } from 'redux-saga/effects';
import { addPreloader, removePreloader } from "@/redux/common/preloaders";
import { noty } from "@/utilities/notifications";
import t from '@/utilities/translate';
import * as api from '@/services/api';
import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";
import { simpleLocation } from '@/utilities/location';

export const SIMPLE_CRUD_CREATE = '@/simple_crud/SIMPLE_CRUD_CREATE';
export const SIMPLE_CRUD_INFO = '@/simple_crud/SIMPLE_CRUD_INFO';
export const SIMPLE_CRUD_SET_INFO = '@/simple_crud/SIMPLE_CRUD_SET_INFO';
export const SIMPLE_CRUD_SET_LIST = '@/simple_crud/SIMPLE_CRUD_SET_LIST';
export const SIMPLE_CRUD_UPDATE = '@/simple_crud/SIMPLE_CRUD_UPDATE';
export const SIMPLE_CRUD_LIST = '@/simple_crud/SIMPLE_CRUD_LIST';
export const SIMPLE_CRUD_CHANGE_STATUS = '@/simple_crud/SIMPLE_CRUD_CHANGE_STATUS';
export const SIMPLE_CRUD_SET_CHANGE_STATUS = '@/simple_crud/SIMPLE_CRUD_SET_CHANGE_STATUS';
export const SIMPLE_CRUD_REMOVE = '@/simple_crud/SIMPLE_CRUD_REMOVE';

export const actions = {};

actions.create = (options) => ({
    type: SIMPLE_CRUD_CREATE,
    ...options
})

actions.info = (options)=>({
    type: SIMPLE_CRUD_INFO,
    ...options
})

actions.update = (options)=>({
    type: SIMPLE_CRUD_UPDATE,
    ...options
})

actions.setInfo = (payload, nameSpace)=>({
    type: SIMPLE_CRUD_SET_INFO,
    payload,
    nameSpace
})

actions.setList = (payload, nameSpace)=>({
    type: SIMPLE_CRUD_SET_LIST,
    payload,
    nameSpace
})

actions.list = (options)=>({
    type: SIMPLE_CRUD_LIST,
    ...options
})

actions.setChangedStatus = (payload, nameSpace)=>({
    type: SIMPLE_CRUD_SET_CHANGE_STATUS,
    payload,
    nameSpace
})

actions.changeStatus = (options)=>({
    type: SIMPLE_CRUD_CHANGE_STATUS,
    ...options
})

actions.remove = (options)=>({
    type: SIMPLE_CRUD_REMOVE,
    ...options
})

export const saga = function* () {
    yield all([
        takeEvery(SIMPLE_CRUD_CREATE, function* ({ type, query, nameSpace }) {
            yield put(addPreloader(type));
            try {
                yield call(
                    api[nameSpace].create,
                    {query}
                );
                yield call(noty, 'success', t(`${nameSpace}/create/success`));
                yield call(simpleLocation({ nameSpace }));
            } catch (e) {
                console.warn(e);
            } finally {
                yield put(removePreloader(type));
            }
        }),
        takeEvery(SIMPLE_CRUD_INFO, function* ({ type, id, nameSpace }) {
            yield put(addPreloader(type));
            try {
                const { data } = yield call(
                    api[nameSpace].info,
                    {id}
                );
                yield put(actions.setInfo(data, nameSpace));
            } catch (e) {
                console.warn(e);
            } finally {
                yield put(removePreloader(type));
            }
        }),
        takeEvery(SIMPLE_CRUD_UPDATE, function* ({ type, id, query, nameSpace }) {
            yield put(addPreloader(type));
            try {
                yield call(
                    api[nameSpace].update,
                    { id, query }
                );
                yield call(noty, 'success', t(`${nameSpace}/update/success`));
                yield call(simpleLocation({ nameSpace, id }));
            } catch (e) {
                console.warn(e);
            } finally {
                yield put(removePreloader(type));
            }
        }),
        takeEvery(SIMPLE_CRUD_LIST, function* ({ type, query, nameSpace }) {
            yield put(addPreloader(type));
            try {
                const { data } = yield call(
                    api[nameSpace].list,
                    { query }
                );
                yield put(actions.setList(data, nameSpace));
            } catch (e) {
                console.warn(e);
            } finally {
                yield put(removePreloader(type));
            }
        }),
        takeEvery(SIMPLE_CRUD_CHANGE_STATUS, function*({ id, nameSpace }) {
            try {
                const { data } = yield call(api[nameSpace].changeStatus, {id});
                yield put(actions.setChangedStatus(data, nameSpace))
                yield call(noty, 'success', t(`${nameSpace}/changeStatus/success`))
            } catch(e) {
                console.warn(e);
            }
        }),
        takeEvery(SIMPLE_CRUD_REMOVE, function* removeProduct({ payload, nameSpace }) {
            try {
                const { id } = payload;
                yield call(api[nameSpace].remove, { id });
                yield call(noty, 'success', t(`${nameSpace}/remove/notify/success`));
            } catch (e) {
                yield call(console.warn, e);
            }
        })
    ]);
}

const initialState = new Map({
    info: Map({})
});

export const scope = createReducer(initialState, {
    [SIMPLE_CRUD_SET_INFO]: (state, { payload, nameSpace })=>(
        state.setIn(['info', nameSpace], payload)
    ),
    [SIMPLE_CRUD_SET_LIST]: (state, { payload, nameSpace })=>(
        state.setIn(['list', nameSpace], payload)
    ),
    [SIMPLE_CRUD_SET_CHANGE_STATUS]: (state, { payload, nameSpace })=>state.updateIn(['list', nameSpace], listData =>{
        debugger;
		listData.data = listData.data.map(item=>item.id === payload.id ? payload : item);
		return listData;
    }),
    [SIMPLE_CRUD_REMOVE]: (state, { payload, nameSpace })=>state.updateIn(['list', nameSpace], listData =>{
		listData.data = listData.data.filter(item=>item.id !== payload.id);
		return listData;
    }),
});
