import React from 'react';
import Helmet from 'react-helmet';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import t from '@/utilities/translate';

const Forbidden = () => (
  <div
	className="page-error"
	style={{ backgroundImage: "url(/images/403-bg.png)" }}
  >
	<Helmet title={t("403/forbidden")}/>
	<div className="page-error__title">{t("403/forbidden")}</div>
	<Link to="/">
	  <Button className="page-error__button" type="primary">
		{t("common/homepage")}
	  </Button>
	</Link>
  </div>
);

export default Forbidden;
