export const CREATE = '@/size-parameters/CREATE';

export const FETCH_LIST = '@/size-parameters/FETCH_LIST';
export const SET_LIST = '@/size-parameters/SET_LIST';

export const FETCH_ITEM = '@/size-parameters/FETCH_ITEM';
export const SAVE_ITEM = '@/size-parameters/SAVE_ITEM';
export const SET_ITEM = '@/size-parameters/SET_ITEM';

export const CHANGE_ITEM_STATUS = '@/size-parameters/CHANGE_ITEM_STATUS';
export const SET_ITEM_STATUS = '@/size-parameters/SET_ITEM_STATUS';

export const FETCH_SIZE_PARAMETR_LIST = '@/size-parameters/FETCH_SIZE_PARAMETR_LIST';
export const PUT_SIZE_PARAMETR_LIST = '@/size-parameters/PUT_SIZE_PARAMETR_LIST';
