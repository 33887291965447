import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { triggers } from '@/redux/notification-emails/actions';
import DispatchOnMount from '@/components/common/DispatchOnMount';
import Form from './Form';
import { store } from '../../../redux/store';
import { change } from 'redux-form';

const CreateNotificationEmail = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		createNotificationEmail,
		isEdit,
		changeNotificationEmail,
		getNotificationEmail,
		emailId,
		data,
		getUsersListForNotificationEmails
	} = props;
	const [status, setStatus] = useState(false);
	const closeModal = (values) => {
		const { user_id } = values;
		if (isEdit) {
			changeNotificationEmail({ emailId, user_id, status: Number(status) })
		} else {
			createNotificationEmail({ user_id, status: Number(status) });
		}
		closeModalFn();
	};

	useEffect(() => {
		if (isEdit && data.user_full_name) {
			getUsersListForNotificationEmails(data.user_full_name);
			store.dispatch(change('NotificationEmails', 'user_id', String(data.user_id)));
			setStatus(!!data.status);
		}
	}, [data.user_full_name, data.status]);

	const handleStatusChange = (event) => {
		setStatus(event.target.checked);
	};

	return (
	  <Modal {...modalProps} footer={null}>
		  <DispatchOnMount actionCreator={isEdit && emailId ? () => {
			  getNotificationEmail(emailId);
		  } : () => {}}>
			  <Form
			    status={status}
			    isEdit={isEdit}
			    handleStatusChange={handleStatusChange}
			    getUsersListForNotificationEmails={getUsersListForNotificationEmails}
			    onSubmit={closeModal}
			  />
		  </DispatchOnMount>
	  </Modal>
	);
};

CreateNotificationEmail.defaultProps = {
	modalProps: {},
	availableClients: [],
};

CreateNotificationEmail.propTypes = {
	modalProps: PropTypes.any,
};


export default connect(
	(state) => ({
		data: state.notificationEmails.get('current')
	}),
	{
		...triggers,
	},
)(CreateNotificationEmail);
