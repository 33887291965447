import * as React from 'react';
import { connect } from 'react-redux';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import PropTypes from 'prop-types';

import Access from '@/components/common/access/Access';
import { Clarification } from './Clarification';

const Option = Select.Option;

// ----------------------------------------------------------------------
// Select Component
// ----------------------------------------------------------------------

class SelectWrapper extends React.PureComponent {
    static propTypes = {
      input: PropTypes.object,
      meta: PropTypes.object,
      label: PropTypes.string,
      defaultValue: PropTypes.any
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      const selectValueDoChange = nextProps.input.value && this.props.input.value !== nextProps.input.value;
      const callOwnOnChange = selectValueDoChange && this.props.ownOnChange;

      callOwnOnChange && this.props.ownOnChange(nextProps.input.value, 1);
    }

    UNSAFE_componentWillMount() {
      this.props.defaultValue && this.props.input.onChange(this.props.defaultValue);
    }

    render() {
      const {
        input,
        meta,
        label,
        clarification,
        defaultValue,
        options,
        emptyLabel,
        handler,
        access_role = undefined,
        _validate = null,
        ownOnChange,
        onChange,
        children,
        disabledReason = false,
        ...attributes
      } = this.props;

      const formItemProps = {
        label: label ? (_validate ? (
          <span>
            {label}
            <span className="f-danger">*</span>
          </span>
        ) : label) : null,
        validateStatus: meta.touched && !meta.valid ? "error" : undefined,
        help: meta.touched && meta.error ? meta.error : null,
      };

      const Component = (
        <Form.Item {...formItemProps} style={{ margin: '0 0 10px' }}>
          <Clarification text={clarification} />
          <Select
            {...attributes}
            disabled={disabledReason || attributes.disabled}
            value={input.value || defaultValue}
            onSearch={(value) => {
              handler && handler(value);
            }}
            onChange={(value, option) => {
              onChange && onChange(value, option);
              input.onChange(value);
            }}
            style={{ width: '100%' }}
          >

            {this.getOptions()}
          </Select>
        </Form.Item>
      );

      return access_role ? <Access access_role={access_role}>{Component}</Access> : Component;
    }

    getOptions() {
      const { options, children, emptyLabel } = this.props;
      if (Array.isArray(options)) {
        if (options.length === 0 && emptyLabel) {
          return <Option key="emptyLabel" disabled>{emptyLabel}</Option>;
        }
        return options.map(({ label, value }, index) => (
			<Option key={index} value={value}>
				{label}
			</Option>
		));
      }
      return children;
    }
}

export default connect(
  (state, ownProps) => {
    const optionsData = ownProps.data ? {
      options: ownProps.data(state, ownProps)
    } : {};
    const disabledReason = ownProps.disabledReason ? {
      disabledReason: ownProps.disabledReason(state, ownProps)
    } : {};
    return Object.assign(
      optionsData,
      disabledReason
    );
  }
)(SelectWrapper);

