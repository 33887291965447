import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';

import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { changeCredentials } from '@/services/api';
import { noty } from "@/utilities/notifications";
import t from '@/utilities/translate';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.changeCoworkerCredentials = (payload) => ({
	type: types.CHANGE_COWORKER_CREDENTIALS,
	payload
});

triggers.changeDealerCredentials = (payload) => ({
	type: types.CHANGE_DEALER_CREDENTIALS,
	payload
});

triggers.changeEmployeeCredentials = (payload) => ({
	type: types.CHANGE_EMPLOYEE_CREDENTIALS,
	payload
});

triggers.changePssCredentials = (payload) => ({
	type: types.CHANGE_PSS_CREDENTIALS,
	payload
});

triggers.changeTmCredentials = (payload) => ({
	type: types.CHANGE_TM_CREDENTIALS,
	payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* changeEmployeeCredentials({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield call(changeCredentials.employee, payload);
		yield call(noty, 'success', t('change-credentials/success'));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* changeDealerCredentials({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield call(changeCredentials.dealers, payload);
		yield call(noty, 'success', t('change-credentials/success'));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* changeTmCredentials({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield call(changeCredentials.tm, payload);
		yield call(noty, 'success', t('change-credentials/success'));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* changePssCredentials({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield call(changeCredentials.pss, payload);
		yield call(noty, 'success', t('change-credentials/success'));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* changeCoworkerCredentials({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield call(changeCredentials.coworker, payload);
		yield call(noty, 'success', t('change-credentials/success'));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.CHANGE_COWORKER_CREDENTIALS, changeCoworkerCredentials);
	yield takeEvery(types.CHANGE_TM_CREDENTIALS, changeTmCredentials);
	yield takeEvery(types.CHANGE_PSS_CREDENTIALS, changePssCredentials);
	yield takeEvery(types.CHANGE_EMPLOYEE_CREDENTIALS, changeEmployeeCredentials);
	yield takeEvery(types.CHANGE_DEALER_CREDENTIALS, changeDealerCredentials);
}
