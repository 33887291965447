import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
	activeListByRole: [],
	activeListByDealers: []
});

export default createReducer(initialState, {
	[types.SET_ACTIVE_LIST_BY_ROLE]: (state, { payload }) => {
		return state.set('activeListByRole', payload)
	},
	[types.SET_OUTLETS_BY_DEALERS]: (state, { payload }) => {
		return state.set('activeListByDealers', payload)
	},
	[types.CLEAR_OUTLETS_BY_DEALERS]: (state) => {
		return state.set('activeListByDealers', [])
	},
});
