export const FETCH_TRADE_IN_ITEM = '@/trade_in_vehicle/FETCH_TRADE_IN_ITEM';
export const SET_TRADE_IN_ITEM = '@/trade_in_vehicle/SET_TRADE_IN_ITEM';

export const CREATE_TRADE_IN_ITEM = '@/trade_in_vehicle/CREATE_TRADE_IN_ITEM';

export const CHANGE_TRADE_IN_ITEM = '@/trade_in_vehicle/CHANGE_TRADE_IN_ITEM';
export const DELETE_TRADE_IN_ITEM = '@/trade_in_vehicle/DELETE_TRADE_IN_ITEM';

export const GET_CLIENT_VEHICLES_LIST = '@/trade_in_vehicle/GET_CLIENT_VEHICLES_LIST';
export const SET_CLIENT_VEHICLES_LIST = '@/trade_in_vehicle/SET_CLIENT_VEHICLES_LIST';
