import * as types from './types';

const initialState = {
    collapsed: process.env.NODE_ENV === 'production' ? false : true,
    menu: {}
};

export const sidebar = (state = initialState, action) => {
    switch (action.type) {
        case types.TOGGLE_SIDEBAR:
            return {
                ...state,
                collapsed: !state.collapsed,
                menu: state.collapsed ? {
                    ...state.menu
                } : {
                    ...state.menu,
                    openKeys: []
                }
            };

        case types.CHANGE_MENU:
            return {
                ...state,
                menu: {
                    ...state.menu,
                    ...action.payload
                }
            };

        default:
            return state;
    }
};
