import React, { Fragment } from 'react';
import { Button, Col, Popconfirm } from 'antd';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import t from '@/utilities/translate';
import FormSkeleton from '@/components/common/form/FormSkeleton';
import { formResolver } from '@/utilities/form';
import addProductFields from './_FIELDS_';
import { compose } from 'recompose';
import { EDIT_POTENTIAL_SALE } from '@/redux/potential-sales/types';
import ButtonPending from '@/components/common/form/ButtonPending';
import validator from '@/utilities/validator';

export const FORM_NAME = 'potential-sales/add-product';

const FORM_FIELDS_RESPONSIVE_OPTIONS = {
	wSpan: { lg: 24, xs: 24 },
	iSpan: { lg: 24, xs: 24 },
};

const Actions = ({ handleSubmit, closeModalFn }) => (
	<Fragment>
		<Col span={12}>
			<ButtonPending type={EDIT_POTENTIAL_SALE} className="w-100 ant-btn--multiple-row" onClick={handleSubmit}>
				{t('potential-sales/add-product/submit')}
			</ButtonPending>
		</Col>
		<Col span={12}>
			<Popconfirm
				onConfirm={closeModalFn}
				placement="bottomRight"
				title={t('potential-sales/add-product/cancel/popconfirm/title')}
				okText={t('common/yes')}
				cancelText={t('common/no')}
			>
				<Button className="w-100 ant-btn--multiple-row">
					{t('potential-sales/add-product/cancel')}
				</Button>
			</Popconfirm>
		</Col>
	</Fragment>
);

const Form = ({ handleSubmit, closeModalFn, pristine, pending, availableOptions, ...props }) => {
	const dataFields = addProductFields(props, FORM_NAME);
	const FIELDS = formResolver(dataFields, FORM_FIELDS_RESPONSIVE_OPTIONS);

	return (
		<FormSkeleton onSubmit={handleSubmit} hideBottomBar={true}>
			{FIELDS}
			<Actions handleSubmit={handleSubmit} closeModalFn={closeModalFn} />
		</FormSkeleton>
	)
};

const mapStateToProps = (state) => {
	const form = state.form[FORM_NAME];
	return form && form.values ? form.values : {};
};

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: FORM_NAME,
		validate: validator.potentialSalesAddProductFields,
		pure: false
	}),
)(Form);
