import * as types from './types';
import {
	call,
	put,
	takeEvery,
} from 'redux-saga/effects';

import { serviceCodes } from '@/services/api';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { noty } from '@/utilities/notifications';
import t from '@/utilities/translate';
import { store } from '@/redux/store';
import { history } from '@/redux/history';
import { routes } from '@/routing/route-tree';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.getList = (payload) => ({
	type: types.FETCH_LIST,
	payload,
});

triggers.updateCode = (payload) => ({
	type: types.UPDATE_CODE,
	payload,
});

triggers.changeStatus = (payload) => ({
	type: types.CHANGE_STATUS,
	payload,
});

triggers.changeActual = (payload) => ({
	type: types.CHANGE_ACTUAL,
	payload,
});

triggers.getCode = (payload) => ({
	type: types.GET_CODE_INFO,
	payload,
});

triggers.saveCode = (payload) => ({
	type: types.SAVE_CODE_INFO,
	payload,
});

triggers.createCode = (payload) => ({
	type: types.CREATE_CODE,
	payload,
});

triggers.setFilter = (payload) => ({
	type: types.SET_FILTER,
	payload,
});

triggers.getCodesArray = (payload) => ({
	type: types.FETCH_CODES_ARRAY,
	payload,
});

triggers.setCodesArray = (payload) => ({
	type: types.SET_CODES_ARRAY,
	payload,
});

triggers.deleteDrafts = (payload) => ({
	type: types.DELETE_DRAFTS,
	payload,
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* getList({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield put(triggers.setFilter(payload));
		const { data } = yield call(serviceCodes.list, payload);
		yield put({
			type: types.SAVE_LIST,
			payload: data,
		});
	} catch (err) {
		throw err;
	} finally {
		yield put(removePreloader(type));
	}
}

export function* createCode({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield call(serviceCodes.create, payload);
		yield call(noty, 'success', t('service-codes/create/success'));
		yield call(history.push, routes['serviceCodes'].link());
	} catch (err) {
		throw err;
	} finally {
		yield put(removePreloader(type));
	}
}

export function* changeStatus({ payload }) {
	try {
		yield call(serviceCodes.changeStatus, payload);
		yield call(noty, 'success', t('service-codes/changeStatus/success'));
		const filter = store.getState().serviceCodes.get('filter');
		yield put({
			type: types.FETCH_LIST,
			payload: { ...filter },
		});
	} catch (err) {
		throw err;
	}
}

export function* changeActual({ payload }) {
	try {
		yield call(serviceCodes.changeActual, payload);
		yield call(noty, 'success', t('service-codes/changeActual/success'));
		const filter = store.getState().serviceCodes.get('filter');
		yield put({
			type: types.FETCH_LIST,
			payload: { ...filter },
		});
	} catch (err) {
		throw err;
	}
}

export function* deleteDrafts({ payload }) {
	try {
		yield call(serviceCodes.deleteDrafts, payload);
		yield call(noty, 'success', t('service-codes/deleteDrafts/success'));
		const filter = store.getState().serviceCodes.get('filter');
		yield put({
			type: types.FETCH_LIST,
			payload: { ...filter },
		});
	} catch (err) {
		throw err;
	}
}

export function* updateCode({ type, payload }) {
	const { id, name, status, actual, code, productLineIds } = payload;
	yield put(addPreloader(type));
	try {
		yield call(serviceCodes.update, id, { name, status, actual, code, productLineIds });
		yield call(noty, 'success', t('service-codes/edit/success'));
		yield call(history.push, routes['serviceCodes'].link());
	} catch (err) {
		throw err;
	} finally {
		yield put(removePreloader(type));
	}
}

export function* getCode({ payload }) {
	try {
		const response = yield call(serviceCodes.current, payload);
		yield put({
			type: types.SAVE_CODE_INFO,
			payload: response.data,
		});
	} catch (err) {
		throw err;
	}
}

export function* getCodesArray({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield put(triggers.setFilter(payload));
		const { data } = yield call(serviceCodes.codesArray, payload);
		yield put({
			type: types.SET_CODES_ARRAY,
			payload: data,
		});
	} catch (err) {
		throw err;
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_LIST, getList);
	yield takeEvery(types.GET_CODE_INFO, getCode);
	yield takeEvery(types.UPDATE_CODE, updateCode);
	yield takeEvery(types.CHANGE_STATUS, changeStatus);
	yield takeEvery(types.CHANGE_ACTUAL, changeActual);
	yield takeEvery(types.DELETE_DRAFTS, deleteDrafts);
	yield takeEvery(types.CREATE_CODE, createCode);
	yield takeEvery(types.FETCH_CODES_ARRAY, getCodesArray);
}
