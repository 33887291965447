import React from 'react';
import { store } from '@/redux/store';

export default class extends React.Component {
  UNSAFE_componentWillMount() {
    const actions = this.props.actionCreator();
    if (actions) {
      store.dispatch(actions);
    }
  }

  render() {
    return this.props.children;
  }
}
