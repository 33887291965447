export const countryForward = (location, props, routes) => {
    switch (location) {
        case routes['country/create'].link():
            return routes['country'].link();

        case routes['country/edit'].link(props.id):
            return routes['country'].link();
        default:
            break;
    }
};

export const regionForward = (location, props, routes) => {
    switch (location) {
        case routes['region/create'].link():
            return routes['region'].link();

        case routes['region/edit'].link(props.id):
            return routes['region'].link();
        default:
            break;
    }
};

export const rayonForward = (location, props, routes) => {
    switch (location) {
        case routes['district/create'].link():
            return routes['district'].link();

        case routes['district/edit'].link(props.id):
            return routes['district'].link();
        default:
            break;
    }
};
