import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
  list: {
    data: [],
    meta: {}
  },
  moduleList: []
});

export default createReducer(initialState, {
  [types.SET_TRANSLATES_LIST]: (state, action) => state.set('list', ((action.payload.data = action.payload.data.map((v, index) => ({
    ...v,
    index
  }))) && action.payload)),
  [types.PUT_MODULE_LIST]: (state, { payload }) => state.set('moduleList', payload)
});
