import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
	list: []
});

export default createReducer(initialState, {
	[types.SAVE_USERS_SETTINGS]: (state, { payload }) => state.set('list', payload),
});
