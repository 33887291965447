export const FETCH_SALES_PLANS_LIST = '@/actual-sales-plans/FETCH_SALES_PLANS_LIST';
export const SET_SALES_PLANS_LIST = '@/actual-sales-plans/SET_SALES_PLANS_LIST';
export const GET_SALES_PLAN_INFO = '@/actual-sales-plans/GET_SALES_PLAN_INFO';
export const SET_SALES_PLAN_INFO = '@/actual-sales-plans/SET_SALES_PLAN_INFO';
export const UPDATE_SALES_PLAN = '@/actual-sales-plans/UPDATE_SALES_PLAN';
export const DELETE_SALES_PLAN = '@/actual-sales-plans/DELETE_SALES_PLAN';
export const SET_SALES_PLAN_PARENT = '@/actual-sales-plans/SET_SALES_PLAN_PARENT';
export const SET_FILTER = '@/actual-sales-plans/SET_FILTER';
export const SET_ACTIVE_FILTER = '@/actual-sales-plans/SET_ACTIVE_FILTER';
export const MAKE_CATALOGUE = '@/actual-sales-plans/MAKE_CATALOGUE';
