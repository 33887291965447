export const CREATE_CO_WORKER = '@/co-worker/CREATE_CO_WORKER';

export const FETCH_CURRENT_CO_WORKER = '@/co-worker/FETCH_CURRENT_CO_WORKER';
export const PUT_CURRENT_CO_WORKER = '@/co-worker/PUT_CURRENT_CO_WORKER';

export const UPDATE_CURRENT_CO_WORKER = '@/co-worker/UPDATE_CURRENT_CO_WORKER';
export const UPDATE_STATUS_CO_WORKER = '@/co-worker/UPDATE_STATUS_CO_WORKER';

export const FETCH_CO_WORKER_ACTIVE_LIST = '@/co-worker/FETCH_CO_WORKER_ACTIVE_LIST';
export const SET_CO_WORKER_ACTIVE_LIST = '@/co-worker/SET_CO_WORKER_ACTIVE_LIST';

export const FETCH_CO_WORKER_BY_DEALER_LIST = '@/co-worker/FETCH_CO_WORKER_BY_DEALER_LIST';
export const SET_CO_WORKER_BY_DEALER_LIST = '@/co-worker/SET_CO_WORKER_BY_DEALER_LIST';

export const SEND_SIGN = '@/co-worker/SEND_SIGN';