import * as React from 'react'

/**
 * @param {Object} props
 * @param {string} [props.text]
 * @returns {React.Component | null}
 * @constructor
 */
export function Clarification (props) {
    return props.text ? <div className="clarification">{ props.text }</div> : null;
}
