import * as types from './types';
import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";
import { pick } from 'lodash/fp';
import { apiNumberFormatter } from '../../../utilities/formatter';

const initialState = new Map({
    info: {},
    userDeals: {}
});

export default createReducer(initialState, {
    [types.SET_USER_PROFILE]: (state, {payload})=>{
        const _payload = apiNumberFormatter(pick(['phone'])(payload));

        return state.set('user_profile', Object.assign(payload, _payload))
    },
    [types.SET_USER_DEALS]: (state, action)=>state.set('userDeals', action.payload)
});
