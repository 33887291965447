import { connect } from 'react-redux';
import React from 'react';

const mapStateToProps = (state) => ({
    role: state.auth.token ? state.auth.token.role_id : null
})

export default connect(
    mapStateToProps
)(({ render, role, access_role = [1, 2, 3, 4, 5], children, other_role_component = null }) => {
    if (!render) {
        return role ? (access_role.includes(role)
            ? children
            : null) : children;
    }
    else {
        const Cmp = render;
        return <Cmp access={access_role.includes(role)} />
    }
})