import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { actualSalesPlansSummary } from '@/services/api';

//-----------------------------------------
//	Triggers
//-----------------------------------------
export const triggers = {};

triggers.getList = (query) => ({
	type: types.FETCH_SALES_PLANS_SUMMARY_LIST,
	query,
});

triggers.setList = (payload) => ({
	type: types.SET_SALES_PLANS_SUMMARY_LIST,
	payload,
});

triggers.setDefaultFilter = (payload) => ({
	type: types.SET_FILTER,
	payload,
});

triggers.setActiveFilter = (payload) => ({
	type: types.SET_ACTIVE_FILTER,
	payload,
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchSalesPlansSummaryList({ type, query }) {
	yield put(addPreloader(type));
	try {
		yield put(triggers.setActiveFilter({
			...(query.dealer_id && { dealer_id: query.dealer_id }),
			...(query.client_id && { client_id: query.client_id }),
			...(query.service_code && { service_code: query.service_code }),
			...(query.description_product && { description_product: query.description_product }),
			...(query.company_lost_deal_id && { company_lost_deal_id: query.company_lost_deal_id }),
		}));
		yield put(triggers.setDefaultFilter(query));
		const { data } = yield call(actualSalesPlansSummary.list, query);
		yield put(triggers.setList(data));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root saga
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_SALES_PLANS_SUMMARY_LIST, fetchSalesPlansSummaryList);
}
