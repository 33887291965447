import * as types from './types'

// ----------------------------------------------------------------------
// TOGGLE_SIDEBAR
// ----------------------------------------------------------------------

export const toggleSidebar = () => ({
    type: types.TOGGLE_SIDEBAR
});

export const menuHandler = (payload) => ({
    type: types.CHANGE_MENU,
    payload
});