import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { triggers } from '@/redux/deal/actions';
import Form from './Form';

const ChangeStatusModal = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		editDeal,
		dealId,
		info,
		availableResults,
		availableStatuses,
	} = props;

	const closeModal = (values) => {
		editDeal({ id: dealId, ...values });
		closeModalFn();
	};

	const deal_result_id = availableResults.find((result) => result.name === info.result)?.id;

	return (
		<Modal {...modalProps} footer={null}>
			<Form initialValues={{ deal_status_id: info.status_id, deal_result_id }}
			      availableResults={availableResults}
			      availableStatuses={availableStatuses}
			      onSubmit={closeModal}
			/>
		</Modal>
	);
};

ChangeStatusModal.defaultProps = {
	modalProps: {},
	availableResults: [],
	availableStatuses: [],
};

ChangeStatusModal.propTypes = {
	modalProps: PropTypes.any,
	closeModal: PropTypes.func.isRequired
};

export default connect(
	(state) => ({
		availableResults: state.deal.get('resultsActiveList'),
		availableStatuses: state.deal.get('statusesActiveList'),
	}),
	{
		...triggers
	}
)(ChangeStatusModal);
