import * as React from 'react';
import { Switch, Form } from 'antd';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------
// TextField Component
// ----------------------------------------------------------------------

export default class SwitchWrapper extends React.PureComponent {
    static propTypes = {
      input: PropTypes.object,
      meta: PropTypes.object,
      label: PropTypes.string
    };

    changeHandler = (checked) => {
      this.props.input.onChange(checked);
    };

    render() {
      const {
        input, meta, label, _validate, afterField, ...attributes
      } = this.props;

      const formItemProps = {
        label: label ? (_validate ? (
          <span>
            {label}
            <span className="f-danger">*</span>
          </span>
        ) : label) : null,
        validateStatus: meta.touched && !meta.valid ? "error" : undefined,
        help: meta.touched && meta.error ? meta.error : null,
      };

      return (
        <Form.Item {...formItemProps} style={{ margin: '0 0 16px' }}>
          <Switch
            defaultChecked={!!input.value}
            {...attributes}
            onChange={(checked) => this.changeHandler(checked)}
          />
          {afterField}
        </Form.Item>
      );
    }
}
