import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { potentialSales } from '@/services/api';
import t from '@/utilities/translate';
import { noty } from '@/utilities/notifications';

//-----------------------------------------
//    Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchPotentialSales = (query) => ({
	type: types.FETCH_POTENTIAL_SALES_LIST,
	query,
});

triggers.fetchStatusesList = () => ({
	type: types.FETCH_STATUSES_LIST,
});

triggers.fetchCurrentPotentialSale = (id) => ({
	type: types.FETCH_CURRENT_POTENTIAL_SALE,
	id,
});

triggers.editPotentialSale = (payload) => ({
	type: types.EDIT_POTENTIAL_SALE,
	payload
});

triggers.approvePotentialSale = (id) => ({
	type: types.APPROVE_POTENTIAL_SALE,
	id,
});

triggers.declinePotentialSale = (id) => ({
	type: types.DECLINE_POTENTIAL_SALE,
	id,
});

const putPotentialSales = (payload) => ({
	type: types.SET_POTENTIAL_SALES_LIST,
	payload,
});

const putStatusesList = (payload) => ({
	type: types.SET_STATUSES_LIST,
	payload,
});

const putCurrentPotentialSale = (payload) => ({
	type: types.SET_CURRENT_POTENTIAL_SALE,
	payload,
});

//-----------------------------------------
//	Saga actions
//-----------------------------------------

export function* fetchPotentialSales({ type, query }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(potentialSales.list, query);
		yield put(putPotentialSales(data));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchStatusesList() {
	try {
		const { data } = yield call(potentialSales.statuses);
		yield put(putStatusesList(data));
	} catch (e) {
		yield call(console.warn, e);
	}
}

export function* fetchCurrentPotentialSale({ type, id }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(potentialSales.current, id);
		yield put(putCurrentPotentialSale(data));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* editCurrentPotentialSale({ type, payload }) {
	const { id, items, fiscal_year, isNewProduct } = payload;

	yield put(addPreloader(type));
	try {
		const { data } = yield call(potentialSales.update, id, { items, fiscal_year });
		yield call(noty, 'success', isNewProduct ? t('potential-sale/add-product/success') : t('potential-sale/update/success'));
		yield put(putCurrentPotentialSale(data));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* approvePotentialSale({ id }) {
	try {
		const { data } = yield call(potentialSales.approve, id);
		yield call(noty, 'success', t('potential-sale/approve/success'));
		yield put(putCurrentPotentialSale(data));
	} catch (err) {
		throw err;
	}
}

export function* declinePotentialSale({ id }) {
	try {
		const { data } = yield call(potentialSales.decline, id);
		yield call(noty, 'success', t('potential-sale/decline/success'));
		yield put(putCurrentPotentialSale(data));
	} catch (err) {
		throw err;
	}
}

//-----------------------------------------
//	Root saga
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_POTENTIAL_SALES_LIST, fetchPotentialSales);
	yield takeEvery(types.FETCH_STATUSES_LIST, fetchStatusesList);
	yield takeEvery(types.FETCH_CURRENT_POTENTIAL_SALE, fetchCurrentPotentialSale);
	yield takeEvery(types.EDIT_POTENTIAL_SALE, editCurrentPotentialSale);
	yield takeEvery(types.APPROVE_POTENTIAL_SALE, approvePotentialSale);
	yield takeEvery(types.DECLINE_POTENTIAL_SALE, declinePotentialSale);
}
