import { call, put, takeEvery } from 'redux-saga/effects';
import moment from "moment";
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { techniques } from '@/services/api';
import t from '@/utilities/translate';
import { noty } from "@/utilities/notifications";

//-----------------------------------------
//	Triggers
//-----------------------------------------
export const triggers = {};


triggers.createTechnique = (payload, cb) => ({
  type: types.CREATE_TECHNIQUE_REQUEST,
  payload,
  cb
});

triggers.fetchVehicle = (payload) => ({
  type: types.FETCH_CURRENT_VEHICLE_REQUEST,
  payload
});

triggers.updateVehicle = (payload, cb) => ({
  type: types.UPDATE_CURRENT_VEHICLE_REQUEST,
  payload,
  cb
});

triggers.fetchVehicles = (payload) => ({
  type: types.FETCH_VEHICLE_LIST_REQUEST,
  payload
});

triggers.removeVehicle = (payload) => ({
  type: types.REMOVE_VEHICLE,
  payload
});

triggers.changeStatusVehicle = (payload) => ({
  type: types.CHANGE_STATUS_VEHICLE,
  payload
});


const putCurrentVehicle = (payload) => ({
  type: types.FETCH_CURRENT_VEHICLE_EMITTER,
  payload
});

const putVehicles = (payload) => ({
  type: types.FETCH_VEHICLE_LIST_EMITTER,
  payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* createTechnique({ type, payload, cb }) {
  yield put(addPreloader(type));
  try {
    yield call(techniques.create, payload);
    yield call(noty, 'success', t('techniques/create/success'));
    if (cb) {
      yield call(cb);
    }
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* currentTechnique({ type, payload }) {
  yield put(addPreloader(type));
  try {
    const { data } = yield call(techniques.current, payload);
    yield put(putCurrentVehicle(data));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* fetchVehicles({ type, payload }) {
  yield put(addPreloader(type));
  if ('boughtInFrom' in payload) {
    payload.boughtInFrom = Math.fround(moment(payload.boughtInFrom).valueOf() / 1000);
  }
  if ('boughtInTo' in payload) {
    payload.boughtInTo = Math.fround(moment(payload.boughtInTo).valueOf() / 1000);
  }
  try {
    const { data } = yield call(techniques.list, payload);
    yield put(putVehicles(data));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* updateTechnique({ type, payload, cb }) {
  yield put(addPreloader(type));
  try {
    yield call(techniques.update, payload);
    yield call(noty, 'success', t('techniques/update/success'));
    yield call(cb);
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* removeVehicle({ type, payload }) {
  const { id, query } = payload;
  yield put(addPreloader(`${type}${id}`));
  console.log(`${type}/${id}`, '##')
  try {
    yield call(techniques.remove, { id });
    yield call(fetchVehicles, { type, payload: query });
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(`${type}${id}`));
  }
}

export function* changeStatusVehicle({ type, payload }) {
  const { id, query } = payload;
  yield put(addPreloader(`${type}${id}`));
  console.log(`${type}/${id}`, '##')
  try {
    yield call(techniques.changeStatus, { id });
    yield call(fetchVehicles, { type, payload: query });
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(`${type}${id}`));
  }
}

//-----------------------------------------
//	Root dealer saga
//-----------------------------------------

export function* saga() {
  yield takeEvery(types.CHANGE_STATUS_VEHICLE, changeStatusVehicle);
  yield takeEvery(types.REMOVE_VEHICLE, removeVehicle);
  yield takeEvery(types.FETCH_VEHICLE_LIST_REQUEST, fetchVehicles);
  yield takeEvery(types.UPDATE_CURRENT_VEHICLE_REQUEST, updateTechnique);
  yield takeEvery(types.CREATE_TECHNIQUE_REQUEST, createTechnique);
  yield takeEvery(types.FETCH_CURRENT_VEHICLE_REQUEST, currentTechnique);
}
