//-----------------------------------------
//	Country
//-----------------------------------------

export const CREATE_LOCATION = '@/locations/CREATE_LOCATION';

export const FETCH_CURRENT_LOCATION = '@/locations/FETCH_CURRENT_LOCATION';
export const EDIT_LOCATION = '@/locations/EDIT_LOCATION';
export const SET_CURRENT_LOCATION = '@/locations/SET_CURRENT_LOCATION';

export const FETCH_LOCATION_LIST = '@/locations/FETCH_LOCATION_LIST';
export const SET_LOCATION_LIST = '@/locations/SET_LOCATION_LIST';

export const CHENGE_LOCATION_STATUS_FETCH = '@/locations/CHENGE_LOCATION_STATUS_FETCH';
export const CHENGE_LOCATION_STATUS_SET = '@/locations/CHENGE_LOCATION_STATUS_SET';

export const FETCH_ACTIVE_LOCATION_LIST = '@/locations/FETCH_ACTIVE_LOCATION_LIST';
export const SET_ACTIVE_LOCATION_LIST = '@/locations/SET_ACTIVE_LOCATION_LIST';

export const FETCH_ACTIVE_COUNTRY_LIST = '@/locations/FETCH_ACTIVE_COUNTRY_LIST';
export const SET_ACTIVE_COUNTRY_LIST = '@/locations/SET_ACTIVE_COUNTRY_LIST';

export const FETCH_ACTIVE_REGION_BY_COUNTRY_LIST = '@/locations/FETCH_ACTIVE_REGION_BY_COUNTRY_LIST';
export const SET_ACTIVE_REGION_BY_COUNTRY_LIST = '@/locations/SET_ACTIVE_REGION_BY_COUNTRY_LIST';

export const FETCH_ACTIVE_REGION_LIST = '@/locations/FETCH_ACTIVE_REGION_LIST';
export const SET_ACTIVE_REGION_LIST = '@/locations/SET_ACTIVE_REGION_LIST';

export const FETCH_ACTIVE_DISTRICT_LIST = '@/locations/FETCH_ACTIVE_DISTRICT_LIST';
export const SET_ACTIVE_DISTRICT_LIST = '@/locations/SET_ACTIVE_DISTRICT_LIST';

export const FETCH_ACTIVE_DISTRICT_BY_REGIONS_LIST = '@/location/FETCH_ACTIVE_DISTRICT_BY_REGIONS_LIST';
export const SET_ACTIVE_DESTRICT_BY_REGIONS_LIST = '@/location/SET_ACTIVE_DESTRICT_BY_REGIONS_LIST';
