import * as types from './types';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { employeesApi } from '@/services/api';
import { uploadPhoto } from '../create/actions';
import t from '@/utilities/translate';
import { noty } from "@/utilities/notifications";
import { history } from '@/redux/history';

const redirectName = '/employees';

/**
 * TRIGGERS
 */

export const triggers = {
    userInfo: (id) => ({
        type: types.FETCH_USER_INFO,
        id
    }),
    userEdit: (id, payload) => ({
        type: types.USER_EDIT,
        id,
        payload
    }),
    userChangeLoginAndPassword: (id, login, password) => ({
        type: types.USER_CHANGE_LOGIN_AND_PASS,
        id,
        payload: {
            login, password
        }
    })
}

export const employee = {
    info: (id) => ({
        type: types.FETCH_USER_INFO,
        id
    }),
    edit: (id, payload) => ({
        type: types.USER_EDIT,
        id,
        payload
    }),
    changeLoginAndPassword: (id, login, password) => ({
        type: types.USER_CHANGE_LOGIN_AND_PASS,
        id,
        payload: {
            login, password
        }
    })
}

/**
 * ACTIONS
 */

export function* info({ type, id }) {

    yield put(addPreloader(type));
    try {
        const response = yield call(employeesApi.userInfo, id);
        yield put({
            type: types.SET_USER_INFO,
            payload: response.data
        })

    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* changeLoginAndPassword({ type, payload, id }) {

    try {
        yield call(employeesApi.changeLoginAndPassword, id, payload);
        yield call(noty, 'success', t('employees/action/userChangeLoginAndPassword/success'));
    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* edit({ type, id, payload }) {
    yield put(addPreloader(type));
    try {

        yield call(employeesApi.edit, id, payload);

        if (payload.photo)
            yield call(uploadPhoto, { payload: { id, photo: payload.photo } });

        yield call(history.push, redirectName);
        yield call(noty, 'success', t('employees/action/update_user/success'));

    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* saga() {
    yield all([
        takeEvery(types.FETCH_USER_INFO, info),
        takeEvery(types.USER_EDIT, edit),
        takeEvery(types.USER_CHANGE_LOGIN_AND_PASS, changeLoginAndPassword)
    ]);
}

