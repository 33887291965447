import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
// import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { coverage_goals_v2 } from '@/services/api';
// import t from '@/utilities/translate';
// import { noty } from "@/utilities/notifications";
// import { getRole } from '@/utilities/role';
// import { history } from '@/redux/history';

//-----------------------------------------
//	Triggers
//-----------------------------------------

const _triggers = {};

_triggers.coverageGoals_v2 = (query)=>({
    type: types.FETCH_COVERAGE_GOALS_V2,
    query
})

_triggers.setCoverageGoals_v2 = (id, query)=>({
    type: types.SET_COVERAGE_GOAL_V2,
    id,
    query
})

export const triggers = _triggers;


//-----------------------------------------
//	Actions
//-----------------------------------------

export function* coverageGoals_v2({ type, query }) {

    try {
        const resp = yield call(coverage_goals_v2.coverageGoals, query);
        yield put({
            type: types.SET_COVERAGE_GOALS_V2,
            payload: resp.data
        })
    } catch(e) {
        yield call(console.warn, e);
    }

}

export function* setCoverageGoals_v2({ type, id, query }) {

    try {
        yield call(coverage_goals_v2.setCoverageGoals, id, query);
    } catch(e) {
        yield call(console.warn, e);
    }

}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.SET_COVERAGE_GOAL_V2, setCoverageGoals_v2);
    yield takeEvery(types.FETCH_COVERAGE_GOALS_V2, coverageGoals_v2);
}
