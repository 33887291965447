import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';
import { apiNumberFormatter } from '../../utilities/formatter';
import { pick } from 'lodash/fp';

const initialState = new Map({
  current: {},
  meta: [],
  listOfDealers: {},
  activeList: [],
  listOfActiveDealers: [],
});

export default createReducer(initialState, {
  [types.SET_CURRENT_DEALER]: (state, { payload }) => {
    const _payload = apiNumberFormatter(pick(['phone'])(payload));
    return state.set('current', Object.assign(payload, _payload));
  },
  [types.SET_DEALER_LIST]: (state, { payload }) => {
    payload.data = apiNumberFormatter(payload.data)
    return state.set('listOfDealers', payload)
  },
  [types.SET_DEALER_ACTIVE_LIST]: (state, { payload }) => state.set('activeList', payload),
  [types.SET_LIST_OF_ACTIVE_DEALERS]: (state, { payload }) => state.set('listOfActiveDealers', payload),
  [types.SET_CURRENT_DEALER_IN_TABLE]: (state, { payload }) => state.update('listOfDealers', (obj) => {
    obj.data = obj.data.map((dealer) => (payload.id === dealer.id ? payload : dealer));
    return obj;
  }),
});
