import React from 'react';
import { Row, Col } from 'antd';
import { Field } from 'redux-form';

export default ({
    inModal,
    wSpan = {},
    iSpan = {},
    aSpan = {},
    colSpan = 8,
    colLgSpan = null,
    afterSpan = 10,
    fullWidth,
    rowSpan = {},
    ...props
}) => {
    return <Col lg={props.span || 24} xs={24} {...wSpan}>
        <Row type="flex" align="bottom" {...rowSpan}>
            <Col lg={fullWidth ? 24 : colSpan} xs={24} {...iSpan}>
                <Field
                    {...props}
                />
            </Col>
            <Col lg={afterSpan || colSpan} style={{ transform: 'translate(11px, -13px)' }} xs={24} {...aSpan}>
                {props.afterField}
            </Col>
        </Row>
    </Col>
}