import React from 'react'
import { Layout } from 'antd'
const { Footer } = Layout;

class LayoutFooter extends React.Component {
    render() {
        return (
            <Footer style={{textAlign: 'center'}}>John Deere © {new Date().getFullYear()}</Footer>
        )
    }
}

export default LayoutFooter
