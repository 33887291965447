import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { countryManager } from '@/services/api';
import { addPreloader, removePreloader } from '../common/preloaders';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.currentCountryManager = (id) => ({
  type: types.FETCH_CURRENT_COUNTRY_MANAGER,
  id
});

//-----------------------------------------
//	Action Creators
//-----------------------------------------

const putCurrentCountryManager = (payload) => ({
  type: types.PUT_CURRENT_COUNTRY_MANAGER,
  payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* currentCountryManager({ type, id }) {
  yield put(addPreloader(type));
  try {
    const resp = yield call(countryManager.current, id);
    yield put(putCurrentCountryManager(resp.data));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}


//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
  yield takeEvery(types.FETCH_CURRENT_COUNTRY_MANAGER, currentCountryManager);
}
