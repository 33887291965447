import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { orderScheduledHistory } from '@/services/api';

//-----------------------------------------
//    Dealer triggers
//-----------------------------------------

export const triggers = {
    fetchHistoryListByDealer: (query) => ({
        type: types.FETCH_HISTORY_LIST_BY_DEALER,
        query
    }),
    fetchHistoryListByTotal: (query) => ({
        type: types.FETCH_HISTORY_LIST_BY_TOTAL,
        query
    }),
    changeHistoryByDealer: (query) => ({
        type: types.CHANGE_HISTORY_BY_DEALER,
        query
    }),
	clearHistoryByTotal: () => ({
		type: types.CLEAR_HISTORY_BY_TOTAL
	}),
};

//-----------------------------------------
//	Dealer saga actions
//-----------------------------------------

export function* fetchHistoryListByDealer({ type, query }) {
    yield put(addPreloader(type));

    try {
        const resp = yield call(orderScheduledHistory.listByDealer, query);
        yield put({
            type: types.SET_HISTORY_LIST_BY_DEALER,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

export function* fetchHistoryListByTotal({ type, query }) {
    yield put(addPreloader(type));

    try {
        const resp = yield call(orderScheduledHistory.listByTotal, query);
        yield put({
            type: types.SET_HISTORY_LIST_BY_TOTAL,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

export function* changeHistoryByDealer ({ type, query }) {
    yield put(addPreloader(type));

    try {
        const resp = yield call(orderScheduledHistory.changeHistoryByDealer, query);
        yield put({
            type: types.SET_CHANGED_HISTORY_LIST_BY_DEALER,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}



//-----------------------------------------
//	Root dealer saga
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.FETCH_HISTORY_LIST_BY_DEALER, fetchHistoryListByDealer);
    yield takeEvery(types.FETCH_HISTORY_LIST_BY_TOTAL, fetchHistoryListByTotal);
    yield takeEvery(types.CHANGE_HISTORY_BY_DEALER, changeHistoryByDealer);
}
