export const FETCH_LIST = '@/service-codes/FETCH_LIST';
export const SAVE_LIST = '@/service-codes/SAVE_LIST';
export const UPDATE_CODE = '@/service-codes/UPDATE_CODE';
export const CHANGE_STATUS = '@/service-codes/CHANGE_STATUS';
export const CHANGE_ACTUAL = '@/service-codes/CHANGE_ACTUAL';
export const DELETE_DRAFTS = '@/service-codes/DELETE_DRAFTS';
export const GET_CODE_INFO = '@/service-codes/GET_CODE_INFO';
export const SAVE_CODE_INFO = '@/service-codes/SAVE_CODE_INFO';
export const CREATE_CODE = '@/service-codes/CREATE_CODE';
export const SET_FILTER = '@/service-codes/SET_FILTER';
export const FETCH_CODES_ARRAY = '@/service-codes/FETCH_CODES_ARRAY';
export const SET_CODES_ARRAY = '@/service-codes/SET_CODES_ARRAY';
