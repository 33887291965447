/* eslint-disable no-sequences */
import React from 'react';
import {
  Layout, Icon, Row, Col, Select,
} from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Record } from 'immutable';
import { Link } from 'react-router-dom';
import EmployeesCreate from '@/components/common/header/EmployeesCreate';
import ProductClassificationCreate from '@/components/common/header/ProductClassificationCreate';
import { triggers } from '@/redux/app/actions';
import t from '@/utilities/translate';
import { changeLanguage } from '@/redux/auth/actions';
import Access from '@/components/common/access/Access';
import { accessRoles } from "@/constants";

const { Header } = Layout;

class LayoutHeader extends React.Component {
    static propTypes = {
      data: PropTypes.instanceOf(Record),
      auth: PropTypes.shape({
        token: PropTypes.object,
      }),
    };

    state = {
      mobileMenuHidden: true,
    }

    toggleChange = () => {
      this.setState({ mobileMenuHidden: !this.state.mobileMenuHidden });
    }

    render() {
      const {
        auth,
        changeLanguage,
      } = this.props;
      const { language = 'en' } = localStorage;
      const { token } = auth;

      return (
        <Header style={{ background: '#fff', padding: '0 16px' }}>
          <Row type="flex" gutter={12} justify="end">

            {token ? (
              <Col>
                <Row gutter={24} type="flex">

                  <Col>
                    <Access access_role={[
                      accessRoles.SALES_MANAGER,
                      accessRoles.OUTLET_MANAGER,
                      accessRoles.TOP_MANAGER,
                      accessRoles.COMPANY,
                      accessRoles.COUNTRY_MANAGER
                    ]}
                    >
                      <ProductClassificationCreate />
                    </Access>
                  </Col>

                  <Col>
                    <Access access_role={[
                      accessRoles.TERRITORY_MANAGER,
                      accessRoles.OUTLET_MANAGER,
                      accessRoles.TOP_MANAGER,
                      accessRoles.COMPANY,
                      accessRoles.COUNTRY_MANAGER
                    ]}
                    >
                      <EmployeesCreate />
                    </Access>
                  </Col>

                  <Col>
                    <Select
                      defaultValue={language}
                      onChange={(val) => (
                        changeLanguage(val)
                      )}
                    >
                      <Select.Option value="ru">RU</Select.Option>
                      <Select.Option value="en">EN</Select.Option>
                        <Select.Option value="ua">UA</Select.Option>
                    </Select>
                  </Col>

                  <Col>
                    <Link to="/profile" className="simple-link" style={{ fontSize: 16 }}>
                      <Icon type="user" style={{ margin: '0 10px 0 0' }} />
                      {auth.token.name}
                    </Link>
                  </Col>

                  <Col>
                    <Link to="/logout" className="simple-link" style={{ fontSize: 16 }}>
                      <Icon type="logout" style={{ margin: '0 10px 0 0' }} />
                      {t('common/logout')}
                    </Link>
                  </Col>
                </Row>
              </Col>
            ) : null}

          </Row>
        </Header>
      );
    }
}

export default connect((state) => ({
  lang: state.app.get('lang'),
  data: state.headerDashBoard,
  auth: state.auth,
}),
Object.assign(
  triggers,
  { changeLanguage },
))(LayoutHeader);
