import * as types from './types';
import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";

const initialState = new Map({
    'info': {}
});

export default createReducer(initialState, {
    [types.SET_USER_INFO]: (state, action)=>{
        action.payload.status = String(action.payload.status);
        return state.set('info', action.payload);
    }
});
