import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	list: [],
	meta: [],
	salesMenByDealerId: [],
	salesMenByQuery: [],
});

export default createReducer(initialState, {
	[types.SET_EMPLOYEES_ALL]: (state, action) => state.set('user_list', action.payload),
	[types.SET_SALES_MEN_BY_DEALER_ID]: (state, { payload }) => state.set('salesMenByDealerId', payload),
	[types.SET_SALES_MEN_BY_QUERY]: (state, { payload }) => state.set('salesMenByQuery', payload),
});
