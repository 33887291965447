import { store } from '@/redux/store';
import { createReducer } from "@/redux/helpers";
import { call, put } from "redux-saga/effects";

export const SHOW = '@preloaders/SHOW';
export const HIDE = '@preloaders/HIDE';

const parseNamespacedName = (value) => {
  const isValid = new RegExp(/^@.+\/.+$/).test(value);
  if (isValid) {
	const arrValue = value.split('/');

	return {
	  name: arrValue[2],
	  namespace: arrValue[1].replace('@', '')
	};
  }
  return {
	name: value,
	namespace: 'UNDEFINED_NS'
  };
};

export const addPreloader = (namespacedName) => ({ type: SHOW, ...parseNamespacedName(namespacedName) });

export const removePreloader = (namespacedName) => ({ type: HIDE, ...parseNamespacedName(namespacedName) });

export const togglePreloader = (name, namespace, value) => {
  if (value === true) {
	addPreloader(name, namespace);
  } else if (value === false) {
	removePreloader(name, namespace);
  } else {
	const preloadersGroup = store.getState().preloaders[namespace];
	if (preloadersGroup && preloadersGroup.includes(name)) {
	  removePreloader(name, namespace);
	} else {
	  addPreloader(name, namespace);
	}
  }
};

export const getPreloadersGroup = (namespace) => store.getState().preloaders[namespace] || [];

export const getPreloader = (value) => {
  const { name, namespace } = parseNamespacedName(value);
  return getPreloadersGroup(namespace).includes(name);
};

export function* withPreloader (preloader, apiFn, apiPayload, errorCb) {
  errorCb = typeof errorCb === 'function' ? errorCb : (err) => {
	console.error(err);
  };
  try {
	yield put(addPreloader(preloader));
	const response = yield call(apiFn, apiPayload);
	yield put(removePreloader(preloader));
	return response.data;
  } catch (err) {
	errorCb(err);
  }
  yield put(removePreloader(preloader));
}

const preloadersInitialState = {};

export const preloaders = createReducer(preloadersInitialState, {
  [SHOW] (state, { name, namespace }) {
	if (!(namespace in state)) {
	  return { ...state, [namespace]: [name] };
	}
	if (!state[namespace].includes(name)) {
	  return { ...state, [namespace]: [...state[namespace], name] };
	}
	return state;
  },
  [HIDE] (state, { name, namespace }) {
	if (!(namespace in state)) return state;
	if (state[namespace].includes(name)) {
	  const i = state[namespace].indexOf(name);
	  return {
		...state,
		[namespace]: [
		  ...state[namespace].slice(0, i),
		  ...state[namespace].slice(i + 1)
		]
	  };
	}
	return state;
  }
});
