import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
  translate: {
    isStatic: true,
    'common/welcome': 'Welcome',
    'common/login': 'Enter Login',
    'common/password': 'Enter Password',
    'common/sign_in': 'Login',
  },
  roles: [],
  lang: 'en',
  location: [],
  fermCategories: {}
});

export const app = createReducer(initialState, {
  [types.SET_USER_LANG]: (state, action) => state.set('lang', action.payload),
  [types.SET_LOCATIONS]: (state, action) => state.set('location', action.payload),
  [types.SET_FERM_CATEGORIES]: (state, { payload }) => state.set('fermCategories', payload),
  [types.PUT_ROLES]: (state, { payload }) => state.set('roles', payload),
  [types.SET_TRANSLATE]: (state, action) => state
    .set('translate', action.payload)
    .set('lang', action.lang)
});
