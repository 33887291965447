import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
	activeListByRole: [],
	activeListByOutlets: []
});

export default createReducer(initialState, {
	[types.SET_ACTIVE_LIST_BY_ROLE]: (state, { payload }) => {
		return state.set('activeListByRole', payload)
	},
	[types.SET_SALES_BY_OUTLETS]: (state, { payload }) => {
		return state.set('activeListByOutlets', payload)
	},
	[types.CLEAR_SALES_BY_OUTLETS]: (state) => {
		return state.set('activeListByOutlets', [])
	}
});
