import * as types from './types';
import * as userProfileTypes from '../profile/types';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { employeesApi } from '@/services/api';
import t from '@/utilities/translate';
import { noty } from "@/utilities/notifications";
import { history } from '@/redux/history';

const redirectName = '/employees';

/**
 * TRIGGERS
 */

export const triggers = {
    create: (payload, isActive) => ({
        type: types._CREATE_NEW_USER,
        payload,
        isActive
    }),
    userSalesStatusChange: (id, status, from) => ({
        type: types.CHANGE_USER_STATUS,
        id,
        from,
        payload: {
            status
        },
    })
}

/**
 * ACTIONS
 */

export function* uploadPhoto({ type, payload }) {
    try {
        if (payload.photo && typeof payload.photo !== 'string')
            yield call(employeesApi.uploadUserPhoto, payload.id, payload.photo);
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* salesStatusChange({ payload, id, from }) {
    try {
        const response = yield call(employeesApi.activate, id, payload);

        if (from) {
            yield put({
                type: userProfileTypes.SET_USER_PROFILE,
                payload: response.data
            })
            yield call(noty, 'success', t('employees/actions/userSalesStatusChange/success'))
        }

    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* create({ type, payload, isActive }) {
    yield put(addPreloader(type));
    try {
        const response = yield call(employeesApi.create, payload);

        yield call(uploadPhoto, { payload: { id: response.data.id, photo: payload.photo } })
        yield call(noty, 'success', t('employees/action/create_user/success'));
        yield call(history.push, redirectName);

    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* saga() {
    yield all([
        takeEvery(types.UPLOAD_USER_PHOTO, uploadPhoto),
        takeEvery(types._CREATE_NEW_USER, create),
        takeEvery(types.CHANGE_USER_STATUS, salesStatusChange),
    ]);
}

