import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
    platform_list_for_eq: [],
    platform_list_by_manufacture: [],
    eq_list: [],
    list: [],
    current_platform: {},
    current_size_class: {},
    size_class_list_by_eq: [],
    eq_list_by_platform: [],
    size_class_list: []
    // current_manufacture: {}
});

export default createReducer(initialState, {
    [types.SET_PLATFROM_LIST_FOR_EQ]: (state, { payload }) => state.set('platform_list_for_eq', payload),
    [types.SET_PLATFORM_AND_EQ_LIST]: (state, { payload }) => state.set('list', payload),
    [types.SET_CURRENT_PLATFORM_OR_EQ]: (state, { payload }) => state.set('current_platform', payload),
    [types.SET_EQUIPMENT_GROUP_LIST]: (state, { payload }) => state.set('eq_list', payload),
    [types.SET_EQ_BY_PLATFROM_ID]: (state, { payload })=>state.set('eq_list_by_platform', payload),
    [types.SET_PLATFORM_LIST_BY_MANUFACTURE]: (state, { payload })=>state.set('platform_list_by_manufacture', payload),
});
