export const sizeParametersForward = (location, props, routes) => {
  switch (location) {
	case routes.sizeParametersCreate.link():
	  return routes.sizeParametersList.link();

	case routes.sizeParametersEdit.link(props.id):
	  return routes.sizeParametersList.link();
	default:
	  break;
  }
};
