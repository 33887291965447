import { all, call, put, takeEvery, } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { employeesApi } from '@/services/api';
import { noty } from '@/utilities/notifications';
import t from '@/utilities/translate';

/**
 * TRIGGERS
 */

export const triggers = {
	userProfile: (id) => ({
		type: types.FETCH_USER_PROFILE,
		id,
	}),
	userDeals: (query, id) => ({
		type: types.FETCH_USER_DEALS,
		query,
		id,
	}),
	sendIosLink: (id) => ({
		type: types.SEND_IOS_LINK,
		id,
	}),
	changeDealsManager: (payload) => ({
		type: types.CHANGE_DEALS_MANAGER,
		payload
	}),
};

const putUserDeals = (payload) => ({
	type: types.SET_USER_DEALS,
	payload,
});

/**
 * ACTIONS
 */

export function* userProfile({ type, id }) {
	yield put(addPreloader(type));
	try {
		const response = yield call(employeesApi.userInfo, id);
		yield put({
			type: types.SET_USER_PROFILE,
			payload: response.data,
		});
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* userDeals({ type, id, query }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(id ? employeesApi.outDeals : employeesApi.delas, { id, query });
		yield put(putUserDeals(data));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* sendIosLink({ type, id }) {
	yield put(addPreloader(type));

	try {
		yield call(employeesApi.sendIosLink, id);
		yield call(noty, 'success', t('employees/sendIosLink/success'));
	} catch (e) {
		yield call(console.log, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* changeDealsManager({ type, payload }) {
	yield put(addPreloader(type));

	try {
		const { data } = yield call(employeesApi.changeManager, payload);
		yield put(putUserDeals(data));
		yield call(noty, 'success', t('employees/changeDealsManager/success'));
	} catch (e) {
		yield call(console.log, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* saga() {
	yield all([
		takeEvery(types.FETCH_USER_DEALS, userDeals),
		takeEvery(types.FETCH_USER_PROFILE, userProfile),
		takeEvery(types.SEND_IOS_LINK, sendIosLink),
		takeEvery(types.CHANGE_DEALS_MANAGER, changeDealsManager),
	]);
}
