import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { triggers } from '@/redux/potential-sales/actions';
import Form from './Form';
import { fy_type } from '@/constants';
import t from '@/utilities/translate';

const ChangeFiscalYear = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		id,
		items,
		defaultValue,
		editPotentialSale
	} = props;

	const closeModal = ({ fiscal_year }) => {
		editPotentialSale({
			id,
			items,
			fiscal_year
		});
		closeModalFn();
	};

	const availableOptions = Object.keys(fy_type).filter((key) => key !== 'total').map((type) => ({
		label: t(fy_type[type]),
		value: type
	}));

	return (
		<Modal
			{...modalProps}
			title={`${t('potential-sales/change-fiscal-year/title')}:`}
			footer={null}
		>
			<Form
				availableOptions={availableOptions}
				initialValues={{ fiscal_year: defaultValue }}
				onSubmit={closeModal}
			/>
		</Modal>
	);
};

const mapDispatchToProps = {
	...triggers,
};

export default connect(null, mapDispatchToProps)(ChangeFiscalYear);
