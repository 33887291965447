import React from 'react'
import {Layout} from 'antd'
import PropTypes from "prop-types";

const {Content} = Layout;
const ContentStyle = {
    margin: 'auto',
    padding: '24px 40px',
    background: '#fff',
    flexGrow: 0,
    width: 400,
    maxWidth: '90%'
};

class EmptyLayout extends React.Component {
    static propTypes = {
        children: PropTypes.element
    };

    render() {
        return (
            <Layout style={{minHeight: '100vh'}}>
                <Content style={ContentStyle}>
                    {this.props.children}
                </Content>
            </Layout>
        )
    }
}


export default EmptyLayout
