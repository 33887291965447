import React from 'react';
import { compose } from 'recompose';
import { withRouter } from "react-router";
import { Switch } from 'react-router-dom';
import RouteConfig from '@/routing/route-tree';
import { withIntl } from '@/utilities/translate';

const buildRoutes = (config, result = []) => {
  if (!config) {
    return false;
  }
  Object.keys(config).forEach((key) => {
    const { layout: Layout, nested: nestedConfig, ...rest } = config[key];

    result.push(<Layout {...rest} key={rest.path} routesConfig={config} />);
    buildRoutes(nestedConfig, result);
  });

  return result;
};

class Router extends React.Component {
    prevPath = null;

    // shouldComponentUpdate(nextProps) {
    //   if (nextProps.history.location.pathname !== this.prevPath || nextProps.lang !== this.props.lang || Object.keys(this.props.translate).length !== Object.keys(nextProps.translate).length) {
    //     this.prevPath = nextProps.history.location.pathname;
    //     return true;
    //   }
    //   return false;
    // }

    render() {
      return <Switch>{buildRoutes(RouteConfig)}</Switch>;
    }
}

const wrapper = compose(
  withRouter
);

export default wrapper(withIntl(Router));
