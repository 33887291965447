import * as types from "./types";
import { addPreloader, removePreloader } from "../common/preloaders";
import { call, put, takeEvery } from "redux-saga/effects";
import { noty } from "@/utilities/notifications";
import T from "@/utilities/translate";
import { baseMachineryDescription } from "@/services/api";

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.createBaseMachineryDescription = (query) => ({
  type: types.CREATE_BASE_MACHINERY_DESCRIPTION,
  query,
});

triggers.editBaseMachineryDescription = (id, query) => ({
  type: types.EDIT_BASE_MACHINERY_DESCRIPTION,
  id,
  query,
});

triggers.currentBaseMachineryDescription = (id) => ({
  type: types.CURRENT_BASE_MACHINERY_DESCRIPTION,
  id,
});

triggers.fetchBaseMachineryDescriptionActiveList = () => ({
  type: types.FETCH_BASE_MACHINERY_DESCRIPTION_ACTIVE_LIST,
});

triggers.fetchBaseMachineryDescriptionListByModelDescription = (
  payload
) => ({
  type: types.FETCH_BASE_MACHINERY_DESCRIPTION_LIST_BY_MODEL_DESCRIPTION,
  payload,
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* createBaseMachineryDescription({ type, query }) {
  yield put(addPreloader(type));
  try {
    yield call(baseMachineryDescription.create, query);
    yield call(noty, "success", T("baseMachineryDescription/create/success"));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* editBaseMachineryDescription({ type, id, query }) {
  yield put(addPreloader(type));
  try {
    yield call(baseMachineryDescription.edit, id, query);
    yield call(noty, "success", T("baseMachineryDescription/edit/success"));
    yield call(window._forward, { id });
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* currentBaseMachineryDescription({ type, id }) {
  yield put(addPreloader(type));

  try {
    const resp = yield call(baseMachineryDescription.current, id);
    yield put({
      type: types.PUT_BASE_MACHINERY_DESCRIPTION,
      payload: resp.data,
    });
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* changeStatusBaseMachineryDescription({ type, id }) {
  try {
    yield call(baseMachineryDescription.changeStatus, id);
    yield call(
      noty,
      "success",
      T("baseMachineryDescription/changeStatus/success")
    );
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* fetchBaseMachineryDescriptionActiveList() {
  try {
    const resp = yield call(baseMachineryDescription.activeList);
    yield put({
      type: types.PUT_BASE_MACHINERY_DESCRIPTION_ACTIVE_LIST,
      payload: resp.data,
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* fetchBaseMachineryDescriptionListByModelDescription({
  type,
  payload,
}) {
  try {
    const resp = yield call(
      baseMachineryDescription.activeListByModelDescription,
      payload
    );
    yield put({
      type: types.PUT_BASE_MACHINERY_DESCRIPTION_LIST_BY_MODEL_DESCRIPTION,
      payload: resp.data,
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
  yield takeEvery(
    types.FETCH_BASE_MACHINERY_DESCRIPTION_LIST_BY_MODEL_DESCRIPTION,
    fetchBaseMachineryDescriptionListByModelDescription
  );
  yield takeEvery(
    types.FETCH_BASE_MACHINERY_DESCRIPTION_ACTIVE_LIST,
    fetchBaseMachineryDescriptionActiveList
  );
  yield takeEvery(
    types.CHANGE_STATUS_BASE_MACHINERY_DESCRIPTION,
    changeStatusBaseMachineryDescription
  );
  yield takeEvery(
    types.CURRENT_BASE_MACHINERY_DESCRIPTION,
    currentBaseMachineryDescription
  );
  yield takeEvery(
    types.CREATE_BASE_MACHINERY_DESCRIPTION,
    createBaseMachineryDescription
  );
  yield takeEvery(
    types.EDIT_BASE_MACHINERY_DESCRIPTION,
    editBaseMachineryDescription
  );
}
