import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { noty } from '@/utilities/notifications';
import { sizeParameters } from '@/services/api';
import t from '@/utilities/translate';

// ----------------------------------------------------------------------
// Triggers
// ----------------------------------------------------------------------

export const triggers = {};

triggers.create = (query) => ({
	type: types.CREATE,
	query
});

triggers.fetchList = (query) => ({
	type: types.FETCH_LIST,
	query
});

triggers.fetchItem = (id) => ({
	type: types.FETCH_ITEM,
	id
});

triggers.saveItem = (query) => ({
	type: types.SAVE_ITEM,
	query
});

triggers.changeItemStatus = (id) => ({
	type: types.CHANGE_ITEM_STATUS,
	id
});

triggers.fetchSizeParametrList = ()=>({
    type: types.FETCH_SIZE_PARAMETR_LIST,
})

// ----------------------------------------------------------------------
// CRUD
// ----------------------------------------------------------------------

// CREATE

function * create ({ type, query }) {
	yield put(addPreloader(type));
	try {
		const resp = yield call(sizeParameters.create, query);
		if (resp.status === 201) {
			yield call(noty, 'success', t('sizeParameters/create/success'));
			yield call(window._forward);
		} else {
			console.warn(resp);
		}
	} catch (e) {
		console.warn(e);
	} finally {
		yield put(removePreloader(type));
	}
}

// READ

function * fetchList ({ type, query }) {
	yield put(addPreloader(type));
	try {
		const resp = yield call(sizeParameters.list, query);
		yield put({
			type: types.SET_LIST,
			payload: resp.data
		});
	} catch (e) {
		console.warn(e);
	} finally {
		yield put(removePreloader(type));
	}
}

function * fetchItem ({ type, id }) {
	yield put(addPreloader(type));
	try {
		const resp = yield call(sizeParameters.item, id);
		yield put({
			type: types.SET_ITEM,
			payload: resp.data
		});
	} catch (e) {
		console.warn(e);
	} finally {
		yield put(removePreloader(type));
	}
}

// UPDATE

function * saveItem ({ type, query }) {
	yield put(addPreloader(type));
	try {
		const { id } = query;
		const resp = yield call(sizeParameters.saveItem, id, query);
		if (resp.status === 200) {
			yield call(noty, 'success', t('sizeParameters/edit/success'));
			yield call(window._forward, { id });
		} else {
			console.warn(resp);
		}
	} catch (e) {
		console.warn(e);
	} finally {
		yield put(removePreloader(type));
	}
}

function * changeItemStatus ({ id }) {
	try {
		const resp = yield call(sizeParameters.changeStatus, id);
		yield put({
			type: types.SET_ITEM_STATUS,
			payload: resp.data
		});
		yield call(noty, 'success', t('common/changeStatus/success'));
	} catch (e) {
		console.warn(e);
	}
}

function * fetchSizeParametrList ({ id }) {
	try {
		const resp = yield call(sizeParameters.activeList);
		yield put({
			type: types.PUT_SIZE_PARAMETR_LIST,
			payload: resp.data
		});
	} catch (e) {
		console.warn(e);
	}
}

// ----------------------------------------------------------------------
// Root saga
// ----------------------------------------------------------------------

export function * saga () {
    yield takeEvery(types.FETCH_SIZE_PARAMETR_LIST, fetchSizeParametrList);
	yield takeEvery(types.CREATE, create);
	yield takeEvery(types.FETCH_LIST, fetchList);
	yield takeEvery(types.FETCH_ITEM, fetchItem);
	yield takeEvery(types.SAVE_ITEM, saveItem);
	yield takeEvery(types.CHANGE_ITEM_STATUS, changeItemStatus);
}
