import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Input, Form, Checkbox } from 'antd';
import t from '@/utilities/translate';
import { triggers } from '@/redux/lost-to-companies/actions';
import DispatchOnMount from '@/components/common/DispatchOnMount';

const CreateCompany = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		createCompany,
		isEdit,
		changeCompany,
		getCompany,
		companyId,
		data,
	} = props;
	const [name, setName] = useState('');
	const [status, setStatus] = useState(false);
	const [actualSales, setActualSales] = useState(false);
	const [taxCode, setTaxCode] = useState('');

	const closeModal = () => {
		if (isEdit) {
			changeCompany({
				id: companyId,
				name,
				tax_code: taxCode,
				status: Number(status),
				actual_sales: Number(actualSales)
			});
		} else {
			createCompany({ name, tax_code: taxCode, status: Number(status), actual_sales: Number(actualSales) });
		}
		closeModalFn();
	};

	useEffect(() => {
		if (isEdit && data.name) {
			setName(data.name);
			setStatus(!!data.status);
			setActualSales(!!data.actual_sales);
			setTaxCode(data.tax_code);
		}
	}, [data.name, data.status, data.tax_code, data.actual_sales]);

	const handleNameChange = (event) => {
		setName(event.target.value);
	};

	const handleStatusChange = (event) => {
		setStatus(event.target.checked);
	};

	const handleActualSalesChange = (event) => {
		setActualSales(event.target.checked);
	};

	const handleTaxCodeChange = (event) => {
		setTaxCode(event.target.value);
	};

	return (
		<Modal {...modalProps}
			   okButtonProps={{ disabled: !name || !taxCode || (data.name === name && data.status === status && data.tax_code === taxCode && data.actual_sales === actualSales) }}
			   onOk={closeModal}
			   okText={isEdit ? t('lost-to-companies/edit/button') : t('lost-to-companies/create/button')}>
			<DispatchOnMount actionCreator={isEdit && companyId ? () => getCompany(companyId) : () => {
			}}>
				<Form>
					<Form.Item style={{ marginBottom: 0 }} label={t('lost-to-companies/name/label')}>
						<Input value={name} onChange={handleNameChange} style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item style={{ marginBottom: 0 }} label={t('lost-to-companies/tax-code/label')}>
						<Input value={taxCode} type={'number'} onChange={handleTaxCodeChange}
							   style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item style={{ marginBottom: 0 }}>
						<Checkbox checked={status} onChange={handleStatusChange}
								  style={{ width: '100%' }}>{t('lost-to-companies/status/label')}</Checkbox>
					</Form.Item>
					<Form.Item style={{ marginBottom: 0 }}>
						<Checkbox checked={actualSales} onChange={handleActualSalesChange}
								  style={{ width: '100%' }}>{t('lost-to-companies/actual-sales/label')}</Checkbox>
					</Form.Item>
				</Form>
			</DispatchOnMount>
		</Modal>
	);
};

CreateCompany.defaultProps = {
	modalProps: {},
};

CreateCompany.propTypes = {
	modalProps: PropTypes.any,
	closeModal: PropTypes.func.isRequired,
};


export default connect(
	(state) => ({
		data: state.lostToCompanies.get('current'),
	}),
	{
		...triggers,
	},
)(CreateCompany);
