import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import T from '@/utilities/translate';
import { compositeLevel } from '@/services/api';
import { noty } from "@/utilities/notifications";
import { addPreloader, removePreloader } from '../common/preloaders';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.createCompositeLevel = (query) => ({
    type: types.CREATE_COMPOSITE_LEVEL,
    query
})

triggers.editCompositeLevel = (id, query) => ({
    type: types.EDIT_COMPOSITE_LEVEL,
    id,
    query
})

triggers.currentCompositeLevel = (id) => ({
    type: types.CURRENT_COMPOSITE_LEVEL,
    id
})

triggers.changeStatusCompositeLevel = (id)=>({
    type: types.CHANGE_STATUS_COMPOSITE_LEVEL,
    id
})

triggers.fetchCompositeLevelListByEquipmentGroup = (equipment_group_id) => {
    return {
        type: types.FETCH_COMPOSITE_LEVEL_LIST_BY_EQUIPMENT_GROUP,
        equipment_group_id
    }
}

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* createCompositeLevel({ type, query }) {
    yield put(addPreloader(type));
    try {
        yield call(compositeLevel.create, query);
        yield call(noty, 'success', T('compositeLevel/create/success'));
        // yield call(window._forward);
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* editCompositeLevel({ type, id, query }) {
    yield put(addPreloader(type));
    try {
        yield call(compositeLevel.edit, id, query);
        yield call(noty, 'success', T('compositeLevel/edit/success'));
        yield call(window._forward, { id });
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* changeStatusCompositeLevel({ type, id }) {

    try {
        yield call(compositeLevel.changeStatus, id);
        yield call(noty, 'success', T('compositeLevel/changeStatus/success'));
    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* currentCompositeLevel({ type, id }) {

    yield put(addPreloader(type));

    try {
        const resp = yield call(compositeLevel.current, id);
        yield put({
            type: types.PUT_COMPOSITE_LEVEL,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* fetchCompositeLevelListByEquipmentGroup({ type, equipment_group_id }) {
    try {
        const resp = yield call(compositeLevel.activeListByEquipmentGroup, equipment_group_id);
        yield put({
            type: types.PUT_COMPOSITE_LEVEL_LIST_BY_EQUIPMENT_GROUP,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    }

}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {

    yield takeEvery(types.FETCH_COMPOSITE_LEVEL_LIST_BY_EQUIPMENT_GROUP, fetchCompositeLevelListByEquipmentGroup);
    yield takeEvery(types.CHANGE_STATUS_COMPOSITE_LEVEL, changeStatusCompositeLevel);
    yield takeEvery(types.CURRENT_COMPOSITE_LEVEL, currentCompositeLevel);
    yield takeEvery(types.CREATE_COMPOSITE_LEVEL, createCompositeLevel);
    yield takeEvery(types.EDIT_COMPOSITE_LEVEL, editCompositeLevel);
}

