import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';
import { pick } from 'lodash/fp';
import { apiNumberFormatter } from '../../utilities/formatter';
import { TABLES } from '@/constants';

const initialState = new Map({
	nextActionList: [],
	dealList: {},
	archivedDealList: {},
	resultsActiveList: [],
	actionsActiveList: [],
	statusesActiveList: [],
	availableManagers: [],
	availableClients: [],
	history: [],
	currentItem: [],
	lostReasonsList: [],
	dealAllList: {},
	dealAllFilter: {
		per_page: TABLES.PAGE_SIZE_OPTIONS_x4_INITIAL,
		order_type: 'asc',
		order_by: 'district_name',
	},
});

export default createReducer(initialState, {
	[types.SET_NEXT_ACTIONS_LIST]: (state, { payload }) => state.set('nextActionList', payload),
	[types.SET_DEALS_LIST]: (state, { payload }) => state.set('dealList', payload),
	[types.SET_ARCHIVED_DEALS]: (state, { payload }) => state.set('archivedDealList', payload),
	[types.SET_RESULTS_ACTIVE_LIST]: (state, { payload }) => state.set('resultsActiveList', payload),
	[types.SET_ACTIONS_ACTIVE_LIST]: (state, { payload }) => state.set('actionsActiveList', payload),
	[types.SET_STATUSES_ACTIVE_LIST]: (state, { payload }) => state.set('statusesActiveList', payload),
	[types.SET_CURRENT_DEAL]: (state, { payload }) => {
		const _payload = apiNumberFormatter(pick(['mobile_number', 'offered_price'])(payload))
		return state.set('current', Object.assign(payload, _payload))
	},
	[types.SET_DEAL_HISTORY]: (state, { payload }) => state.set('history', payload),
	[types.SET_AVAILABLE_MANAGER]: (state, { payload }) => state.set('availableManagers', payload),
	[types.SET_AVAILABLE_CLIENT]: (state, { payload }) => state.set('availableClients', payload),
	[types.SET_DEAL_ITEM]: (state, { payload }) => state.set('currentItem', payload),
	[types.SET_LOST_REASONS]: (state, { payload }) => state.set('lostReasonsList', payload),
	[types.SET_ALL_DEALS_LIST]: (state, { payload }) => state.set('dealAllList', payload),
	[types.SET_ALL_DEALS_FILTER]: (state, { payload }) => state.set('dealAllFilter', payload),
	[types.CLEAR_ALL_DEALS]: (state) => state.set('dealAllList', initialState.get('dealAllList')).set('dealAllFilter', initialState.get('dealAllFilter')),
});
