import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	list: [],
	salesPlansList: [],
	fyTypeList: [],
	defaultYear: undefined,
	salesPlansDefaultYear: undefined,
});

export default createReducer(initialState, {
	[types.SET_YEARS_LIST]: (state, { payload }) => state.set('list', payload).set('defaultYear', payload[payload.length - 1]),
	[types.SET_FISCAL_YEARS_LIST]: (state, { payload }) => state.set('fyTypeList', payload),
	[types.SET_SALES_PLANS_YEARS_LIST]: (state, { payload }) => state.set('salesPlansList', payload).set('salesPlansDefaultYear', payload[payload.length - 2]),
});
