import React from 'react';
import { Button, Col, Row } from 'antd';
import { reduxForm } from 'redux-form';
import t from '@/utilities/translate';
import FormSkeleton from '@/components/common/form/FormSkeleton';
import SelectField from '@/components/common/form/SelectWrapper';
import FormField from '@/components/common/form/DefaultFormWrapper';

const Form = ({
	  availableOptions,
	  handleSubmit,
	  pending,
	  pristine,
	  defaultValue
  }) => {

	return (
		<FormSkeleton onSubmit={handleSubmit} hideBottomBar={true}>
			<Row gutter={15} style={{ marginTop: 24 }}>
				<Col xs={24} style={{ marginBottom: 16 }}>
					<FormField component={SelectField}
							   colSpan={24}
							   afterSpan={0}
							   data={() => (availableOptions.map((item) => ({
								   value: Number(item.value),
								   label: item.label,
							   })))}
							   defaultValue={defaultValue}
							   name="fiscal_year"
							   label={t('deal-change-fiscal-year/result/label')}
							   placeholder={t('deal-change-fiscal-year/result/label')}/>
				</Col>
				<Col xs={24}>
					<Button type="primary"
							disabled={pristine}
							loading={pending}
							htmlType="submit"
							style={{ width: '100%' }}
					>
						{t('deal-change-fiscal-year/submit/button')}
					</Button>
				</Col>
			</Row>
		</FormSkeleton>
	)
};

export default reduxForm({
	form: 'ChangeFiscalYear',
})(Form);
