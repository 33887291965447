import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { equipmentOperationTimes } from '@/services/api';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchEquipmentOperationTimesList = (payload, cb) => ({
	type: types.FETCH_EQUIPMENT_OPERATION_TIMES_LIST,
	payload
});

triggers.setEquipmentOperationTime = (payload) => ({
	type: types.SET_EQUIPMENT_OPERATION_TIME,
	payload
})

export function* fetchEquipmentOperationTimesList({ type, payload }) {
	yield put(addPreloader(type));
	try {
		const resp = yield call(equipmentOperationTimes.list, payload);
		yield put({
			type: types.SET_EQUIPMENT_OPERATION_TIMES_LIST,
			payload: resp.data
		})
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}

}

export function* setEquipmentOperationTime({ payload }) {
	const { id, count } = payload;
	try {
		yield call(equipmentOperationTimes.update, id, { count });
	} catch (e) {
		yield call(console.warn, e);
	}
}

export function* saga() {
	yield takeEvery(types.FETCH_EQUIPMENT_OPERATION_TIMES_LIST, fetchEquipmentOperationTimesList);
	yield takeEvery(types.SET_EQUIPMENT_OPERATION_TIME, setEquipmentOperationTime);
}
