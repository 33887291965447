import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	current: {},
	filter: {},
	list: [],
	fullData: []
});

export default createReducer(initialState, {
	[types.SAVE_ALL_LIST_DATA]: (state, { payload }) => state.set('list', payload),
	[types.SAVE_FULL_DATA]: (state, { payload }) => state.set('fullData', payload),
	[types.SAVE_COMPANY_INFO]: (state, { payload }) => state.set('current', payload),
	[types.SET_FILTER]: (state, { payload }) => state.set('filter', payload)
});
