import { Map } from 'immutable';
import { createReducer } from "@/redux/helpers";
import { call, put, takeEvery } from 'redux-saga/effects';
import * as api from '@/services/api';
// import * as types from './types';
// import t from '@/utilities/translate';
// import { addPreloader, removePreloader } from '@/redux/common/preloaders';
// import { job } from '@/services/api';
// import { noty } from "@/utilities/notifications";

const SET_COMPLATE_LIST = '@/comaplate/SET_COMPLATE_LIST';
const FETCH_COMPLATE_LIST = '@/comaplate/FETCH_COMPLATE_LIST';

const initialState = new Map({});

export const complate = {};

complate.list = (payload) => ({
    type: FETCH_COMPLATE_LIST,
    ...payload
});

complate.set = (payload) => ({
    type: SET_COMPLATE_LIST,
    ...payload
});

export default createReducer(initialState, {
    [SET_COMPLATE_LIST]: (state, { payload, context }) => (
        state.set(context, payload)
        ),
});

function* fetchList({ type, context, query, ...rest }) {
    try {
        const { data } = yield call(api.complate[context], { query, ...rest });
        yield put(complate.set({ payload: data, context }));
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* saga() {
    yield takeEvery(FETCH_COMPLATE_LIST, fetchList);
}
