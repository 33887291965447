import React from 'react';
import { branch, compose, renderComponent } from 'recompose';
import { connect } from 'react-redux';

import { store } from '@/redux/store';

const Spiner = () => (
  <div className="progress-bar">
    <span className="bar">
      <span className="progress" />
    </span>
  </div>
);

export default (key) => {
  const tr = store.getState().app.get('translate');
  return tr[key] || key;
};

const mapStateToProps = (state) => ({
  intl: state.app.get('translate')
});

export const withIntl = (Component) => {
  const haveFullTranslate = ({ intl }) => {
    const length = Object.keys(intl).length;
    return !intl.isStatic && length < 20;
  }

  const wrapper = compose(
    connect(
      mapStateToProps
    ),
    branch(
      haveFullTranslate,
      renderComponent(Spiner)
    ),
  );

  return wrapper(Component);
};
