export const platformForward = (location, props, routes) => {
  switch (location) {
	case routes['platform/create'].link():
	  return routes['platform'].link();

	case routes['platform/edit'].link(props.id):
	  return routes['platform'].link();
	default:
	  break;
  }
};
