import { store } from '@/redux/store';

export const getRole = ()=>store.getState().auth.token
? store.getState().auth.token.role_id
: 5;

export const getSecurityLevel = ()=>store.getState().auth.token
? store.getState().auth.token.security_level
: null;

export const role = (cb) => (access_role) => {
    const state = store.getState();
    let _state = false;
    if (state.auth.token && state.auth.token.role_id && access_role.includes(state.auth.token.role_id))
        _state = true;
    else
        _state = false;

    return cb(_state);
}

export const replaceByRole = (objectWithAccessReplace) => (arrFields) => {

    const roleId = store.getState().auth.token
        ? store.getState().auth.token.role_id
        : 5;

    return objectWithAccessReplace[String(roleId)]
        ? arrFields.filter(field => !objectWithAccessReplace[String(roleId)].includes(field.name))
        : arrFields;

}
