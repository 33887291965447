export const CREATE_FARM_SIZE = '@/farm-size/CREATE_FARM_SIZE';
export const EDIT_FARM_SIZE = '@/farm-size/EDIT_FARM_SIZE';

export const CURRENT_FARM_SIZE = '@/farm-size/CURRENT_FARM_SIZE';
export const PUT_FARM_SIZE = '@/farm-size/PUT_FARM_SIZE';

export const PUT_FARM_SIZE_LIST = '@/farm-size/PUT_FARM_SIZE_LIST';
export const FETCH_FARM_SIZE_LIST = '@/farm-size/FETCH_FARM_SIZE_LIST';

export const CHANGE_STATUS_FARM_SIZE = '@/farm-size/CHANGE_STATUS_FARM_SIZE';
export const UPDATE_FARM_SIZE_LIST = '@/farm-size/UPDATE_FARM_SIZE_LIST';