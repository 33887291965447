import * as React from 'react';
import { connect } from 'react-redux';
import { AutoComplete, Form } from 'antd';
import PropTypes from 'prop-types';
import Access from '@/components/common/access/Access';
import { Clarification } from './Clarification';

const { Option } = AutoComplete;

// ----------------------------------------------------------------------
// Select Component
// ----------------------------------------------------------------------

class SelectWrapper extends React.PureComponent {
    static propTypes = {
      input: PropTypes.object,
      meta: PropTypes.object,
      label: PropTypes.string,
      defaultValue: PropTypes.any
    };

    state = {
      $key: 'autoComplateKey',
      value: this.props.input.value
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (!!this.props.input.value) {
        const selectValueDoChange = nextProps.input.value && this.props.input.value !== nextProps.input.value;
        const callOwnOnChange = selectValueDoChange && this.props.ownOnChange;
        this.setState({ ...nextProps.input.value });
        callOwnOnChange && this.props.ownOnChange(nextProps.input.value, 1);

        if (!nextProps.input.value && this.props.input.value) {
          this.setState({ $key: String(Math.random()) });
        }
      }
    }

    UNSAFE_componentWillMount() {
      this.props.defaultValue && this.props.input.onChange(this.props.defaultValue);
    }

    render() {
      const {
        input,
        meta,
        label,
        clarification,
        defaultValue,
        options,
        handler,
        access_role = undefined,
        _validate = null,
        ownOnChange,
        onChange,
        disabledReason = false,
        ...attributes
      } = this.props;

      const formItemProps = {
        label: label ? (_validate ? (
          <span>
            {label}
            <span className="f-danger">*</span>
          </span>
        ) : label) : null,
        validateStatus: meta.touched && !meta.valid ? "error" : undefined,
        help: meta.touched && meta.error ? meta.error : null,
      };

      const Component = (
        <Form.Item {...formItemProps} style={{ margin: '0 0 10px' }}>
          <Clarification text={clarification} />
          <AutoComplete
            key={this.state.$key}
            value={input.value || defaultValue}
            dataSource={options.map((opt) => {
              const val = String(this.state.value).replace(/^(\s)+/gi, '').replace(/\\/g, '\\\\');
              const regExp = new RegExp(`${val}`, 'gi');
              const key = Array.from(opt.label.matchAll(regExp));
              const strHTML = opt.label.replace(key[0], `<span class="find-in">${key[0]}</span>`);
              return (
                <Option
                  key={opt.value}
                  value={String(opt.value)}
                  text={opt.label}
                >
                  <div dangerouslySetInnerHTML={{ __html: strHTML }} />
                </Option>
              );
            })}
            disabled={disabledReason}
            onSelect={(value) => {
              this.setState({ value });
              onChange && onChange(value);
              input.onChange(value);
            }}
            style={{ width: '100%' }}
            onSearch={(value) => {
              input.value = /^\s/.test(value) ? value : `${value.replace(/^(\s)+/gi, '')}`;
              this.setState({ value: input.value });
              handler && handler(value);
            }}
            optionLabelProp="text"
            {...attributes}
          />
        </Form.Item>
      );

      return access_role ? (
        <Access
          access_role={access_role}
        >
          {Component}
        </Access>
      ) : Component;
    }
}

export default connect(
  (state, ownProps) => {
    const optionsData = ownProps.data ? {
      options: ownProps.data(state, ownProps)
    } : {};
    const disabledReason = ownProps.disabledReason ? {
      disabledReason: ownProps.disabledReason(state, ownProps)
    } : {};
    return Object.assign(
      optionsData,
      disabledReason
    );
  }
)(SelectWrapper);
