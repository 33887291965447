import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Select, Form } from 'antd';
import t from '@/utilities/translate';
import { triggers as triggersDealer } from '@/redux/dealer/actions';
import DispatchOnMount from '../DispatchOnMount';
import { routes } from '@/routing/route-tree';
import { history } from '@/redux/history';

const mapStateToProps = (state) => ({
  dealers: state.dealer.get('activeList'),
});

export default connect(
  mapStateToProps,
)(({ modalProps, dealers, closeModal: closeModalFn }) => {
  const [opt, setOpt] = useState({ dealer: null });
  const closeModal = () => {
    closeModalFn();
    history.push(routes['co-worker/create'].link(opt.dealer));
  };

  return (
    <Modal {...modalProps} onOk={closeModal}>
      <DispatchOnMount actionCreator={triggersDealer.listActiveDealers}>
        <Form.Item label={t('dealer/breadcrumb')} style={{ padding: '20px 0 0 0' }}>
          <Select style={{ width: '100%' }}>
            {dealers.map((dealer) => (
              <Select.Option
                key={dealer.id}
                onClick={() => setOpt({ dealer })}
              >
                {dealer.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </DispatchOnMount>
    </Modal>
  );
});
