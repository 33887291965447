import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';
import { months } from '@/constants';
import moment from 'moment';
import { MONTH_FORMAT, MONTH_YEAR_FORMAT } from '@/constants/date-time';
import { ENTITY_KEYS, FIELDS_FOR_TOTAL_COUNT, FIELDS_WITH_DATE } from './CONSTANTS';

const initialState = new Map({
  activeListByTotal: {},
  activeListByDealer: {},
});

export default createReducer(initialState, {
  [types.SET_LIST_BY_TOTAL]: (state, { payload }) => {
    if (payload && payload.length) {
		const tableData = payload.map((_estDesc) => {
			const { id, estimate_description_name, items } = _estDesc;
			const _months = months.map((el) => el.key);

			const result = Object.values(ENTITY_KEYS).map((key) => {
				const calculateTotal = (key) => {
					if (FIELDS_FOR_TOTAL_COUNT.includes(key)) {
						return items.reduce((acc, cur) => {
							return acc += cur[key]
						}, 0)
					} else {
						return '-';
					}
				}
				const createTempMonths = () => {
					let tempMonths = {};

					_months.forEach((month, idx) => {
						if (FIELDS_WITH_DATE.includes(key)) {
							tempMonths[month] = moment(items[idx][key]).format(MONTH_YEAR_FORMAT)
						} else {
							if (key === ENTITY_KEYS.rss || key === ENTITY_KEYS.actual_rss) {
								tempMonths[month] = items[idx][key].toFixed() + '%'
							} else {
								tempMonths[month] = items[idx][key]
							}
						}
					});

					return tempMonths;
				}

				return {
					name: key,
					total: key === ENTITY_KEYS.rss ? calculateTotal(key).toFixed(0) + '%' : calculateTotal(key),
					...createTempMonths()
				}
			})

			const resultMonths = items.map((el, idx) => {
				return {
					key: _months[idx],
					value: el.date
				}
			})

			return {
				estimate_description_name,
				id,
				result,
				resultMonths
			}
		});

		const chartData = payload[0].items.map((_month) => {
			const calculateByKey = (key) => {
				return payload.reduce((acc, cur) => {
					const neededMonth = cur.items.filter((el) => el.date === _month.date)[0];
					return acc += neededMonth[key]
				}, 0)
			}

			return {
				name: moment(_month.date).format(MONTH_FORMAT),
				pov: calculateByKey(ENTITY_KEYS.pov),
				rss: calculateByKey(ENTITY_KEYS.rss).toFixed(2) + '%',				// % Retail Sales Split
				actual_rdd: calculateByKey(ENTITY_KEYS.actual_rdd), 							// count retail sales split
				actual_rss: calculateByKey(ENTITY_KEYS.actual_rss).toFixed(2) + '%', // Count % Retail Sales Split
				risk_max: calculateByKey(ENTITY_KEYS.risk_max),
				risk_min: calculateByKey(ENTITY_KEYS.risk_min),
				count_retail_sold: calculateByKey(ENTITY_KEYS.count_retail_sold),
				actual_count_retail_sold: calculateByKey(ENTITY_KEYS.actual_count_retail_sold)
			}
		});

		const normalizedPayload = {
			tableData: tableData,
			chartData: chartData
		}

		return state.set('activeListByTotal', normalizedPayload)
	}

	  return state.set('activeListByTotal', payload)
  },
  [types.SET_LIST_BY_DEALER]: (state, { payload }) => {
    if (payload) {
		const { risks, values } = payload;

		if (values && values.length) {
			const tableData = values.map((_estDesc) => {
				const { id, estimate_description_name, items } = _estDesc;
				const _months = months.map((el) => el.key);

				const result = Object.values(ENTITY_KEYS).map((key) => {
					const calculateTotal = (key) => {
						if (FIELDS_FOR_TOTAL_COUNT.includes(key)) {
							return items.reduce((acc, cur) => {
								return acc += cur[key]
							}, 0)
						} else {
							return '-';
						}
					}
					const createTempMonths = () => {
						let tempMonths = {};

						_months.forEach((month, idx) => {
							if (FIELDS_WITH_DATE.includes(key)) {
								tempMonths[month] = moment(items[idx][key]).format(MONTH_YEAR_FORMAT)
							} else {
								if (key === ENTITY_KEYS.rss || key === ENTITY_KEYS.actual_rss) {
									tempMonths[month] = items[idx][key].toFixed() + '%'
								} else {
									tempMonths[month] = items[idx][key]
								}
							}
						});

						return tempMonths;
					}

					return {
						name: key,
						total: key === ENTITY_KEYS.rss ? calculateTotal(key).toFixed(0) + '%' : calculateTotal(key),
						...createTempMonths()
					}
				})

				const resultMonths = items.map((el, idx) => {
					return {
						key: _months[idx],
						value: el.date
					}
				})

				return {
					estimate_description_name,
					id,
					result,
					resultMonths
				}
			})

			const chartData = payload.values[0].items.map((_month) => {
				const calculateByKey = (key) => {
					return payload.values.reduce((acc, cur) => {
						const neededMonth = cur.items.filter((el) => el.date === _month.date)[0];
						return acc += neededMonth[key]
					}, 0)
				}

				return {
					name: moment(_month.date).format(MONTH_FORMAT),
					pov: calculateByKey(ENTITY_KEYS.pov),
					rss: calculateByKey(ENTITY_KEYS.rss).toFixed(2) + '%',				// % Retail Sales Split
					actual_rdd: calculateByKey(ENTITY_KEYS.actual_rdd), 							// count retail sales split
					actual_rss: calculateByKey(ENTITY_KEYS.actual_rss).toFixed(2) + '%', // Count % Retail Sales Split
					risk_max: calculateByKey(ENTITY_KEYS.risk_max),
					risk_min: calculateByKey(ENTITY_KEYS.risk_min),
					count_retail_sold: calculateByKey(ENTITY_KEYS.count_retail_sold),
					actual_count_retail_sold: calculateByKey(ENTITY_KEYS.actual_count_retail_sold)
				}
			});

			const normalizedPayload = {
				risks: risks,
				tableData: tableData,
				chartData: chartData
			};
			return state.set('activeListByDealer', normalizedPayload)
		} else if (risks) {
			return state.set('activeListByDealer', {
				risks: risks
			})
		} else {
			return state.set('activeListByDealer', {})
		}
	}

	  return state.set('activeListByDealer', payload)
  },
  [types.CLEAR_LIST_BY_TOTAL]: (state) => {
	  return state.set('activeListByTotal', {})
  },
});
