import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
	importsList: [],
	currentImport: {},
	canUpload: true,
	filter: {},
});

export default createReducer(initialState, {
	[types.SET_IMPORTS_LIST]: (state, { payload }) => state.set('importsList', payload),
	[types.SET_IMPORT_INFO]: (state, { payload }) => state.set('currentImport', payload),
	[types.SET_UPLOAD_AVAILABILITY]: (state, { payload }) => state.set('canUpload', payload.success),
	[types.SET_FILTER_VALUE]: (state, { payload }) => state.set('filter', payload),
});
