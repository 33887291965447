import { accessHOC } from '@/utilities/access';
import { accessRoles } from '@/constants';

export const canSeeSettingsRoles = [
  accessRoles.COMPANY,
  accessRoles.TERRITORY_MANAGER,
  accessRoles.COUNTRY_MANAGER,
  accessRoles.TOP_MANAGER,
  accessRoles.PSS
];

export const canSeeSettings = accessHOC(canSeeSettingsRoles);

export const canSeeFactoriesListRoles = [accessRoles.COUNTRY_MANAGER];
export const canSeeImportListsRoles = [accessRoles.COUNTRY_MANAGER];
export const canSeeImportLists = accessHOC(canSeeImportListsRoles);
export const canSeeFactoriesList = accessHOC(canSeeFactoriesListRoles);

export const canSeeDealerListRoles = [
  accessRoles.COUNTRY_MANAGER,
  accessRoles.TERRITORY_MANAGER,
  accessRoles.PSS
];

export const canSeeDealerList = accessHOC(canSeeDealerListRoles);

export const canSeeTranslateModuleRoles = [
  accessRoles.COUNTRY_MANAGER
];

export const canSeeTranslateModule = accessHOC(canSeeTranslateModuleRoles);

export const canSeeNotificationEmailsModule = accessHOC([accessRoles.COUNTRY_MANAGER]);

export const canSeePlanFactRoles = [
	accessRoles.COMPANY,
	accessRoles.TERRITORY_MANAGER,
	accessRoles.COUNTRY_MANAGER,
	accessRoles.TOP_MANAGER,
	accessRoles.PSS,
];
export const canSeePlanFact = accessHOC(canSeePlanFactRoles);

export const canUpdateServiceCodesRoles = [
	accessRoles.TERRITORY_MANAGER,
	accessRoles.COUNTRY_MANAGER,
];
export const canUpdateServiceCodes = accessHOC(canUpdateServiceCodesRoles);

