import React from 'react';
import { get, compact } from 'lodash/fp';
import { store } from '@/redux/store';
import Forbidden from '@/pages/403/Forbidden';
import { isAuth } from '@/routing/auth-rules';
// Sheme example

const ruleHandlers = {
  disabled: (field) => ((field && field.name ? (field.disabled = true) : (field = null)) && field)
};

export const accessSheme = (sheme) => (_fields) => {
  const fields = compact(_fields);
  const haveColumns = get('constructor.name')(fields[0]) === 'Array';
  if (haveColumns) {
    return fields.map((col) => {
      const b2 = accessSheme(sheme)(col);
      return b2;
    });
  }

  const state = store.getState();
  const roleId = state.auth.token ? state.auth.token.role_id : null;
  const shemeByRole = sheme[roleId];
  if (!shemeByRole) return fields;
  const fieldsByKey = fields.reduce((acc, item) => (acc[item.name || (new Date()).getTime()] = item) && acc, {});

  const fieldsAfterSheme = shemeByRole.reduce((acc, item) => {
    const handler = ruleHandlers[item.type];
    item.target.forEach((tKey) => (acc[tKey] = handler(acc[tKey])));
    return acc;
  }, fieldsByKey);

  const b1 = Object.entries(fieldsAfterSheme).map(([key, val]) => val);
  return b1;
};

export const accessHOC = (accessRoles, defaultValue = null) => (target) => {
  const state = store.getState();
  const roleId = state.auth.token ? state.auth.token.role_id : null;
  return accessRoles.includes(roleId) ? target : defaultValue;
};

export const accessFn = (accessRoles) => (fn) => {
  const state = store.getState();
  const roleId = state.auth.token ? state.auth.token.role_id : null;

  return accessRoles.includes(roleId) && fn();
};

export const accessTerritorial = accessHOC([4]);

export const accessPermission = (roles) => (Component) => {
  const WrappedForbiden = isAuth(Forbidden);
  const WrappedComponent = isAuth(Component);
  return (props) => {
    const Cmp = accessHOC(roles, WrappedForbiden)(WrappedComponent);
    return <Cmp {...props} />;
  };
};
