import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from "@/redux/helpers";
import { pick } from 'lodash/fp';
import { apiNumberFormatter } from '../../utilities/formatter';

const initialState = new Map({
  current: {},
  all: [],
  listByDealer: []
  // meta: [],
  // listOfDealers: [],
  // activeList: []
});

export default createReducer(initialState, {
  [types.PUT_CURRENT_CO_WORKER]: (state, { payload }) => {
    const _payload = apiNumberFormatter(pick(['phone'])(payload));
    return state.set('current', Object.assign(payload, _payload));
  },
  [types.SET_CO_WORKER_ACTIVE_LIST]: (state, { payload }) => state.set('all', payload),
  [types.SET_CO_WORKER_BY_DEALER_LIST]: (state, { payload }) => state.set('listByDealer', payload),
  // [types.SET_DEALER_LIST]: (state, { payload })=>state.set('listOfDealers', payload),
  // [types.SET_DEALER_ACTIVE_LIST]: (state, { payload })=>state.set(`activeList`, payload)
});
