export const coWorkerForward = (location, props, routes) => {
  switch (location) {
	case routes['co-worker/create'].link({ id: props.dealer_id, name: props.name }):
	  return routes['staff'].link();
	default:
	  break;
  }
  if (location.indexOf('dealer') >= 0 && props.dealer_name)
	return routes['staff'].link();
};
