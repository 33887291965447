import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	list: {
		data: []
	},
	item: {},
	activeList: []
});

export default createReducer(initialState, {
	[types.SET_LIST]: (state, { payload }) => state.set('list', payload),
	[types.SET_ITEM]: (state, { payload }) => state.set('item', payload),
	[types.PUT_SIZE_PARAMETR_LIST]: (state, { payload })=>state.set('activeList', payload),
	[types.SET_ITEM_STATUS]: (state, { payload }) => state.update('list', list => {
		list.data = list.data.map(item => item.id === payload.id ? payload : item);
		return list;
	})
});
