import * as React from 'react';
import { DatePicker, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { SERVER_DATE_FORMAT } from "@/constants/date-time";
import { connect } from 'react-redux';

const { RangePicker } = DatePicker;

// ----------------------------------------------------------------------
// DatePicker Component
// ----------------------------------------------------------------------

class DatePickerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      changed: false
    }
  }
    static propTypes = {
      range: PropTypes.bool,
      input: PropTypes.object,
      meta: PropTypes.object,
      label: PropTypes.string,
      defaultValue: PropTypes.any
    };

    render() {
      const {
        input,
        meta,
        label,
        range,
        dateFormat = SERVER_DATE_FORMAT,
        disabledReason,
        ...attributes
      } = this.props;

      const formItemProps = {
        label: label || null,
        validateStatus: meta.touched && !meta.valid ? "error" : undefined,
        help: meta.touched && meta.error ? meta.error : null,
      };

      const isMomentConstruct = (value) => (value ? (value.constructor.name !== 'Moment' ? moment(value) : value) : null);

      return (
        <Form.Item {...formItemProps} style={{ margin: '0 0 10px' }}>
          {range ? (
            <RangePicker
              {...attributes}
              format={dateFormat}
              value={!this.state.changed && attributes.defaultValue ? attributes.defaultValue : isMomentConstruct(input.value)}
              onChange={(value) => {
                if (!this.state.changed) {
                  this.setState({ changed: true })
                }
                input.onChange(isMomentConstruct(value))
              }}
              style={{ width: '100%' }}
            />
          ) : (
            <DatePicker
              {...attributes}
              disabled={disabledReason || attributes.disabled}
              format={dateFormat}
              value={!this.state.changed && attributes.defaultValue ? attributes.defaultValue : isMomentConstruct(input.value)}
              onChange={(value) => {
                if (!this.state.changed) {
                  this.setState({ changed: true })
                }
                input.onChange(isMomentConstruct(value));
              }}
              style={{ width: '100%' }}
            />
          )}
        </Form.Item>
      );
    }
}

export default connect((state, ownProps) => {
  const optionsData = ownProps.data ? {
    options: ownProps.data(state, ownProps)
  } : {};
  const disabledReason = ownProps.disabledReason ? {
    disabledReason: ownProps.disabledReason(state, ownProps)
  } : {};
  return Object.assign(
    optionsData,
    disabledReason
  );
})(DatePickerWrapper)
