import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { actualSalesPlans } from '@/services/api';
import t from '@/utilities/translate';
import { noty } from '@/utilities/notifications';
import { store } from '@/redux/store';

//-----------------------------------------
//	Triggers
//-----------------------------------------
export const triggers = {};

triggers.getList = (query) => ({
	type: types.FETCH_SALES_PLANS_LIST,
	query,
});

triggers.setList = (payload) => ({
	type: types.SET_SALES_PLANS_LIST,
	payload,
});

triggers.fetchCurrentSalesPlan = (id) => ({
	type: types.GET_SALES_PLAN_INFO,
	id,
});

triggers.setCurrentSalesPlan = (payload) => ({
	type: types.SET_SALES_PLAN_INFO,
	payload,
});

triggers.updateSalesPlan = (payload) => ({
	type: types.UPDATE_SALES_PLAN,
	payload,
});

triggers.setSalesPlanParent = (payload) => ({
	type: types.SET_SALES_PLAN_PARENT,
	payload,
});

triggers.deleteSalesPlan = (id) => ({
	type: types.DELETE_SALES_PLAN,
	id,
});

triggers.setDefaultFilter = (payload) => ({
	type: types.SET_FILTER,
	payload,
});

triggers.setActiveFilter = (payload) => ({
	type: types.SET_ACTIVE_FILTER,
	payload,
});

triggers.makeCatalogue = (payload) => ({
	type: types.MAKE_CATALOGUE,
	payload,
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchSalesPlansList({ type, query }) {
	yield put(addPreloader(type));
	try {
		yield put(triggers.setActiveFilter({
			...(query.dealer_id && { dealer_id: query.dealer_id }),
			...(query.client_id && { client_id: query.client_id }),
			...(query.service_code && { service_code: query.service_code }),
			...(query.description_product && { description_product: query.description_product }),
			...(query.company_lost_deal_id && { company_lost_deal_id: query.company_lost_deal_id }),
		}));
		yield put(triggers.setDefaultFilter(query));
		const { data } = yield call(actualSalesPlans.list, query);
		yield put(triggers.setList(data));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchCurrentSalesPlan({ type, id }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(actualSalesPlans.current, id);
		yield put(triggers.setCurrentSalesPlan(data));
		const filter = store.getState().actualSalesPlans.get('defaultFilter');
		yield put(triggers.setActiveFilter(filter));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* updateSalesPlan({ type, payload }) {
	const { id, ...query } = payload;
	yield put(addPreloader(type));
	try {
		yield call(actualSalesPlans.update, id, query);
		yield call(noty, 'success', t('actual-sales-plan-draft/edit/success'));
		const filter = store.getState().actualSalesPlans.get('defaultFilter');
		yield put(triggers.getList(filter));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* setSalesPlanParent({ type, payload }) {
	const { id, ...query } = payload;
	yield put(addPreloader(type));
	try {
		yield call(actualSalesPlans.setParent, id, query);
		yield call(noty, 'success', t('actual-sales-plans/set-parent/success'));
		const filter = store.getState().actualSalesPlans.get('defaultFilter');
		yield put(triggers.getList(filter));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* makeCatalogue({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield call(actualSalesPlans.makeCatalogue, payload);
		yield call(noty, 'success', t('actual-sales-plans/make-catalogue/success'));
		const filter = store.getState().actualSalesPlans.get('defaultFilter');
		yield put(triggers.getList(filter));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* deleteSalesPlan({ type, id }) {
	yield put(addPreloader(type));
	try {
		yield call(actualSalesPlans.delete, id);
		yield call(noty, 'success', t('actual-sales-plan-draft/delete/success'));
		const filter = store.getState().actualSalesPlans.get('defaultFilter');
		yield put(triggers.getList(filter));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root saga
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_SALES_PLANS_LIST, fetchSalesPlansList);
	yield takeEvery(types.GET_SALES_PLAN_INFO, fetchCurrentSalesPlan);
	yield takeEvery(types.UPDATE_SALES_PLAN, updateSalesPlan);
	yield takeEvery(types.SET_SALES_PLAN_PARENT, setSalesPlanParent);
	yield takeEvery(types.MAKE_CATALOGUE, makeCatalogue);
	yield takeEvery(types.DELETE_SALES_PLAN, deleteSalesPlan);
}
