import * as React from 'react';
import { Button, Form, Upload } from 'antd';
import PropTypes from 'prop-types';
import t from '@/utilities/translate';
import { noty } from "@/utilities/notifications";

// ----------------------------------------------------------------------
// Slider Component
// ----------------------------------------------------------------------

export default class FileWrapper extends React.PureComponent {
    static propTypes = {
      input: PropTypes.object,
      meta: PropTypes.object,
      label: PropTypes.string
    };

    render() {
      const {
        input, meta, label, current, fileTypes = [], maxSize = 2097152, ...attributes
      } = this.props;

      const formItemProps = {
        label: label || null,
        validateStatus: meta.touched && !meta.valid ? "error" : undefined,
        help: meta.touched && meta.error ? meta.error : null,
      };


      const uploadProps = {
        ...attributes,
        action: null,
        beforeUpload: (file) => {
          if (fileTypes.length && !fileTypes.includes(file.type)) {
            noty('error', `${t(`common/form/exceeded_file_type/error`)} ${fileTypes.join(', ')}`);
          } else if (maxSize && maxSize < file.size) {
            noty('error', t(`common/form/max_size/error`));
          } else {
            input.onChange([file]);
          }

          return false;
        },
        onRemove: (file) => {
          input.onChange([]);
          return { fileList: [] };
        },
        fileList: (() => {
          if (input.value && typeof input.value !== 'string') {
            return input.value;
          }
          return [...(current ? [{
            url: current,
            uid: -1,
            name: 'Изображение акции',
            status: 'done',
          }] : [])];
        })(),
      };

      const preview = () => {
        if (meta.initial) {
          return (
            <div className="thumbnail" style={{ width: '200px', height: '200px' }}>
              <img src={meta.initial} alt="pic" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
          );
        }
      };

      return (
        <Form.Item {...formItemProps} style={{ margin: '0 0 10px' }}>
          <React.Fragment>
            { preview() }
            <Upload {...uploadProps}>
              <Button type="primary" htmlType="button" icon="upload">{t('common/uploadingFiles')}</Button>
            </Upload>
          </React.Fragment>
        </Form.Item>
      );
    }
}
