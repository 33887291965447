import React, { Fragment } from 'react';
import { change, formValueSelector } from 'redux-form';
import { store } from '@/redux/store';
import t from '@/utilities/translate';

import SelectField from '@/components/common/form/SelectWrapper';
import { triggers as triggersLocation } from '@/redux/locations/actions';

//-----------------------------------------
//	Selectors
//-----------------------------------------

const countryIdSelector = (state) => state.locations.get('activeCountryList').map((country) => ({
	value: Number(country.id),
	label: country.name,
}));

const regionIdSelector = ({ formName, fieldName }) => (state, ownProps) => {
	const countryID = formValueSelector(formName)(state, fieldName);
	const regions = state.locations.getIn(['activeRegionListByCountry', countryID]);
	return regions ? regions.map((region) => ({
		value: Number(region.id),
		label: region.name,
	})) : [];
};

const destrictIdSelector = ({ formName, fieldName }) => (state, ownProps) => {
	const field = formValueSelector(formName)(state, fieldName);
	const regionID = field instanceof Array ? field.join('#') : field;
	const destrict = state.locations.getIn(['activeDistrictListByRegions', regionID]);
	return destrict ? destrict.map((destrict) => ({
		value: Number(destrict.id),
		label: destrict.name,
	})) : [];
};

//-----------------------------------------
//	Helpers
//-----------------------------------------

const _change = (...args) => store.dispatch(change(...args));
const isEditProccess = (status) => (fn) => {
	if (status) {
		return fn;
	}
};

//-----------------------------------------
//	Handler
//-----------------------------------------

const countryIdOnChange = (props, type) => (id, init) => {
	store.dispatch(triggersLocation.activeRegionListByCountry(id, props.clients));
};

const deleteRegionsAndRayons = (props) => () => {
	_change(props.formName, props.regionFieldName, null);
	_change(props.formName, props.districtFieldName, null);
};

const deleteDistricts = (props) => () => {
	_change(props.formName, props.districtFieldName, null);
};

const regionIdChange = (props, type) => (ids, init) => {
	// props.initialValues[props.districtFieldName] = numberToArray(props.initialValues[props.districtFieldName]);
	// ids = numberToArray(ids);
	// console.log( props.initialValues[props.districtFieldName], props.districtFieldName, '##' )
	// if (props.initialValues && props.initialValues[props.districtFieldName]) {
	//     // console.log(props.initialValues[props.districtFieldName], props.districtFieldName, '##')
	//     // const districts = state.form[props.formName].values[props.districtFieldName];
	//     const getDestrictByCurrentRegions = _.compact(props.initialValues[props.districtFieldName].map(district => ids.includes(district.region_id) ? district.id : null));
	//     !init && _change(props.formName, 'district_ids', getDestrictByCurrentRegions);
	// }
	store.dispatch(triggersLocation.activeDistrictListByRegions(ids, props.clients));
};

export const locationFields = (props, type, options = { countryId: {}, regionId: {}, districtIds: {} }) => {
	options.countryId = options.countryId || {};
	options.regionId = options.regionId || {};
	options.districtIds = options.districtIds || {};

	const countryPrefix = options.countryId.prefixName;
	const regionPrefix = options.regionId.prefixName;
	const districtPrefix = options.districtIds.prefixName;

	const countryFieldName = options.countryId.name || 'country_id';
	const regionFieldName = options.regionId.name || 'region_id';
	const districtFieldName = options.districtIds.name || 'district_ids';


	const districtFieldVisible = options.districtIds.visible !== false;

	props = {
		...props, countryFieldName, regionFieldName, districtFieldName,
	};

	const formName = props.formName || type || `territorial/${type}`;

	const onlyEditProccess = isEditProccess(formName.indexOf('edit') >= 0);

	return [
		{
			id: countryFieldName,
			name: countryFieldName,
			label: t(countryPrefix ? `${countryPrefix}/country_id/label` : 'territorial/create/country_id/label'),
			placeholder: t(countryPrefix ? `${countryPrefix}/country_id/placeholder` : 'territorial/create/country_id/placeholder'),
			type: 'text',
			mode: !options.countryId.single ? 'multiple' : null,
			data: countryIdSelector,
			_validate: true,
			actionCreator: () => triggersLocation.activeCountryList(options.countryId.clients),
			ownOnChange: countryIdOnChange({ ...props, clients: options.countryId.clients }, 'own'),
			onChange: (_, value = []) => {
				const countryIds = formValueSelector(formName)(store.getState(), countryFieldName) || [];
				if (options.countryId.single || value?.[0] !== countryIds?.[0]) {
					return deleteRegionsAndRayons(props)();
				}
			},
			component: SelectField,
			...options.countryId,
		},
		{
			id: regionFieldName,
			name: regionFieldName,
			label: t(regionPrefix ? `${regionPrefix}/oblast_id/label` : 'territorial/create/oblast_id/label'),
			placeholder: t(regionPrefix ? `${regionPrefix}/oblast_id/placeholder` : 'territorial/create/oblast_id/placeholder'),
			type: 'text',
			actionCreator: onlyEditProccess(() => {
				// FIXME props.initialValues приходит как undefined!
				store.dispatch(triggersLocation.activeRegionListByCountry(props.initialValues && props.initialValues[countryFieldName], options.regionId.clients));
			}),
			mode: !options.regionId.single ? 'multiple' : null,
			data: regionIdSelector({ formName, fieldName: countryFieldName }),
			_validate: true,
			disabledReason: (state, ownProps) => Boolean((state.form[formName] && state.form[formName].values) ? !state.form[formName].values[countryFieldName] : 1),
			ownOnChange: regionIdChange({ ...props, districtFieldName, clients: options.regionId.clients }, type),
			onChange: (_, value = []) => {
				const regionIds = formValueSelector(formName)(store.getState(), regionFieldName) || [];
				if (options.regionId.single || value.length < regionIds.length) {
					return deleteDistricts(props)();
				}
			},
			component: SelectField,
			...options.regionId,
		},
		districtFieldVisible && {
			id: districtFieldName,
			name: districtFieldName,
			label: t(districtPrefix ? `${districtPrefix}/district_ids/label` : 'territorial/create/region_id/label'),
			placeholder: t(districtPrefix ? `${districtPrefix}/district_ids/placeholder` : 'territorial/create/region_id/placeholder'),
			type: 'text',
			mode: !options.districtIds.single ? 'multiple' : null,
			actionCreator: onlyEditProccess(() => {
				// FIXME props.initialValues приходит как undefined!
				store.dispatch(triggersLocation.activeDistrictListByRegions(props.initialValues && props.initialValues[regionFieldName], options.districtIds.clients));
			}),
			_validate: true,
			disabledReason: (state, ownProps) => Boolean((state.form[formName] && state.form[formName].values) ? !state.form[formName].values[regionFieldName] : 1),
			data: destrictIdSelector({ formName, fieldName: regionFieldName }),
			component: SelectField,
			...options.districtIds,
		},
	];
};


// FIXME Костыль, нет времени на рефактор
export const locationFields_FIX_ME = (props, type, options = { countryId: {}, regionId: {}, districtIds: {} }) => {
	const countryPrefix = options.countryId.prefixName;
	const regionPrefix = options.regionId.prefixName;

	const countryFieldName = options.countryId.name || 'country_id';
	const regionFieldName = options.regionId.name || 'region_id';
	const districtFieldName = options.districtIds.name || 'district_ids';

	const formName = props.formName || type || `territorial/${type}`;

	const onlyEditProccess = isEditProccess(type.indexOf('edit') >= 0);

	return [
		{
			id: countryFieldName,
			name: countryFieldName,
			label: t(countryPrefix ? `${countryPrefix}/country_id/label` : 'territorial/create/country_id/label'),
			placeholder: t(countryPrefix ? `${countryPrefix}/country_id/placeholder` : 'territorial/create/country_id/placeholder'),
			type: 'text',
			data: countryIdSelector,
			_validate: true,
			actionCreator: triggersLocation.activeCountryList,
			ownOnChange: countryIdOnChange(props, type),
			component: SelectField,
		},
		{
			id: regionFieldName,
			name: regionFieldName,
			label: t(regionPrefix ? `${regionPrefix}/oblast_id/label` : 'territorial/create/oblast_id/label'),
			placeholder: t(regionPrefix ? `${regionPrefix}/oblast_id/placeholder` : 'territorial/create/oblast_id/placeholder'),
			type: 'text',
			actionCreator: onlyEditProccess(() => {
				// FIXME props.initialValues приходит как undefined!
				store.dispatch(triggersLocation.activeRegionListByCountry(props.initialValues && props.initialValues[countryFieldName]));
			}),
			mode: null,
			data: regionIdSelector({ formName, fieldName: countryFieldName }),
			_validate: true,
			disabledReason: (state, ownProps) => Boolean((state.form[formName] && state.form[formName].values) ? !state.form[formName].values[countryFieldName] : 1),
			ownOnChange: regionIdChange({ ...props, districtFieldName }, type),
			component: SelectField,
		},
	];
};

//-----------------------------------------
//	Render locations
//-----------------------------------------

export const countryNormalize = (districts) => {
	const countries = {};

	return districts?.reduce((countries, district) => {
		if (!countries[district.country_name]) countries[district.country_name] = {};
		if (!countries[district.country_name][district.region_name]) countries[district.country_name][district.region_name] = [];
		countries[district.country_name][district.region_name].push(district.name);

		return countries;
	}, countries);
};

export const renderLocations = (normLocations = {}) => Object.keys(normLocations).map((countryName) => (
	<div key={countryName}>
		<div><b>{countryName}</b></div>
		{Object.keys(normLocations[countryName]).map((oblastName) => (
			<Fragment key={oblastName}>
				<div>
					{t('common/oblast')}
					:
					{' '}
					{oblastName}
				</div>
				<div>
					{t('common/rayon')}
					:
					{' '}
					{normLocations[countryName][oblastName].map((regionName, index) => (
						<span key={regionName}>
              {regionName}
							{index < (normLocations[countryName][oblastName].length - 1) ? ',' : ''}
							{' '}

            </span>
					))}
				</div>
			</Fragment>
		))}
	</div>
));
