import * as types from './types';
import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';

import { trade_in } from '@/services/api';

import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { triggers as deal } from '@/redux/deal/actions';
import { history } from '@/redux/history';

export const triggers = {
    fetchItem: (payload) => ({
        type: types.FETCH_TRADE_IN_ITEM,
        ...payload
    }),
    editItem: (payload) => ({
        type: types.CHANGE_TRADE_IN_ITEM,
        ...payload
    }),
    getClientVehiclesList: (payload) => ({
        type: types.GET_CLIENT_VEHICLES_LIST,
        payload
    }),
    createItem: (payload) => ({
        type: types.CREATE_TRADE_IN_ITEM,
        ...payload
    }),
    deleteItem: (payload) => ({
        type: types.DELETE_TRADE_IN_ITEM,
        ...payload
    })
};

export function* fetchTradeInItem ({ deal_id, deal_trade_in_item, type }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(trade_in.fetchItem, { deal_id, deal_trade_in_item });
        yield put({
            type: types.SET_TRADE_IN_ITEM,
            payload: resp.data
        });
    } catch(e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

export function* createTradeInItem ({ deal_id, type, ...payload }) {
    yield put(addPreloader(type));
    try {
        yield call(trade_in.create, { deal_id, ...payload });
    } catch(e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
        yield call(history.push, `/deals/${deal_id}`);
    }
}

export function* deleteTradeInItem ({ deal_id, deal_trade_in_item, type }) {
    yield put(addPreloader(type));
    try {
        yield call(trade_in.delete, { deal_id, deal_trade_in_item });
    } catch(e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
        yield put(deal.fetchCurrentDeal(deal_id));
    }
}

export function* getClientVehiclesList ({payload, type}) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(trade_in.clientVehiclesList, payload);
        yield put({
            type: types.SET_CLIENT_VEHICLES_LIST,
            payload: resp.data
        });
    } catch(e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

export function* editTradeInItem ({ deal_id, deal_trade_in_item, client_vehicle_id, type }) {
    yield put(addPreloader(type));
    try {
        yield call(trade_in.edit, { deal_id, deal_trade_in_item, client_vehicle_id });
    } catch(e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
        yield call(history.push, `/deals/${deal_id}`);
    }
}

export function* saga() {
    yield takeEvery(types.FETCH_TRADE_IN_ITEM, fetchTradeInItem);
    yield takeEvery(types.CHANGE_TRADE_IN_ITEM, editTradeInItem);
    yield takeEvery(types.GET_CLIENT_VEHICLES_LIST, getClientVehiclesList);
    yield takeEvery(types.CREATE_TRADE_IN_ITEM, createTradeInItem);
    yield takeEvery(types.DELETE_TRADE_IN_ITEM, deleteTradeInItem);
}
