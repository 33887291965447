import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import t from '@/utilities/translate';
import UpdateLocationsForm from '@/components/client/edit/LocationsForm';
import fields from '../_LOCATIONS_FIELDS_';
import validator from '@/utilities/validator';

const sendQuery = (values) => ({
	country_id: values.country_id,
	region_id: values.region_id,
	district_id: values.district_id
});

const EditClientLocations = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		data,
	} = props;

	return (
		<Modal
			{...modalProps}
			title={`${t('client/updateLocations/title')}: ${data.company_name}, ${data.tax_code}`}
			footer={null}
		>
			<UpdateLocationsForm
				fields={fields}
				sendQuery={sendQuery}
				closeModal={closeModalFn}
				validate={validator.clientLocationsFields}
				{...props}
			/>
		</Modal>
	);
};

EditClientLocations.defaultProps = {
	modalProps: {},
};

EditClientLocations.propTypes = {
	modalProps: PropTypes.any,
	closeModal: PropTypes.func.isRequired,
};

export default EditClientLocations;
