import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from "@/redux/helpers";
import { apiFormatFermSizeCategory } from '../../utilities/formatter';
// import _ from 'lodash';

const initialState = new Map({
    current: {},
    list: {}
});

export default createReducer(initialState, {
    [types.PUT_FARM_SIZE]: (state, { payload }) => state.set('current', payload),
    [types.PUT_FARM_SIZE_LIST]: (state, { payload }) => {
        payload.data = apiFormatFermSizeCategory(payload.data, 'name')
        return state.set('list', payload)
    },
    [types.UPDATE_FARM_SIZE_LIST]: (state, { payload }) => state.update('list',
        (farmSizeObj) => {
            return {
                ...farmSizeObj,
                data:farmSizeObj.data.filter(farmSize => farmSize.id !== payload)
            }
        }),
});
