import React, { Fragment } from 'react';
import { Col } from 'antd';
import FormField from '@/components/common/form/DefaultFormWrapper';
import DispatchOnMount from '@/components/common/DispatchOnMount';
import T from '@/utilities/translate';

import TextField from '@/components/common/form/TextFieldWrapper';
import SelectField from '@/components/common/form/SelectWrapper';
import DatePickerWrapper from "@/components/common/form/DatePickerWrapper";
import InputMask from '@/components/common/form/InputMaskWrapper';
import FileWrapper from "@/components/common/form/FileWrapper";
import AutoComplete from '@/components/common/form/AutoComplateWrapper';
import SwitchWrapper from '@/components/common/form/SwitchWrapper';

const PhoneNumber = InputMask({
  type: 'phone_number',
});

const inputTypes = {
  select: SelectField,
  text: TextField,
  number: TextField,
  date: DatePickerWrapper,
  phone: PhoneNumber,
  file: FileWrapper,
  autocomplete: AutoComplete,
  switch: SwitchWrapper,
  default: null
};

const getComponentByType = (type, hideMask = false) => hideMask ? inputTypes.text : inputTypes[type] || inputTypes.default;

let fallbackKey = 1000;
const renderField = (field, otherProps) => {
  if (!isNaN(field)) return null;

  const {
    name: key = `fallback-key-${++fallbackKey}`
  } = field;
  if (typeof field.actionCreator === 'function' && !field.$$typeof) {
    return (
      <DispatchOnMount
        actionCreator={field.actionCreator}
        key={key}
      >
        <FormField
          component={getComponentByType(field.type, field.hideMask)}
          {...field}
          {...otherProps}
          label={T(field.label)}
          placeholder={T(field.placeholder)}
          key={key}
        />
      </DispatchOnMount>
    );
  } return !field.$$typeof ? (
    <FormField
      component={getComponentByType(field.type, field.hideMask)}
      {...field}
      {...otherProps}
      label={T(field.label)}
      placeholder={T(field.placeholder)}
      key={key}
    />
  ) : <Fragment key={key}>{field}</Fragment>;
};

export const formResolver = (arrFields, otherProps) => arrFields.map((field, index) => {
  fallbackKey = 1000;
  if (!field) {
    return <Col span={24 / arrFields.length} key={index}/>;
  } else {
    if (field instanceof Array) {
      return <Col span={24 / arrFields.length} key={index}>{field.map((col) => renderField(col, otherProps))}</Col>;
    }
    if (!isNaN(field)) return null;

    return renderField(field, otherProps);
  }

});

export const formResolverFP = (otherProps, middleWare = (v) => v) => (arrFields) => formResolver(middleWare(arrFields), otherProps);
export const filterFieldsFP = (fn, options, middleWare = (f) => f) => (arrFieldsName) => middleWare(fn(options)).reduce(arrFieldsName);

//-----------------------------------------
//	Form config
//-----------------------------------------

export const twoInRowsGridConfig = {
  iSpan: { span: 24 },
  aSpan: { lg: 16 },
  fullWidth: true
};
