export const ENTITY_KEYS = {
	rss: 'rss',
	order_deadline_in: 'order_deadline_in',
	pov: 'pov',
	atp_date: 'atp_date',
	actual_rdd: 'actual_rdd',
	risk_max: 'risk_max',
	risk_min: 'risk_min',
	count_retail_sold: 'count_retail_sold',
	actual_count_retail_sold: 'actual_count_retail_sold',
	actual_rss: 'actual_rss'
};

export const FIELDS_FOR_TOTAL_COUNT = [ENTITY_KEYS.rss, ENTITY_KEYS.pov];
export const FIELDS_WITH_DATE = [ENTITY_KEYS.atp_date, ENTITY_KEYS.order_deadline_in];
