import {createAction, createReducer} from "@/redux/helpers";

export const SHOW = '@popup/SHOW';
export const HIDE = '@popup/HIDE';

export const showPopup = (popupProps) => ({type: SHOW, popupProps});
export const hidePopup = () => createAction(HIDE);

const initialState = {
    isOpen: false,
    popupProps: {}
};

export const popups = createReducer(initialState, {
    [SHOW](state, {popupProps}) {
        return {
            ...state,
            isOpen: true,
            popupProps: {
                ...popupProps
            }
        };
    },

    [HIDE](state) {
        return {
            ...state,
            isOpen: false,
            popupProps: {}
        };
    }
});