import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';

import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { years } from '@/services/api';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchYearsList = () => ({
	type: types.FETCH_YEARS_LIST,
});

triggers.fetchFyTypeList = (payload) => ({
	type: types.FETCH_FISCAL_YEARS_LIST,
	payload,
});

triggers.fetchSalesPlansYearsList = () => ({
	type: types.FETCH_SALES_PLANS_YEARS_LIST,
});

triggers.setYearsList = (payload) => ({
	type: types.SET_YEARS_LIST,
	payload,
});

triggers.setFyTypeList = (payload) => ({
	type: types.SET_FISCAL_YEARS_LIST,
	payload,
});

triggers.setSalesPlansYearsList = (payload) => ({
	type: types.SET_SALES_PLANS_YEARS_LIST,
	payload,
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchYearsList({ type }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(years.list);
		yield put(triggers.setYearsList(data));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchFyTypeList({ type, payload }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(years.fyTypeList, payload);
		yield put(triggers.setFyTypeList(data));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchSalesPlansYearsList({ type }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(years.salesPlansList);
		yield put(triggers.setSalesPlansYearsList(data));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_YEARS_LIST, fetchYearsList);
	yield takeEvery(types.FETCH_FISCAL_YEARS_LIST, fetchFyTypeList);
	yield takeEvery(types.FETCH_SALES_PLANS_YEARS_LIST, fetchSalesPlansYearsList);
}
