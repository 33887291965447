import { applyMiddleware, createStore } from 'redux'
// import immJson from 'json-immutable';
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '@/redux/rootReducer'
import { history } from '@/redux/history'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import rootSaga from '@/redux/rootSagas'

const composeEnhancers = composeWithDevTools({});
const sagaMiddleware = createSagaMiddleware();

function configureStore() {
    let initialState = {};
    // Deserialize store from global scope or localStorage (DEV)
    if (module.hot) {
        if (window.$store) {
            initialState = window.$store;
        }
    }
    const middlewares = [
        sagaMiddleware,
        routerMiddleware(history),
    ].concat(module.hot ? [store => next => action => {
        window.$store = store.getState();
        next(action);
    }] : [])

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(
                ...middlewares
            )
        )
    )

    if (module.hot) {
        // Save store to localStorage (DEV)
        // window.addEventListener("beforeunload", function (e) {
        //     window.localStorage.setItem('$store', immJson.serialize(store.getState()));
        // }, false);
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../redux/rootReducer', () => {
            const nextRootReducer = require('./rootReducer');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}

export default configureStore;

const store = configureStore();

sagaMiddleware.run(rootSaga);

export { store }
