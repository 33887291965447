import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {closeModal, destroyModal} from '@/redux/common/modals';
import {MODALS} from '@/modals';

class ModalContainer extends Component {
    static propTypes = {
        closeModal: PropTypes.func,
        destroyModal: PropTypes.func,
        settings: PropTypes.shape({
            name: PropTypes.string,
            modalProps: PropTypes.oneOfType([
                PropTypes.object
            ]),
            props: PropTypes.oneOfType([
                PropTypes.object
            ])
        })
    };

    defaultParams = {
        destroyOnClose: true
    };

    render() {
        const {settings} = this.props;

        const {name, modalProps, props} = settings || {};
        const Content = MODALS[name];
        const isVisible = MODALS.hasOwnProperty(name);

        return isVisible && <Content modalProps={{
            ...this.defaultParams,
            ...modalProps,
            onCancel: this.props.closeModal,
            afterClose: this.props.destroyModal
        }} {...props} {...this.props}/>;
    }
}

export default connect(state => ({settings: state.modal}), {closeModal, destroyModal})(ModalContainer);
