import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import t from '@/utilities/translate';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { job } from '@/services/api';
import { noty } from "@/utilities/notifications";

//-----------------------------------------
//	Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchActiveJobsList = ()=>({
    type: types.FETCH_ACTIVE_JOB_TITLE_LIST
})

triggers.createJob = (query)=>({
    type: types.CREATE_JOB_TITLE,
    query
})

triggers.listOfJob = (query)=>({
    type: types.FETCH_JOB_LIST,
    query
})

triggers.changeStatusOfJob = (id)=>({
    type: types.CHANGE_JOB_STATUS,
    id
})

triggers.currentJob = (id)=>({
    type: types.FETCH_CURRENT_JOB,
    id
})

triggers.editJob = (id, query)=>({
    type: types.UPDATE_CURRENT_JOB,
    id,
    query
})

//-----------------------------------------
//	Sagas
//-----------------------------------------

function* currentJob({ type, id }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(job.current, id);
        yield put({
            type: types.SET_CURRENT_JOB,
            payload: resp.data
        })
    } catch(e) {
        console.warn(e);
    } finally {
        yield put(removePreloader(type));
    }
}

function* editJob({ type, id, query }) {
    yield put(addPreloader(type));
    try {
        yield call(job.edit, id, query);
        yield call(noty, 'success', t('job/edit/success'));
        yield call(window._forward, { id });
    } catch(e) {
        console.warn(e);
    } finally {
        yield put(removePreloader(type));
    }
}

function* fetchActiveJobList() {
    try {
        const resp = yield call(job.activeList);
        yield put({
            type: types.SET_ACTIVE_JOB_TITLE_LIST,
            payload: resp.data
        })
    } catch(e) {
        console.warn(e);
    }
}

function* createJob({ type, query }) {
    yield put(addPreloader(type));
    try {
        yield call(job.create, query);
        yield call(noty, 'success', t('job/create/success'));
        yield call(window._forward);
    } catch(e) {
        console.warn(e);
    } finally {
        yield put(removePreloader(type));
    }
}

function* changeStatusOfJob({ id }) {
    try {
        const resp = yield call(job.changeStatus, id);
        yield put({
            type: types.SET_JOB_WITH_CHANGE_STATUS,
            payload: resp.data
        })
        yield call(noty, 'success', t('job/changeStatus/success'))
    } catch(e) {
        console.warn(e);
    }
}

function* listOfJob({ type, query }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(job.list, query);
        yield put({
            type: types.SET_JOB_LIST,
            payload: resp.data
        })
    } catch(e) {
        console.warn(e);
    } finally {
        yield put(removePreloader(type));
    }
}

//-----------------------------------------
//	Root sagas
//-----------------------------------------

export function* saga() {

    yield takeEvery(types.UPDATE_CURRENT_JOB, editJob);
    yield takeEvery(types.FETCH_CURRENT_JOB, currentJob);
    yield takeEvery(types.CHANGE_JOB_STATUS, changeStatusOfJob);
    yield takeEvery(types.FETCH_ACTIVE_JOB_TITLE_LIST, fetchActiveJobList);
    yield takeEvery(types.CREATE_JOB_TITLE, createJob);
    yield takeEvery(types.FETCH_JOB_LIST, listOfJob);

}
