export const dashboardChartConfig = {
	colors: {
		toVerify: 'rgba(255, 222, 0, 0.4)',
		open: 'rgba(255, 222, 0, 0.6)',
		offerMade: 'rgba(255, 222, 0, 1)',
		lost: '#ff330a',
		wonPrePay: 'rgba(54, 124, 43, 0.4)',
		wonPaid: 'rgba(54, 124, 43, 0.6)',
		unitSalesGoal: 'rgba(54, 124, 43, 0.8)',
		gapToGoal: 'rgba(54, 124, 43, 1)',
	}
};
