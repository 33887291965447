import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { iosLinksImport } from '@/services/api';
import t from '@/utilities/translate';
import { noty } from "@/utilities/notifications";
import {store} from "../store";

//-----------------------------------------
//	Triggers
//-----------------------------------------
export const triggers = {};

triggers.importsList = (query) => ({
	type: types.FETCH_IMPORTS_LIST,
	query
});

triggers.setImportsList = (payload) => ({
	type: types.SET_IMPORTS_LIST,
	payload
});

triggers.getCurrentImport = (id) => ({
	type: types.GET_IMPORT_INFO,
	id
});

triggers.setCurrentImport = (payload) => ({
	type: types.SET_IMPORT_INFO,
	payload
});

triggers.uploadFile = (payload) => ({
	type: types.UPLOAD_FILE,
	payload
});

triggers.checkImportAvailability = () => ({
	type: types.CHECK_UPLOAD_AVAILABILITY
});

triggers.setUploadAvailability = (payload) => ({
	type: types.SET_UPLOAD_AVAILABILITY,
	payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchImportsList({ type, query }) {
	yield put(addPreloader(type));
	try {
		// yield put(triggers.setFilterValue(query));
		const { data } = yield call(iosLinksImport.list, query);
		yield put(triggers.setImportsList(data));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchImportsCurrent({ type, id }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(iosLinksImport.getImportInfo, id);
		yield put(triggers.setCurrentImport(data));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* uploadFile({ type, payload }) {
	const { file, query } = payload;

	yield put(addPreloader(type));
	try {
		yield call(iosLinksImport.uploadImportFile, { file });
		yield put(triggers.setUploadAvailability({ success: false }));
		const { data } = yield call(iosLinksImport.list, query);
		yield put(triggers.setImportsList(data));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* checkUploadAvailability({ type }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(iosLinksImport.canUseImport);
		if (store.getState().iosLinksImport.get('canUpload') === false && data.success) {
			yield call(noty, 'success', t('ios-links-import/import/completed'));
		}
		yield put(triggers.setUploadAvailability(data));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root dealer saga
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_IMPORTS_LIST, fetchImportsList);
	yield takeEvery(types.GET_IMPORT_INFO, fetchImportsCurrent);
	yield takeEvery(types.UPLOAD_FILE, uploadFile);
	yield takeEvery(types.CHECK_UPLOAD_AVAILABILITY, checkUploadAvailability);
}
