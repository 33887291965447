import * as types from './types';
import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";

const initialState = new Map({
    current: {},
});

export default createReducer(initialState, {
    [types.PUT_CURRENT_COUNTRY_MANAGER]: (state, { payload })=>state.set('current', payload)
});