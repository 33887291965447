import { routes } from '@/routing/route-tree';
import { history } from '@/redux/history';
import * as forwards from '@/redux/forwardExports';

export const combileLocation = (...locationArray) => (location, props) => locationArray.reduce((acc, fn) => {
  const res = fn(location, props, routes);
  if (res) acc = res;

  return acc;
}, null);

const allLocationRedirection = combileLocation(
  ...Object.keys(forwards).map((fdName) => forwards[fdName])
);

const locationRoutes = (props = {}) => {
  const location = history.location.pathname;
  return allLocationRedirection(location, props) || null;
};

/**
 * @function locationReducer
 * @param props:Object
 * @description Reduce and change location
 */

export const locationReducer = (props) => {
  const res = locationRoutes(props);
  res && history.push(locationRoutes(props));
};

export const locationMiddleware = (store) => (next) => (action) => {
  if (action._location) {
    locationReducer(action);
  }
  const result = next(action);
  return result;
};

/**
 * @description Add locationReducer to window scope
 */

window._forward = locationReducer;
Object.defineProperty(window, '_location', {
    get: function () {
        return history.location.pathname;
    }
})

export const simpleLocation = ({ nameSpace, ...props }) => () => {
  const location = history.location.pathname;
  if (routes[`${nameSpace}/edit`].link(props) === location || routes[`${nameSpace}/create`].link() === location) {
    history.push(routes[`${nameSpace}/list`].link());
  }
};
