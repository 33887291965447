import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import t from '@/utilities/translate';
import * as api from '@/services/api';
import { noty } from "@/utilities/notifications";
import { locationReducer } from '@/utilities/location';
import { addPreloader, removePreloader } from '../common/preloaders';

export const triggers = {};

//-----------------------------------------
//	Trigger Helpers
//-----------------------------------------

triggers.createLocation = (query, _type_) => ({
  type: types.CREATE_LOCATION,
  query,
  _type_
});

triggers.deleteAllCountries = () => ({
  type: types.SET_ACTIVE_COUNTRY_LIST,
  payload: []
});

triggers.currentLocation = (id, _type_) => ({
  type: types.FETCH_CURRENT_LOCATION,
  id,
  _type_
});

triggers.editLocation = (id, query, _type_) => ({
  type: types.EDIT_LOCATION,
  id,
  query,
  _type_
});

triggers.listOfLocation = (query, _type_) => ({
  type: types.FETCH_LOCATION_LIST,
  query,
  _type_
});

triggers.changeStatusOfLocation = (id, _type_) => ({
  type: types.CHENGE_LOCATION_STATUS_FETCH,
  id,
  _type_
});

triggers.activeCountryList = (clients) => ({
  type: types.FETCH_ACTIVE_COUNTRY_LIST,
  clients
});

triggers.activeRegionListByCountry = (id, clients) => ({
  type: types.FETCH_ACTIVE_REGION_BY_COUNTRY_LIST,
  id,
  clients
});

triggers.activeDistrictListByRegions = (ids, clients) => ({
  type: types.FETCH_ACTIVE_DISTRICT_BY_REGIONS_LIST,
  ids,
  clients
});

triggers.regionActiveList = () => ({
  type: types.FETCH_ACTIVE_REGION_LIST
});

triggers.activeDistrictList = (ids) => ({
  type: types.FETCH_ACTIVE_DISTRICT_LIST,
  ids
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* createLocationByType({ type, query, _type_ }) {
  yield put(addPreloader(type + _type_));
  try {
    yield call(api[_type_].create, query);
    yield call(noty, 'success', t(`${_type_}/create/success`));
    yield call(locationReducer);
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type + _type_));
  }
}

export function* editLocationByType({
  type, id, query, _type_
}) {
  yield put(addPreloader(type + _type_));
  try {
    yield call(api[_type_].edit, id, query);
    yield call(noty, 'success', t(`${_type_}/edit/success`));
    yield call(locationReducer, { id });
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type + _type_));
  }
}

export function* changeStatusOfLocationByType({ type, id, _type_ }) {
  try {
    const resp = yield call(api[_type_].changeStatus, id);
    yield put({
      type: types.CHENGE_LOCATION_STATUS_SET,
      _type_,
      payload: resp.data
    });
    yield call(noty, 'success', t(`${_type_}/changeStatus/success`));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* currentLocationByType({ type, id, _type_ }) {
  try {
    const resp = yield call(api[_type_].current, id);
    yield put({
      type: types.SET_CURRENT_LOCATION,
      _type_,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* listOfLocationByType({ type, query, _type_ }) {
  yield put(addPreloader(types.FETCH_LOCATION_LIST));
  try {
    const resp = yield call(api[_type_].list, query);
    yield put({
      type: types.SET_LOCATION_LIST,
      _type_,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(types.FETCH_LOCATION_LIST));
  }
}

export function* activeCountryList({ clients }) {
  try {
    const resp = yield call(api.country.activeList, clients);
    yield put({
      type: types.SET_ACTIVE_COUNTRY_LIST,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* activeRegionListByCountry({ id, clients }) {
  try {
    const resp = yield call(api.region.activeListByCountry, id, clients);
    yield put({
      type: types.SET_ACTIVE_REGION_BY_COUNTRY_LIST,
      payload: resp.data,
      id
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* activeRegionList () {
  try {
    const resp = yield call(api.region.activeList);
    yield put({
      type: types.SET_ACTIVE_REGION_LIST,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* activeDistrictListByRegions({ ids, clients }) {
  try {
    const resp = yield call(api.rayon.activeListByRegions, ids, clients);
    yield put({
      type: types.SET_ACTIVE_DESTRICT_BY_REGIONS_LIST,
      payload: resp.data,
      ids
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* activeDistrictList ({ ids }) {
  try {
    const resp = yield call(api.rayon.activeListByRegions, ids);
    yield put({
      type: types.SET_ACTIVE_DISTRICT_LIST,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

//-----------------------------------------
//	Root sagas
//-----------------------------------------

export function* saga() {
  yield takeEvery(types.FETCH_ACTIVE_DISTRICT_BY_REGIONS_LIST, activeDistrictListByRegions);
  yield takeEvery(types.FETCH_ACTIVE_COUNTRY_LIST, activeCountryList);
  yield takeEvery(types.FETCH_ACTIVE_REGION_BY_COUNTRY_LIST, activeRegionListByCountry);
  yield takeEvery(types.FETCH_ACTIVE_REGION_LIST, activeRegionList);
  yield takeEvery(types.FETCH_ACTIVE_DISTRICT_LIST, activeDistrictList);
  yield takeEvery(types.CHENGE_LOCATION_STATUS_FETCH, changeStatusOfLocationByType);
  yield takeEvery(types.FETCH_LOCATION_LIST, listOfLocationByType);
  yield takeEvery(types.FETCH_CURRENT_LOCATION, currentLocationByType);
  yield takeEvery(types.EDIT_LOCATION, editLocationByType);
  yield takeEvery(types.CREATE_LOCATION, createLocationByType);
}
