export const CREATE_TECHNIQUE_REQUEST = '@/techniques/CREATE_TECHNIQUE_REQUEST';

export const FETCH_CURRENT_VEHICLE_REQUEST = '@/techniques/FETCH_CURRENT_VEHICLE_REQUEST';
export const FETCH_CURRENT_VEHICLE_EMITTER = '@/techniques/FETCH_CURRENT_VEHICLE_EMITTER';

export const UPDATE_CURRENT_VEHICLE_REQUEST = '@/techniques/UPDATE_CURRENT_VEHICLE_REQUEST';

export const FETCH_VEHICLE_LIST_REQUEST = '@/techniques/FETCH_VEHICLE_LIST_REQUEST';
export const FETCH_VEHICLE_LIST_EMITTER = '@/techniques/FETCH_VEHICLE_LIST_EMITTER';

export const REMOVE_VEHICLE = '@/techniques/REMOVE_VEHICLE';
export const CHANGE_STATUS_VEHICLE = '@/techniques/CHANGE_STATUS_VEHICLE';