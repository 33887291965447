import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
	current: {},
	activeList: [],
	filter: {},
	availableUsers: []
});

export default createReducer(initialState, {
	[types.SAVE_NOTIFICATION_EMAILS_LIST]: (state, { payload }) => state.set('activeList', payload),
	[types.SAVE_NOTIFICATION_EMAIL_INFO]: (state, { payload }) => state.set('current', payload),
	[types.SET_FILTER]: (state, { payload }) => state.set('filter', payload),
	[types.SET_USERS_FOR_NOTIFICATION_LIST]: (state, { payload }) => state.set('availableUsers', payload),
})
