import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	list: [],
});

export default createReducer(initialState, {
	[types.SET_EQUIPMENT_OPERATION_TIMES_LIST]: (state, { payload }) => state.set('list', payload),
});
