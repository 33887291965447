import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';

import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { manufacture } from '@/services/api';
import { noty } from '@/utilities/notifications';
import t from '@/utilities/translate';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.list = (payload) => ({
	type: types.FETCH_MANUFACTURES_LIST,
	payload
});

triggers.allManufactureList = (payload) => ({
	type: types.FETCH_MANUFACTURES_LIST,
	payload
});

triggers.create = (payload) => ({
	type: types.CREATE_MANUFACTURE,
	payload
});

triggers.edit = (id, payload) => ({
	type: types.UPDATE_MANUFACTURE,
	id,
	payload
});

triggers.current = (id) => ({
	type: types.FETCH_CURRENT_MANUFACTURE,
	id
});

triggers.change_status = (id) => ({
	type: types.CHANGE_MANUFACTURE_STATUS,
	id
});

triggers.changeActualSales = (id) => ({
	type: types.CHANGE_MANUFACTURE_ACTUAL_SALES,
	id
});

triggers.manufacture_list = (payload) => ({
	type: types.FETCH_MANUFACTURE_DATA_LIST,
	payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* manufactureDataList({ type = types.FETCH_MANUFACTURE_DATA_LIST, payload }) {
	yield put(addPreloader(type));
	try {
		const resp = yield call(manufacture.manufacture_list, payload);
		yield put({
			type: types.SET_MANUFACURER_DATA_LIST,
			payload: resp.data
		})
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* manufactureCurrent({ type, id }) {
	yield put(addPreloader(type));
	try {
		const resp = yield call(manufacture.current, id);
		yield put({
			type: types.SET_CURRENT_MANUFACTURE,
			payload: {
				name: resp.data.name,
				status: resp.data.status,
				actual_sales: resp.data.actual_sales,
				relationship: resp.data.relationship,
				active_for_farmsight: resp.data.active_for_farmsight,
			}
		})
	} finally {
		yield put(removePreloader(type));
	}
}

export function* manufactureChangeStatus({ id }) {
	try {
		const resp = yield call(manufacture.change_status, id);
		yield put({
			type: types.UPDATE_MANUFACTURES_LIST,
			payload: resp.data
		});
		yield call(noty, 'success', t('manufacture/change_status/success') + (resp.data.status ? t('common/activate') : t('common/deactivate')))
	} catch (e) {
		yield call(console.warn, e);
	}
}

export function* manufactureChangeActualSales({ id }) {
	try {
		const resp = yield call(manufacture.changeActualSales, id);
		yield put({
			type: types.UPDATE_MANUFACTURES_LIST,
			payload: resp.data
		});
		yield call(noty, 'success', t('manufacture/change-actual-sales/success'))
	} catch (e) {
		yield call(console.warn, e);
	}
}

export function* manufactureEdit({ type, id, payload }) {
	yield put(addPreloader(type));
	try {
		yield call(manufacture.edit, id, payload);
		yield call(noty, 'success', t('manufacture/edit/success'));
		yield call(window._forward, { id });
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* manufactureCreate({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield call(manufacture.create, payload);
		yield call(noty, 'success', t('manufacture/create/success'));
		yield call(window._forward);

	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* manufactureList({ type, payload }) {
	yield put(addPreloader(type));
	try {
		const resp = yield call(manufacture.list, payload);
		yield put({
			type: types.SET_MANUFACTURES_LIST,
			payload: resp.data
		})
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_MANUFACTURE_DATA_LIST, manufactureDataList);
	yield takeEvery(types.FETCH_MANUFACTURES_LIST, manufactureList);
	yield takeEvery(types.CREATE_MANUFACTURE, manufactureCreate);
	yield takeEvery(types.FETCH_CURRENT_MANUFACTURE, manufactureCurrent);
	yield takeEvery(types.UPDATE_MANUFACTURE, manufactureEdit);
	yield takeEvery(types.CHANGE_MANUFACTURE_STATUS, manufactureChangeStatus);
	yield takeEvery(types.CHANGE_MANUFACTURE_ACTUAL_SALES, manufactureChangeActualSales);
}
