import React from 'react';
import {
  Dropdown, Menu, Icon,
} from 'antd';
import { history } from '@/redux/history';
import { routes } from '@/routing/route-tree';
import t from '@/utilities/translate';
import { accessHOC } from '@/utilities/access';
import { accessRoles } from '@/constants';

const canSeeCustomerButton = accessHOC([
  accessRoles.COUNTRY_MANAGER,
  accessRoles.TOP_MANAGER,
  accessRoles.OUTLET_MANAGER,
  accessRoles.SALES_MANAGER,
  accessRoles.COMPANY
]);
const canSeeProductButton = accessHOC([accessRoles.COUNTRY_MANAGER]);
const canSeeManufacturerButton = accessHOC([accessRoles.COUNTRY_MANAGER]);

export default () => (
  <Dropdown overlay={(
    <Menu>
      {canSeeCustomerButton(<Menu.Item onClick={() => history.push(routes['client/create'].link())}>{t('create/heading/customer')}</Menu.Item>)}
      {canSeeProductButton(<Menu.Item onClick={() => history.push(routes['product/create'].link())}>{t('create/heading/product')}</Menu.Item>)}
      {canSeeManufacturerButton(<Menu.Item onClick={() => history.push(routes['manufacturers/create'].link())}>{t('create/heading/manufacture')}</Menu.Item>)}
    </Menu>
  )}
  >
    <Icon type="file-add" />
  </Dropdown>
);
