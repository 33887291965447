import * as types from './types';
import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";

const initialState = new Map({
    superVisorByDealerId: [],
    superVisorByCoWorkerId: []
});

export default createReducer(initialState, {
    [types.SET_SUPERVISOR_BY_DEALER_ID]: (state, { payload })=>state.set('superVisorByDealerId',payload),
    [types.SET_SUPERVISOR_BY_COWORKER_ID]: (state, { payload })=>state.set('superVisorByCoWorkerId',payload)
});