import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	list: {},
	current: {},
	statusesList: [],
});

export default createReducer(initialState, {
	[types.SET_POTENTIAL_SALES_LIST]: (state, { payload }) => state.set('list', payload),
	[types.SET_STATUSES_LIST]: (state, { payload }) => state.set('statusesList', payload),
	[types.SET_CURRENT_POTENTIAL_SALE]: (state, { payload }) => state.set('current', payload),
});
