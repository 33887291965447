import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { productSalesSpecialist } from '@/services/api';
import { noty } from "@/utilities/notifications";
import t from '@/utilities/translate';

export const triggers = {};

//-----------------------------------------
//	Trigger Helpers
//-----------------------------------------

triggers.pssCreate = (query) => ({
    type: types.PSS_CREATE,
    query
});

triggers.pssCurrent = (id) => ({
    type: types.FETCH_PSS_CURRENT,
    id
});

triggers.list = (query) => ({
    type: types.FETCH_PSS_LIST,
    query
});

triggers.pssChangeStatus = (id, _type_) => ({
    type: types.FETCH_CHANGE_PSS_STATUS,
    id,
    _type_
});

triggers.pssEdit = (id, query) => ({
    type: types.UPDATE_PSS,
    id,
    query
});

triggers.pssChangeLoginAndPassword = (id)=>({
    type: types.CHANGE_PSS_LOGIN_AND_PASSWORD,
    id
});

triggers.pssActiveList = ()=>({
    type: types.FETCH_PSS_ACTIVE_LIST,
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* pssActiveList ({ type }) {
    const setActivePss = (payload)=>({
        type: types.PUT_PSS_ACTIVE_LIST,
        payload
    });

    try {
        const resp = yield call(productSalesSpecialist.activeList);
        yield put(setActivePss(resp.data));

    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* pssCreate ({ type, query }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(productSalesSpecialist.create, query);

        if (query.photo)
            yield call(productSalesSpecialist.uploadPhoto, resp.data.id, query.photo);

        yield call(noty, 'success', t('pss/create/success'));
        yield call(window._forward);
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* pssEdit ({ type, id, query }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(productSalesSpecialist.edit, id, query);
        if (query.photo && typeof query.photo === 'object')
        yield call(productSalesSpecialist.uploadPhoto, resp.data.id, query.photo);
        yield call(noty, 'success', t('pss/edit/success'));
        yield call(window._forward, { id });
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* pssChangeLoginAndPassword ({ type, id }) {
    try {
        yield call(productSalesSpecialist.changePasswordLogin, id);
        yield call(noty, 'success', t('pss/changeLoginAndPassword/success'));
    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* pssCurrent ({ type, id }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(productSalesSpecialist.current, id);
        yield put({
            type: types.SET_PSS_CURRENT,
            payload: resp.data
        })

    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* pssList ({ type, query }) {
    yield put(addPreloader(type));

    try {
        const resp = yield call(productSalesSpecialist.list, query);
        yield put({
            type: types.SET_PSS_LIST,
            payload: resp.data
        })

    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* pssChangeStatus ({ type, id, _type_ }) {

    try {
        const resp = yield call(productSalesSpecialist.changeStatus, id);
        if (_type_ !== 'profile')
            yield put({
                type: types.SET_PSS_CHANGE_STATUS,
                payload: resp.data
            });
        else
            yield put({
                type: types.SET_PSS_CURRENT,
                payload: resp.data
            });
        yield call(noty, 'success', t('pss/changeStatus/success'));

    } catch (e) {
        yield call(console.warn, e);
    }

}

//-----------------------------------------
//	Root sagas
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.FETCH_PSS_ACTIVE_LIST, pssActiveList);
    yield takeEvery(types.CHANGE_PSS_LOGIN_AND_PASSWORD, pssChangeLoginAndPassword);
    yield takeEvery(types.UPDATE_PSS, pssEdit);
    yield takeEvery(types.FETCH_CHANGE_PSS_STATUS, pssChangeStatus);
    yield takeEvery(types.FETCH_PSS_LIST, pssList);
    yield takeEvery(types.PSS_CREATE, pssCreate);
    yield takeEvery(types.FETCH_PSS_CURRENT, pssCurrent);
}
