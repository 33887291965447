export const CREATE_PRODUCT_LINE_GROUP = '@/product-line-group/CREATE_PRODUCT_LINE_GROUP';
export const EDIT_PRODUCT_LINE_GROUP = '@/product-line-group/EDIT_PRODUCT_LINE_GROUP';

export const CURRENT_PRODUCT_LINE_GROUP = '@/product-line-group/CURRENT_PRODUCT_LINE_GROUP';
export const PUT_PRODUCT_LINE_GROUP = '@/product-line-group/PUT_PRODUCT_LINE_GROUP';

export const PUT_PRODUCT_LINE_GROUP_LIST = '@/product-line-group/PUT_PRODUCT_LINE_GROUP_LIST';
export const FETCH_PRODUCT_LINE_GROUP_LIST = '@/product-line-group/FETCH_PRODUCT_LINE_GROUP_LIST';

export const PUT_PRODUCT_LINE_GROUP_LIST_BY_PLATFORM = '@/product-line-group/PUT_PRODUCT_LINE_GROUP_LIST_BY_PLATFORM';
export const FETCH_PRODUCT_LINE_GROUP_LIST_BY_PLATFORM = '@/product-line-group/FETCH_PRODUCT_LINE_GROUP_LIST_BY_PLATFORM';

export const CHANGE_STATUS_PRODUCT_LINE_GROUP = '@/product-line-group/CHANGE_STATUS_PRODUCT_LINE_GROUP';
