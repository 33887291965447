import * as types from './types';
import {
	call,
	put,
	takeEvery
} from 'redux-saga/effects';
import {usersSettings} from '@/services/api';
import {addPreloader, removePreloader} from '@/redux/common/preloaders';
import { noty } from '@/utilities/notifications';
import t from '@/utilities/translate';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchSettings = () => ({
	type: types.FETCH_USERS_SETTINGS
});

triggers.fetchSetting = (payload) => ({
	type: types.FETCH_USERS_SETTING,
	payload
});

triggers.editSetting = (payload) => ({
	type: types.EDIT_USERS_SETTING,
	payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchSettings ({ type }) {
	yield put(addPreloader(type));
	try {
		const resp = yield call(usersSettings.getUsersSettings);
		yield put({
			type: types.SAVE_USERS_SETTINGS,
			payload: resp.data
		})
	} catch (err) {
		yield call(console.warn, err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* editSetting ({ type, payload }) {
	const {
		id,
		value
	} = payload;

	yield put(addPreloader(type));
	try {
		yield call(usersSettings.editUsersSetting, {id, data: { value }});
		yield put({
			type: types.FETCH_USERS_SETTINGS
		});
		yield put({
			type: '@auth/GET_USER_INFO',
			payload: {
				translates: false
			}
		});
		yield call(noty, 'success', t('userSettings/edit/success'));
	} catch (err) {
		yield call(console.warn, err);
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
	// yield takeEvery(types.FETCH_USERS_SETTING, fetchSetting);
	yield takeEvery(types.FETCH_USERS_SETTINGS, fetchSettings);
	yield takeEvery(types.EDIT_USERS_SETTING, editSetting);
}
