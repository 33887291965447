import { Map } from 'immutable';
import { pick } from "lodash/fp";
import * as types from './types';
import { createReducer } from "@/redux/helpers";
import { apiNumberFormatter } from '../../utilities/formatter';

const initialState = new Map({
  listOfStaff: {},
});

export default createReducer(initialState, {
  [types.PUT_STAFF_LIST]: (state, { payload }) => state.set('listOfStaff', {
    ...payload,
    data: payload.data.map((item) => ({
      ...item,
      ...apiNumberFormatter(pick(['phone'])(item))
    }))
  }),
  [types.UPDATE_STAFF_LIST]: (state, { payload }) => state.update('listOfStaff', (staffs) => {
    staffs.data = staffs.data.map((staff) => (staff.id === payload.id ? (staff.status = payload.status, staff) : staff));
    return staffs;
  })
});
