import * as types from './types';
import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";

const initialState = new Map({
    current: {},
    activeListWithProductsActiveModel: [],
    activeListByCompositeLevel: [],
    activeListByEquipmentGroup: [],
    activeListByManufacturerAndEq: [],
	activeListByProductLineGroup: []
});

export default createReducer(initialState, {
    [types.PUT_ESTIMATE_DESCRIPTION]: (state, { payload })=>state.set('current', payload),
    [types.PUT_ESTIMATE_DESCRIPTION_WITH_PRODUCTS_ACTIVE_MODEL]: (state, { payload })=> state.set('activeListWithProductsActiveModel', payload),
    [types.PUT_ESTIMATE_DESCRIPTION_LIST_BY_COMPOSITE_LEVEL]: (state, { payload })=>state.set('activeListByCompositeLevel', payload),
    [types.PUT_ESTIMATE_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP]: (state, { payload })=>state.set('activeListByEquipmentGroup', payload),
    [types.PUT_ESTIMATE_DESCRIPTION_LIST_BY_MANUFACTURER_AND_EQ]: (state, { payload })=>state.set('activeListByManufacturerAndEq', payload),
    [types.PUT_ESTIMATE_DESCRIPTION_LIST_BY_PRODUCT_LINE_GROUP]: (state, { payload })=>state.set('activeListByProductLineGroup', payload),
});
