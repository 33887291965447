import * as types from './types';
import {createReducer} from "@/redux/helpers";

const initialState = {
    isAuthenticated: false,
    isRefreshingToken: false,
    isGettingUserData: false,
    isLoggingIn: false,
    token: localStorage.getItem('token') ? {
        token_type: 'Bearer'
    } : null,
    settings: [],
    permissions: {}
};

export const reducer = createReducer(initialState, {
    [types.LOGIN_REQUEST](state) {
        return {
            ...state,
            isLoggingIn: true
        };
    },
    [types.GET_USER_INFO](state) {
        return {
            ...state,
            isGettingUserData: true
        };
    },
    [types.LOGIN_SUCCESS](state, {data}) {
        const {actions, ...token} = data;

        return {
            ...state,
            token: token,
            permissions: actions,
            isLoggingIn: false,
            isAuthenticated: true,
            isRefreshingToken: false
        };
    },
    [types.SET_USER_INFO](state, {data}) {
        const {actions, settings, ...token} = data;

        return {
            ...state,
            token: {
                token_type: 'Bearer',
                ...token
            },
            settings: settings,
            permissions: actions,
            isLoggingIn: false,
            isAuthenticated: true,
            isGettingUserData: false,
            isRefreshingToken: false
        };
    },
    [types.LOGIN_FAIL](state) {
        return {
            ...state,
            isLoggingIn: false,
        };
    },
    [types.REFRESH_TOKEN](state) {
        return {
            ...state,
            isRefreshingToken: true
        };
    },
    [types.CLEAR_TOKEN](state) {
        return initialState;
    }
});
