import * as types from './types';
import { Map } from 'immutable';
import { sortBy, pipe, orderBy } from 'lodash/fp';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	manufactures: [],
	current_manufacture: {},
	manufacture_list: [],
});

export default createReducer(initialState, {
	[types.SET_MANUFACTURES_LIST]: (state, action) => state.set('manufactures', action.payload),
	[types.UPDATE_MANUFACTURES_LIST]: (state, action) => state.set('manufactures', (() => {
		const manufacturers = {
			...state.get('manufactures')
		};
		manufacturers.data = state.get('manufactures').data.map(
			(manufacture) => manufacture.id === action.payload.id ? (manufacture.status = action.payload.status, manufacture.actual_sales = action.payload.actual_sales, manufacture) : manufacture
		);
		return manufacturers;
	})()), // eslint-disable-line no-sequences
	[types.SET_MANUFACURER_DATA_LIST]: (state, { payload }) => {
		return state.set('manufacture_list', pipe(
			orderBy([user => user.name.toLowerCase()], ['asc']),
			sortBy(({ name }) => name === 'John Deere' ? -1 : 1)
		)(payload));
	},
	[types.SET_CURRENT_MANUFACTURE]: (state, action) => state.set('current_manufacture', action.payload)
});
