import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import t from '@/utilities/translate';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { factory } from '@/services/api';
import { noty } from "@/utilities/notifications";

//-----------------------------------------
//	Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchFactoriesList = (query)=>({
    type: types.FETCH_FACTORIES_LIST,
    query
})

triggers.fetchFactory = (id)=>({
    type: types.FETCH_FACTORY,
    id
})

triggers.createFactory = (query)=>({
    type: types.CREATE_FACTORY,
    query
})

triggers.editFactory = (query)=>({
    type: types.EDIT_FACTORY,
    query
})

triggers.changeFactoryStatus = (query)=>({
    type: types.CHANGE_FACTORY_STATUS,
    query
})

//-----------------------------------------
//	Sagas
//-----------------------------------------

function* fetchFactoriesList({ type, query }) {
    yield put(addPreloader(type));

    try {
        const resp = yield call(factory.list, query);
        yield put({
            type: types.SET_FACTORIES_LIST,
            payload: resp.data
        })
    } catch(e) {
        console.warn(e);
    } finally {
        yield put(removePreloader(type));
    }
}

function* fetchFactory({ type, id }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(factory.current, id);
        yield put({
            type: types.SET_FACTORY,
            payload: resp.data
        })
    } catch(e) {
        console.warn(e);
    } finally {
        yield put(removePreloader(type));
    }
}

function* createFactory({ type, query }) {
    yield put(addPreloader(type));
    try {
        yield call(factory.create, query);
        yield call(noty, 'success', t('factories/create/success'));
        yield call(window._forward);
    } catch(e) {
        console.warn(e);
    } finally {
        yield put(removePreloader(type));
    }
}

function* editFactory({ type, query }) {
    yield put(addPreloader(type));

    try {
        yield call(factory.edit, query);
        yield call(noty, 'success', t('factories/edit/success'));
        yield call(window._forward, query.id);
    } catch(e) {
        console.warn(e);
    } finally {
        yield put(removePreloader(type));
    }
}

function* changeFactoryStatus({ type, query }) {
    yield put(addPreloader(type));

    try {
        const resp = yield call(factory.changeStatus, query);

        yield put({
            type: types.SET_FACTORY_WITH_CHANGED_STATUS,
            payload: resp.data
        })
        yield call(noty, 'success', t('factories/changeStatus/success'))
    } catch(e) {
        console.warn(e);
    } finally {
        yield put(removePreloader(type));
    }
}

//-----------------------------------------
//	Root sagas
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.FETCH_FACTORIES_LIST, fetchFactoriesList);
    yield takeEvery(types.FETCH_FACTORY, fetchFactory);
    yield takeEvery(types.CREATE_FACTORY, createFactory);
    yield takeEvery(types.EDIT_FACTORY, editFactory);
    yield takeEvery(types.CHANGE_FACTORY_STATUS, changeFactoryStatus);
}
