import React from 'react';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { compose } from 'redux';
import Forbidden from '@/pages/403/Forbidden';
import { Spiner } from '@/components/common/Spiner';

const locationHelper = locationHelperBuilder({});

export const isAuth = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state) => state.auth.isAuthenticated === true,
  authenticatingSelector: (state) => state.auth.isRefreshingToken || state.auth.isGettingUserData,
  AuthenticatingComponent: Spiner,
  wrapperDisplayName: 'UserIsAuthenticated'
});

export const isGuest = connectedRouterRedirect({
  redirectPath: (state, ownProps) => {
	const willLogout = ownProps.location.search.endsWith('logout');
	return willLogout ? '/' : locationHelper.getRedirectQueryParam(ownProps);
  },
  allowRedirectBack: false,
  authenticatedSelector: (state) => state.auth.isAuthenticated === false,
  authenticatingSelector: (state) => state.auth.isRefreshingToken,
  AuthenticatingComponent: Spiner,
  wrapperDisplayName: 'UserIsNotAuthenticated'
});

export const isAccessByRule = (component) => (rule) => compose(isAuth, connectedAuthWrapper({
  authenticatedSelector: (state) => {
	if (!rule || !component) return false;

	return state.auth.permissions[rule];
  },
  wrapperDisplayName: 'isAccessByRule',
  FailureComponent: () => <Forbidden/>
}))(component);
