import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import t from '@/utilities/translate';
import { Row, Button, Col, Popconfirm, Spin } from 'antd';
import { reduxForm, SubmissionError } from 'redux-form';
import ButtonPending from '@/components/common/form/ButtonPending';
import { formResolver } from '@/utilities/form';
import { connect } from 'react-redux';
import { getPreloader } from '@/redux/common/preloaders';
import { UPDATE_LOCATIONS, FETCH_CURRENT_CLIENT } from '@/redux/client/types';
import { triggers } from '@/redux/client/actions';

export const FORM_NAME = 'client/editLocations';

const FORM_RESPONSIVE = { colSpan: 24 };

const LocationsForm = (props) => {
	const {
		handleSubmit,
		closeModal,
		fields = [],
		pending = false,
	} = props;

	const FIELDS = compose(
		(fields) => formResolver(fields, FORM_RESPONSIVE),
		fields,
	)(props, FORM_NAME);

	return (
		<Spin spinning={pending}>
			<form onSubmit={handleSubmit} className="ant-row--size-compact ant-row--size-tiny:def">
				<Row>
					{FIELDS}
					<Col span={24} className="m-t_15">
						<Row gutter={15}>
							<Col lg={6} xs={24}>
								<ButtonPending
									type={UPDATE_LOCATIONS}
									className="md-mb w-100 ant-btn--multiple-row"
									htmlType={'submit'}
								>
									{t('manufacture/edit/submit')}
								</ButtonPending>
							</Col>
							<Col lg={6} xs={24}>
								<Popconfirm
									onConfirm={closeModal}
									placement="bottomRight"
									title={t('client/updateLocations/cancel/popconfirm/title')}
									okText={t('common/yes')}
									cancelText={t('common/no')}
								>
									<Button className="w-100 ant-btn--multiple-row">
										{t('manufacture/cancel')}
									</Button>
								</Popconfirm>
							</Col>
						</Row>
					</Col>
				</Row>
			</form>
		</Spin>
	);
}

LocationsForm.propTypes = {
	sendQuery: PropTypes.func,
	handleSubmit: PropTypes.func,
	closeModal: PropTypes.func,
}

const mapStateToProps = (state) => ({
	initialValues: state.client.get('clientCurrent'),
	pending: getPreloader(FETCH_CURRENT_CLIENT),
});

const mapDispatchToProps = {
	...triggers,
};

const wrapper = compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
);

const LocationsFormEnhanced = reduxForm({
	enableReinitialize: true,
	form: FORM_NAME
})(LocationsForm);

export default wrapper(({ sendQuery, initialValues, id, closeModal, ...props }) => {
	useEffect(() => {
		props.currentClient(id);
	}, []);

	return (
		<LocationsFormEnhanced
			onSubmit={(payload) => {
				const err = props.validate(payload);
				if (err.length) {
					throw new SubmissionError(err);
				}
				props.clientUpdateLocations(id, sendQuery(payload));
				closeModal && closeModal();
			}}
			initialValues={initialValues}
			closeModal={closeModal}
			{...props}
		/>
	);
});
