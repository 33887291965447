export const baseMachineryDescriptionForward = (location, props, routes) => {
  switch (location) {
    case routes["base-machinery-description/create"].link():
      return routes["platform"].link();

    case routes["base-machinery-description/edit"].link(props.id):
      return routes["platform"].link();
    default:
      break;
  }
};
