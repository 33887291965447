import React from 'react';
import PropTypes from 'prop-types';

const MyComponent = props => {

    return (
        <div id={'progressbar'} className={props.loading ? 'is-active' : ''}/>
    );
};

MyComponent.propTypes = {
    loading: PropTypes.bool,
};

export default MyComponent;
