import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import T from '@/utilities/translate';
import { notificationEmails } from '@/services/api';
import { noty } from "@/utilities/notifications";
import { addPreloader, removePreloader } from '../common/preloaders';
import { store } from '@/redux/store';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchNotificationEmails = (payload) => ({
	type: types.FETCH_NOTIFICATION_EMAILS_LIST,
	payload
});

triggers.getUsersListForNotificationEmails = (payload) => ({
	type: types.GET_USERS_FOR_NOTIFICATION_LIST,
	payload
});

triggers.saveNotificationEmails = (payload) => ({
	type: types.SAVE_NOTIFICATION_EMAILS_LIST,
	payload
});

triggers.changeNotificationEmail = (payload) => ({
	type: types.CHANGE_NOTIFICATION_EMAIL,
	payload
});

triggers.changeNotificationEmailStatus = (payload) => ({
	type: types.CHANGE_NOTIFICATION_EMAIL_STATUS,
	payload
});

triggers.deleteNotificationEmail = (payload) => ({
	type: types.DELETE_NOTIFICATION_EMAIL,
	payload
});

triggers.getNotificationEmail = (payload) => ({
	type: types.GET_NOTIFICATION_EMAIL_INFO,
	payload
});

triggers.saveNotificationEmail = (payload) => ({
	type: types.SAVE_NOTIFICATION_EMAIL_INFO,
	payload
});

triggers.createNotificationEmail = (payload) => ({
	type: types.CREATE_NOTIFICATION_EMAIL,
	payload
});

triggers.setFilter = (payload) => ({
	type: types.SET_FILTER,
	payload
});

export function* fetchNotificationsList ({ type, payload }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(notificationEmails.list, payload);
		yield put({
			type: types.SAVE_NOTIFICATION_EMAILS_LIST,
			payload: data
		});
	} catch (err) {
		throw err;
	} finally {
		yield put(removePreloader(type));
	}
}

export function* createNotificationEmail ({ payload }) {
	try {
		yield call(notificationEmails.create, payload);
		yield call(noty, 'success', T('notificationEmail/create/success'));
		const filter = store.getState().notificationEmails.get('filter');
		yield put({
			type: types.FETCH_NOTIFICATION_EMAILS_LIST,
			payload: { ...filter }
		});
	} catch (err) {
		throw err;
	}
}

export function* changeNotificationEmailStatus ({ payload }) {
	try {
		yield call(notificationEmails.change_status, payload);
		yield call(noty, 'success', T('notificationEmail/changeStatus/success'));
		const filter = store.getState().notificationEmails.get('filter');
		yield put({
			type: types.FETCH_NOTIFICATION_EMAILS_LIST,
			payload: { ...filter }
		});
	} catch (err) {
		throw err;
	}
}

export function* deleteNotificationEmail ({ payload }) {
	try {
		yield call(notificationEmails.delete, payload);
		yield call(noty, 'success', T('notificationEmail/delete/success'));
		const filter = store.getState().notificationEmails.get('filter');
		yield put({
			type: types.FETCH_NOTIFICATION_EMAILS_LIST,
			payload: { ...filter }
		});
	} catch (err) {
		throw err;
	}
}

export function* changeNotificationEmail ({ payload }) {
	const { emailId, ...rest } = payload;
	try {
		yield call(notificationEmails.edit, emailId, { ...rest });
		yield call(noty, 'success', T('notificationEmail/change/success'));
		const filter = store.getState().notificationEmails.get('filter');
		yield put({
			type: types.FETCH_NOTIFICATION_EMAILS_LIST,
			payload: { ...filter }
		});
	} catch (err) {
		throw err;
	}
}

export function* getNotificationEmailInfo ({ payload }) {
	try {
		const response = yield call(notificationEmails.current, payload);
		yield put({
			type: types.SAVE_NOTIFICATION_EMAIL_INFO,
			payload: response.data
		})
	} catch (err) {
		throw err;
	}
}

export function* getUsersListForNotificationEmails ({ payload }) {
	try {
		const response = yield call(notificationEmails.getAvailableUsers, payload);
		yield put({
			type: types.SET_USERS_FOR_NOTIFICATION_LIST,
			payload: response.data
		});
	} catch (err) {
		throw err;
	}
}

export function* saga () {
	yield takeEvery(types.FETCH_NOTIFICATION_EMAILS_LIST, fetchNotificationsList);
	yield takeEvery(types.CREATE_NOTIFICATION_EMAIL, createNotificationEmail);
	yield takeEvery(types.CHANGE_NOTIFICATION_EMAIL_STATUS, changeNotificationEmailStatus);
	yield takeEvery(types.DELETE_NOTIFICATION_EMAIL, deleteNotificationEmail);
	yield takeEvery(types.GET_NOTIFICATION_EMAIL_INFO, getNotificationEmailInfo);
	yield takeEvery(types.CHANGE_NOTIFICATION_EMAIL, changeNotificationEmail);
	yield takeEvery(types.GET_USERS_FOR_NOTIFICATION_LIST, getUsersListForNotificationEmails);
}
