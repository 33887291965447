export const farmSizeForward = (location, props, routes) => {
  switch (location) {
	case routes['farm-size/create'].link():
	  return routes['farm-size/list'].link();

	case routes['farm-size/edit'].link(props.id):
	  return routes['farm-size/list'].link();
	default:
	  break;
  }
};
