import { call, put, takeEvery } from 'redux-saga/effects';
import _ from 'lodash/fp';
import t from '@/utilities/translate';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { deal, dealLostReasons } from '@/services/api';
import { noty } from '@/utilities/notifications';
import { history } from '@/redux/history';

//-----------------------------------------
//    Dealer triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchActionList = () => ({
  type: types.FETCH_NEXT_ACTIONS_LIST,
});

triggers.fetchDealItem = (id) => ({
  type: types.FETCH_DEAL_ITEM,
  id
});

triggers.fetchDeals = (query) => ({
  type: types.FETCH_DEALS_LIST,
  query,
});

triggers.fetchArchivedDeals = (query) => ({
  type: types.FETCH_ARCHIVED_DEALS,
  query,
});

triggers.editDeal = (payload) => ({
  type: types.EDIT_DEAL,
  payload
});

triggers.deleteDeal = (payload) => ({
	type: types.DELETE_DEAL,
	payload
});

triggers.fetchStatusesActiveList = () => ({
  type: types.FETCH_STATUSES_ACTIVE_LIST,
});

triggers.fetchActionsActiveList = () => ({
  type: types.FETCH_ACTIONS_ACTIVE_LIST,
});

triggers.fetchResultsActiveList = () => ({
  type: types.FETCH_RESULTS_ACTIVE_LIST,
});

triggers.fetchCurrentDeal = (id) => ({
  type: types.FETCH_CURRENT_DEAL,
  id,
});

triggers.fetchDealHistory = (id) => ({
  type: types.FETCH_DEAL_HISTORY,
  id,
});

triggers.fetchAvailableManagers = (id) => ({
  type: types.FETCH_AVAILABLE_MANAGER,
  id,
});

triggers.fetchAvailableClients = (id) => ({
  type: types.FETCH_AVAILABLE_CLIENT,
  id,
});

triggers.changeManager = (payload) => ({
  type: types.CHANGE_MANAGER,
  payload,
});

triggers.changeClient = (payload) => ({
  type: types.CHANGE_CLIENT,
  payload,
});

triggers.fetchLostReasons = (payload) => ({
	type: types.FETCH_LOST_REASONS,
	payload,
});

triggers.fetchAllDealsList = (payload) => ({
	type: types.FETCH_ALL_DEALS_LIST,
	payload
});

triggers.clearAllDealsList = () => ({
	type: types.CLEAR_ALL_DEALS_LIST
});

const putActionsList = (payload) => ({
  type: types.SET_NEXT_ACTIONS_LIST,
  payload,
});

const putDeals = (payload) => ({
  type: types.SET_DEALS_LIST,
  payload,
});

const putArchivedDeals = (payload) => ({
  type: types.SET_ARCHIVED_DEALS,
  payload,
});

const putStatusesActiveList = (payload) => ({
  type: types.SET_STATUSES_ACTIVE_LIST,
  payload,
});

const putActionsActiveList = (payload) => ({
  type: types.SET_ACTIONS_ACTIVE_LIST,
  payload,
});

const putResultsActiveList = (payload) => ({
  type: types.SET_RESULTS_ACTIVE_LIST,
  payload,
});

const putCurrentDeal = (payload) => ({
  type: types.SET_CURRENT_DEAL,
  payload,
});

const putDealHistory = (payload) => ({
  type: types.SET_DEAL_HISTORY,
  payload,
});

const putAvailableManagers = (payload) => ({
  type: types.SET_AVAILABLE_MANAGER,
  payload,
});

const putAvailableClients = (payload) => ({
  type: types.SET_AVAILABLE_CLIENT,
  payload,
});

const putDealItem = (payload) => ({
  type: types.SET_DEAL_ITEM,
  payload,
});

const putLostReasons = (payload) => ({
	type: types.SET_LOST_REASONS,
	payload,
});

const setAllDealsFilter = (payload) => ({
	type: types.SET_ALL_DEALS_FILTER,
	payload
});

triggers.editDealItem = (payload) => ({
  type: types.CHANGE_DEAL_ITEM,
  payload,
});

triggers.archiveHistoryItemStatus = (payload) => ({
	type: types.ARCHIVE_HISTORY_ITEM_STATUS,
	payload,
});

triggers.archiveHistoryItemResult = (payload) => ({
	type: types.ARCHIVE_HISTORY_ITEM_RESULT,
	payload,
});

triggers.changeFiscalYear = (payload) => ({
	type: types.CHANGE_FISCAL_YEAR,
	payload,
});

//-----------------------------------------
//	Dealer saga actions
//-----------------------------------------

export function* fetchNextActionList() {
  try {
    const { data } = yield call(deal.actionList);
    yield put(putActionsList(data));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* fetchDeals({ type, query }) {
  yield put(addPreloader(type));
  try {
    if(query.lastVisitFrom) {
      query.lastVisitFrom = _.pipe(
        _.split(''),
        _.slice(0, _.size(query.lastVisitFrom) - 3),
        _.join('')
      )(query.lastVisitFrom);
    }
    if(query.lastVisitTo) {
      query.lastVisitTo = _.pipe(
        _.split(''),
        _.slice(0, _.size(query.lastVisitTo) - 3),
        _.join('')
      )(query.lastVisitTo);
    }
    const { data } = yield call(deal.list, query);
    yield put(putDeals(data));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* fetchArchivedDeals({ type, query }) {
  yield put(addPreloader(type));
  try {
    if(query.lastVisitFrom) {
      query.lastVisitFrom = _.pipe(
        _.split(''),
        _.slice(0, _.size(query.lastVisitFrom) - 3),
        _.join('')
      )(query.lastVisitFrom);
    }
    if(query.lastVisitTo) {
      query.lastVisitTo = _.pipe(
        _.split(''),
        _.slice(0, _.size(query.lastVisitTo) - 3),
        _.join('')
      )(query.lastVisitTo);
    }
    const { data } = yield call(deal.archivedList, query);
    yield put(putArchivedDeals(data));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* fetchCurrentDeal({ type = types.FETCH_CURRENT_DEAL, id }) {
  yield put(addPreloader(type));
  try {
    const { data } = yield call(deal.current, id);
    yield put(putCurrentDeal(data));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* editCurrentDeal ({ type = types.EDIT_DEAL, payload }) {
  yield put(addPreloader(type));
  try {
    const { data } = yield call(deal.edit, payload);
    yield call(noty, 'success', t('deal/changeStatus/success'));
    yield put(putCurrentDeal(data));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* deleteCurrentDeal ({ type = types.DELETE_DEAL, payload }) {
	yield put(addPreloader(type));
	try {
		yield call(deal.delete, payload);
		yield call(noty, 'success', t('deal/deleteDeal/success'));
		yield call(history.go, -1);
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchDealHistory({ type, id }) {
  yield put(addPreloader(type));
  try {
    const { data } = yield call(deal.history, id);
    yield put(putDealHistory(data));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* fetchStatusesActiveList() {
  try {
    const { data } = yield call(deal.statusesActiveList);
    yield put(putStatusesActiveList(data));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* fetchActionsActiveList() {
  try {
    const { data } = yield call(deal.actionsActiveList);
    yield put(putActionsActiveList(data));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* fetchResultsActiveList() {
  try {
    const { data } = yield call(deal.resultsActiveList);
    yield put(putResultsActiveList(data));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* fetchAvailableManagers({ id }) {
  try {
    const { data } = yield call(deal.getManagers, id);
    yield put(putAvailableManagers(data));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* fetchAvailableClients({ id }) {
  try {
    const { data } = yield call(deal.getClients, id);
    yield put(putAvailableClients(data));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* changeManager({ type, payload }) {
  try {
    const { dealId, managerId } = payload;
    yield call(deal.changeManager, { dealId, managerId });
    yield call(noty, 'success', t('deal/changeManager/success'));
    yield call(fetchCurrentDeal, { id: dealId });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* changeClient({ type, payload }) {
  try {
    const { dealId, clientId } = payload;
    yield call(deal.changeClient, { dealId, clientId });
    yield call(noty, 'success', t('deal/changeClient/success'));
    yield call(fetchCurrentDeal, { id: dealId });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* fetchDealItem(action) {
  const { id } = action;
  try {
    const { data } = yield call(deal.getDealItem, id);
    yield put(putDealItem(data));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* changeDealItem(action) {
  const { payload } = action;
  try {
    const { data } = yield call(deal.changeDealItem, { payload });
    yield put(putDealItem(data));
    yield call(noty, 'success', t('deal/editItem/success'));
    yield call(history.go, -1);
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* archiveHistoryItemStatus(action) {
	const { payload, type } = action;
	yield put(addPreloader(type));

	try {
		yield call(deal.archiveHistoryItemStatus, payload);
		const { data } = yield call(deal.history, payload.dealId);
		yield put(putDealHistory(data));
		yield call(noty, 'success', t('deal/archiveItemStatus/success'));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* archiveHistoryItemResult(action) {
	const { payload, type } = action;
	yield put(addPreloader(type));

	try {
		yield call(deal.archiveHistoryItemResult, payload);
		const { data } = yield call(deal.history, payload.dealId);
		yield put(putDealHistory(data));
		yield call(noty, 'success', t('deal/archiveItemResult/success'));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* changeFiscalYear(action) {
	const { payload, type } = action;
	yield put(addPreloader(type));

	try {
		const { data } = yield call(deal.changeFiscalYear, payload);
		yield put(putCurrentDeal(data));
		yield call(noty, 'success', t('deal/changeFiscalYear/success'));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchLostReasons({ type }) {
	try {
		const { data } = yield call(dealLostReasons.activeList);
		yield put(putLostReasons(data));
	} catch (e) {
		yield call(console.warn, e);
	}
}

export function* fetchAllDealsList({ type, payload }) {
	yield put(addPreloader(type));

	try {
		const { data } = yield call(deal.fullList, payload);
		yield put({
			type: types.SET_ALL_DEALS_LIST,
			payload: data
		});
		yield put(setAllDealsFilter(payload));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* clearAllDealsList({ type }) {
	yield put(addPreloader(type));

	try {
		yield put({ type: types.CLEAR_ALL_DEALS });
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root deal saga
//-----------------------------------------

export function* saga() {
  yield takeEvery(types.CHANGE_MANAGER, changeManager);
  yield takeEvery(types.CHANGE_CLIENT, changeClient);
  yield takeEvery(types.FETCH_AVAILABLE_MANAGER, fetchAvailableManagers);
  yield takeEvery(types.FETCH_AVAILABLE_CLIENT, fetchAvailableClients);
  yield takeEvery(types.FETCH_DEAL_HISTORY, fetchDealHistory);
  yield takeEvery(types.FETCH_CURRENT_DEAL, fetchCurrentDeal);
  yield takeEvery(types.FETCH_NEXT_ACTIONS_LIST, fetchNextActionList);
  yield takeEvery(types.FETCH_DEALS_LIST, fetchDeals);
  yield takeEvery(types.FETCH_ARCHIVED_DEALS, fetchArchivedDeals);
  yield takeEvery(types.FETCH_ACTIONS_ACTIVE_LIST, fetchActionsActiveList);
  yield takeEvery(types.FETCH_STATUSES_ACTIVE_LIST, fetchStatusesActiveList);
  yield takeEvery(types.FETCH_RESULTS_ACTIVE_LIST, fetchResultsActiveList);
  yield takeEvery(types.FETCH_DEAL_ITEM, fetchDealItem);
  yield takeEvery(types.CHANGE_DEAL_ITEM, changeDealItem);
  yield takeEvery(types.EDIT_DEAL, editCurrentDeal);
  yield takeEvery(types.DELETE_DEAL, deleteCurrentDeal);
  yield takeEvery(types.ARCHIVE_HISTORY_ITEM_STATUS, archiveHistoryItemStatus);
  yield takeEvery(types.ARCHIVE_HISTORY_ITEM_RESULT, archiveHistoryItemResult);
  yield takeEvery(types.CHANGE_FISCAL_YEAR, changeFiscalYear);
  yield takeEvery(types.FETCH_LOST_REASONS, fetchLostReasons);
  yield takeEvery(types.FETCH_ALL_DEALS_LIST, fetchAllDealsList);
  yield takeEvery(types.CLEAR_ALL_DEALS_LIST, clearAllDealsList);
}
