import { all, call, put, takeEvery, throttle } from 'redux-saga/effects';
import { systemApi } from '@/services/api';

import * as types from './types';

//-----------------------------------------
//	Triggers
//-----------------------------------------

export const triggers = {};

triggers.getTranslate = (lang) => ({
    type: types.FETCH_TRANSLATE,
    payload: lang
})

triggers.getLocation = (payload = 'Херсон') => ({
    type: types.FETCH_LOCATIONS,
    payload
})

triggers.fermCategories = (query)=>({
    type: types.FETCH_FERM_CATEGORIES,
    query
})

triggers.fetchRoles = ()=>({
    type: types.FETCH_ROLES
})

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchRoles({ type }) {
    const setToState = (resp)=>({
        type: types.PUT_ROLES,
        payload: resp.data
    })

    try {
        const resp = yield call(systemApi.roles);
        yield put(setToState(resp));
    } catch(e){
        yield call(console.warn, e);
    }

}

export function* getTranslate({ type, payload }) {

    try {
        yield put({
            type: types.SET_TRANSLATE,
            payload: {},
            lang: payload
        });
        const response = yield call(systemApi.translate, payload);
        yield put({
            type: types.SET_TRANSLATE,
            payload: response.data,
            lang: payload
        });

    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* fermCategories({ type, query }) {

    try {
        const resp = yield call(systemApi.fermCategories, query);
        yield put({
            type: types.SET_FERM_CATEGORIES,
            payload: resp.data
        })
    } catch(e){
        yield call(console.warn, e);
    }

}

export function* fetchLocation({ type, payload }) {

    try {
        const resp = yield call(systemApi.location, payload);
        yield put({
            type: types.SET_LOCATIONS,
            payload: resp.data
        })
    } catch (e) { }

}

export function* saga() {
    yield all([
        takeEvery(types.FETCH_ROLES, fetchRoles),
        takeEvery(types.FETCH_FERM_CATEGORIES, fermCategories),
        takeEvery(types.FETCH_TRANSLATE, getTranslate),
        throttle(500, types.FETCH_LOCATIONS, fetchLocation)
    ]);
}
