//-----------------------------------------
//	Lits
//-----------------------------------------

export const FETCH_CLIENT_LIST = '@/client/FETCH_CLIENT_LIST';
export const SET_CLIENT_LIST = '@/client/SET_CLIENT_LIST';

//-----------------------------------------
//	Create
//-----------------------------------------

export const CLIENT_CREATE = '@/client/CLIENT_CREATE';

//-----------------------------------------
//	Array of clients
//-----------------------------------------

export const CLIENT_ARRAY = '@/client/CLIENT_ARRAY';
export const SET_CLIENT_ARRAY = '@/client/SET_CLIENT_ARRAY';

//-----------------------------------------
//	Current client
//-----------------------------------------

export const FETCH_CURRENT_CLIENT = '@/client/FETCH_CURRENT_CLIENT';
export const SET_CURRENT_CLIENT = '@/client/SET_CURRENT_CLIENT';

//-----------------------------------------
//	Update client
//-----------------------------------------

export const UPDATE_CURRENT_CLIENT = '@/client/UPDATE_CURRENT_CLIENT';

//-----------------------------------------
//	Client change status
//-----------------------------------------

export const CLIENT_CHANGE_STATUS = '@/client/CLIENT_CHANGE_STATUS';
export const CHANGE_STATUS_FROM_LIST = '@/client/CHANGE_STATUS_FROM_LIST';

export const FETCH_CLIENTS_BY_COMPANY_NAME = '@/client/FETCH_CLIENTS_BY_COMPANY_NAME';
export const PUT_CLIENTS_BY_COMPANY_NAME = '@/client/PUT_CLIENTS_BY_COMPANY_NAME';

export const FETCH_CHILD_CLIENTS_BY_COMPANY_NAME = '@/client/FETCH_CHILD_CLIENTS_BY_COMPANY_NAME';
export const PUT_CHILD_CLIENTS_BY_COMPANY_NAME = '@/client/PUT_CHILD_CLIENTS_BY_COMPANY_NAME';
export const CLEAR_CHILD_CLIENTS_BY_COMPANY_NAME = '@/client/CLEAR_CHILD_CLIENTS_BY_COMPANY_NAME';

export const FETCH_CONTACTS = '@/client/FETCH_CONTACTS';
export const PUT_CONTACTS = '@/client/PUT_CONTACTS';
export const REMOVE_CONTACT_REQUEST = '@/client/REMOVE_CONTACT_REQUEST';

export const CREATE_CONTACT_REQUEST = '@/client/CREATE_CONTACT_REQUEST';

export const FETCH_CONTACT_REQUEST = '@/client/FETCH_CONTACT_REQUEST';
export const FETCH_CONTACT_EMITTER = '@/client/FETCH_CONTACT_EMITTER';

export const UPDATE_CONTACT_REQUEST = '@/client/UPDATE_CONTACT_REQUEST';

export const UPDATE_LOCATIONS = '@/client/UPDATE_LOCATIONS';
