import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Select, Form } from 'antd';
import t from '@/utilities/translate';
import { triggers as triggersDeal } from '@/redux/deal/actions';
import DispatchOnMount from '@/components/common/DispatchOnMount';
// import { routes } from '@/routing/route-tree';
// import { history } from '@/redux/history';

const AvailableManagers = ({
  modalProps,
  closeModal: closeModalFn,
  dealId,
  changeManager,
  availableManagers,
}) => {
  const [opt, setOpt] = useState({ manager: null });
  const closeModal = () => {
    closeModalFn();
    changeManager({ dealId, managerId: opt.manager.id });
  };

  return (
    <Modal {...modalProps} onOk={closeModal} okText={t('deal/availableManagers/change')}>
      <DispatchOnMount actionCreator={() => triggersDeal.fetchAvailableManagers(dealId)}>
        <Form.Item label={t('deal/availableManagers/label')} style={{ padding: '20px 0 0 0' }}>
          <Select style={{ width: '100%' }}>
            {availableManagers.map((manager) => (
              <Select.Option
                key={manager.id}
                onClick={() => setOpt({ manager })}
              >
                {manager.name +" "+ manager.last_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </DispatchOnMount>
    </Modal>
  );
};

AvailableManagers.defaultProps = {
  modalProps: {},
  availableManagers: [],
};

AvailableManagers.propTypes = {
  modalProps: PropTypes.any,
  changeManager: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  dealId: PropTypes.number.isRequired,
  availableManagers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
};


export default connect(
  (state) => ({
    availableManagers: state.deal.get('availableManagers'),
  }),
  {
    ...triggersDeal,
  },
)(AvailableManagers);
