export const CREATE_EQUIPMENT_GROUP = '@/equipment-group/CREATE_EQUIPMENT_GROUP';
export const EDIT_EQUIPMENT_GROUP = '@/equipment-group/EDIT_EQUIPMENT_GROUP';

export const CURRENT_EQUIPMENT_GROUP = '@/equipment-group/CURRENT_EQUIPMENT_GROUP';
export const PUT_EQUIPMENT_GROUP = '@/equipment-group/PUT_EQUIPMENT_GROUP';
export const PUT_EQUIPMENT_GROUP_BY_MANUFACTURER = '@/equipment-group/PUT_EQUIPMENT_GROUP_BY_MANUFACTURER';

export const PUT_EQUIPMENT_GROUP_LIST_BY_PRODUCT_LINE = '@/equipment-group/PUT_EQUIPMENT_GROUP_LIST_BY_PRODUCT_LINE';
export const FETCH_EQUIPMENT_GROUP_LIST_BY_PRODUCT_LINE = '@/equipment-group/FETCH_EQUIPMENT_GROUP_LIST_BY_PRODUCT_LINE';

export const CHANGE_STATUS_EQUIPMENT_GROUP = '@/equipment-group/CHANGE_STATUS_EQUIPMENT_GROUP';

export const FETCH_EQUIPMENT_GROUP_ACTIVE_LIST = '@/equipment-group/FETCH_EQUIPMENT_GROUP_ACTIVE_LIST';
export const PUT_EQUIPMENT_GROUP_ACTIVE_LIST = '@/equipment-group/PUT_EQUIPMENT_GROUP_ACTIVE_LIST';

export const PUT_EQUIPMENT_GROUP_LIST_BY_PLATFORM = '@/equipment-group/PUT_EQUIPMENT_GROUP_LIST_BY_PLATFORM';
export const FETCH_EQUIPMENT_GROUP_LIST_BY_PLATFORM = '@/equipment-group/FETCH_EQUIPMENT_GROUP_LIST_BY_PLATFORM';
export const FETCH_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER = '@/equipment-group/FETCH_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER';
export const PUT_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER = '@/equipment-group/PUT_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER';

export const FETCH_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER_AND_PLATFORM = '@/equipment-group/FETCH_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER_AND_PLATFORM';
export const PUT_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER_AND_PLATFORM = '@/equipment-group/PUT_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER_AND_PLATFORM';
