import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
    product_list: [],
    product_current: {},
    productListByParams: [],
    currenciesList: []
});

export default createReducer(initialState, {
    [types.SET_PRODUCT_LIST]: (state, { payload }) => state.set('product_list', payload),
    [types.UPDATE_PRODUCT_LIST]: (state, { payload }) => state.update('product_list', (value) => ({
        ...value,
        data: value.data.map(product => {
            if (product.id === payload.id)
                return payload;
            return product
        })
    })),
    [types.SET_CURRENT_PRODUCT]: (state, { payload }) => state.set('product_current', payload),
    [types.SET_LIST_BY_PARAMS]: (state, { payload }) => state.set('productListByParams', payload),
    [types.CLEAR_PRODUCT_LIST]: (state, { payload }) => state.set('productListByParams', []),
    [types.SET_CURRENCIES_LIST]: (state, { payload }) => state.set('currenciesList', payload),
});
