export const CREATE_ESTIMATE_DESCRIPTION = '@/estimate-description/CREATE_ESTIMATE_DESCRIPTION';
export const EDIT_ESTIMATE_DESCRIPTION = '@/estimate-description/EDIT_ESTIMATE_DESCRIPTION';

export const FETCH_ESTIMATE_DESCRIPTION_WITH_PRODUCTS_ACTIVE_MODEL = '@/estimate-description/ETCH_ESTIMATE_DESCRIPTION_WITH_PRODUCTS_ACTIVE_MODEL';
export const PUT_ESTIMATE_DESCRIPTION_WITH_PRODUCTS_ACTIVE_MODEL = '@/estimate-description/PUT_ESTIMATE_DESCRIPTION_WITH_PRODUCTS_ACTIVE_MODEL';

export const CURRENT_ESTIMATE_DESCRIPTION = '@/estimate-description/CURRENT_ESTIMATE_DESCRIPTION';
export const PUT_ESTIMATE_DESCRIPTION = '@/estimate-description/PUT_ESTIMATE_DESCRIPTION';

export const PUT_ESTIMATE_DESCRIPTION_LIST_BY_COMPOSITE_LEVEL = '@/estimate-description/PUT_ESTIMATE_DESCRIPTION_LIST_BY_COMPOSITE_LEVEL';
export const FETCH_ESTIMATE_DESCRIPTION_LIST_BY_COMPOSITE_LEVEL = '@/estimate-description/FETCH_ESTIMATE_DESCRIPTION_LIST_BY_COMPOSITE_LEVEL';

export const CHANGE_STATUS_ESTIMATE_DESCRIPTION = '@/estimate-description/CHANGE_STATUS_ESTIMATE_DESCRIPTION';

export const PUT_ESTIMATE_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP = '@/estimate-description/PUT_ESTIMATE_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP';
export const FETCH_ESTIMATE_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP = '@/estimate-description/FETCH_ESTIMATE_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP';

export const PUT_ESTIMATE_DESCRIPTION_LIST_BY_MANUFACTURER_AND_EQ = '@/estimate-description/PUT_ESTIMATE_DESCRIPTION_LIST_BY_MANUFACTURER_AND_EQ';
export const FETCH_ESTIMATE_DESCRIPTION_LIST_BY_MANUFACTURER_AND_EQ = '@/estimate-description/FETCH_ESTIMATE_DESCRIPTION_LIST_BY_MANUFACTURER_AND_EQ';

export const PUT_ESTIMATE_DESCRIPTION_LIST_BY_PRODUCT_LINE_GROUP = '@/estimate-description/PUT_ESTIMATE_DESCRIPTION_LIST_BY_PRODUCT_LINE_GROUP';
export const FETCH_ESTIMATE_DESCRIPTION_LIST_BY_PRODUCT_LINE_GROUP = '@/estimate-description/FETCH_ESTIMATE_DESCRIPTION_LIST_BY_PRODUCT_LINE_GROUP';
