import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

const rootNode = document.getElementById('root');

//-----------------------------------------
//	PWA
//-----------------------------------------

// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('service-worker1.js')
//         .then((reg) => {
//             // регистрация сработала
//             console.log('Registration succeeded. Scope is ' + reg.scope);
//         }).catch((error) => {
//             // регистрация прошла неудачно
//             console.log('Registration failed with ' + error);
//         });
// }

// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <Component />,
    rootNode,
  );
};

// Do this once
// registerServiceWorker();

// Render once
render(Root);

/*
if (process.env.NODE_ENV !== 'production') {
    const {whyDidYouUpdate} = require('why-did-you-update');

    whyDidYouUpdate(React, {
        // include: /^DriverDocs/,
        groupByComponent: true,
        collapseComponentGroups: false
    });
}
*/
