import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import EditActualSalesPlansForm from '@/components/actual-sales-plans/edit';
import fields from '@/components/actual-sales-plans/fields';
import validator from '@/utilities/validator';
import t from '@/utilities/translate';

export const FORM_NAME = 'actualSalesPlans/edit';

const sendQuery = (values) => ({
	id: values.id,
	company_lost_deal_id: values.company_lost_deal_id,
	count: values.count,
	cost: values.cost,
	amount: values.amount,
	client_id: values.client_id,
	equipment_type: values.equipment_type,
	year: values.year,
	month: values.month,
	comment: values.comment
});

const EditActualSalesPlan = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		initialValues,
	} = props;

	return (
		<Modal
			{...modalProps}
			title={`${t('actual-sales-plans/edit/title')}: ${initialValues.description_product_name}`}
			footer={null}
		>
			<EditActualSalesPlansForm
				form={FORM_NAME}
				fields={fields}
				sendQuery={sendQuery}
				closeModal={closeModalFn}
				validate={validator.actualSalesPlansFields}
				{...props}
			/>
		</Modal>
	);
};

EditActualSalesPlan.defaultProps = {
	modalProps: {},
};

EditActualSalesPlan.propTypes = {
	modalProps: PropTypes.any,
	closeModal: PropTypes.func.isRequired,
};

export default EditActualSalesPlan;
