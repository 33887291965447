import axios from 'axios';
import React from 'react';
import MockAdapter from 'axios-mock-adapter';
import { List } from 'antd';
import { store } from '../redux/store';
import { noty } from '@/utilities/notifications';
import { clearToken, loginSuccess } from '@/redux/auth/actions';
import { authApi } from '@/services/api';
// import t from '@/utilities/translate';
import { forceLogout } from "../redux/auth/actions";

export const host = process.env.REACT_APP_API;
const logoutTimer = process.env.REACT_APP_LOGOUT_TIMEOUT;

export const setPostHeaderContentLanguage = (language = 'en') => {
  axios.defaults.headers.common['Content-Language'] = language;
};

setPostHeaderContentLanguage(localStorage.getItem('language'));

let refreshTokenPromise = null;
let pendingRequestsCount = 0;

/**
 * Добавляем неудавшиеся запросы (401 ошибка) в очередь и запускам все,
 * после обновления токена
 * @param config
 * @returns {Promise<any>}
 * @constructor
 */

const ProcessByQueue = (config) => new Promise((resolve) => {
  refreshTokenPromise.then(() => {
    pendingRequestsCount++;
    resolve(http.request(config));
  });
}).then((res) => {
  pendingRequestsCount--;
  if (pendingRequestsCount <= 0) refreshTokenPromise = null;
  return res;
});


const requestInterceptor = function (config) {
  const { token } = store.getState().auth;
  if (token && token.token_type) {
    config.headers.Authorization = `${token.token_type} ${localStorage.token}`;
  } else {
    config.headers.Authorization = `Bearer ${localStorage.token}`;
  }

  return config;
};

export const http = axios.create({
  baseURL: host,
  headers: {
    'Admin-Panel': true,
  },
});

http.interceptors.request.use(requestInterceptor);

let logoutTimeout;

http.interceptors.response.use(
  (response) => {
    const { isAuthenticated } = store.getState().auth;
    clearTimeout(logoutTimeout);
    if (isAuthenticated && localStorage.token) {
      logoutTimeout = setTimeout(() => {
        store.dispatch(forceLogout());
      }, logoutTimer);
    }
    return response;
  },
  (error) => {
    const { response } = error;
    const { isAuthenticated } = store.getState().auth;
    clearTimeout(logoutTimeout);
    if (isAuthenticated && localStorage.token) {
      logoutTimeout = setTimeout(() => {
        store.dispatch(forceLogout());
      }, logoutTimer);
    }
    // Если произошла ошибка токена
    if (response && response.status === 401) {
      const { isAuthenticated, token } = store.getState().auth;
      if (refreshTokenPromise && token) {
        // Если токен уже обновляется, просто ставим в очередь
        return ProcessByQueue(response.config);
      } if (isAuthenticated || token) {
        // Если 401 и токен еще не обновляется, то рефрешим токен
        refreshTokenPromise = authApi.refreshToken(localStorage.refresh_token);
        refreshTokenPromise.then((res) => {
          if (res.status === 200) {
            // Если новый токен пришел, то сохраняем его в хранилище
            store.dispatch(loginSuccess(res.data));
          }
        });
        // Добавляем неудавшийся запрос в очередь
        return ProcessByQueue(response.config);
      }
    } else if (response.status === 422) {
      if (response.config.url.endsWith('/refresh-token')) {
        store.dispatch(clearToken());
      }
      if (response.data.errors) {
        const messages = Object.keys(response.data.errors).map((key) => ({
          key,
          error: response.data.errors[key][0],
        }));

        noty('error', 'Auth Error', (
          <List size="small">
            {messages.map(({ key, error }) => <List.Item key={key}>{error}</List.Item>)}
          </List>
        ));
      } else if (response.data.message) {
        noty('error', `${response.status} ${response.statusText}`, response.data.message);
      }
    } else if (response.status === 403) {
      noty('error', 'У вас недостаточно прав', response.data.message);
    } else if (response.status === 500) {
      noty('error', `${response.status} Ошибка на сервере`, response.data.message);
    } else {
      noty('error', `${response.status} ${response.statusText}`, response.data.message);
    }

    throw error;
  },
);

export const mockHttp = axios.create({
  baseURL: '​https://testjohndeereapi.wezom.agency/api',
});

export const mock = new MockAdapter(mockHttp, { delayResponse: 2000 });

export default http;

if (process.env.NODE_ENV === 'development') {
  window.$http = http;
}
