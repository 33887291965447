import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Select, Form } from 'antd';
import t from '@/utilities/translate';
import { triggers as triggersDeal } from '@/redux/deal/actions';
import DispatchOnMount from '@/components/common/DispatchOnMount';

const AvailableClients = ({
	modalProps,
	closeModal: closeModalFn,
	dealId,
	changeClient,
	availableClients,
	...rest
}) => {
	const [opt, setOpt] = useState({ client: null });
	const closeModal = () => {
		closeModalFn();
		changeClient({ dealId, clientId: opt.client.id });
	};

	return (
		<Modal {...modalProps} okButtonProps={{ disabled: !opt.client }} onOk={closeModal} okText={t('deal/info/changeClient/button')}>
			<DispatchOnMount actionCreator={() => triggersDeal.fetchAvailableClients(dealId)}>
				<Form.Item label={t('deal/availableClients/label')} style={{ padding: '20px 0 0 0' }}>
					<Select style={{ width: '100%' }}>
						{availableClients.map((client) => (
							<Select.Option
								key={client.id}
								onClick={() => setOpt({ client })}
							>
								{client.company_name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</DispatchOnMount>
		</Modal>
	);
};

AvailableClients.defaultProps = {
	modalProps: {},
	availableClients: [],
};

AvailableClients.propTypes = {
	modalProps: PropTypes.any,
	changeManager: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	dealId: PropTypes.number.isRequired,
	availableClients: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
	})),
};


export default connect(
	(state) => ({
		availableClients: state.deal.get('availableClients'),
	}),
	{
		...triggersDeal,
	},
)(AvailableClients);
