import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import t from '@/utilities/translate';
import { Row, Button, Col, Popconfirm, Spin } from 'antd';
import { reduxForm, SubmissionError } from 'redux-form';
import ButtonPending from '@/components/common/form/ButtonPending';
import { formResolver } from '@/utilities/form';
import { connect } from 'react-redux';

import { UPDATE_SALES_PLAN } from '@/redux/actual-sales-plans/types';
import { triggers } from '@/redux/actual-sales-plans/actions';

const FORM_RESPONSIVE = { colSpan: 24 };

const ActualSalesPlansEdit = (props) => {
	const {
		handleSubmit,
		closeModal,
		fields = [],
		pending = false,
	} = props;

	const FIELDS = compose(
		(fields) => formResolver(fields, FORM_RESPONSIVE),
		fields,
	)(props);

	return (
		<Spin spinning={pending}>
			<form onSubmit={handleSubmit} className="ant-row--size-compact ant-row--size-tiny:def">
				<Row>
					{FIELDS}
					<Col span={24} className="m-t_15">
						<Row gutter={15}>
							<Col lg={6} xs={24}>
								<ButtonPending
									type={UPDATE_SALES_PLAN}
									className="md-mb w-100 ant-btn--multiple-row"
									htmlType={'submit'}
								>
									{t('manufacture/edit/submit')}
								</ButtonPending>
							</Col>
							<Col lg={6} xs={24}>
								<Popconfirm
									onConfirm={closeModal}
									placement="bottomRight"
									title={t('actual-sales-plans/edit/cancel/popconfirm/title')}
									okText={t('common/yes')}
									cancelText={t('common/no')}
								>
									<Button className="w-100 ant-btn--multiple-row">
										{t('manufacture/cancel')}
									</Button>
								</Popconfirm>
							</Col>
						</Row>
					</Col>
				</Row>
			</form>
		</Spin>
	);
}

ActualSalesPlansEdit.propTypes = {
	sendQuery: PropTypes.func,
	handleSubmit: PropTypes.func,
	closeModal: PropTypes.func,
}

// const mapStateToProps = (state) => ({
// 	initialValues: state.actualSalesPlans.get('current'),
// 	pending: getPreloader(GET_SALES_PLAN_INFO),
// });

const mapDispatchToProps = {
	...triggers,
};

const wrapper = compose(
	connect(
		null,
		mapDispatchToProps
	),
);

const ActualSalesPlansEditEnhanced = reduxForm({
	enableReinitialize: true,
})(ActualSalesPlansEdit);

export default wrapper(({ sendQuery, initialValues, id, closeModal, ...props }) => {
	// useEffect(() => {
	// 	props.fetchCurrentSalesPlan(id);
	// }, []);

	return (
		<ActualSalesPlansEditEnhanced
			onSubmit={(payload) => {
				const err = props.validate(payload);
				if (err.length) {
					throw new SubmissionError(err);
				}
				props.updateSalesPlan(sendQuery({ id, ...payload, }));
				closeModal && closeModal();
			}}
			initialValues={initialValues}
			closeModal={closeModal}
			{...props}
		/>
	);
});
