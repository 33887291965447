import {
  call, put, select, takeEvery
} from "redux-saga/es/effects";
import { delay } from "redux-saga";
import * as types from "@/redux/auth/types";
import {
  clearToken,
  loginSuccess,
  refreshTokenRequest,
  getUserInfo,
  setUserInfo
} from "@/redux/auth/actions";
import { triggers } from "@/redux/app/actions";
import { authApi, profile } from '@/services/api';
import { SET_USER_LANG } from '@/redux/app/types';
import { setPostHeaderContentLanguage } from '@/services/http';
import { history } from '@/redux/history';

const saveTokenToLocalStorage = (data) => {
  localStorage.setItem('token', data.access_token);
  localStorage.setItem('language', data.language);
  localStorage.setItem('refresh_token', data.refresh_token);
};
const removeTokenFromLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('language');
  localStorage.removeItem('refresh_token');
};

export function* changeLanguage({ type, payload }) {
  try {
    localStorage.setItem('language', payload.language);
    setPostHeaderContentLanguage(payload.language);
    yield call(profile.changeLanguage, payload);
    yield put(triggers.getTranslate(payload.language));

    yield put({
      type: SET_USER_LANG,
      payload: payload.language
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* loginWorker({ data }) {
  try {
    const response = yield call(authApi.login, data);
    yield put(loginSuccess(response.data));
    yield put(getUserInfo());
    yield put(triggers.getTranslate(response.data.language));
  } catch (err) {
    yield put({ type: types.LOGIN_FAIL });
  }
}

function* loginSuccessWorker({ data }) {
  saveTokenToLocalStorage(data);
  if (data.expires_in >= 15) {
    // Принудительно обновляем токен за 10 секунд до сгорания
    yield delay((data.expires_in - 10) * 1000);

    if (yield select((state) => state.auth.token)) {
      yield put(refreshTokenRequest(localStorage.refresh_token));
    }
  }
}

export function* logoutWorker() {
  try {
    yield call(authApi.logout);
  } finally {
    yield put(clearToken());
  }
}

export function* forceLogoutWorker() {
  try {
    if (localStorage.getItem('token')) {
      yield call(authApi.logout);
    }
  } finally {
    yield put(clearToken());
    yield call(history.push, `/logged-out`);
  }
}

export function* refreshTokenWorker({ refresh_token }) {
  try {
    const response = yield call(authApi.refreshToken, refresh_token);
    yield put(loginSuccess(response.data));
    yield put(triggers.getTranslate(response.data.language));
  } catch (err) {
    console.log(err);
  }
}

export function* userDataWorker ({ payload = { translates: true } }) {
  const {
    translates
  } = payload;
  const response = yield call(authApi.userInfo);
  try {
    yield put(setUserInfo(response.data));
    if (translates) {
      yield put(triggers.getTranslate(response.data.language));
    }
  } catch (err) {
    console.log(err);
  }
}

export function clearTokenWorker() {
  removeTokenFromLocalStorage();
}

export function* saga() {
  yield takeEvery(types.CHANGE_LANGUAGE, changeLanguage);
  yield takeEvery(types.LOGIN_REQUEST, loginWorker);
  yield takeEvery(types.LOGIN_SUCCESS, loginSuccessWorker);
  yield takeEvery(types.LOGOUT_REQUEST, logoutWorker);
  yield takeEvery(types.REFRESH_TOKEN, refreshTokenWorker);
  yield takeEvery(types.GET_USER_INFO, userDataWorker);
  yield takeEvery(types.CLEAR_TOKEN, clearTokenWorker);
  yield takeEvery(types.FORCE_LOGOUT_REQUEST, forceLogoutWorker);
}
