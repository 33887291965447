import * as types from './types';
import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';

import { salesManagerEfficiency_v2 } from '@/services/api';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchData = (payload) => ({
  type: types.FETCH_DATA_V2,
  payload
});
//-----------------------------------------
//	Actions
//-----------------------------------------

export function* getData_v2 ({ type, payload }) {
  yield put(addPreloader(type));
  try {
	const { data } = yield call(salesManagerEfficiency_v2.getData, payload);
	yield put({
	  type: types.SAVE_DATA_V2,
	  payload: data
	});
  } finally {
	yield put(removePreloader(type));
  }
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga () {
  yield takeEvery(types.FETCH_DATA_V2, getData_v2);
}
