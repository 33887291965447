import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';

import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { BOED } from '@/services/api';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchList = (payload) => ({
    type: types.FETCH_BOED_LIST,
    payload
});

triggers.setDisplay = (payload) => ({
    type: types.SET_BOED_DISPLAY,
    payload
});

const setList = (payload)=>({
    type: types.SET_BOED_LIST,
    payload
})

const setFilterValue = (payload)=>({
    type: types.SET_FILTER_VALUE,
    payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchList({ type, payload }) {

    yield put(addPreloader(type));
    try {
        yield put(setFilterValue(payload));
        const { data } = yield call(BOED.list, payload);
        yield put(setList(data));
    } catch (err) {
        console.error(err);
    } finally {
        yield put(removePreloader(type));
    }

}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.FETCH_BOED_LIST, fetchList);
}
