import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';

import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { brandLoyalty } from '@/services/api';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchList = (payload) => ({
	type: types.FETCH_BRAND_LOYALTY_LIST,
	payload
});

const setTotal = (payload)=>({
	type: types.SET_TOTAL,
	payload
});

const setOffers = (payload)=>({
	type: types.SET_OFFERS,
	payload
});

const setWonLost = (payload)=>({
	type: types.SET_WON_LOST,
	payload
});

const clearBrandLoyalty = (payload)=>({
	type: types.CLEAR_BRAND_LOYALTY,
	payload
});

const setActiveManufacturers = (payload)=>({
	type: types.SET_ACTIVE_MANUFACTURERS,
	payload
});

const setValuesType = (payload)=>({
	type: types.SET_VALUES_TYPE,
	payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchList({ type, payload }) {
	yield put(addPreloader(type));
	const { chartValuesType } = payload;
	try {
		yield put(clearBrandLoyalty());
		yield put(setActiveManufacturers(payload.manufacturer));
		const { data } = yield call(brandLoyalty.getData, payload);
		yield put (setValuesType(chartValuesType));
		yield put(setTotal(data));
		yield put(setOffers(data));
		yield put(setWonLost(data));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}

}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_BRAND_LOYALTY_LIST, fetchList);
}
