import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import T from '@/utilities/translate';
import { productLine } from '@/services/api';
import { noty } from "@/utilities/notifications";
import { addPreloader, removePreloader } from '../common/preloaders';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.createProductLine = (query)=>({
    type: types.CREATE_PRODUCT_LINE,
    query
})

triggers.editProductLine = (id, query)=>({
    type: types.EDIT_PRODUCT_LINE,
    id,
    query
})

triggers.currentProductLine = (id)=>({
    type: types.CURRENT_PRODUCT_LINE,
    id
})

triggers.changeStatusProductLine = (id)=>({
    type: types.CHANGE_STATUS_PRODUCT_LINE,
    id
})

triggers.fetchProductLineListByProductLineGroup = (product_line_group_id)=>({
    type: types.FETCH_PRODUCT_LINE_LIST_BY_PRODUCT_LINE_GROUP,
    product_line_group_id
})

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* createProductLine({ type, query }) {
    yield put(addPreloader(type));
    try {
        yield call(productLine.create, query);
        yield call(noty, 'success', T('productLine/create/success'));
        // yield call(window._forward);
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* editProductLine({ type, id, query }) {
    yield put(addPreloader(type));
    try {
        yield call(productLine.edit, id, query);
        yield call(noty, 'success', T('productLine/edit/success'));
        yield call(window._forward, { id });
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* changeStatusProductLine({ type, id }) {

    try {
        yield call(productLine.changeStatus, id);
        yield call(noty, 'success', T('productLine/changeStatus/success'));
    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* currentProductLine({ type, id }) {

    yield put(addPreloader(type));

    try {
        const resp = yield call(productLine.current, id);
        yield put({
            type: types.PUT_PRODUCT_LINE,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* fetchProductLineListByProductLineGroup({ type, product_line_group_id }) {

    try {
        const resp = yield call(productLine.activeListByProductLineGroup, product_line_group_id);
        yield put({
            type: types.PUT_PRODUCT_LINE_LIST_BY_PRODUCT_LINE_GROUP,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    }

}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {

    yield takeEvery(types.FETCH_PRODUCT_LINE_LIST_BY_PRODUCT_LINE_GROUP, fetchProductLineListByProductLineGroup);
    yield takeEvery(types.CHANGE_STATUS_PRODUCT_LINE, changeStatusProductLine);
    yield takeEvery(types.CURRENT_PRODUCT_LINE, currentProductLine);
    yield takeEvery(types.CREATE_PRODUCT_LINE, createProductLine);
    yield takeEvery(types.EDIT_PRODUCT_LINE, editProductLine);
}

