export const productLineGroupForward = (location, props, routes) => {
  switch (location) {
	case routes['product-line-group/create'].link():
	  return routes['platform'].link();

	case routes['product-line-group/edit'].link(props.id):
	  return routes['platform'].link();
	default:
	  break;
  }
};
