import * as types from './types';
import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";

const initialState = new Map({
    current: {},
    activeListByProductLineGroup: []
});

export default createReducer(initialState, {
    [types.PUT_PRODUCT_LINE]: (state, { payload })=>state.set('current', payload),
    [types.PUT_PRODUCT_LINE_LIST_BY_PRODUCT_LINE_GROUP]: (state, { payload })=>state.set('activeListByProductLineGroup', payload)
});