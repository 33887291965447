export const FETCH_NOTIFICATION_EMAILS_LIST = '@/notification-emails/FETCH_NOTIFICATION_EMAILS_LIST';
export const SAVE_NOTIFICATION_EMAILS_LIST = '@/notification-emails/SAVE_NOTIFICATION_EMAILS_LIST';
export const GET_USERS_FOR_NOTIFICATION_LIST = '@/notification-emails/GET_USERS_FOR_NOTIFICATION_LIST';
export const SET_USERS_FOR_NOTIFICATION_LIST = '@/notification-emails/SET_USERS_FOR_NOTIFICATION_LIST';

export const GET_NOTIFICATION_EMAIL_INFO = '@/notification-emails/GET_NOTIFICATION_EMAIL_INFO';
export const SAVE_NOTIFICATION_EMAIL_INFO = '@/notification-emails/SAVE_NOTIFICATION_EMAIL_INFO';
export const CHANGE_NOTIFICATION_EMAIL = '@/notification-emails/CHANGE_NOTIFICATION_EMAIL';

export const DELETE_NOTIFICATION_EMAIL = '@/notification-emails/DELETE_NOTIFICATION_EMAIL';
export const CHANGE_NOTIFICATION_EMAIL_STATUS = '@/notification-emails/CHANGE_NOTIFICATION_EMAIL_STATUS';

export const CREATE_NOTIFICATION_EMAIL = '@/notification-emails/CREATE_NOTIFICATION_EMAIL';
export const SET_FILTER = '@/notification-emails/SET_FILTER';
