import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	activeJobList: [],
	jobList: {},
	current: {}
});

export default createReducer(initialState, {
	[types.SET_ACTIVE_JOB_TITLE_LIST]: (state, { payload }) => state.set('activeJobList', payload),
	[types.SET_JOB_LIST]: (state, { payload }) => state.set('jobList', payload),
	[types.SET_CURRENT_JOB]: (state, { payload })=> state.set('current', payload),
	[types.SET_JOB_WITH_CHANGE_STATUS]: (state, { payload })=>state.update('jobList', jobsData =>{
		jobsData.data = jobsData.data.map(job=>job.id === payload.id ? payload : job);
		return jobsData;
	}),
});
