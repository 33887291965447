export const CREATE_MODEL_DESCRIPTION = '@/model-description/CREATE_MODEL_DESCRIPTION';
export const EDIT_MODEL_DESCRIPTION = '@/model-description/EDIT_MODEL_DESCRIPTION';

export const CURRENT_MODEL_DESCRIPTION = '@/model-description/CURRENT_MODEL_DESCRIPTION';
export const PUT_MODEL_DESCRIPTION = '@/model-description/PUT_MODEL_DESCRIPTION';

export const CHANGE_STATUS_MODEL_DESCRIPTION = '@/model-description/CHANGE_STATUS_MODEL_DESCRIPTION'

export const PUT_MODEL_DESCRIPTION_LIST_BY_ESTIMATE_DESCRIPTION = '@/model-description/PUT_MODEL_DESCRIPTION_LIST_BY_ESTIMATE_DESCRIPTION';
export const FETCH_MODEL_DESCRIPTION_LIST_BY_ESTIMATE_DESCRIPTION = '@/model-description/FETCH_MODEL_DESCRIPTION_LIST_BY_ESTIMATE_DESCRIPTION';

export const PUT_MODEL_DESCRIPTION_LIST_BY_PRODUCT_LINES = '@/model-description/PUT_MODEL_DESCRIPTION_LIST_BY_PRODUCT_LINES';
export const FETCH_MODEL_DESCRIPTION_LIST_BY_PRODUCT_LINES = '@/model-description/FETCH_MODEL_DESCRIPTION_LIST_BY_PRODUCT_LINES';

export const PUT_MODEL_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP = '@/model-description/PUT_MODEL_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP';
export const FETCH_MODEL_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP = '@/model-description/FETCH_MODEL_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP';

export const FETCH_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_WITH_PRODUCTS = '@/model-description/FETCH_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_WITH_PRODUCTS';
export const PUT_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_WITH_PRODUCTS = '@/model-description/PUT_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_WITH_PRODUCTS';
export const CLEAR_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_WITH_PRODUCTS = '@/model-description/CLEAR_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_WITH_PRODUCTS';

export const FETCH_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_AND_ESTIMATE_DESC = '@/model-description/FETCH_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_AND_ESTIMATE_DESC';
export const PUT_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_AND_ESTIMATE_DESC = '@/model-description/PUT_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_AND_ESTIMATE_DESC';
