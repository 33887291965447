const initialState = {};

export const exports = (state = initialState, {type}) => {
    switch (type) {

        // case types.EXPORT_FILE_REQUEST:
        //     return {...state,
        //         [payload]: {
        //             pending: true
        //         }
        //     };
        //
        // case types.EXPORT_FILE_SUCCESS:
        //     return {...state,
        //         [payload]: {
        //             pending: false
        //         }
        //     };
        //
        // case types.EXPORT_FILE_ERROR:
        //     return {...state,
        //         [payload]: {
        //             pending: false
        //         }
        //     };


        default:
            return state;

    }
};