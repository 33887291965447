export const FETCH_MANUFACTURES_LIST = '@/manufacture/FETCH_MANUFACTURES_LIST';
export const SET_MANUFACTURES_LIST = '@/manufacture/SET_MANUFACTURES_LIST';

export const CREATE_MANUFACTURE = '@/manufacture/CREATE_MANUFACTURE';

export const FETCH_CURRENT_MANUFACTURE = '@/manufacture/FETCH_CURRENT_MANUFACTURE';
export const SET_CURRENT_MANUFACTURE = '@/manufacture/SET_CURRENT_MANUFACTURE';

export const UPDATE_MANUFACTURE = '@/manufacture/UPDATE_MANUFACTURE';
export const CHANGE_MANUFACTURE_STATUS = '@/manufacture/CHANGE_MANUFACTURE_STATUS';
export const CHANGE_MANUFACTURE_ACTUAL_SALES = '@/manufacture/CHANGE_MANUFACTURE_ACTUAL_SALES';

export const FETCH_MANUFACTURE_DATA_LIST = '@/manufacture/FETCH_MANUFACTURE_DATA_LIST';
export const SET_MANUFACURER_DATA_LIST = '@/manufacture/SET_MANUFACURER_DATA_LIST';

export const UPDATE_MANUFACTURES_LIST = '@/manufacture/UPDATE_MANUFACTURES_LIST';
