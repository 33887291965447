import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";
import { pick } from 'lodash/fp';
import { apiNumberFormatter } from '../../utilities/formatter';
import * as types from './types';

const initialState = new Map({
    pssCurrent: {},
    activeList: [],
    pssList: {}
});

export default createReducer(initialState, {
    [types.SET_PSS_CURRENT]: (state, { payload })=> {
        const _payload = apiNumberFormatter(pick(['phone'])(payload));

        return state.set('pssCurrent', Object.assign(payload, _payload) )
    },
    [types.SET_PSS_LIST]: (state, { payload })=>state.set('pssList', payload),
    [types.PUT_PSS_ACTIVE_LIST]: (state, { payload })=>state.set('activeList', payload),
    [types.SET_PSS_CHANGE_STATUS]: (state, { payload })=>state.update('pssList', (pss)=>{
        pss.data = pss.data.map(pss_ => pss_.id === payload.id ? payload : pss_);
        return pss;
    })
});
