import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import t from '@/utilities/translate';
import * as api from '@/services/api';
import { staff } from '@/services/api';
import { noty } from "@/utilities/notifications";

//-----------------------------------------
//    Triggers
//-----------------------------------------

export const triggers = {};

triggers.listOfStaff = (query) => ({
  type: types.FETCH_STAFF_LIST,
  query
});

triggers.changeStatusBySecurityLevel = (payload) => ({
  type: types.UPDATE_STATUS_BY_SECURITY_LEVEL,
  payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* changeStatusBySecurityLevel({ type, payload }) {
  const { data, query } = payload;
  const setToState = (resp) => ({ type: types.UPDATE_STAFF_LIST, payload: resp.data });
  const apiNameBySecurityLevel = {
    1: 'employeesApi',
    2: 'employeesApi',
    3: 'dealer',
    6: 'coWorker',
    4: 'territorial',
    7: 'productSalesSpecialist'
  };
  yield put(addPreloader(type));

  try {
    const resp = yield call(api[apiNameBySecurityLevel[data.security_level_id]].changeStatus, data.id);
    yield put(setToState(resp));
    yield call(noty, 'success', t('staff/changeStatus/success'));
    yield put(triggers.listOfStaff(query));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* listOfStaff({ type, query }) {
  const setToState = (resp) => ({ type: types.PUT_STAFF_LIST, payload: resp.data });
  yield put(addPreloader(type));

  try {
    const resp = yield call(staff.list, query);
    yield put(setToState(resp));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

//-----------------------------------------
//	Root saga
//-----------------------------------------

export function* saga() {
  yield takeEvery(types.UPDATE_STATUS_BY_SECURITY_LEVEL, changeStatusBySecurityLevel);
  yield takeEvery(types.FETCH_STAFF_LIST, listOfStaff);
}
