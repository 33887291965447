import React from 'react';
import { Row, Col } from 'antd';

export default ({
  children, actions, actionsProps, hideBottomBar = false, ...props
}) => (
  <form {...props } className="form-skeleton">
    <Row gutter={24}>
      {children}

	  { !hideBottomBar ? (
		  <Col lg={24} xs={24}>
			  <Row
				  gutter={25}
				  className="m-t_15"
				  type="flex"
				  {...actionsProps}
				  justify="center"
				  style={{
					  position: 'fixed',
					  bottom: 10,
					  left: 0,
					  width: '100%',
					  zIndex: 99,
					  visibility: 'hidden'
				  }}
			  >
				  <Col
					  span={12}
					  style={{
						  paddingLeft: '12.5px',
						  paddingRight: '12.5px',
						  background: '#fff',
						  padding: 10,
						  boxShadow: '1px -1px 10px 1px #d0cdcd',
						  borderRadius: 6,
						  visibility: 'visible'
					  }}
				  >
					  {actions}
				  </Col>
			  </Row>
		  </Col>
	  ): null }
    </Row>
  </form>
);
