import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';

import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { actualSalesPlanDealMaps } from '@/services/api';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchMapsList = (payload) => ({
	type: types.FETCH_MAPS_LIST,
	payload
});

triggers.fetchChartData = (payload) => ({
	type: types.FETCH_CHART_DATA,
	payload
});

const setActiveManufacturers = (payload) => ({
	type: types.SET_ACTIVE_MANUFACTURERS,
	payload
});

const setLoadingChartData = (payload) => ({
	type: types.SET_LOADING_CHART_DATA,
	payload
});

const clearMapsData = () => ({
	type: types.CLEAR_MAPS_DATA
});

const clearChartData = () => ({
	type: types.CLEAR_CHART_DATA
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchMapsList({ type, payload }) {
	yield put(addPreloader(type));

	try {
		yield put(clearMapsData());
		yield put(setActiveManufacturers(payload.manufacturer));
		const { data } = yield call(actualSalesPlanDealMaps.getData, payload);
		yield put({
			type: types.SET_MAPS_DATA,
			payload: data
		});
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchChartData({ type, payload }) {
	yield put(addPreloader(type));

	try {
		yield put(clearChartData());
		yield put(setLoadingChartData(true));
		const response = yield call(actualSalesPlanDealMaps.getChartData, payload);
		yield put({
			type: types.SET_CHART_DATA,
			payload: response.data
		});
	} catch (err) {
		console.error(err);
	} finally {
		yield put(setLoadingChartData(false));
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_MAPS_LIST, fetchMapsList);
	yield takeEvery(types.FETCH_CHART_DATA, fetchChartData);
}
