import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { platform } from '@/services/api';
import * as api from '@/services/api';
import { noty } from "@/utilities/notifications";
import t from '@/utilities/translate';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {
    platformListForEQ: () => ({
        type: types.PLATFROM_LIST_FOR_EQ,
    }),
    createPlatformAndEQ: (payload) => ({
        type: types.CREATE_PLATFORM,
        payload
    }),
    platformListByManufacture: (payload) =>  ({
        type: types.FETCH_PLATFORM_LIST_BY_MANUFACTURE,
        payload
    }),
    platformAndEQList: () => ({
        type: types.PLATFORM_AND_EQ_LIST,
    }),
    current: (id, _type_) => ({
        type: types.CURRENT_PLATFORM_OR_EQ,
        id,
        _type_
    }),
    changeStatus: (id, _type_) => ({
        type: types.FETCH_CHANGE_STATUS_PLATFORM_OR_EQ,
        id,
        _type_
    }),
    edit: (id, payload) => ({
        type: types.PLATFORM_OR_EQ_EDIT,
        id,
        payload
    })
}

//-----------------------------------------
//	Actions
//-----------------------------------------



export function* platformCurrent({ type, id }) {

    yield put(addPreloader(type));
    try {

        const resp = yield call(platform.current, id);
        yield put({
            type: types.SET_CURRENT_PLATFORM_OR_EQ,
            payload: {
                en: resp.data.en.name,
                ru: resp.data.ru.name,
                ua: resp.data.ua.name,
                status: resp.data.status
            }
        })

    } finally {
        yield put(removePreloader(type));
    }

}

export function* platformCreate({ type, payload }) {

    yield put(addPreloader(type));
    try {

        /*const resp = */yield call(platform.create, payload);
        yield call(noty, 'success', t('platform/create/success'));
        // yield call(window._forward);

    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

export function* platformAndEQList({ type, payload }) {

    yield put(addPreloader(type));
    try {
        const resp = yield call(platform.list, payload);

        yield put({
            type: types.SET_PLATFORM_AND_EQ_LIST,
            payload: resp.data
        })
    } finally {
        yield put(removePreloader(type));
    }

}

export function* platformChangeStatus({ type, id, _type_ }) {

    const subjectList = {
        '0': 'platform',
        '1': 'productLineGroup',
        '2': 'productLine',
        '3': 'equipmentGroup',
        '4': 'compositeLevel',
        '5': 'estimateDescription',
        '6': 'modelDescription',
        '7': 'baseMachineryDescription'
    };

    try {
        const subjectName = subjectList[_type_];

        yield call(api[subjectName].changeStatus, id);
        yield call(noty, 'success', t(`${subjectName}/changeStatus/success`));
        yield call(platformAndEQList, { type: types.PLATFORM_AND_EQ_LIST });

    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* platformListForEQ({ type = types.PLATFROM_LIST_FOR_EQ, payload = {} }) {

    yield put(addPreloader(type));
    try {
        const resp = yield call(platform.platform_list_for_eq, payload);

        yield put({
            type: types.SET_PLATFROM_LIST_FOR_EQ,
            payload: resp.data
        })
    } finally {
        yield put(removePreloader(type));
    }

}

export function* platformEdit({ type, id, payload }) {

    yield put(addPreloader(type));
    try {

        yield call(platform.edit, id, payload);
        yield call(noty, 'success', t('platform/actions/create/success'));
        yield call(window._forward, { id });

    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}
export function* getPlatformByManufacture({ type, payload }) {

    yield put(addPreloader(type));
    try {
        const resp = yield call(platform.platform_list_by_manufacture, payload);

        yield put({
            type: types.SET_PLATFORM_LIST_BY_MANUFACTURE,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.PLATFROM_LIST_FOR_EQ, platformListForEQ);
    yield takeEvery(types.CREATE_PLATFORM, platformCreate);
    yield takeEvery(types.PLATFORM_AND_EQ_LIST, platformAndEQList);
    yield takeEvery(types.CURRENT_PLATFORM_OR_EQ, platformCurrent);
    yield takeEvery(types.FETCH_CHANGE_STATUS_PLATFORM_OR_EQ, platformChangeStatus);
    yield takeEvery(types.PLATFORM_OR_EQ_EDIT, platformEdit);
    yield takeEvery(types.FETCH_PLATFORM_LIST_BY_MANUFACTURE, getPlatformByManufacture);
}
