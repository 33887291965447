import React from 'react';
import { Button, Col, Row } from 'antd';
import { reduxForm } from 'redux-form';
import validator from '@/utilities/validator';
import t from '@/utilities/translate';
import FormSkeleton from '@/components/common/form/FormSkeleton';
import SelectField from '@/components/common/form/SelectWrapper';
import FormField from '@/components/common/form/DefaultFormWrapper';

const Form = ({
	              availableResults,
	              availableStatuses,
	              handleSubmit,
	              pending,
	              pristine
              }) => {
	return (
		<FormSkeleton onSubmit={handleSubmit} hideBottomBar={true}>
			<Row gutter={15} style={{ marginTop: 24 }}>
				<Col xs={24} style={{ marginBottom: 16 }}>
					<FormField component={SelectField}
					           colSpan={24}
					           afterSpan={0}
					           data={() => (availableResults.map((item) => ({
						           value: Number(item.id),
						           label: item.name,
					           })))}
					           name="deal_result_id"
					           label={t('deal-change-status/result/label')}
					           placeholder={t('deal-change-status/result/label')}/>
				</Col>
				<Col xs={24} style={{ marginBottom: 16 }}>
					<FormField component={SelectField}
					           colSpan={24}
					           afterSpan={0}
					           data={() => (availableStatuses.map((item) => ({
						           value: Number(item.id),
						           label: item.name,
					           })))}
					           name="deal_status_id"
					           label={t('deal-change-status/status/label')}
					           placeholder={t('deal-change-status/status/label')}/>
				</Col>
				<Col xs={24}>
					<Button type="primary"
					        disabled={pristine}
					        loading={pending}
					        htmlType="submit"
					        style={{ width: '100%' }}>{t('deal-change-status/submit/button')}</Button>
				</Col>
			</Row>
		</FormSkeleton>
	)
};

export default reduxForm({
	form: 'ChangeDealStatusForm',
	validate: validator.changeDealStatus
})(Form);
