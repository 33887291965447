import { locationFields } from '@/components/common/locationHelp';

export default (props, form) => {
	const _props = { ...props, formName: form }

	return locationFields(_props, undefined, {
		countryId: {
			prefixName: 'client/create',
			single: true,
			clients: 0,
		},
		regionId: {
			prefixName: 'client/create',
			single: true,
			clients: 0,
		},
		districtIds: {
			prefixName: 'client/create',
			single: true,
			clients: 0,
			name: 'district_id'
		},
	});
};
