/* eslint-disable no-sequences  */
/* eslint-disable no-useless-escape  */
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isFloat from 'validator/lib/isFloat';
import _ from 'lodash';
import t from '@/utilities/translate';

export const requires = (arrName) => (values) => {
  const result = arrName.reduce(
    (acc, fieldName) => {
      const _values = _.get(values, fieldName);
      if (_values === undefined || String(_values).length === 0 || _values === null) acc[fieldName] = t('employees/error/role/role_data'); // eslint-disable-line no-sequences
      return acc;
    },
    {}
  );


  return _(result).keys().reduce((acc, item) => (_.set(acc, item, result[item]), acc), {});
};

export const rulesHandler = {

  required: (value, rule) => {
    if (rule === true && ['', undefined, null].some((v) => v === value)) {
      return 'Поле обязательно';
    }
  },

  minLength: (value, length) => {
    if (value.length < length) {
      return `Минимальное колличество символов: ${length}`;
    }
  },

  maxLength: (value, length) => {
    if (value.length > length) {
      return `Максимальное колличество символов: ${length}`;
    }
  },

  digit: (value, rule) => {
    if (rule === true && typeof value !== 'undefined' && isNaN(value)) {
      return 'Поле может содержать только числовое значение';
    }
  },

  float: (value, rule) => {
    if (rule === true && !isFloat(String(value))) {
      return 'Поле может содержать только числовое значение';
    }
  },

  integer: (value, rule) => {
    value = value ? String(value).replace(',', '.') : value;
    if (rule === true && typeof value !== 'undefined' && !Number.isInteger(parseFloat(value))) {
      return 'Поле может содержать только целочисленное значение';
    }
  },

  minValue: (value, min) => {
    if (Number(value) < min || isNaN(value)) {
      return `Минимальное значение: ${min}`;
    }
  },

  maxValue: (value, max) => {
    if (Number(value) > max || isNaN(value)) {
      return `Максимальное значение: ${max}`;
    }
  },

  email: (value, rule = true) => {
    if (rule === true && !isEmail(String(value))) {
      return t('form/validate/incorrent_email');
    }
  },

  phone: (value, rule) => {
    if (rule === true && !isMobilePhone(String(value), 'uk-UA')) {
      return 'Введите кореектный телефон';
    }
  },

  phoneX: (value) => {
    if (value && value.indexOf('050') === -1
            && value.indexOf('095') === -1
            && value.indexOf('066') === -1
            && value.indexOf('099') === -1
            && value.indexOf('093') === -1
            && value.indexOf('067') === -1
            && value.indexOf('073') === -1
            && value.indexOf('063') === -1
            && value.indexOf('097') === -1
            && value.indexOf('098') === -1
            && value.indexOf('068') === -1) {
      return t('validate/phone');
    }
  },

  url: (value, rule) => {
    if (rule === true && !isURL(String(value))) {
      return 'Введите кореектный URL';
    }
  },

  regex: (value, { regex, message }) => {
    if (!(regex instanceof RegExp)) {
      regex = new RegExp(regex);
    }

    if (value && !regex.test(value)) {
      return message || 'Введите корректное значение';
    }
  },

  onlyLatin: (value) => {
    if (value && !/^[0-9a-zA-Z\s\.\,\'\"\/\(\)\.\&\+\,\-]+$/.test(value)) {
      return t('manufacture/create/ru_name/latin');
    }
  },

  onlyRus: (value) => {
    if (value && !/^[0-9А-Яа-я\s\.\,\'\"\/\(\)\.\&\+\,\-]+$/.test(value)) {
      return t('manufacture/field/validate/kirilic');
    }
  },

  onlyRusWithoutNumber: (value) => {
    if (value && !/^[А-Яа-я\s\.\,\'\"\/\(\)\.\&\+\,\-]+$/.test(value)) {
      return t('manufacture/field/validate/kirilic');
    }
  },

  wordsAndSymbol: (value) => {
    if (value && !/^[0-9А-Яа-яa-zA-Z\s\.\,\'\"\/\(\)\.\&\+\,\-]+$/.test(value)) {
      return t('common/wordsAndSymbol');
    }
  },

  wordsAndNumber: (value) => {
    if (value && !/^[0-9А-Яа-яa-zA-Z\s]+$/.test(value)) {
      return t('common/wordsAndNumber');
    }
  },

  wordsAndSymbolWithoutNumber: (value) => {
    if (value && !/^[0-9А-Яа-яa-zA-Z\s\.\,\'\"\/\(\)\.\&\+\,\-]+$/.test(value)) {
      return t('common/wordsAndSymbolWithoutNumber');
    }
  },

  onlyLatinWithoutNumber: (value) => {
    if (value && !/^[a-zA-Z\s\.\,\'\"\/\(\)\.\&\+\,\-]+$/.test(value)) {
      return t('common/latinWithoutNumber');
    }
  },

  reduce(values, type, params) {
    const handler = this[type];
    return (arrName) => arrName.reduce((acc, item) => {
      const _values = _.get(values, item);
      const handlerResult = handler(_values, params);
      if (handlerResult) _.set(acc, item, handlerResult);
      return acc;
    }, {});
  },

  sizeParameterName(value = '', type, param) {
    if (!/^[a-zA-Z\s.]+$/i.test(value)) {
      return t('sizeParameters/validate/name');
    }
  }

};

rulesHandler.min = rulesHandler.minValue;
rulesHandler.max = rulesHandler.maxValue;

export function checkValue(value, rules) {
  for (const rule in rules) {
    if (rulesHandler.hasOwnProperty(rule)) {
      const error = rulesHandler[rule].call(null, value, rules[rule]);
      if (error) return error;
    }
  }
}

export function checkForm(values, rules) {
  return Object.keys(rules).reduce((result, index) => ({
    ...result,
    [index]: rules[index].key
      ? checkValue(values[index][rules[index].key], rules[index])
      : checkValue(values[index], rules[index])
  }), {});
}
