//-----------------------------------------
//	list
//-----------------------------------------

export const FETCH_IMPORTS_LIST = '@/actual-sales-plan-import/FETCH_IMPORTS_LIST';
export const SET_IMPORTS_LIST = '@/actual-sales-plan-import/SET_IMPORTS_LIST';

//-----------------------------------------
//	Import info
//-----------------------------------------

export const GET_IMPORT_INFO = '@/actual-sales-plan-import/GET_IMPORT_INFO';
export const SET_IMPORT_INFO = '@/actual-sales-plan-import/SET_IMPORT_INFO';

//-----------------------------------------
//	Check if can upload
//-----------------------------------------

export const CHECK_UPLOAD_AVAILABILITY = '@/actual-sales-plan-import/CHECK_UPLOAD_AVAILABILITY';
export const SET_UPLOAD_AVAILABILITY = '@/actual-sales-plan-import/SET_UPLOAD_AVAILABILITY';

//-----------------------------------------
//	Upload file
//-----------------------------------------

export const UPLOAD_FILE = '@/actual-sales-plan-import/UPLOAD_FILE';
