import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
    data: [],
    currentItem: {},
    clientVehiclesList: []
});

export default createReducer(initialState, {
    [types.SET_TRADE_IN_ITEM]: (state, { payload }) => state.set('currentItem', payload),
    [types.SET_CLIENT_VEHICLES_LIST]: (state, { payload }) => state.set('clientVehiclesList', payload),
});
