import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';

import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { translates } from '@/services/api';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.list = (query) => ({
  type: types.FETCH_TRANSLATES_LIST,
  payload: {
    query
  }
});

triggers.edit = (query) => ({
  type: types.UPDATE_TRANSLATE_BY_KEY,
  payload: {
    query
  }
});

triggers.moduleList = () => ({
  type: types.FETCH_MODULE_LIST,
});

const putModuleList = (payload) => ({
  type: types.PUT_MODULE_LIST,
  payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* translatesDataList({ type, payload }) {
  yield put(addPreloader(type));

  try {
    const { query = {} } = payload;
    const resp = yield call(translates.list, query);
    yield put({
      type: types.SET_TRANSLATES_LIST,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* editTranslate({ type, payload }) {
  try {
    const { query = {} } = payload;
    yield call(translates.edit, query);
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* fetchModuleList() {
  try {
    const { data } = yield call(translates.moduleList);
    yield put(putModuleList(data));
  } catch (e) {
    yield call(console.warn, e);
  }
}


//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
  yield takeEvery(types.FETCH_TRANSLATES_LIST, translatesDataList);
  yield takeEvery(types.UPDATE_TRANSLATE_BY_KEY, editTranslate);
  yield takeEvery(types.FETCH_MODULE_LIST, fetchModuleList);
}
