import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon, Layout, Menu } from 'antd';
import { compact } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { history } from '@/redux/history';
import * as actions from '@/redux/sidebar/actions';
import logo from '@/assets/svg/logo.svg';
import smLogo from '@/assets/svg/logo-sm.svg';
import routes from '@/routing/route-tree';
import './style.css';
import t from '@/utilities/translate';
import {
	canSeeSettings,
	canSeeDealerList,
	canSeeTranslateModule,
	canSeeNotificationEmailsModule,
	canSeeFactoriesList,
	canSeeImportLists,
	canSeePlanFact,
} from './access';

const { Sider } = Layout;
const { SubMenu } = Menu;

class ProductIcon extends React.PureComponent {
	render() {
		return <img key='product' alt='product' style={{ marginTop: -7 }} src='/images/traktor.svg' />;
	}
}

class SideLeft extends React.Component {
	state = {
		mobileHidden: true,
		menuType: !(document.documentElement.clientWidth < 760),
	};

	menu = () => compact([
		{
			key: 'dashboard',
			icon: 'dashboard',
			get text() {
				return t('SideLeft/Menu/Dashboard');
			},
			link: routes['index'].link,
		},
		{
			key: 'statistics',
			icon: 'fund',
			get text() {
				return t('SideLeft/Menu/Statistics');
			},
			submenu: [
				// routes.index,
				// routes['BOED/list'],
				routes['BOED/list_v2'],
				routes.maps,
				// routes.analytics,
				routes.analytics_v2,
				// routes.productsQuantity,
				routes.productsQuantity_v2,
				// routes.salesEfficiency,
				routes.salesEfficiency_v2,
			],
		},
		{
			key: 'Deals',
			icon: 'solution',
			get text() {
				return t('SideLeft/Menu/Deals');
			},
			link: routes['deals/list'].link,
		},
		{
			key: 'users',
			icon: 'team',
			get text() {
				return t('SideLeft/Menu/Users');
			},
			submenu: [
				canSeeDealerList(routes.dealer),
				routes.staff,
			],
		},
		{
			key: '/customers',
			icon: 'user',
			get text() {
				return t('SideLeft/Menu/Clients');
			},
		},
		{
			key: 'estimate',
			icon: 'area-chart',
			get text() {
				return t('SideLeft/Menu/Estimate');
			},
			submenu: [
				// routes.total_country_goals,
				routes.total_country_goals_v2,
				routes['order-scheduled-history'],
				routes['orders-scheduled'],
			],
		},
		{
			key: '/product',
			icon: ProductIcon,
			get text() {
				return t('SideLeft/Menu/Product');
			},
			submenu: [
				routes.product,
				routes.platform,
				routes.manufacturers,
			],
		},
		// {
		//   key: 'analitics',
		//   icon: 'team',
		//   get text() { return t('SideLeft/Menu/Analitics'); },
		//   submenu: [
		//     routes.employees,
		//   ],
		// },
		canSeePlanFact({
			key: 'planFact',
			icon: 'audit',
			get text() {
				return t('SideLeft/Menu/PlanFact');
			},
			submenu: [
				routes.actualSalesPlans,
				routes.actualSalesPlansSummary,
				routes.actualSalesPlanDraft,
				routes.actualSalesPlanMaps,
				routes.actualSalesPlanDealMaps,
				canSeeTranslateModule(routes.actualSalesPlanImport),
				routes.serviceCodes,
			],
		}),
		{
			key: 'potentialSales',
			icon: 'snippets',
			get text() {
				return t('SideLeft/Menu/PotentialSales');
			},
			submenu: [
				routes.potentialSales,
				routes.equipmentOperationTimes,
			],
		},
		canSeeSettings({
			key: 'settings',
			icon: 'setting',
			get text() {
				return t('SideLeft/Menu/Settings');
			},
			submenu: [
				{
					key: 'aor',
					get text() {
						return t('SideLeft/Menu/AOR');
					},
					submenu: [
						routes.country,
						routes.region,
						routes.district,
					],
				},
				{
					key: 'products',
					get text() {
						return t('SideLeft/Menu/Products');
					},
					submenu: [
						routes['farm-size/list'],
						routes.sizeParametersList,
						// ??? factories
						// routes.coverage_goals,
						routes.coverage_goals_v2,
					],
				},
				{
					key: 'deals',
					get text() {
						return t('SideLeft/Menu/SettingsDeals');
					},
					submenu: [
						routes['currencies/list'],
						routes['dealStatuses/list'],
						routes['dealActions/list'],
						routes['dealResults/list'],
						routes['dealLostReasons/list'],
						canSeeImportLists(routes.lostToCompanies),
					],
				},
				routes.job,
				canSeeImportLists(routes.clientVehicleImport),
				canSeeImportLists(routes.iosLinksImport),
				canSeeFactoriesList(routes.factories),
				canSeeNotificationEmailsModule(routes.notificationEmails),
			],
		}),
		canSeeTranslateModule({
			key: 'translates',
			icon: 'file-word',
			get text() {
				return t('SideLeft/Menu/Translates');
			},
			link: routes['translate/list'].link,
		}),
	]);

	toggle = () => {
		this.props.toggleSidebar();
	};

	clickHandler = (link) => {
		history.push(link.key);
	};

	openHandler = (openKeys) => {
		this.props.menuHandler({ openKeys });
	};

	renderMenuItems = (items, depth = 1) => items.map((item) => {
		if (!item) return null;
		const { icon } = item;
		const key = typeof item.link === 'function' ? item.link() : item.path || item.key;
		const text = item.sidebarText || item.breadcrumb || item.text;
		const { submenu } = item;
		const iconProps = typeof icon === 'string' ? { type: icon } : { component: icon };
		return (
			Array.isArray(submenu)
				? (
					<SubMenu
						key={key}
						title={(
							<span>
                  {depth === 1 && icon
					  && <Icon key={key} {...iconProps} />}
								<span>{text}</span>
                </span>
						)}
					>
						{this.renderMenuItems(submenu, depth + 1)}
					</SubMenu>
				) : (item
						? (
							<Menu.Item key={key} title={text} disabled={item.dev}>
								{depth === 1 && icon && <Icon {...iconProps} />}
								<span>{text}</span>
							</Menu.Item>
						) : null
				)
		);
	});

	mobileMenuToggle = () => {
		this.setState(Object.assign(this.state, { mobileHidden: !this.state.mobileHidden }));
	};

	render() {
		const { collapsed, menu: { openKeys = [] }, router: { location: { pathname } } } = this.props;

		const needsLink = pathname !== '/';

		return (
			<Fragment>
				{!this.state.menuType ? (
					<Menu
						mode='vertical'
						onClick={this.clickHandler}
						onOpenChange={this.openHandler}
					>
						<Menu.Item key='#' disabled
								   style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<Icon onClick={this.mobileMenuToggle} type='menu-unfold' />
							<img src={smLogo} alt='' style={{ height: 35 }} />
						</Menu.Item>
						{!this.state.mobileHidden ? this.renderMenuItems(this.menu()) : null}
						{
							process.env.NODE_ENV !== 'production' && !this.state.mobileHidden
							&& (
								<Menu.Item key='/api' style={{ backgroundColor: 'red', color: 'white' }}>
									<Icon type='api' />
									<span>API</span>
								</Menu.Item>
							)
						}
					</Menu>
				) : null}


				{this.state.menuType ? (
					<Sider
						width={280}
						collapsible
						collapsed={collapsed}
						onCollapse={this.toggle}
						style={this.props.style}
					>

						{collapsed ? (needsLink ? (
							<Link to={'/'} style={{
								textAlign: 'center',
								background: 'none',
								margin: 5,
								padding: 10,
								borderBottom: '2px solid #42804b',
								display: 'block',
							}}
							>
								<img src={smLogo} alt='' style={{ height: 35 }} />
							</Link>
						) : (
							<div style={{
								textAlign: 'center',
								background: 'none',
								margin: 5,
								padding: 10,
								borderBottom: '2px solid #42804b',
							}}
							>
								<img src={smLogo} alt='' style={{ height: 35 }} />
							</div>
						)) : (needsLink ? (
							<Link to={'/'} style={{
								textAlign: 'center',
								background: 'none',
								margin: 5,
								padding: 10,
								borderBottom: '2px solid #42804b',
								display: 'block',
							}}
							>
								<img src={logo} alt='' style={{ height: 35 }} />
							</Link>
						) : (
							<div style={{
								textAlign: 'center',
								background: 'none',
								margin: 5,
								padding: 10,
								borderBottom: '2px solid #42804b',
							}}
							>
								<img src={logo} alt='' style={{ height: 35 }} />
							</div>
						))}

						<Menu
							mode='inline'
							defaultSelectedKeys={['/']}
							selectedKeys={[pathname]}
							openKeys={openKeys}
							onClick={this.clickHandler}
							onOpenChange={this.openHandler}
						>
							{this.renderMenuItems(this.menu())}
							{
								process.env.NODE_ENV !== 'production'
								&& (
									<Menu.Item key='/api' style={{ backgroundColor: 'red', color: 'white' }}>
										<Icon type='api' />
										<span>API</span>
									</Menu.Item>
								)
							}
						</Menu>
					</Sider>
				) : null}

			</Fragment>
		);
	}
}

// ----------------------------------------------------------------------
// PropTypes
// ----------------------------------------------------------------------

SideLeft.propTypes = {
	collapsed: PropTypes.bool,
	fetchSettingsGroups: PropTypes.func,
	menuHandler: PropTypes.any,
	router: PropTypes.shape({
		location: PropTypes.object,
	}),
	settings: PropTypes.any,
	toggleSidebar: PropTypes.func,
};

// ----------------------------------------------------------------------
// state to props
// ----------------------------------------------------------------------

const mapStateToProps = (state) => ({
	router: state.router,
	collapsed: state.sidebar.collapsed,
	menu: state.sidebar.menu,
});

// ----------------------------------------------------------------------
// dispatch to props
// ----------------------------------------------------------------------

const mapDispatchToProps = (dispatch) => ({
	menuHandler: (payload) => dispatch(actions.menuHandler(payload)),
	toggleSidebar: () => dispatch(actions.toggleSidebar()),
});

// ----------------------------------------------------------------------
// connect to props
// ----------------------------------------------------------------------

export default connect(mapStateToProps, mapDispatchToProps)(SideLeft);
