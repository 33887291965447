import React from 'react';
import {
  Dropdown, Menu, Icon,
} from 'antd';
import { history } from '@/redux/history';
import { routes } from '@/routing/route-tree';
import t from '@/utilities/translate';
import { accessHOC } from '@/utilities/access';
import { accessRoles } from '@/constants';

const canCreateSalesManager = accessHOC([accessRoles.COUNTRY_MANAGER, accessRoles.COMPANY, accessRoles.TOP_MANAGER, accessRoles.OUTLET_MANAGER]);
const canCreateProductSalesManager = accessHOC([accessRoles.COUNTRY_MANAGER, accessRoles.COMPANY, accessRoles.OUTLET_MANAGER]);
const canCreateOutletManager = accessHOC([accessRoles.COUNTRY_MANAGER, accessRoles.COMPANY, accessRoles.TOP_MANAGER]);
const canCreateTopManager = accessHOC([accessRoles.COUNTRY_MANAGER, accessRoles.TERRITORY_MANAGER, accessRoles.COMPANY]);
const canCreateCompany = accessHOC([accessRoles.COUNTRY_MANAGER, accessRoles.TERRITORY_MANAGER]);
const canCreateTeritoryManager = accessHOC([accessRoles.COUNTRY_MANAGER]);

export default () => (
  <Dropdown overlay={(
    <Menu>
      {canCreateSalesManager(<Menu.Item onClick={() => history.push(routes['employees/createByType'].link({ type: 'sales_manager' }))}>{t('create/heading/sales_manager')}</Menu.Item>)}
      {canCreateOutletManager(<Menu.Item onClick={() => history.push(routes['employees/createByType'].link({ type: 'outlet_manager' }))}>{t('create/heading/outlet_manager')}</Menu.Item>)}
      {canCreateTopManager(<Menu.Item onClick={() => history.push(routes['employees/createByType'].link({ type: 'top_manager' }))}>{t('create/heading/top_manager')}</Menu.Item>)}
      {canCreateCompany(<Menu.Item onClick={() => history.push(routes['dealer/create'].link())}>{t('create/heading/dealer')}</Menu.Item>)}
      {canCreateTeritoryManager(<Menu.Item onClick={() => history.push(routes['territorial/create'].link())}>{t('create/heading/territorial')}</Menu.Item>)}
      {canCreateProductSalesManager(<Menu.Item onClick={() => history.push(routes['pss/create'].link())}>{t('create/heading/pss')}</Menu.Item>)}
    </Menu>
  )}
  >
    <Icon type="user-add" />
  </Dropdown>
);
