import * as React from 'react';
import { Input, Form, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import Access from '@/components/common/access/Access';

// ----------------------------------------------------------------------
// TextField Component
// ----------------------------------------------------------------------

export default class TextFieldWrapper extends React.PureComponent {
    static propTypes = {
      input: PropTypes.object,
      meta: PropTypes.object,
      label: PropTypes.string,
      number: PropTypes.bool
    };

    changeHandler = (value) => {
      this.props.input.onChange(value);
    };

    render() {
      const {
        input,
        meta,
        type,
        number,
        label,
        access_role = undefined,
        labelCol = {},
        wrapperCol = {},
        textArea = false,
	    asTextInput = false,
        _validate = null,
        ...attributes
      } = this.props;

      const formItemProps = {
        labelCol,
        wrapperCol,
        label: label ? (_validate ? (
          <span>
            {label}
            <span className="f-danger">*</span>
          </span>
        ) : label) : null,
        validateStatus: meta.touched && !meta.valid ? "error" : undefined,
        help: meta.touched && meta.error ? meta.error : null,
      };

      const Component = (
        <Form.Item {...formItemProps} style={{ margin: '0 0 10px' }}>
          {(number || type === 'number') && !asTextInput ? (
            <InputNumber
              {...input}
              {...attributes}
              onChange={this.changeHandler}
              style={{ width: '100%' }}
            />
          ) : textArea ? (
            <Input.TextArea {...input} {...attributes} />
          ) : (
            <Input {...input} {...attributes} type={type} />
          )}
        </Form.Item>
      );

      return access_role
        ? (
          <Access
            access_role={access_role}
          >
            {Component}
          </Access>
        ) : Component;
    }
}
