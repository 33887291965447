import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	factoriesList: [],
	current: {}
});

export default createReducer(initialState, {
	[types.SET_FACTORIES_LIST]: (state, { payload }) => state.set('factoriesList', payload),
	[types.SET_FACTORY]: (state, { payload })=> state.set('current', {
		...payload,
		estimate_descriptions: payload.estimate_descriptions.map((el) => el.id)
	}),
	[types.SET_FACTORY_WITH_CHANGED_STATUS]: (state, { payload })=>state.update('factoriesList', factoriesData =>{
		factoriesData.data = factoriesData.data.map(factory=>factory.id === payload.id ? payload : factory);
		return factoriesData;
	}),
});
