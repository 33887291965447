
//-----------------------------------------
//	Country goal
//-----------------------------------------

export const FETCH_TOTAL_COUNTRY_LIST_V2 = '@/total_country_goals/FETCH_TOTAL_COUNTRY_LIST_V2';
export const SET_TOTAL_COUNTRY_LIST_V2 = '@/total_country_goals/SET_TOTAL_COUNTRY_LIST_V2';
export const SET_COUNTRU_GOAL_V2 = '@/total_country_goals/SET_COUNTRU_GOAL_V2';

//-----------------------------------------
//	Manufacture unit
//-----------------------------------------

export const FETCH_DEALER_MODEL_BREAKDOWN_UNIT_V2 = '@/total_country_goals/FETCH_DEALER_MODEL_BREAKDOWN_UNIT_V2';
export const SET_DEALER_MODEL_BREAKDOWN_UNIT_V2 = '@/total_country_goals/SET_DEALER_MODEL_BREAKDOWN_UNIT_V2';
export const SET_DEALER_MODEL_BREAKDOWN_UNIT_TO_V2 = '@/total_country_goals/SET_DEALER_MODEL_BREAKDOWN_UNIT_TO_V2';

//-----------------------------------------
//	Dealer unit
//-----------------------------------------

export const FETCH_DEALER_UNIT_V2 = '@/total_country_goals/FETCH_DEALER_UNIT_V2';
export const SET_DEALER_UNIT_V2 = '@/total_country_goals/SET_DEALER_UNIT_V2';
export const SET_DEALER_UNIT_TO_V2 = '@/total_country_goals/SET_DEALER_UNIT_TO_V2';

//-----------------------------------------
//	Sales Man Plan
//-----------------------------------------

export const FETCH_SALES_MAN_PLAN_V2 = '@/total_country_goals/FETCH_SALES_MAN_PLAN_V2';
export const SET_SALES_MAN_PLAN_V2 = '@/total_country_goals/SET_SALES_MAN_PLAN_V2';
export const SET_SALES_MAN_PLAN_TO_V2 = '@/total_country_goals/SET_SALES_MAN_PLAN_TO_V2';
