import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import T from '@/utilities/translate';
import { modelDescription } from '@/services/api';
import { noty } from "@/utilities/notifications";
import { addPreloader, removePreloader } from '../common/preloaders';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchModelDescriptionByEquipmentGroup = (payload) => ({
  type: types.FETCH_MODEL_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP,
  payload
});

triggers.fetchModelDescriptionByManufactureWithProducts = (payload) => ({
  type: types.FETCH_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_WITH_PRODUCTS,
  payload
});

triggers.fetchModelDescriptionByManufactureAndEstimateDesc = (payload) => ({
  type: types.FETCH_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_AND_ESTIMATE_DESC,
  payload
});

triggers.createModelDescription = (query) => ({
  type: types.CREATE_MODEL_DESCRIPTION,
  query
});

triggers.editModelDescription = (id, query) => ({
  type: types.EDIT_MODEL_DESCRIPTION,
  id,
  query
});

triggers.changeStatusModelDescription = (id) => ({
  type: types.CHANGE_STATUS_MODEL_DESCRIPTION,
  id
});

triggers.currentModelDescription = (id) => ({
  type: types.CURRENT_MODEL_DESCRIPTION,
  id
});

triggers.fetchModelDescriptionListByEstimateDescription = (payload) => ({
  type: types.FETCH_MODEL_DESCRIPTION_LIST_BY_ESTIMATE_DESCRIPTION,
  payload
});

triggers.fetchModelDescriptionListByProductLines = (payload) => ({
	type: types.FETCH_MODEL_DESCRIPTION_LIST_BY_PRODUCT_LINES,
	payload
});

const putModelDescriptionByEquipmentGroup = (payload)=>({
    type: types.PUT_MODEL_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP,
    payload
});

triggers.clearModelDescriptionListByManufactureWithProducts = ()=>({
    type: types.CLEAR_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_WITH_PRODUCTS
});

const putModelDescriptionByManufacturerAndEstimateDesc = (payload)=>({
    type: types.PUT_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_AND_ESTIMATE_DESC,
    payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchModelDescriptionByEquipmentGroup({ type, payload }) {
    const { equipmentGroupId } = payload;
    try {
        const { data } = yield call(modelDescription.listByEquipmentGroup, equipmentGroupId);
        yield put(putModelDescriptionByEquipmentGroup(data));
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* fetchModelDescriptionByManufacturerAndEstimateDesc({ type, payload }) {
    try {
        const { data } = yield call(modelDescription.activeListByManufacturerAndEstimateDescription, payload);
        yield put(putModelDescriptionByManufacturerAndEstimateDesc(data));
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* createModelDescription({ type, query }) {
  yield put(addPreloader(type));
  try {
    yield call(modelDescription.create, query);
    yield call(noty, 'success', T('modelDescription/create/success'));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* editModelDescription({ type, id, query }) {
  yield put(addPreloader(type));
  try {
    yield call(modelDescription.edit, id, query);
    yield call(noty, 'success', T('modelDescription/edit/success'));
    yield call(window._forward, { id });
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* changeStatusModelDescription({ type, id }) {
  try {
    yield call(modelDescription.changeStatus, id);
    yield call(noty, 'success', T('modelDescription/changeStatus/success'));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* currentModelDescription({ type, id }) {
  yield put(addPreloader(type));

  try {
    const resp = yield call(modelDescription.current, id);
    yield put({
      type: types.PUT_MODEL_DESCRIPTION,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* fetchModelDescriptionListByEstimateDescription({ type, payload }) {
  try {
    const resp = yield call(modelDescription.activeListByEstimateDescription, payload);
    yield put({
      type: types.PUT_MODEL_DESCRIPTION_LIST_BY_ESTIMATE_DESCRIPTION,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* fetchModelDescriptionListByProductLines({ type, payload }) {
	try {
		const resp = yield call(modelDescription.activeListByProductLines, payload);
		yield put({
			type: types.PUT_MODEL_DESCRIPTION_LIST_BY_PRODUCT_LINES,
			payload: resp.data
		});
	} catch (e) {
		yield call(console.warn, e);
	}
}

export function* fetchModelDescriptionListByManufactureWithProducts(payload) {
  try {
    const resp = yield call(modelDescription.listByManufacturerWithProducts, payload);
    yield put({
      type: types.PUT_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_WITH_PRODUCTS,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
  yield takeEvery(types.FETCH_MODEL_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP, fetchModelDescriptionByEquipmentGroup);
  yield takeEvery(types.FETCH_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_AND_ESTIMATE_DESC, fetchModelDescriptionByManufacturerAndEstimateDesc);
  yield takeEvery(types.FETCH_MODEL_DESCRIPTION_LIST_BY_ESTIMATE_DESCRIPTION, fetchModelDescriptionListByEstimateDescription);
  yield takeEvery(types.FETCH_MODEL_DESCRIPTION_LIST_BY_PRODUCT_LINES, fetchModelDescriptionListByProductLines);
  yield takeEvery(types.FETCH_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_WITH_PRODUCTS, fetchModelDescriptionListByManufactureWithProducts);
  yield takeEvery(types.CHANGE_STATUS_MODEL_DESCRIPTION, changeStatusModelDescription);
  yield takeEvery(types.CURRENT_MODEL_DESCRIPTION, currentModelDescription);
  yield takeEvery(types.CREATE_MODEL_DESCRIPTION, createModelDescription);
  yield takeEvery(types.EDIT_MODEL_DESCRIPTION, editModelDescription);
}
