import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Select, Form } from 'antd';
import t from '@/utilities/translate';
import { fetchSalesMenByQuery } from '@/redux/employees/all/actions';
import { triggers as triggersEmployees } from '@/redux/employees/profile/actions';
import { store } from '@/redux/store';
import { debounce } from 'lodash';

const dispatch = (...args) => store.dispatch(...args);

const TransferDeals = ({
	modalProps,
	closeModal: closeModalFn,
    employeeId,
    changeDealsManager,
	activeManagers
}) => {
	const [opt, setOpt] = useState({ manager: null });
	const closeModal = () => {
		closeModalFn();
		changeDealsManager({ id: employeeId, managerId: opt.manager.id });
	};

	return (
		<Modal {...modalProps} onOk={closeModal} okText={t('employees/transferDeals/change')}>
			<Form.Item label={t('employees/transferDeals/label')} style={{ padding: '20px 0 0 0' }}>
				<Select
					style={{ width: '100%' }}
					showSearch={true}
					defaultActiveFirstOption={false}
					filterOption={false}
					onSearch={debounce((query) => {
						query.trim().length >= 3 && dispatch(fetchSalesMenByQuery({ query }))
					}, 300)}
				>
					{activeManagers.map((manager) => (
						<Select.Option
							key={manager.id}
							onClick={() => setOpt({ manager })}
						>
							{manager.name}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
		</Modal>
	);
};

TransferDeals.defaultProps = {
	modalProps: {},
	activeManagers: [],
};

TransferDeals.propTypes = {
	modalProps: PropTypes.any,
	changeDealsManager: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	employeeId: PropTypes.number.isRequired,
	activeManagers: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
	})),
};


export default connect(
	(state) => ({
		activeManagers: state.employees.all.get('salesMenByQuery'),
	}),
	{
		fetchSalesMenByQuery,
		...triggersEmployees
	},
)(TransferDeals);
