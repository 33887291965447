import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { store } from '@/redux/store';
import { history } from '@/redux/history';
import Routes from '@/routing/routes';
import 'antd/dist/antd.less';
import './style.css';
import Helmet from "react-helmet";
import { hot } from "react-hot-loader";
import { getUserInfo } from "@/redux/auth/actions";

class Root extends React.Component {
  UNSAFE_componentWillMount() {
    const { token, refresh_token } = localStorage;

    if (token && refresh_token) {
      store.dispatch(getUserInfo());
    }
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <React.Fragment>
            <Helmet defaultTitle="John Deere" titleTemplate="%s | John Deere" />
            <Routes />
          </React.Fragment>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default hot(module)(Root);
