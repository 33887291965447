export const factoryForward = (location, props, routes) => {
  switch (location) {
	case routes['factories/create'].link():
	  return routes['factories'].link();

	case routes['factories/edit'].link(props.id):
	  return routes['factories'].link();
	default:
	  break;
  }
};
