/* eslint-disable eqeqeq */
import { Map } from 'immutable';
import { pick, omit } from 'lodash/fp';
import * as types from './types';
import { createReducer } from "@/redux/helpers";
import { apiFormatFermSizeCategory, apiNumberFormatter } from '@/utilities/formatter';

const initialState = new Map({
  clientList: {},
  clientArray: [],
  clientsByCompanyName: [],
  contacts: {},
  currentContact: {},
  childClientsByCompanyName: []
});

export default createReducer(initialState, {
  [types.SET_CURRENT_CLIENT]: (state, { payload }) => {
    const _payload = apiNumberFormatter(pick(['phone', 'farm_size'])(payload));
    payload = apiFormatFermSizeCategory(payload, 'farm_size_category_name');

    return state.set('clientCurrent', Object.assign(payload, _payload));
  },
  [types.SET_CLIENT_LIST]: (state, { payload }) => {
    payload.data = payload.data.map((item) => ({
      ...item,
      ...apiNumberFormatter(omit(['status', 'id', 'tax_code'])(item))
    }));
    payload.data = apiFormatFermSizeCategory(payload.data, 'farm_size_category_name');
    return state.set('clientList', payload);
  },
  [types.SET_CLIENT_ARRAY]: (state, { payload }) => state.set('clientArray', payload),
  [types.FETCH_CONTACT_EMITTER]: (state, { payload }) => {
    return state.set('currentContact', payload);
  },
  [types.PUT_CLIENTS_BY_COMPANY_NAME]: (state, { payload }) => state.set('clientsByCompanyName', payload),
  [types.PUT_CHILD_CLIENTS_BY_COMPANY_NAME]: (state, { payload }) => state.set('childClientsByCompanyName', payload),
  [types.CLEAR_CHILD_CLIENTS_BY_COMPANY_NAME]: (state, { payload }) => state.set('childClientsByCompanyName', []),
  [types.PUT_CONTACTS]: (state, { payload }) => {
    payload.data = payload.data.map((item) => ({
      ...item,
      ...apiNumberFormatter(omit(['status'])(item)),
	  id: item.id
    }));
    return state.set('contacts', payload);
  },
  [types.CHANGE_STATUS_FROM_LIST]: (state, { payload }) => state.update('clientList', (clients) => {
    clients.data = clients.data.map((client) => {
      return client.id == payload.id ? payload : client;
    });
    console.log(clients.data, 'clients.data', payload);
    return clients;
  })
});
