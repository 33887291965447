import * as types from './types';
import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";
import { pick } from 'lodash/fp';
import { apiNumberFormatter } from '../../utilities/formatter';

const initialState = new Map({
    territorialCurrent: {},
    activeList: [],
    territorials: {}
});

export default createReducer(initialState, {
    [types.SET_TERRITORIAL_CURRENT]: (state, { payload })=> {
        const _payload = apiNumberFormatter(pick(['phone'])(payload));

        return state.set('territorialCurrent', Object.assign(payload, _payload) )
    },
    [types.SET_TERRITORIAL_LIST]: (state, { payload })=>state.set('territorials', payload),
    [types.PUT_TERRITORIAL_ACTIVE_LIST]: (state, { payload })=>state.set('activeList', payload),
    [types.SET_TERRITORIAL_CHANGE_STATUS]: (state, { payload })=>state.update('territorials', (territorials)=>{
        territorials.data = territorials.data.map(territorial => territorial.id === payload.id ? payload : territorial);
        return territorials;
    })
});