import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { actualSalesPlanDraft } from '@/services/api';
import t from '@/utilities/translate';
import { noty } from '@/utilities/notifications';
import { store } from '@/redux/store';
import { history } from '@/redux/history';
import { routes } from '@/routing/route-tree';

//-----------------------------------------
//	Triggers
//-----------------------------------------
export const triggers = {};

triggers.getList = (query) => ({
	type: types.FETCH_DRAFTS_LIST,
	query,
});

triggers.setList = (payload) => ({
	type: types.SET_DRAFTS_LIST,
	payload,
});

triggers.fetchCurrentDraft = (id) => ({
	type: types.GET_DRAFT_INFO,
	id,
});

triggers.setCurrentDraft = (payload) => ({
	type: types.SET_DRAFT_INFO,
	payload,
});

triggers.clearCurrent = () => ({
	type: types.CLEAR_CURRENT,
});

triggers.updateDraft = (payload) => ({
	type: types.UPDATE_DRAFT,
	payload,
});

triggers.verifyDraft = (id) => ({
	type: types.VERIFY_DRAFT,
	id,
});

triggers.deleteDraft = (id) => ({
	type: types.DELETE_DRAFT,
	id,
});

triggers.massDeleteDrafts = (payload) => ({
	type: types.MASS_DELETE_DRAFTS,
	payload,
});

triggers.restoreDraft = (id) => ({
	type: types.RESTORE_DRAFT,
	id,
});

triggers.setDefaultFilter = (payload) => ({
	type: types.SET_FILTER,
	payload,
});

triggers.setActiveFilter = (payload) => ({
	type: types.SET_ACTIVE_FILTER,
	payload,
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchDraftsList({ type, query }) {
	yield put(addPreloader(type));
	try {
		yield put(triggers.setActiveFilter({
			...(query.dealer_id && { dealer_id: query.dealer_id }),
			...(query.client_id && { client_id: query.client_id }),
			...(query.service_code_id && { service_code_id: query.service_code_id }),
			...(query.service_code && { service_code: query.service_code }),
			...(query.description_product && { description_product: query.description_product }),
			...(query.company_lost_deal_id && { company_lost_deal_id: query.company_lost_deal_id }),
		}));
		yield put(triggers.setDefaultFilter(query));
		const { data } = yield call(actualSalesPlanDraft.list, query);
		yield put(triggers.setList(data));
		yield put(triggers.clearCurrent());
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchCurrentDraft({ id }) {
	try {
		const { data } = yield call(actualSalesPlanDraft.current, id);
		yield put(triggers.setCurrentDraft(data));
		const filter = store.getState().actualSalesPlanDraft.get('defaultFilter');
		yield put(triggers.setActiveFilter(filter));
	} catch (e) {
		yield call(console.warn, e);
	}
}

export function* updateDraft({ type, payload }) {
	const { id, ...query } = payload;
	yield put(addPreloader(type));
	try {
		yield call(actualSalesPlanDraft.update, id, query);
		yield call(noty, 'success', t('actual-sales-plan-draft/edit/success'));
		yield call(history.push, routes['actualSalesPlanDraft'].link());
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* verifyDraft({ type, id }) {
	yield put(addPreloader(type));
	try {
		yield call(actualSalesPlanDraft.verify, id);
		yield call(noty, 'success', t('actual-sales-plan-draft/verify/success'));
		const filter = store.getState().actualSalesPlanDraft.get('defaultFilter');
		yield put(triggers.getList(filter));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* deleteDraft({ type, id }) {
	yield put(addPreloader(type));
	try {
		yield call(actualSalesPlanDraft.delete, id);
		yield call(noty, 'success', t('actual-sales-plan-draft/delete/success'));
		const filter = store.getState().actualSalesPlanDraft.get('defaultFilter');
		yield put(triggers.getList(filter));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* massDeleteDrafts({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield call(actualSalesPlanDraft.massDelete, payload);
		yield call(noty, 'success', t('actual-sales-plan-draft/delete/success'));
		const filter = store.getState().actualSalesPlanDraft.get('defaultFilter');
		yield put(triggers.getList(filter));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* restoreDraft({ type, id }) {
	yield put(addPreloader(type));
	try {
		yield call(actualSalesPlanDraft.restore, id);
		yield call(noty, 'success', t('actual-sales-plan-draft/restore/success'));
		const filter = store.getState().actualSalesPlanDraft.get('defaultFilter');
		yield put(triggers.getList(filter));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root saga
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_DRAFTS_LIST, fetchDraftsList);
	yield takeEvery(types.GET_DRAFT_INFO, fetchCurrentDraft);
	yield takeEvery(types.UPDATE_DRAFT, updateDraft);
	yield takeEvery(types.VERIFY_DRAFT, verifyDraft);
	yield takeEvery(types.DELETE_DRAFT, deleteDraft);
	yield takeEvery(types.MASS_DELETE_DRAFTS, massDeleteDrafts);
	yield takeEvery(types.RESTORE_DRAFT, restoreDraft);
}
