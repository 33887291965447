import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import T from '@/utilities/translate';
import { equipmentGroup } from '@/services/api';
import { noty } from "@/utilities/notifications";
import { addPreloader, removePreloader } from '../common/preloaders';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.createEquipmentGroup = (query)=>({
    type: types.CREATE_EQUIPMENT_GROUP,
    query
})

triggers.editEquipmentGroup = (id, query)=>({
    type: types.EDIT_EQUIPMENT_GROUP,
    id,
    query
})

triggers.currentEquipmentGroup = (id)=>({
    type: types.CURRENT_EQUIPMENT_GROUP,
    id
})

triggers.changeStatusEquipmentGroup = (id)=>({
    type: types.CHANGE_STATUS_EQUIPMENT_GROUP,
    id
})

triggers.fetchEquipmentGroupListByProductLine = (product_line_id)=>({
    type: types.FETCH_EQUIPMENT_GROUP_LIST_BY_PRODUCT_LINE,
    product_line_id
})

triggers.fetchEquipmentGroupListByManufacturerAndPlatform = (payload)=>({
    type: types.FETCH_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER_AND_PLATFORM,
    payload
});

triggers.fetchEquipmentGroupListByPlatform = (platform_id)=>({
    type: types.FETCH_EQUIPMENT_GROUP_LIST_BY_PLATFORM,
    platform_id
})

triggers.fetchEquipmentGroupListByManufacturer = (manufacturer_id)=>({
    type: types.FETCH_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER,
    manufacturer_id
})

triggers.fetchEquipmentGroupActiveList = ()=>({
    type: types.FETCH_EQUIPMENT_GROUP_ACTIVE_LIST
})

//-----------------------------------------
//	Actions
//-----------------------------------------

const putEquipmentGroupActiveList = (payload)=>({
    type: types.PUT_EQUIPMENT_GROUP_ACTIVE_LIST,
    payload
})

const putEquipmentGroupActiveListByPlatform = (payload)=>({
    type: types.PUT_EQUIPMENT_GROUP_LIST_BY_PLATFORM,
    payload
})

const putEquipmentGroupActiveListByManufacturer = (payload)=>({
    type: types.PUT_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER,
    payload
})

const putEquipmentGroupActiveListByManufacturerAndPlatform= (payload)=>({
    type: types.PUT_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER_AND_PLATFORM,
    payload
})

export function* fetchEquipmentGroupActiveList() {
    try {
        const resp = yield call(equipmentGroup.activeList);
        yield put(putEquipmentGroupActiveList(resp.data));
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* createEquipmentGroup({ type, query }) {
    yield put(addPreloader(type));
    try {
        yield call(equipmentGroup.create, query);
        yield call(noty, 'success', T('equipmentGroup/create/success'));
        // yield call(window._forward);
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* editEquipmentGroup({ type, id, query }) {
    yield put(addPreloader(type));
    try {
        yield call(equipmentGroup.edit, id, query);
        yield call(noty, 'success', T('equipmentGroup/edit/success'));
        yield call(window._forward, { id });
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* changeStatusEquipmentGroup({ id }) {

    try {
        yield call(equipmentGroup.changeStatus, id);
        yield call(noty, 'success', T('equipmentGroup/changeStatus/success'));
    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* currentEquipmentGroup({ type, id }) {

    yield put(addPreloader(type));

    try {
        const resp = yield call(equipmentGroup.current, id);
        yield put({
            type: types.PUT_EQUIPMENT_GROUP,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* fetchEquipmentGroupListByProductLine({ product_line_id }) {

    try {
        const resp = yield call(equipmentGroup.activeListByProductLine, product_line_id);
        yield put({
            type: types.PUT_EQUIPMENT_GROUP_LIST_BY_PRODUCT_LINE,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* fetchEquipmentGroupListByPlatform({ platform_id }) {
    try {
        const { data } = yield call(equipmentGroup.activeListByPlatform, platform_id);
        yield put(putEquipmentGroupActiveListByPlatform(data))
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* fetchEquipmentGroupListByManufacturer({ manufacturer_id }) {
    try {
        const { data } = yield call(equipmentGroup.activeListByManufacturer, manufacturer_id);
        yield put(putEquipmentGroupActiveListByManufacturer(data))
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* fetchEquipmentGroupListByManufacturerAndPlatform ({ payload }) {
    try {
        const { data } = yield call(equipmentGroup.activeListByManufacturerAndPlatform, payload);
        yield put(putEquipmentGroupActiveListByManufacturerAndPlatform(data))
    } catch (e) {
        yield call(console.warn, e);
    }
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.FETCH_EQUIPMENT_GROUP_ACTIVE_LIST, fetchEquipmentGroupActiveList);
    yield takeEvery(types.FETCH_EQUIPMENT_GROUP_LIST_BY_PRODUCT_LINE, fetchEquipmentGroupListByProductLine);
    yield takeEvery(types.FETCH_EQUIPMENT_GROUP_LIST_BY_PLATFORM, fetchEquipmentGroupListByPlatform);
    yield takeEvery(types.FETCH_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER, fetchEquipmentGroupListByManufacturer);
    yield takeEvery(types.CHANGE_STATUS_EQUIPMENT_GROUP, changeStatusEquipmentGroup);
    yield takeEvery(types.CURRENT_EQUIPMENT_GROUP, currentEquipmentGroup);
    yield takeEvery(types.CREATE_EQUIPMENT_GROUP, createEquipmentGroup);
    yield takeEvery(types.EDIT_EQUIPMENT_GROUP, editEquipmentGroup);
    yield takeEvery(types.FETCH_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER_AND_PLATFORM, fetchEquipmentGroupListByManufacturerAndPlatform);
}

