import { change } from 'redux-form';
import { triggers as triggersManufacture } from '@/redux/manufacture/actions';
import { triggers as triggersModelDescription } from '@/redux/model-description/actions';
import { triggers as triggersProduct } from '@/redux/product/actions';
import { store } from '@/redux/store';
import { equipment_type, fy_type } from '@/constants';
import t from '@/utilities/translate';
import { debounce } from 'lodash';

//-----------------------------------------
//	Helpers
//-----------------------------------------

const manufacturerHandler = ({ _clearFields }) => () => {
	store.dispatch(triggersModelDescription.clearModelDescriptionListByManufactureWithProducts());
	store.dispatch(triggersProduct.clearProducts());
	_clearFields([
		'model_description_id',
		'product_id',
	]);
};

const dispatch = (...args) => store.dispatch(...args);
const _changeFormField = (...args) => dispatch(change(...args));
const _changeFormFields = (form, arrFields) => arrFields.forEach((field) => {
	_changeFormField(form, field.name, field.value);
});

const clearFields = (formName) => (arrFields) => _changeFormFields(formName, arrFields.map((fieldName) => ({
	name: fieldName,
	value: null
})));

const manufactureSelector = (state) => state.manufacture.get('manufacture_list').map((item) => ({
	value: Number(item.id),
	label: item.name
}));

const modelDescriptionSelector = (state) => state.modelDescription.get('activeListByManufactureWithProducts').map((item) => ({
	value: Number(item.id),
	label: item.name,
}));

export default ({ formName, manufacturer_id, model_description_id, initialValues }) => {
	const _clearFields = clearFields(formName);
	return [
		!initialValues?.fiscal_year && {
			id: 'fiscal_year',
			name: 'fiscal_year',
			type: 'select',
			label: 'filter/year/label',
			placeholder: 'filter/year/placeholder',
			data: () => Object.keys(fy_type).filter((key) => key !== 'total').map((type) => ({
				label: t(fy_type[type]),
				value: type
			})),
			_validate: true,
		},
		{
			id: 'manufacturer_id',
			name: 'manufacturer_id',
			type: 'select',
			label: 'techniques/create/manufacture_id/label',
			placeholder: 'techniques/create/manufacture_id/placeholder',
			actionCreator: triggersManufacture.manufacture_list,
			ownOnChange: manufacturerHandler({ _clearFields }),
			data: manufactureSelector,
			_validate: true,
		},
		{
			id: 'model_description_id',
			name: 'model_description_id',
			type: 'autocomplete',
			label: `techniques/model_description_id/label`,
			disabledReason: () => !manufacturer_id,
			placeholder: `techniques/model_description_id/placeholder`,
			_validate: true,
			handler: debounce((value) => {
				const currentValue = store.getState().form[formName].values['model_description_id'];
				if (value) {
					store.dispatch(triggersModelDescription.fetchModelDescriptionByManufactureWithProducts({
						manufacture_id: manufacturer_id,
						query: value
					}))
					if (currentValue === model_description_id) {
						_changeFormField(formName, 'model_description_id', value);
						_clearFields(['product_id']);
					}
				} else {
					store.dispatch(triggersModelDescription.clearModelDescriptionListByManufactureWithProducts());
					_clearFields(['product_id', 'model_description_id']);
					store.dispatch(triggersProduct.clearProducts());
				}
			}, 300),
			ownOnChange: (value) => {
				_clearFields(['product_id']);
				const data = store.getState().modelDescription.get('activeListByManufactureWithProducts').filter((item) => item.id === +value)[0];
				if (data) {
					store.dispatch(triggersProduct.listByParams({
						manufacture_id: manufacturer_id,
						model_description_id: value,
						equipment_group_id: data.equipment_group_id,
						estimate_description_id: data.estimate_description_id,
						platform_id: data.platform_id
					}))
				}
			},
			data: modelDescriptionSelector
		},
		{
			id: 'product_id',
			name: 'product_id',
			type: 'select',
			label: `techniques/edit/product/label`,
			disabledReason: () => !model_description_id,
			placeholder: `techniques/edit/product/label`,
			_validate: true,
			data: (state) => state.product.get('productListByParams').map((item) => ({
				value: Number(item.id),
				label: item.name
			}))
		},
		{
			id: 'equipment_type',
			name: 'equipment_type',
			type: 'select',
			_validate: true,
			get label() {
				return t('total_country_goals/filter/equipment_type/label')
			},
			get placeholder() {
				return t('total_country_goals/filter/equipment_type/placeholder')
			},
			data: (state) => (Object.keys(equipment_type).map((type) => ({
				label: equipment_type[type],
				value: type,
			}))),
		},
		{
			id: 'quantity',
			name: 'quantity',
			label: 'techniques/create/quantity/label',
			placeholder: 'techniques/create/quantity/placeholder',
			_validate: true,
			type: 'number'
		},
		{
			id: 'offered_price',
			name: 'offered_price',
			label: 'techniques/create/offered_price/label',
			placeholder: 'techniques/create/offered_price/placeholder',
			_validate: true,
			type: 'number',
			precision: 2,
		},
		{
			id: 'currency_id',
			name: 'currency_id',
			label: 'techniques/create/currency_id/label',
			placeholder: 'techniques/create/currency_id/placeholder',
			type: 'select',
			actionCreator: triggersProduct.currenciesList,
			_validate: true,
			data: (state) => state.product.get('currenciesList').map((item) => ({
				value: Number(item.id),
				label: item.name
			}))
		},
	];
};
