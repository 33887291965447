import React from 'react';
import { connect } from 'react-redux';
import { Button, Spin } from 'antd';

import { getPreloader } from "@/redux/common/preloaders";


const mapStateToProps = (state, ownProps) => ({
    pending: getPreloader(ownProps.type)
})

export default connect(
    mapStateToProps
)(({ pending, dispatch, ownType,...props }) => <Button {...props} disabled={pending ? true : false} type={ownType} style={{ maxHeight: 34 }}>{pending ? <Spin spinning={true} size="small" /> : props.children}</Button>)