import * as DATE_TIME from './date-time';
import * as TABLES from './tables';
import t from '@/utilities/translate';

export { DATE_TIME, TABLES };

export const FLAGS = {
  ru: '/flags/ru.png',
  en: '/flags/en.png',
  ua: '/flags/ua.png'
};

export const MARKERS = {
  car: '/markers/car.png',
  customer: '/markers/customer.png',
  finish: '/markers/finish.png',
  start: '/markers/route-start.png',
  at_place: '/markers/at-place.png'
};

export const territorialNames = {
  jd: 'John Deere',
  fast: 'Fast',
  hagie: 'Hagie',
  kramer: 'Kramer',
  monosem: 'Monosem',
  sulky: 'Sulky',
  vaderstad: 'Vaderstad',
};

export const MANUFACTURE_RELATIONSHIP = {
  get 1(){return t('common/affiliate')},
  get 2(){return t('common/competitor')}
};

export const countrys = {
  1: 'Ukraine'
};

export const equipment_type = {
  1: 'New Equipment',
  2: 'Used Equipment'
};

export const fy_type = {
  1: 'translates/current_fiscal_year',
  2: 'translates/new_fiscal_year',
  total: 'translates/total_fiscal_year'
};

export const PLACEHOLDERS = {
  NO_AVATAR: '/placeholders/no-avatar.jpg'
};

export const GEO = {
  EQUATOR: 40075.696 // Метров
};

export const FILTERS = {
  defaultState: false
};

export const months = [
  {
    title: 'common/months/november',
    key: 'november',
    month: 11
  },
  {
    title: 'common/months/december',
    key: 'december',
    month: 12
  },
  {
    title: 'common/months/january',
    key: 'january',
    month: 1
  },
  {
    title: 'common/months/february',
    key: 'february',
    month: 2
  },
  {
    title: 'common/months/march',
    key: 'march',
    month: 3
  },
  {
    title: 'common/months/april',
    key: 'april',
    month: 4
  },
  {
    title: 'common/months/may',
    key: 'may',
    month: 5
  },
  {
    title: 'common/months/june',
    key: 'june',
    month: 6
  },
  {
    title: 'common/months/july',
    key: 'july',
    month: 7
  },
  {
    title: 'common/months/august',
    key: 'august',
    month: 8
  },
  {
    title: 'common/months/september',
    key: 'september',
    month: 9
  },
  {
    title: 'common/months/october',
    key: 'october',
    month: 10
  }
];

export { default as accessRoles } from './accessRoles';
