export const TERRITORIAL_CREATE = '@/territorial/TERRITORIAL_CREATE';

export const FETCH_TERRITORIAL_CURRENT = '@/territorial/FETCH_TERRITORIAL_CURRENT';
export const SET_TERRITORIAL_CURRENT = '@/territorial/SET_TERRITORIAL_CURRENT';

export const FETCH_TERRITORIAL_LIST = '@/territorial/FETCH_TERRITORIAL_LIST';
export const SET_TERRITORIAL_LIST = '@/territorial/SET_TERRITORIAL_LIST';

export const SET_TERRITORIAL_CHANGE_STATUS = '@/territorial/SET_TERRITORIAL_CHANGE_STATUS';
export const FETCH_CHANGE_TERRITORIAL_STAUTS = '@/territorial/FETCH_CHANGE_TERRITORIAL_STAUTS';

export const UPDATE_TERRITORIAL = '@/territorial/UPDATE_TERRITORIAL';
export const CHANGE_TERRITORIAL_LOGIN_AND_PASSWORD = '@/territorial/CHANGE_TERRITORIAL_LOGIN_AND_PASSWORD';

export const FETCH_TERRITORIAL_ACTIVE_LIST = '@/territorial/FETCH_TERRITORIAL_ACTIVE_LIST';
export const PUT_TERRITORIAL_ACTIVE_LIST = '@/territorial/PUT_TERRITORIAL_ACTIVE_LIST';