import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';

import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { productsQuantity } from '@/services/api';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchList = (payload) => ({
	type: types.FETCH_PRODUCTS_QUANTITY_LIST,
	payload
});

const setTotal = (payload)=>({
	type: types.SET_TOTAL,
	payload
});

const clearProductsQuantity = (payload)=>({
	type: types.CLEAR_PRODUCTS_QUANTITY,
	payload
});

const setActiveManufacturers = (payload)=>({
	type: types.SET_ACTIVE_MANUFACTURERS,
	payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchList({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield put(clearProductsQuantity());
		yield put(setActiveManufacturers(payload.manufacturer));
		const { data } = yield call(productsQuantity.getData, payload);
		yield put(setTotal(data));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}

}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_PRODUCTS_QUANTITY_LIST, fetchList);
}
