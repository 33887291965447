import * as types from './types';
import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";

const initialState = new Map({
    current: {},
    activeList: [],
    activeListByProductLine: [],
    activeListByPlatform: [],
    activeListByManufacturer: [],
    activeListByPlatformAndManufacturer: [],
});

export default createReducer(initialState, {
    [types.PUT_EQUIPMENT_GROUP_ACTIVE_LIST]: (state, { payload })=>state.set('activeList', payload),
    [types.PUT_EQUIPMENT_GROUP]: (state, { payload })=>state.set('current', payload),
    [types.PUT_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER]: (state, { payload })=>state.set('activeListByManufacturer', payload),
    [types.PUT_EQUIPMENT_GROUP_LIST_BY_PRODUCT_LINE]: (state, { payload })=>state.set('activeListByProductLine', payload),
    [types.PUT_EQUIPMENT_GROUP_LIST_BY_PLATFORM]: (state, { payload })=>state.set('activeListByPlatform', payload),
    [types.PUT_EQUIPMENT_GROUP_LIST_BY_MANUFACTURER_AND_PLATFORM]: (state, { payload })=>state.set('activeListByPlatformAndManufacturer', payload)
});
