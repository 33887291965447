import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from "@/redux/helpers";
import { SERVER_DATE_FORMAT } from "@/constants/date-time";
import moment from "moment";

const initialState = new Map({
  currentVehicle: {},
  listOfVehicles: {
    data:[],
    meta: {}
  }
});

export default createReducer(initialState, {
  [types.FETCH_CURRENT_VEHICLE_EMITTER]: (state, { payload }) => {
    payload.bought_in = payload.bought_in ? moment.unix(payload.bought_in).format(SERVER_DATE_FORMAT) : null;
    payload.production_in = payload.production_in ? moment.unix(payload.production_in).format(SERVER_DATE_FORMAT) : null;
    return state.set('currentVehicle', payload);
  },
  [types.FETCH_VEHICLE_LIST_EMITTER]: (state, { payload }) => {
    payload.data = payload.data.map((vehicle)=>{
      vehicle.bought_in = vehicle.bought_in ? moment.unix(vehicle.bought_in).format(SERVER_DATE_FORMAT) : null;
      vehicle.production_in = vehicle.production_in ? moment.unix(vehicle.production_in).format('YYYY') : null;
      return vehicle;
    });
    return state.set('listOfVehicles', payload);
  }
});
