import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';

import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { dashboard } from '@/services/api';
import moment from 'moment';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchList = (payload) => ({
	type: types.FETCH_DASHBOARD_LIST,
	payload
});

triggers.fetchSalesActivity = (payload) => ({
	type: types.FETCH_DASHBOARD_SALES_ACTIVITY,
	payload
});

triggers.fetchSalesTotal = (payload) => ({
	type: types.FETCH_DASHBOARD_SALES_TOTAL,
	payload
});

triggers.fetchProductMix = (payload) => ({
	type: types.FETCH_DASHBOARD_PRODUCT_MIX,
	payload
});

const setSalesActivity = (payload) => ({
	type: types.SET_DASHBOARD_SALES_ACTIVITY,
	payload
});

const setSalesTotal = (payload) => ({
	type: types.SET_DASHBOARD_SALES_TOTAL,
	payload
});

const setProductMix = (payload) => ({
	type: types.SET_DASHBOARD_PRODUCT_MIX,
	payload
});

const setFilterValue = (payload) => ({
	type: types.SET_FILTER_VALUE,
	payload
});

const setUpdateTime = (payload) => ({
	type: types.SET_UPDATE_TIME,
	payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchList({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield put(setFilterValue(payload));
		const { data: salesActivityData } = yield call(dashboard.salesActivity, payload);
		const { data: salesTotalData } = yield call(dashboard.salesTotal, payload);
		const { data: productMixData } = yield call(dashboard.productMix, payload);
		yield put(setSalesActivity(salesActivityData));
		yield put(setSalesTotal(salesTotalData));
		yield put(setProductMix(productMixData));
		yield put(setUpdateTime(moment().format('DD MMM YYYY HH:mm')));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchSalesActivity({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield put(setFilterValue(payload));
		const { data } = yield call(dashboard.salesActivity, payload);
		yield put(setSalesActivity(data));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchSalesTotal({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield put(setFilterValue(payload));
		const { data } = yield call(dashboard.salesTotal, payload);
		yield put(setSalesTotal(data));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchProductMix({ type, payload }) {
	yield put(addPreloader(type));
	try {
		yield put(setFilterValue(payload));
		const { data } = yield call(dashboard.productMix, payload);
		yield put(setProductMix(data));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_DASHBOARD_LIST, fetchList);
	yield takeEvery(types.FETCH_DASHBOARD_SALES_ACTIVITY, fetchSalesActivity);
	yield takeEvery(types.FETCH_DASHBOARD_SALES_TOTAL, fetchSalesTotal);
	yield takeEvery(types.FETCH_DASHBOARD_PRODUCT_MIX, fetchProductMix);
}
