import {
  call, put, takeEvery, select,
} from 'redux-saga/effects';
import * as types from './types';
import { refreshTokenWorker } from '../auth/saga';
import { profile } from '@/services/api';
import { noty } from '@/utilities/notifications';
import { history } from '@/redux/history';

export const triggers = {
  me: () => ({
    type: types.FETCH_ME_PROFILE,
  }),
  meEdit: (payload) => ({
    type: types.PROFILE_ME_EDIT,
    payload,
  }),
};

export function* fetchMeProfile({ type, payload }) {
  try {
    const roleId = yield select((state) => (state.auth.token ? state.auth.token.role_id : 0));
    const securityLevel = yield select((state) => (state.auth.token.security_level ? state.auth.token.security_level : 0));

    const resp = yield call(
      (roleId === 1 || roleId === 2) ? profile.employeesInfo
        : (roleId === 3 && securityLevel !== 6) ? profile.dealerInfo
          : (roleId === 6 && securityLevel === 6) ? profile.coWorkerInfo
            : roleId === 4 ? profile.territorialInfo
              : roleId === 5 ? profile.countryManagerInfo
                : roleId === 7 ? profile.productSalesSpecialistInfo
                  : null,
    );
    yield put({
      type: types.SET_ME_PROFILE,
      payload: resp.data,
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* editMeProfile({ type, payload }) {
  try {
    const roleId = yield select((state) => (state.auth.token ? state.auth.token.role_id : 0));

    yield call(
      (roleId === 1 || roleId === 2) ? profile.employeesEdit
        : roleId === 3 ? profile.dealerEdit
          : roleId === 6 ? profile.coWorkerEdit
            : roleId === 4 ? profile.territorialEdit
              : roleId === 5 ? profile.countryManagerEdit
                : roleId === 7 ? profile.productSalesSpecialistEdit
                  : null,
      payload,
    );
    yield call(refreshTokenWorker, { refresh_token: localStorage.refresh_token });
    if (payload.photo && typeof payload.photo === "object") {
      const fd = new FormData();
      fd.append('photo', payload.photo[0]);
      yield call(
        (roleId === 1 || roleId === 2) ? profile.employeesPhoto
          : roleId === 3 ? profile.dealerPhoto
            : roleId === 6 ? profile.coWorkerPhoto
              : roleId === 4 ? profile.territorialPhoto
                : roleId === 5 ? profile.countryManagerPhoto
                  : roleId === 7 ? profile.productSalesSpecialistPhoto
                    : null,
        fd,
      );
    }

    yield call(history.push, '/profile');
    yield call(noty, 'success', 'Profile edit success!');
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* saga() {
  yield takeEvery(types.FETCH_ME_PROFILE, fetchMeProfile);
  yield takeEvery(types.PROFILE_ME_EDIT, editMeProfile);
}
