import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { supervisor } from '@/services/api';

export const triggers = {};

//-----------------------------------------
//	Trigger Helpers
//-----------------------------------------

triggers.superVisorListByDealerId = (id)=>({
    type: types.FETCH_SUPERVISOR_BY_DEALER_ID,
    id
})

triggers.superVisorListByCoWorkerId = (id)=>({
    type: types.FETCH_SUPERVISOR_BY_COWORKER_ID,
    id
})

const setSupervisorListByCoWorker = (payload)=>({
    type: types.SET_SUPERVISOR_BY_COWORKER_ID,
    payload
})

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* superVisorListByDealerId({ type, id }) {
    try {

        const resp = yield call(supervisor.listByDealerId, id);
        yield put({
            type: types.SET_SUPERVISOR_BY_DEALER_ID,
            payload: resp.data
        })

    } catch(e) {
        yield call(console.warn, e);
    }
}

export function* superVisorListByCoWorkerId({ type, id }) {
    try {
        const { data } = yield call(supervisor.listByCoWorkerId, id);
        yield put(setSupervisorListByCoWorker(data));

    } catch(e) {
        yield call(console.warn, e);
    }
}

//-----------------------------------------
//	Root sagas
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.FETCH_SUPERVISOR_BY_DEALER_ID, superVisorListByDealerId);
    yield takeEvery(types.FETCH_SUPERVISOR_BY_COWORKER_ID, superVisorListByCoWorkerId);
}

