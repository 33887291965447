
//-----------------------------------------
//	Country goal
//-----------------------------------------

export const FETCH_TOTAL_COUNTRY_LIST = '@/total_country_goals/FETCH_TOTAL_COUNTRY_LIST';
export const SET_TOTAL_COUNTRY_LIST = '@/total_country_goals/SET_TOTAL_COUNTRY_LIST';
export const SET_COUNTRU_GOAL = '@/total_country_goals/SET_COUNTRU_GOAL';

//-----------------------------------------
//	Manufacture unit
//-----------------------------------------

export const FETCH_DEALER_MODEL_BREAKDOWN_UNIT = '@/total_country_goals/FETCH_DEALER_MODEL_BREAKDOWN_UNIT';
export const SET_DEALER_MODEL_BREAKDOWN_UNIT = '@/total_country_goals/SET_DEALER_MODEL_BREAKDOWN_UNIT';
export const SET_DEALER_MODEL_BREAKDOWN_UNIT_TO = '@/total_country_goals/SET_DEALER_MODEL_BREAKDOWN_UNIT_TO';

//-----------------------------------------
//	Dealer unit
//-----------------------------------------

export const FETCH_DEALER_UNIT = '@/total_country_goals/FETCH_DEALER_UNIT';
export const SET_DEALER_UNIT = '@/total_country_goals/SET_DEALER_UNIT';
export const SET_DEALER_UNIT_TO = '@/total_country_goals/SET_DEALER_UNIT_TO';

//-----------------------------------------
//	Sales Man Plan
//-----------------------------------------

export const FETCH_SALES_MAN_PLAN = '@/total_country_goals/FETCH_SALES_MAN_PLAN';
export const SET_SALES_MAN_PLAN = '@/total_country_goals/SET_SALES_MAN_PLAN';
export const SET_SALES_MAN_PLAN_TO = '@/total_country_goals/SET_SALES_MAN_PLAN_TO';
