export const SCROLL = { x: 1400 };
export const SIZE = 'middle';
export const BORDERED = true;
export const PAGE_SIZE_OPTIONS = ['12', '24', '48', '96'];
export const PAGE_SIZE_OPTIONS_10 = ['10', '20', '40', '80'];
export const PAGE_SIZE_OPTIONS_x4 = ['12', '24', '48', '96'];
export const PAGE_SIZE_OPTIONS_x4_INITIAL = PAGE_SIZE_OPTIONS_x4[0];
export const PAGE_SIZE_OPTIONS_10_INITIAL = PAGE_SIZE_OPTIONS_10[0];

export const BASE_CONFIG = {
  scroll: SCROLL,
  size: SIZE,
  bordered: BORDERED
};
