const fields = ['offer_time', 'created_at'];

export const formats = (data)=>{
    const res = Object.keys(data).filter(field=>{
        return fields.indexOf(field) >= 0;
    });
    if( res.length > 0 ) {
        res.forEach(field=>{
            data[field+'_from'] = data[field][0];
            data[field+'_to'] = data[field][1];
            delete data[field];
        })
    }
    return data;
}