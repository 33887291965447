export const boedChartConfig = {
	boed_chart_colors: {
		unitSalesGoal: 'rgba(255, 222, 0, 0.6)',
		industryForecast: 'rgba(255, 222, 0, 0.8)',
		coverageGoal: 'rgba(255, 222, 0, 1)',
		hotProspects: 'rgba(54, 124, 43, 0.4)',
		salesProject: 'rgba(54, 124, 43, 0.6)',
		offersMade: 'rgba(54, 124, 43, 0.8)',
		won: 'rgba(54, 124, 43, 1)',
		offersNotMade: '#8884d8',
		lost: '#ff330a',
		missingSalesToGoal: '#a4de6c'
	}
};
