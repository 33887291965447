import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import t from '@/utilities/translate';

const AnotherBreadcrumb = connect(
    state=>({_state: state}),
    null,
    null,
    { pure:false }
)(({
    _state,
    path,
  params
}) => {

    const anotherTypes = {
		'/vehicles/:deal_id/:id': [
			{name: 'Home', link: '/'},
			{name: t('deals/list/breadcrumbs'), link: '/deals'},
			{name: params.deal_id, link: `/deals/${params.deal_id}`},
			{name: t('vehicle/edit/breadcrumbs')}
		],
        '/territorials/add_new_territory_manager': [
            {name: 'Home', link: '/'},
            {name: t('territorials/add_new_territory_manager/breadcrumbs')}
        ],
        '/territorials/add_new_territory_manager/:distributor': [
            {name: 'Home', link: '/'},
            {name: t('territorials/add_new_territory_manager/breadcrumbs')}
        ],
        '/territorials/:id-:name/edit': [
            {name: 'Home', link: '/'},
            {name: t('territorials/add_new_territory_manager/breadcrumbs')}
        ],
        '/trade-in/:deal_id/client/:client_id/vehicles/:id': [
            {name: 'Home', link: '/'},
            {name: t('deals/list/breadcrumbs'), link: '/deals'},
            {name: params.deal_id, link: `/deals/${params.deal_id}`},
            {name: t('trade_in/edit/breadcrumbs')}
        ],
        '/trade-in/:deal_id/client/:client_id/create': [
            {name: 'Home', link: '/'},
            {name: t('deals/list/breadcrumbs'), link: '/deals'},
            {name: params.deal_id, link: `/deals/${params.deal_id}`},
            {name: t('trade_in/create/breadcrumbs')}
        ]
    }

    return <Breadcrumb style={{ margin: '16px 16px 0' }}>
        {anotherTypes[path].map((brd, index)=><Breadcrumb.Item key={index}>{!brd.link ? brd.name : <Link to={brd.link}>{brd.name}</Link>}</Breadcrumb.Item>)}
    </Breadcrumb>
})

// map & render your breadcrumb components however you want
const Breadcrumbs = ({
    breadcrumbs,
    match,
    history,
    exclude// ,
    // ...props
}) => {
    return !exclude.includes(match.path) ? <Breadcrumb style={{ margin: '16px 16px 0' }}>
        {breadcrumbs.map((breadcrumb, index) => {
            return (
                <Breadcrumb.Item key={breadcrumb.key}>
                    {
                        breadcrumbs.length - 1 !== index
                            ? <Link to={breadcrumb.props.match.url}>{breadcrumb}</Link>
                            : <span>{breadcrumb}</span>
                    }
                </Breadcrumb.Item>
            );
        })}
    </Breadcrumb> : <AnotherBreadcrumb params={match.params} path={match.path}/>;
};

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.array
};

export default Breadcrumbs;
