export const FETCH_HISTORY_LIST_BY_TOTAL = '@/order-scheduled-history/FETCH_HISTORY_LIST_BY_TOTAL';
export const FETCH_HISTORY_LIST_BY_DEALER = '@/order-scheduled-history/FETCH_HISTORY_LIST_BY_DEALER';
export const SET_HISTORY_LIST_BY_TOTAL = '@/order-scheduled-history/SET_HISTORY_LIST_BY_TOTAL';
export const SET_HISTORY_LIST_BY_DEALER = '@/order-scheduled-history/SET_HISTORY_LIST_BY_DEALER';

export const CHANGE_HISTORY_BY_DEALER = '@/order-scheduled-history/CHANGE_HISTORY_BY_DEALER';
export const SET_CHANGED_HISTORY_LIST_BY_DEALER = '@/order-scheduled-history/SET_CHANGED_HISTORY_LIST_BY_DEALER';

export const CLEAR_HISTORY_BY_TOTAL = '@/order-scheduled-history/CLEAR_HISTORY_BY_TOTAL';


