import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import T from '@/utilities/translate';
import { estimateDescription } from '@/services/api';
import { noty } from "@/utilities/notifications";
import { addPreloader, removePreloader } from '../common/preloaders';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.createEstimateDescription = (query) => ({
    type: types.CREATE_ESTIMATE_DESCRIPTION,
    query
})

triggers.editEstimateDescription = (id, query) => ({
    type: types.EDIT_ESTIMATE_DESCRIPTION,
    id,
    query
})

triggers.currentEstimateDescription = (id) => ({
    type: types.CURRENT_ESTIMATE_DESCRIPTION,
    id
})

triggers.changeEstimateDescription = (id)=>({
    type: types.CHANGE_STATUS_ESTIMATE_DESCRIPTION,
    id
})

triggers.fetchEstimateDescriptionWithProductsActiveModel = (payload) => {
    return {
        type: types.FETCH_ESTIMATE_DESCRIPTION_WITH_PRODUCTS_ACTIVE_MODEL,
        payload
    }
}

triggers.fetchEstimateDescriptionListByCompositeLevel = (composite_level_id) => {
    return {
        type: types.FETCH_ESTIMATE_DESCRIPTION_LIST_BY_COMPOSITE_LEVEL,
        composite_level_id
    }
}

triggers.fetchEstimateDescriptionListByManufacturerAndEq = (payload) => {
    return {
        type: types.FETCH_ESTIMATE_DESCRIPTION_LIST_BY_MANUFACTURER_AND_EQ,
        payload
    }
}

triggers.fetchEstimateDescriptionListByEquipmentGroup = (equipment_group_id) => {
    return {
        type: types.FETCH_ESTIMATE_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP,
        equipment_group_id
    }
}

triggers.fetchEstimateDescriptionListByProductLineGroup = (payload) => {
    return {
        type: types.FETCH_ESTIMATE_DESCRIPTION_LIST_BY_PRODUCT_LINE_GROUP,
		payload
    }
}

const putEstimateDescriptionByEquipmentGroup = (payload)=>({
    type: types.PUT_ESTIMATE_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP,
    payload
})

const putEstimateDescriptionByManufacturerAndEq = (payload)=>({
    type: types.PUT_ESTIMATE_DESCRIPTION_LIST_BY_MANUFACTURER_AND_EQ,
    payload
});

const putEstimateDescriptionByProductLineGroup = (payload)=>({
	type: types.PUT_ESTIMATE_DESCRIPTION_LIST_BY_PRODUCT_LINE_GROUP,
	payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* createEstimateDescription({ type, query }) {
    yield put(addPreloader(type));
    try {
        yield call(estimateDescription.create, query);
        yield call(noty, 'success', T('estimateDescription/create/success'));
        // yield call(window._forward);
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* changeStatusEstimateDescription({ type, id }) {

    try {
        yield call(estimateDescription.changeStatus, id);
        yield call(noty, 'success', T('estimateDescription/changeStatus/success'));
    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* editEstimateDescription({ type, id, query }) {
    yield put(addPreloader(type));
    try {
        yield call(estimateDescription.edit, id, query);
        yield call(noty, 'success', T('estimateDescription/edit/success'));
        yield call(window._forward, { id });
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* currentEstimateDescription({ type, id }) {

    yield put(addPreloader(type));

    try {
        const resp = yield call(estimateDescription.current, id);
        yield put({
            type: types.PUT_ESTIMATE_DESCRIPTION,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* fetchEstimateDescriptionWithProductsActiveModel({ payload }) {
    try {
        const resp = yield call(estimateDescription.activeListWithProductsActiveModel, payload);
        yield put({
            type: types.PUT_ESTIMATE_DESCRIPTION_WITH_PRODUCTS_ACTIVE_MODEL,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* fetchEstimateDescriptionListByCompositeLevel({ type, composite_level_id }) {
    try {
        const resp = yield call(estimateDescription.activeListByCompositeLevel, composite_level_id);
        yield put({
            type: types.PUT_ESTIMATE_DESCRIPTION_LIST_BY_COMPOSITE_LEVEL,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* fetchEstimateDescriptionListByEquipmentGroup({ equipment_group_id }) {
    try {
        const { data } = yield call(estimateDescription.activeListByEqupmentGroup, equipment_group_id);
        yield put(putEstimateDescriptionByEquipmentGroup(data))
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* fetchEstimateDescriptionListByManufacturerAndEq({ payload }) {
    try {
        const { data } = yield call(estimateDescription.activeListByManufacturerAndEq, payload);
        yield put(putEstimateDescriptionByManufacturerAndEq(data))
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* fetchEstimateDescriptionListByProductLineGroup({ payload }) {

	try {
		const { data } = yield call(estimateDescription.activeListByProductLineGroup, payload);
		yield put(putEstimateDescriptionByProductLineGroup(data))
	} catch (e) {
		yield call(console.warn, e);
	}
}

export function* saga() {
    yield takeEvery(types.FETCH_ESTIMATE_DESCRIPTION_WITH_PRODUCTS_ACTIVE_MODEL, fetchEstimateDescriptionWithProductsActiveModel);
    yield takeEvery(types.FETCH_ESTIMATE_DESCRIPTION_LIST_BY_COMPOSITE_LEVEL, fetchEstimateDescriptionListByCompositeLevel);
    yield takeEvery(types.FETCH_ESTIMATE_DESCRIPTION_LIST_BY_MANUFACTURER_AND_EQ, fetchEstimateDescriptionListByManufacturerAndEq);
    yield takeEvery(types.FETCH_ESTIMATE_DESCRIPTION_LIST_BY_PRODUCT_LINE_GROUP, fetchEstimateDescriptionListByProductLineGroup);
    yield takeEvery(types.FETCH_ESTIMATE_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP, fetchEstimateDescriptionListByEquipmentGroup);
    yield takeEvery(types.CHANGE_STATUS_ESTIMATE_DESCRIPTION, changeStatusEstimateDescription);
    yield takeEvery(types.CURRENT_ESTIMATE_DESCRIPTION, currentEstimateDescription);
    yield takeEvery(types.CREATE_ESTIMATE_DESCRIPTION, createEstimateDescription);
    yield takeEvery(types.EDIT_ESTIMATE_DESCRIPTION, editEstimateDescription);
}

