export const FETCH_ALL_LIST_DATA = '@/list-to-companies/FETCH_ALL_LIST_DATA';
export const SAVE_ALL_LIST_DATA = '@/list-to-companies/SAVE_ALL_LIST_DATA';
export const FETCH_FULL_DATA = '@/list-to-companies/FETCH_FULL_DATA';
export const SAVE_FULL_DATA = '@/list-to-companies/SAVE_FULL_DATA';
export const CHANGE_COMPANY = '@/list-to-companies/CHANGE_COMPANY';
export const CHANGE_COMPANY_STATUS = '@/list-to-companies/CHANGE_COMPANY_STATUS';
export const CHANGE_COMPANY_ACTUAL_SALES = '@/list-to-companies/CHANGE_COMPANY_ACTUAL_SALES';
export const DELETE_COMPANY = '@/list-to-companies/DELETE_COMPANY';
export const GET_COMPANY_INFO = '@/list-to-companies/GET_COMPANY_INFO';
export const SAVE_COMPANY_INFO = '@/list-to-companies/SAVE_COMPANY_INFO';
export const CREATE_COMPANY = '@/list-to-companies/CREATE_COMPANY';
export const SET_FILTER = '@/list-to-companies/SET_FILTER';
