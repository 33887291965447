import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';
import t from '@/utilities/translate';
import { dashboardChartConfig } from '@/components/dashboard/config';

const initialState = new Map({
	salesActivity: {
		table: [],
		chart: []
	},
	salesTotalTable: [],
	productMixChart: [],
	filter: {},
	updateTime: ''
});

export default createReducer(initialState, {
	[types.SET_DASHBOARD_SALES_ACTIVITY]: (state, { payload }) => state.set('salesActivity', (() => {
		const chart = payload.salesPipeline;
		const chartData = [];

		const chartOrder = [
			'toVerify',
			'open',
			'offerMade',
			'lost',
			'wonPrePay',
			'wonPaid',
			'unitSalesGoal',
			'gapToGoal'
		];
		chartOrder.forEach((item) => {
			if (dashboardChartConfig.colors[item]) {
				chartData.push({
					name: t(`dashboard/list/${item}`),
					value: [-chart[item], chart[item]],
					fill: dashboardChartConfig.colors[item],
					isPercent: item === 'gapToGoal'
				});
			}
		});

		return {
			table: payload.salesActivity,
			chart: chartData
		}
	})()),
	[types.SET_DASHBOARD_SALES_TOTAL]: (state, { payload }) => state.set('salesTotalTable', payload),
	[types.SET_DASHBOARD_PRODUCT_MIX]: (state, { payload }) => state.set('productMixChart', payload),
	[types.SET_UPDATE_TIME]: (state, { payload }) => state.set('updateTime', payload),
});
