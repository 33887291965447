import React from 'react';
import { store } from '@/redux/store';
import { change } from 'redux-form';
import { equipment_type, months } from '@/constants';

import { triggers as triggersLostToCompanies } from '@/redux/lost-to-companies/actions';
import { triggers as triggersClient } from '@/redux/client/actions';
import { triggers as triggersYears } from '@/redux/years/actions';
import { debounce } from 'lodash';
import t from '@/utilities/translate';
import { getRole } from '@/utilities/role';

const dispatch = (...args) => store.dispatch(...args);
const _changeFormField = (...args) => dispatch(change(...args));

export const companyLostDealActionCreator = (initialValues, formName) => {
	const { company_lost_deal_name, company_lost_deal_id } = initialValues;

	if (company_lost_deal_id && company_lost_deal_name) {
		store.dispatch(triggersLostToCompanies.getFullData({ query: company_lost_deal_name }));
		setTimeout(() => {
			_changeFormField(formName, 'company_lost_deal_id', String(company_lost_deal_id));
		}, 100);
	}
};

const clientActionCreator = (initialValues, formName) => {
	const { client_id, client_company_name } = initialValues;

	if (client_id && client_company_name) {
		store.dispatch(triggersClient.fetchClientsByCompanyName(client_company_name));
		setTimeout(() => {
			_changeFormField(formName, 'client_id', String(client_id));
		}, 100);
	}
};

export default (props) => {
	const roleId = getRole();
	const rolesHavePermissions = [4, 5, 7];
	const canChangeCost = props.initialValues.cost ? rolesHavePermissions.includes(roleId) : true;

	return ([
		{
			id: 'company_lost_deal_id',
			name: 'company_lost_deal_id',
			label: 'actual-sales-plan-draft/edit/company_lost_deal_id/label',
			placeholder: 'actual-sales-plan-draft/edit/company_lost_deal_id/placeholder',
			type: 'autocomplete',
			data: (state) => state.lostToCompanies.get('fullData').map((company) => ({
				value: Number(company.id),
				label: company.name
			})),
			actionCreator: () => companyLostDealActionCreator(props.initialValues, props.form),
			handler: debounce((value) => {
				const query = value.trim();

				if (query.length > 2) {
					store.dispatch(triggersLostToCompanies.getFullData({ query }));
				}
			}, 300),
			disabled: !!(props.initialValues.parent_id || props.initialValues.child?.length),
			_validate: true,
		},
		{
			id: 'count',
			name: 'count',
			label: 'actual-sales-plan-draft/edit/count/label',
			placeholder: 'actual-sales-plan-draft/edit/count/placeholder',
			type: 'number',
			onChange: (_, value) => {
				const formValues = store.getState().form[props.form].values;
				store.dispatch(change(props.form, 'amount', Number(value) * Number(formValues.cost)));
			},
			_validate: true,
		},
		{
			id: 'cost',
			name: 'cost',
			label: 'actual-sales-plan-draft/edit/cost/label',
			placeholder: 'actual-sales-plan-draft/edit/cost/placeholder',
			type: 'number',
			onChange: (_, value) => {
				const formValues = store.getState().form[props.form].values;
				store.dispatch(change(props.form, 'amount', Number(value) * Number(formValues.count)));
			},
			precision: 2,
			disabled: !canChangeCost,
			_validate: true,
		},
		{
			id: 'amount',
			name: 'amount',
			label: 'actual-sales-plan-draft/edit/amount/label',
			placeholder: 'actual-sales-plan-draft/edit/amount/placeholder',
			type: 'number',
			disabled: true,
			precision: 2,
			_validate: true,
		},
		{
			id: 'client_id',
			name: 'client_id',
			label: 'actual-sales-plan-draft/edit/client_id/label',
			placeholder: 'actual-sales-plan-draft/edit/client_id/placeholder',
			type: 'autocomplete',
			data: (state) => state.client.get('clientsByCompanyName').map((client) => ({
				value: Number(client.id),
				label: client.name
			})),
			actionCreator: () => clientActionCreator(props.initialValues, props.form),
			handler: debounce((company_name) => {
				const name = company_name.trim();

				if (name.length > 2) {
					store.dispatch(triggersClient.fetchClientsByCompanyName(name))
				}
			}, 300),
			disabled: !!(props.initialValues.parent_id || props.initialValues.child?.length),
			_validate: true,
		},
		{
			id: 'equipment_type',
			name: 'equipment_type',
			label: 'total_country_goals/filter/equipment_type/label',
			placeholder: 'total_country_goals/filter/equipment_type/placeholder',
			type: 'select',
			data: (state) => (Object.keys(equipment_type).map((type) => ({
				label: equipment_type[type],
				value: Number(type),
			}))),
			_validate: true,
		},
		{
			id: 'year',
			name: 'year',
			label: 'boed/filter/statistics_year/label',
			placeholder: 'boed/filter/statistics_year/placeholder',
			type: 'select',
			actionCreator: triggersYears.fetchSalesPlansYearsList,
			data: (state) => state.years.get('salesPlansList').map((year) => ({
				label: year,
				value: year,
			})),
			_validate: true,
		},
		{
			id: 'month',
			name: 'month',
			label: 'filter/date_fiscal_month/label',
			placeholder: 'filter/date_fiscal_month/placeholder',
			type: 'select',
			data: () => months.sort((a, b) => a.month - b.month).map((item) => ({
				label: t(item.title),
				value: item.month,
			})),
			// _validate: true,
		},
		{
			id: 'comment',
			name: 'comment',
			label: 'client/create/comment/label',
			placeholder: 'client/create/comment/placeholder',
			type: 'text',
			textArea: true,
			style: {
				height: 100
			},
		},
	])
};
