export const LOGIN_REQUEST = '@auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = '@auth/LOGIN_FAIL';

export const LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST';
export const FORCE_LOGOUT_REQUEST = '@auth/FORCE_LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = '@auth/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = '@auth/LOGOUT_FAIL';

export const REFRESH_TOKEN = '@auth/REFRESH_TOKEN';
export const CLEAR_TOKEN = '@auth/CLEAR_TOKEN';
export const GET_USER_INFO = '@auth/GET_USER_INFO';
export const SET_USER_INFO = '@auth/SET_USER_INFO';

export const CHANGE_LANGUAGE = '@/auth/CHANGE_LANGUAGE';
