export const manufactureForward = (location, props, routes) => {
  switch (location) {
	case routes['manufacturers/create'].link():
	  return routes['manufacturers'].link();

	case routes['manufacturers/edit'].link(props.id):
	  return routes['manufacturers'].link();
	default:
	  break;
  }
};
