import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
    goals: [],
    manufacture_units: [],
    dealer_units: [],
    salesManPlans: [],
    dealerBreakdownUnits: []
});

export default createReducer(initialState, {
    [types.SET_TOTAL_COUNTRY_LIST]: (state, { payload }) => state.set('goals', payload),
    [types.SET_DEALER_MODEL_BREAKDOWN_UNIT]: (state, { payload })=> state.set('dealerBreakdownUnits', payload),
    [types.SET_DEALER_UNIT]: (state, { payload })=> state.set('dealer_units', payload),
    [types.SET_DEALER_UNIT_TO]: (state, { id, query })=> {
        return state.set('dealer_units', state.get('dealer_units').reduce((acc, dealership) => {
            const targetObj = dealership.find(el => el.id === id);
            if (targetObj) {
                dealership[dealership.indexOf(targetObj)] = {
                    ...targetObj,
                    ...query,
                    count_in_units: Number(query.count_in_units)
                };
            }
            acc.push(dealership);
            return acc;
        }, [])).set('dealerBreakdownUnits', state.get('dealerBreakdownUnits').reduce((acc, dealerBreakdown) => {
            acc.push(dealerBreakdown.aor_potentional.id === id ? {
                ...dealerBreakdown,
                aor_potentional: {
                    ...dealerBreakdown.aor_potentional,
                    count_in_units: +query.count_in_units
                }
            } : dealerBreakdown);
            return acc;
        }, []));
    },
    [types.SET_SALES_MAN_PLAN]: (state, { payload })=>state.set('salesManPlans', payload),
    [types.SET_SALES_MAN_PLAN_TO]: (state, { id, query }) => {
        return state.set('salesManPlans', state.get('salesManPlans').reduce((acc, salesManPlan) => {
            const targetObj = salesManPlan.find(el => el.id === id);
            if (targetObj) {
                salesManPlan[salesManPlan.indexOf(targetObj)] = {
                    ...targetObj,
                    ...query,
                    count_in_units: Number(query.count_in_units)
                };
            }
            acc.push(salesManPlan);
            return acc;
        }, []));
    }
});
