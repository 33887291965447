import { call, put, takeEvery } from 'redux-saga/effects';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import * as types from './types';
import t from '@/utilities/translate';
import { territorial } from '@/services/api';
import { noty } from "@/utilities/notifications";

export const triggers = {};

//-----------------------------------------
//	Trigger Helpers
//-----------------------------------------

triggers.territorialCreate = (query) => ({
    type: types.TERRITORIAL_CREATE,
    query
})

triggers.territorialCurrent = (id) => ({
    type: types.FETCH_TERRITORIAL_CURRENT,
    id
})

triggers.list = (query) => ({
    type: types.FETCH_TERRITORIAL_LIST,
    query
})

triggers.territorialChangeStatus = (id, _type_) => ({
    type: types.FETCH_CHANGE_TERRITORIAL_STAUTS,
    id,
    _type_
})

triggers.territorialEdit = (id, query) => ({
    type: types.UPDATE_TERRITORIAL,
    id,
    query
})

triggers.territorialChangeLoginAndPassword = (id)=>({
    type: types.CHANGE_TERRITORIAL_LOGIN_AND_PASSWORD,
    id
})

triggers.territorialActiveList = ()=>({
    type: types.FETCH_TERRITORIAL_ACTIVE_LIST,
})

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* territorialActiveList({ type }) {
    const setActiveTerritorial = (payload)=>({
        type: types.PUT_TERRITORIAL_ACTIVE_LIST,
        payload
    })

    try {
        const resp = yield call(territorial.activeList);
        yield put(setActiveTerritorial(resp.data));

    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* territorialCreate({ type, query }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(territorial.create, query);

        if (query.photo)
            yield call(territorial.uploadPhoto, resp.data.id, query.photo);

        yield call(noty, 'success', t('territorial/create/success'));
        yield call(window._forward);
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* territorialEdit({ type, id, query }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(territorial.edit, id, query);
        if (query.photo && typeof query.photo === 'object')
            yield call(territorial.uploadPhoto, resp.data.id, query.photo);

        yield call(noty, 'success', t('territorial/edit/success'));
        yield call(window._forward, { id });
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* territorialChangeLoginAndPassword({ type, id }) {

    try {
        yield call(territorial.changePasswordLogin, id);
        yield call(noty, 'success', t('territorial/changeLoginAndPassword/success'));
    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* territorialCurrent({ type, id }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(territorial.current, id);
        yield put({
            type: types.SET_TERRITORIAL_CURRENT,
            payload: resp.data
        })

    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* territorialList({ type, query }) {
    yield put(addPreloader(type));

    try {
        const resp = yield call(territorial.list, query);
        yield put({
            type: types.SET_TERRITORIAL_LIST,
            payload: resp.data
        })

    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* territorialChangeStatus({ type, id, _type_ }) {

    try {
        const resp = yield call(territorial.changeStatus, id);
        if (_type_ !== 'profile')
            yield put({
                type: types.SET_TERRITORIAL_CHANGE_STATUS,
                payload: resp.data
            })
        else
            yield put({
                type: types.SET_TERRITORIAL_CURRENT,
                payload: resp.data
            })
        yield call(noty, 'success', t('territorial/changeStatus/success'));

    } catch (e) {
        yield call(console.warn, e);
    }

}

//-----------------------------------------
//	Root sagas
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.FETCH_TERRITORIAL_ACTIVE_LIST, territorialActiveList);
    yield takeEvery(types.CHANGE_TERRITORIAL_LOGIN_AND_PASSWORD, territorialChangeLoginAndPassword);
    yield takeEvery(types.UPDATE_TERRITORIAL, territorialEdit);
    yield takeEvery(types.FETCH_CHANGE_TERRITORIAL_STAUTS, territorialChangeStatus);
    yield takeEvery(types.FETCH_TERRITORIAL_LIST, territorialList);
    yield takeEvery(types.TERRITORIAL_CREATE, territorialCreate);
    yield takeEvery(types.FETCH_TERRITORIAL_CURRENT, territorialCurrent);
}
