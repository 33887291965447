import * as types from './types';
import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';

import { product } from '@/services/api';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { noty } from "@/utilities/notifications";
import t from '@/utilities/translate';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.createProduct = (payload) => ({
    type: types.CREATE_NEW_PRODUCT,
    payload
});

triggers.listProduct = (payload) => ({
    type: types.FETCH_PRODUCT_LIST,
    payload,
});

triggers.listByParams = (payload) => ({
    type: types.FETCH_LIST_BY_PARAMS,
    payload,
});

triggers.currenciesList = (payload) => ({
    type: types.FETCH_CURRENCIES_LIST,
    payload,
});

triggers.currentProduct = (id) => ({
    type: types.FETCH_CURRENT_PRODUCT,
    id
});

triggers.editProduct = (payload, id) => ({
    type: types.UPDATE_PRODUCT,
    id,
    payload
});

triggers.changeStatusProduct = (id, _type_) => ({
    type: types.CHANGE_PRODUCT_STATUS,
    id,
    _type_
});

triggers.removeProduct = (id) => ({
    type: types.REMOVE_PRODUCT,
    id
});

triggers.clearProducts = () => ({
    type: types.CLEAR_PRODUCT_LIST
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* changeStatusProduct({ type, id, _type_ }) {
    const isProductList = _type_ === 'fromList';
    const putChangeStatusType = isProductList ? types.UPDATE_PRODUCT_LIST : types.SET_CURRENT_PRODUCT;
    const successResultNoty = t('product/change_status/notify/success');

    yield put(addPreloader(type));
    try {
        const resp = yield call(product.change_status, id);
        yield put({
            type: putChangeStatusType,
            payload: resp.data
        })
        yield call(noty, 'success', successResultNoty);
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

export function* removeProduct({ type, id }) {
    try {
        yield call(product.remove, id);
        yield call(noty, 'success', t('product/remove/notify/success'));
        yield call(window._forward, { id });
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* editProduct({ type, id, payload }) {
    const productHavePhoto = typeof payload.photo === 'object' && payload.photo !== null;
    const successResultNoty = t('product/edit/notify/success');

    yield put(addPreloader(type));
    try {
        yield call(product.edit, id, payload);

        productHavePhoto && (yield call(product.upload_photo, id, payload.photo));

        yield call(noty, 'success', successResultNoty);
        yield call(window._forward, { id });
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

export function* currentProduct({ type, id }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(product.current, id);
        yield put({
            type: types.SET_CURRENT_PRODUCT,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}


export function* listProduct({ type, payload }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(product.list, payload);
        yield put({
            type: types.SET_PRODUCT_LIST,
            payload: resp.data
        })
    } finally {
        yield put(removePreloader(type));
    }
}

export function* fetchListByParams(action) {
    const { payload } = action;
    try {
        const resp = yield call(product.listByParams, payload);
        yield put({
            type: types.SET_LIST_BY_PARAMS,
            payload: resp.data
        })
    } catch (err) {
        throw err;
    }
}

export function* fetchCurrenciesList() {
    try {
        const resp = yield call(product.currenciesActiveList);
        yield put({
            type: types.SET_CURRENCIES_LIST,
            payload: resp.data
        })
    } catch (err) {
        throw err;
    }
}

export function* createProduct({ type, payload }) {
    yield put(addPreloader(type));
    const productHavePhoto = Boolean(payload.photo);
    const successResultNoty = t('product/create/notify/success');

    try {
        const resp = yield call(product.create, payload);
        productHavePhoto && (yield call(product.upload_photo, resp.data.id, payload.photo));
        yield call(noty, 'success', successResultNoty);
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.REMOVE_PRODUCT, removeProduct);
    yield takeEvery(types.CHANGE_PRODUCT_STATUS, changeStatusProduct);
    yield takeEvery(types.UPDATE_PRODUCT, editProduct);
    yield takeEvery(types.FETCH_CURRENT_PRODUCT, currentProduct);
    yield takeEvery(types.FETCH_PRODUCT_LIST, listProduct);
    yield takeEvery(types.CREATE_NEW_PRODUCT, createProduct);
    yield takeEvery(types.FETCH_LIST_BY_PARAMS, fetchListByParams);
    yield takeEvery(types.FETCH_CURRENCIES_LIST, fetchCurrenciesList);
}
