import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
    goals: [],
    manufacture_units: [],
    dealer_units: [],
    salesManPlans: [],
    dealerBreakdownUnits: []
});

export default createReducer(initialState, {
    [types.SET_TOTAL_COUNTRY_LIST_V2]: (state, { payload }) => state.set('goals', payload),
    [types.SET_DEALER_MODEL_BREAKDOWN_UNIT_V2]: (state, { payload })=> state.set('dealerBreakdownUnits', payload),
    [types.SET_DEALER_UNIT_V2]: (state, { payload })=> state.set('dealer_units', payload),
    [types.SET_DEALER_UNIT_TO_V2]: (state, { id, query })=> {
        return state.set('dealer_units', state.get('dealer_units').reduce((acc, dealership) => {
            const targetObj = dealership.find(el => el.id === id);
            if (targetObj) {
                dealership[dealership.indexOf(targetObj)] = {
                    ...targetObj,
                    ...query,
                    count_in_units: Number(query.count_in_units)
                };
            }
            acc.push(dealership);
            return acc;
        }, [])).set('dealerBreakdownUnits', state.get('dealerBreakdownUnits').reduce((acc, dealerBreakdown) => {
            acc.push(dealerBreakdown.aor_potentional.id === id ? {
                ...dealerBreakdown,
                aor_potentional: {
                    ...dealerBreakdown.aor_potentional,
                    count_in_units: +query.count_in_units
                }
            } : dealerBreakdown);
            return acc;
        }, []));
    },
    [types.SET_SALES_MAN_PLAN_V2]: (state, { payload })=>state.set('salesManPlans', payload),
    [types.SET_SALES_MAN_PLAN_TO_V2]: (state, { id, query }) => {
        return state.set('salesManPlans', state.get('salesManPlans').reduce((acc, salesManPlan) => {
            const targetObj = salesManPlan.find(el => el.id === id);
            if (targetObj) {
                salesManPlan[salesManPlan.indexOf(targetObj)] = {
                    ...targetObj,
                    ...query,
                    count_in_units: Number(query.count_in_units)
                };
            }
            acc.push(salesManPlan);
            return acc;
        }, []));
    },
	[types.SET_COUNTRU_GOAL_V2]: (state, { query }) => {
		return state.set('goals', state.get('goals').reduce((acc, goal) => {
			if (goal.id === query.id) {
				goal = {
					...goal,
					...query,
					units_retail_sales_market_share_goals: Number(query.units_retail_sales_market_share_goals),
					percentage_retail_sales_market_share_goals: Number(query.percentage_retail_sales_market_share_goals),
					total_market_potentional: Number(query.total_market_potentional)
				};
			}
			acc.push(goal);
			return acc;
		}, []));
	}
});
