import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { triggers } from '@/redux/deal/actions';
import Form from './Form';
import { fy_type } from "@/constants";
import t from '@/utilities/translate';

const ChangeFiscalYear = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		dealId,
		defaultValue,
		changeFiscalYear
	} = props;

	const closeModal = ({ fiscal_year }) => {
		changeFiscalYear({
			dealId: dealId,
			fiscal_year
		});
		closeModalFn();
	};

	const availableOptions = Object.keys(fy_type).map((type) => ({
		label: t(fy_type[type]),
		value: type
	}));

	return (
		<Modal {...modalProps} footer={null}>
			<Form availableOptions={availableOptions}
				  initialValues={{ fiscal_year: defaultValue }}
				  onSubmit={closeModal}
			/>
		</Modal>
	);
};

export default connect(
	(state) => {},
	{
		...triggers
	}
)(ChangeFiscalYear);
