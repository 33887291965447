import { Map } from "immutable";
import * as types from "./types";
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
  current: {},
  activeList: [],
  activeListByModelDescription: [],
});

export default createReducer(initialState, {
  [types.PUT_BASE_MACHINERY_DESCRIPTION]: (state, { payload }) =>
    state.set("current", payload),
  [types.PUT_BASE_MACHINERY_DESCRIPTION_ACTIVE_LIST]: (state, { payload }) =>
    state.set("activeList", payload),
  [types.PUT_BASE_MACHINERY_DESCRIPTION_LIST_BY_MODEL_DESCRIPTION]: (
    state,
    { payload }
  ) => state.set("activeListByModelDescription", payload),
});
