import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';
import { store } from '../store';

const initialState = new Map({
  total: [],
  manufacturers: [],
  offers: [],
  wonLost: [],
  valuesType: 'percent'
});

export default createReducer(initialState, {
  [types.SET_TOTAL]: (state, { payload }) => state.set('total', (() => {
	const isPercentValue = state.get('valuesType') === 'percent';
	const data = {};
	//----------- to be verified
	for (let manufacturer in payload.toBeVerify) {
	  data[manufacturer] = {};
	  data[manufacturer].toBeVerify = payload.toBeVerify[manufacturer];
	}
	//----------- sales projects
	for (let manufacturer in payload.salesProject) {
	  if (!data[manufacturer]) {
		data[manufacturer] = {};
	  }
	  if (!data[manufacturer].salesProjects) {
		data[manufacturer].salesProjects = payload.salesProject[manufacturer];
	  }
	}
	//----------- postponed
	for (let manufacturer in payload.postponed) {
	  if (!data[manufacturer]) {
		data[manufacturer] = {};
	  }
	  data[manufacturer].postponed = payload.postponed[manufacturer];
	}
	//----------- invalid
	for (let manufacturer in payload.invalid) {
	  if (!data[manufacturer]) {
		data[manufacturer] = {};
	  }
	  data[manufacturer].invalid = payload.invalid[manufacturer];
	}
	const _data = Object.keys(data).map((name) => {
	  const obj = { name };
	  if (data[name].toBeVerify) {
		obj.toBeVerify = data[name].toBeVerify;
	  }
	  if (data[name].salesProjects) {
		obj.salesProjects = data[name].salesProjects;
	  }
	  if (data[name].postponed) {
		obj.postponed = data[name].postponed;
	  }
	  if (data[name].invalid) {
		obj.invalid = data[name].invalid;
	  }
	  return obj;
	});

	return _data.sort((a, b) => {
	  if (a.name === 'John Deere') {
		return -1;
	  } else if (b.name === 'John Deere') {
		return 1;
	  } else {
		const aTotal = Object.keys(a).filter(el => el !== 'name').reduce((acc, item) => {
		  if (isPercentValue) {
			acc += a[item].percent;
		  } else {
			acc += a[item].count;
		  }
		  return acc;
		}, 0);
		const bTotal = Object.keys(b).filter(el => el !== 'name').reduce((acc, item) => {
		  if (isPercentValue) {
			acc += b[item].percent;
		  } else {
			acc += b[item].count;
		  }
		  return acc;
		}, 0);
		return aTotal < bTotal ? 1 : -1;
	  }
	});
  })()),
  [types.SET_OFFERS]: (state, { payload }) => state.set('offers', (() => {
	const isPercentValue = state.get('valuesType') === 'percent';
	const data = {};
	for (let manufacturer in payload.offersMade) {
	  data[manufacturer] = {};
	  data[manufacturer].offersMade = payload.offersMade[manufacturer];
	}
	for (let manufacturer in payload.offersNotMade) {
	  if (!data[manufacturer]) {
		data[manufacturer] = {};
	  }
	  data[manufacturer].offersNotMade = payload.offersNotMade[manufacturer];
	}
	const _data = Object.keys(data).map((name) => {
	  const obj = { name };
	  if (data[name].offersMade) {
		obj.offersMade = data[name].offersMade;
	  }
	  if (data[name].offersNotMade) {
		obj.offersNotMade = data[name].offersNotMade;
	  }
	  return obj;
	});

	return _data.sort((a, b) => {
	  if (a.name === 'John Deere') {
		return -1;
	  } else if (b.name === 'John Deere') {
		return 1;
	  } else {
		const aTotal = Object.keys(a).filter(el => el !== 'name').reduce((acc, item) => {
		  if (isPercentValue) {
			acc += a[item].percent;
		  } else {
			acc += a[item].count;
		  }
		  return acc;
		}, 0);
		const bTotal = Object.keys(b).filter(el => el !== 'name').reduce((acc, item) => {
		  if (isPercentValue) {
			acc += b[item].percent;
		  } else {
			acc += b[item].count;
		  }
		  return acc;
		}, 0);
		return aTotal < bTotal ? 1 : -1;
	  }
	});
  })()),
  [types.SET_WON_LOST]: (state, { payload }) => state.set('wonLost', (() => {
	const isPercentValue = state.get('valuesType') === 'percent';
	const data = {};
	for (let manufacturer in payload.won) {
	  data[manufacturer] = {};
	  data[manufacturer].won = payload.won[manufacturer];
	}
	for (let manufacturer in payload.lost) {
	  if (!data[manufacturer]) {
		data[manufacturer] = {};
	  }
	  data[manufacturer].lost = payload.lost[manufacturer];
	}
	for (let manufacturer in payload.fullPayment) {
	  if (!data[manufacturer]) {
		data[manufacturer] = {};
	  }
	  data[manufacturer].fullPayment = payload.fullPayment[manufacturer];
	}
	for (let manufacturer in payload.downPayment) {
	  if (!data[manufacturer]) {
		data[manufacturer] = {};
	  }
	  data[manufacturer].downPayment = payload.downPayment[manufacturer];
	}
	const _data = Object.keys(data).map((name) => {
	  const obj = { name };
	  if (data[name].won) {
		obj.won = data[name].won;
	  }
	  if (data[name].lost) {
		obj.lost = data[name].lost;
	  }
	  if (data[name].fullPayment) {
		obj.fullPayment = data[name].fullPayment;
	  }
	  if (data[name].downPayment) {
		obj.downPayment = data[name].downPayment;
	  }
	  return obj;
	});

	return _data.sort((a, b) => {
	  if (a.name === 'John Deere') {
		return -1;
	  } else if (b.name === 'John Deere') {
		return 1;
	  } else {
		const aTotal = Object.keys(a).filter(el => el !== 'name').reduce((acc, item) => {
		  if (isPercentValue) {
			acc += a[item].percent;
		  } else {
			acc += a[item].count;
		  }
		  return acc;
		}, 0);
		const bTotal = Object.keys(b).filter(el => el !== 'name').reduce((acc, item) => {
		  if (isPercentValue) {
			acc += b[item].percent;
		  } else {
			acc += b[item].count;
		  }
		  return acc;
		}, 0);
		return aTotal < bTotal ? 1 : -1;
	  }
	});
  })()),
  [types.SET_ACTIVE_MANUFACTURERS]: (state, { payload }) => state.set('manufacturers', (() => {
	return store.getState().manufacture.get('manufacture_list').filter((manufacturer) => payload.includes(manufacturer.id));
  })()),
  [types.SET_VALUES_TYPE]: (state, { payload }) => state.set('valuesType', payload),
  [types.CLEAR_BRAND_LOYALTY]: (state) => state.set('manufacturers', initialState.get('manufacturers'))
	.set('total', initialState.get('total'))
	.set('offers', initialState.get('offers'))
	.set('wonLost', initialState.get('wonLost')),
});
