export const CREATE_DEALER = '@/dealer/CREATE_DEALER';

export const FETCH_CURRENT_DEALER = '@/dealer/FETCH_CURRENT_DEALER';
export const SET_CURRENT_DEALER = '@/dealer/SET_CURRENT_DEALER';

export const CHANGE_DEALER_LOGIN_AND_PASSWORD = '@/dealer/CHANGE_DEALER_LOGIN_AND_PASSWORD';
export const CHANGE_DEALER_STATUS = '@/dealer/CHANGE_DEALER_STATUS';

export const EDIT_CURRENT_DEALER = '@/dealer/EDIT_CURRENT_DEALER';

export const FETCH_DEALER_LIST = '@/delaer/FETCH_DEALER_LIST';
export const SET_DEALER_LIST = '@/delaer/SET_DEALER_LIST';

export const FETCH_DEALER_ACTIVE_LIST = '@/dealer/FETCH_DEALER_ACTIVE_LIST';
export const SET_DEALER_ACTIVE_LIST = '@/dealer/SET_DEALER_ACTIVE_LIST';
export const SET_CURRENT_DEALER_IN_TABLE = '@/dealer/SET_CURRENT_DEALER_IN_TABLE';

export const FETCH_LIST_OF_ACTIVE_DEALERS = '@/dealer/FETCH_LIST_OF_ACTIVE_DEALERS';
export const SET_LIST_OF_ACTIVE_DEALERS = '@/dealer/SET_LIST_OF_ACTIVE_DEALERS';