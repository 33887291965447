import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { triggers } from '@/redux/potential-sales/actions';
import t from '@/utilities/translate';
import Form, { FORM_NAME } from './Form';

const AddProduct = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		id,
		items,
		fiscal_year,
		editPotentialSale
	} = props;

	const closeModal = (values) => {
		const newItems = [...items, {
			product_id: values.product_id,
			equipment_type: values.equipment_type,
			quantity: Number(values.quantity),
			offered_price: Number(values.offered_price),
			currency_id: values.currency_id,
		}];
		editPotentialSale({
			id,
			items: newItems,
			fiscal_year: values.fiscal_year,
			isNewProduct: true
		});
		closeModalFn();
	};

	return (
		<Modal
			{...modalProps}
			title={`${t('potential-sales/add-product/title')}:`}
			footer={null}
		>
			<Form
				initialValues={{ fiscal_year }}
				formName={FORM_NAME}
				onSubmit={closeModal}
				closeModalFn={closeModalFn}
			/>
		</Modal>
	);
};

const mapDispatchToProps = {
	...triggers,
};

export default connect(null, mapDispatchToProps)(AddProduct);
