import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { ordersScheduled } from '@/services/api';

//-----------------------------------------
//    Dealer triggers
//-----------------------------------------

export const triggers = {
    fetchListByDealer: (query) => ({
        type: types.FETCH_LIST_BY_DEALER,
        query
    }),
    fetchListByTotal: (query) => ({
        type: types.FETCH_LIST_BY_TOTAL,
        query
    }),
	clearListByTotal: () => ({
		type: types.CLEAR_LIST_BY_TOTAL
	}),
};

//-----------------------------------------
//	Dealer saga actions
//-----------------------------------------

export function* fetchListByDealer({ type, query }) {
    yield put(addPreloader(type));

    try {
        const resp = yield call(ordersScheduled.listByDealer, query);
        yield put({
            type: types.SET_LIST_BY_DEALER,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

export function* fetchListByTotal({ type, query }) {
    yield put(addPreloader(type));

    try {
        const resp = yield call(ordersScheduled.listByTotal, query);
        yield put({
            type: types.SET_LIST_BY_TOTAL,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}



//-----------------------------------------
//	Root dealer saga
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.FETCH_LIST_BY_DEALER, fetchListByDealer);
    yield takeEvery(types.FETCH_LIST_BY_TOTAL, fetchListByTotal);
}
