import * as types from './types';
import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';

import { total_country } from '@/services/api';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';

export const triggers = {
    marketPotentional: (query, cb) => ({
        type: types.FETCH_TOTAL_COUNTRY_LIST,
        query,
        cb
    }),
    dealerBreakdownList: (query) => ({
        type: types.FETCH_DEALER_MODEL_BREAKDOWN_UNIT,
        query
    }),
    dealerList: (query)=>({
        type: types.FETCH_DEALER_UNIT,
        query
    }),
    setMarketPotentional: (query)=>({
        type: types.SET_COUNTRU_GOAL,
        query
    }),
    setDealerBreakdownUnit: (id, query)=>({
        type: types.SET_DEALER_MODEL_BREAKDOWN_UNIT_TO,
        id,
        query
    }),
    setDealerUnit: (id, query)=>({
        type: types.SET_DEALER_UNIT_TO,
        id,
        query
    }),
    salesManPlanList: (query)=>({
        type: types.FETCH_SALES_MAN_PLAN,
        query
    }),
    setSalesManPlanUnit: (id, query)=>({
        type: types.SET_SALES_MAN_PLAN_TO,
        query,
        id
    }),
}

//-----------------------------------------
//	Market potential
//-----------------------------------------

export function* marketPotentional({ type, query, cb }) {

    yield put(addPreloader(type));
    try {
        const resp = yield call(total_country.marketPotentional, query);
        yield put({
            type: types.SET_TOTAL_COUNTRY_LIST,
            payload: resp.data
        })
        yield call(cb);
    } catch(e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* setMarketPotentional({ query }) {

    try {
        yield call(total_country.setMarketPotentional, query);
    } catch(e) {
        yield call(console.warn, e);
    }

}

//-----------------------------------------
//	Manufacture unit
//-----------------------------------------

export function* dealerBrakedownList({ type, query }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(total_country.dealerBrakedown, query);
        yield put({
            type: types.SET_DEALER_MODEL_BREAKDOWN_UNIT,
            payload: resp.data
        })
    } catch(e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* setDealerBrakedown({ type, id, query }) {

    try {
        yield call(total_country.setDealerBrakedown, id, query);
    } catch(e) {
        yield call(console.warn, e);
    }

}

//-----------------------------------------
//	Dealer unit
//-----------------------------------------

export function* dealerUnitList({ type, query }) {
    try {
        const resp = yield call(total_country.AORPotentional, query);
        yield put({
            type: types.SET_DEALER_UNIT,
            payload: resp.data
        })
    } catch(e) {
        yield call(console.warn, e);
    }
}

export function* setDealerUnit({ type, id, query }) {

    try {
        yield call(total_country.setAORPotentional, id, query);
    } catch(e) {
        yield call(console.warn, e);
    }

}

//-----------------------------------------
//	Sales Man Plan
//-----------------------------------------

export function* salesManPlanList({ type, query }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(total_country.salesManPlan, query);
        yield put({
            type: types.SET_SALES_MAN_PLAN,
            payload: resp.data
        })
    } catch(e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* setSalesManPlanUnit({ type, id, query }) {

    try {
        yield call(total_country.setSalesManPlan, id, query);
    } catch(e) {
        yield call(console.warn, e);
    }

}

export function* saga() {
    yield takeEvery(types.SET_DEALER_UNIT_TO, setDealerUnit);
    yield takeEvery(types.FETCH_DEALER_UNIT, dealerUnitList);
    yield takeEvery(types.SET_DEALER_MODEL_BREAKDOWN_UNIT_TO, setDealerBrakedown);
    yield takeEvery(types.FETCH_DEALER_MODEL_BREAKDOWN_UNIT, dealerBrakedownList);
    yield takeEvery(types.SET_COUNTRU_GOAL, setMarketPotentional);
    yield takeEvery(types.FETCH_TOTAL_COUNTRY_LIST, marketPotentional);
    yield takeEvery(types.SET_SALES_MAN_PLAN_TO, setSalesManPlanUnit);
    yield takeEvery(types.FETCH_SALES_MAN_PLAN, salesManPlanList);
}
