import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import T from '@/utilities/translate';
import { productLineGroup } from '@/services/api';
import { noty } from "@/utilities/notifications";
import { addPreloader, removePreloader } from '../common/preloaders';

export const triggers = {};

triggers.createProductLineGroup = (query)=>({
    type: types.CREATE_PRODUCT_LINE_GROUP,
    query
})

triggers.editProductLineGroup = (id, query)=>({
    type: types.EDIT_PRODUCT_LINE_GROUP,
    id,
    query
})

triggers.currentProductLineGroup = (id)=>({
    type: types.CURRENT_PRODUCT_LINE_GROUP,
    id
})

triggers.changeStatusProductLineGroup = (id)=>({
    type: types.CHANGE_STATUS_PRODUCT_LINE_GROUP,
    id
})

triggers.fetchProductLineGroupList = ()=>({
	type: types.FETCH_PRODUCT_LINE_GROUP_LIST
})

triggers.fetchProductLineGroupListByPlatform = (platform_id)=>({
    type: types.FETCH_PRODUCT_LINE_GROUP_LIST_BY_PLATFORM,
    platform_id
})

export function* createProductLineGroup({ type, query }) {
    yield put(addPreloader(type));
    try {
        yield call(productLineGroup.create, query);
        yield call(noty, 'success', T('productLineGroup/create/success'));
        // yield call(window._forward);
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* editProductLineGroup({ type, id, query }) {
    yield put(addPreloader(type));
    try {
        yield call(productLineGroup.edit, id, query);
        yield call(noty, 'success', T('productLineGroup/edit/success'));
        yield call(window._forward, { id });
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* changeStatusProductLineGroup({ type, id }) {

    try {
        yield call(productLineGroup.changeStatus, id);
        yield call(noty, 'success', T('productLineGroup/changeStatus/success'));
    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* currentProductLineGroup({ type, id }) {

    yield put(addPreloader(type));

    try {
        const resp = yield call(productLineGroup.current, id);
        yield put({
            type: types.PUT_PRODUCT_LINE_GROUP,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* fetchProductLineGroupList() {

	try {
		const resp = yield call(productLineGroup.activeList);
		yield put({
			type: types.PUT_PRODUCT_LINE_GROUP_LIST,
			payload: resp.data
		})
	} catch (e) {
		yield call(console.warn, e);
	}

}

export function* fetchProductLineGroupListByPlatform({ type, platform_id }) {

    try {
        const resp = yield call(productLineGroup.activeListByPlatform, platform_id);
        yield put({
            type: types.PUT_PRODUCT_LINE_GROUP_LIST_BY_PLATFORM,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* saga() {
    yield takeEvery(types.FETCH_PRODUCT_LINE_GROUP_LIST_BY_PLATFORM, fetchProductLineGroupListByPlatform);
    yield takeEvery(types.FETCH_PRODUCT_LINE_GROUP_LIST, fetchProductLineGroupList);
    yield takeEvery(types.CHANGE_STATUS_PRODUCT_LINE_GROUP, changeStatusProductLineGroup);
    yield takeEvery(types.CURRENT_PRODUCT_LINE_GROUP, currentProductLineGroup);
    yield takeEvery(types.CREATE_PRODUCT_LINE_GROUP, createProductLineGroup);
    yield takeEvery(types.EDIT_PRODUCT_LINE_GROUP, editProductLineGroup);
}

