import {
	call, put, takeEvery
} from 'redux-saga/effects';
import * as types from './types';
import { salesManager } from '@/services/api';

export const triggers = {};

triggers.activeListByRole = () => ({
	type: types.FETCH_ACTIVE_LIST_BY_ROLE
});

triggers.listByOutlets = (payload) => ({
	type: types.FETCH_SALES_BY_OUTLETS,
	payload
});

triggers.clearListByOutlets = () => ({
	type: types.CLEAR_SALES_BY_OUTLETS
});

function* activeListByRoleWorker () {
	try {
		const response = yield call(salesManager.listByRole);
		yield put({
			type: types.SET_ACTIVE_LIST_BY_ROLE,
			payload: response.data
		});
	} catch (e) {
		yield call(console.warn, e);
	}
}

function* activeListByOutlets ({ payload }) {
	try {
		const response = yield call(salesManager.listByOutlets, payload);
		yield put({
			type: types.SET_SALES_BY_OUTLETS,
			payload: response.data
		});
	} catch (e) {
		yield call(console.warn, e);
	}
}

export function* saga () {
	yield takeEvery(types.FETCH_ACTIVE_LIST_BY_ROLE, activeListByRoleWorker);
	yield takeEvery(types.FETCH_SALES_BY_OUTLETS, activeListByOutlets);
}
