export const FETCH_ACTIVE_JOB_TITLE_LIST = '@/job/FETCH_ACTIVE_JOB_TITLE_LIST';
export const SET_ACTIVE_JOB_TITLE_LIST = '@/job/SET_ACTIVE_JOB_TITLE_LIST';

export const CREATE_JOB_TITLE = '@/job/CTREATE_JOB_TITLE';

export const FETCH_JOB_LIST = '@/job/FETCH_JOB_LIST';
export const SET_JOB_LIST = '@/job/SET_JOB_LIST';

export const CHANGE_JOB_STATUS = '@/job/CHANGE_JOB_STATUS';
export const SET_JOB_WITH_CHANGE_STATUS = '@/job/SET_JOB_WITH_CHANGE_STATUS';

export const UPDATE_CURRENT_JOB = '@/job/UPDATE_CURRENT_JOB';

export const FETCH_CURRENT_JOB = '@/job/FETCH_CURRENT_JOB';
export const SET_CURRENT_JOB = '@/job/SET_CURRENT_JOB';