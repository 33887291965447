import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';

import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { regionsCoordinates } from '@/services/api';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchRegionsList = (payload) => ({
	type: types.FETCH_REGIONS_LIST,
	payload
});

triggers.updateRegion = (payload) => ({
	type: types.UPDATE_REGION,
	payload
});

const setRegionsData = (payload) => ({
	type: types.SET_REGIONS_DATA,
	payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchRegionsList({ type, payload }) {
	yield put(addPreloader(type));

	try {
		const { data } = yield call(regionsCoordinates.getData, payload);
		yield put(setRegionsData(data));
	} catch (err) {
		console.error(err);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* updateRegion({ payload }) {
	const { id, coordinates } = payload;
	try {
		yield call(regionsCoordinates.edit, id, { coordinates });
	} catch (err) {
		throw err;
	}
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_REGIONS_LIST, fetchRegionsList);
	yield takeEvery(types.UPDATE_REGION, updateRegion);
}
