import React, { Component } from 'react';
import { Input, Form } from 'antd';
import ReactInputMask from 'react-input-mask';

export const InputMaskF = (props) => (
  <ReactInputMask {...props}>
    {(inputProp) => <Input {...inputProp} disabled={props.disabled ? props.disabled : null} />}
  </ReactInputMask>
);

class InputMask extends Component {
    state = {
      value: '232323'
    }

    render() {
      const {
        meta,
        label,
	    placeholder,
        labelCol = {},
        wrapperCol = {},
        _validate = null
      } = this.props;

      const formItemProps = {
        labelCol,
        wrapperCol,
        label: label ? (_validate ? (
          <span>
            {label}
            <span className="f-danger">*</span>
          </span>
        ) : label) : null,
        validateStatus: meta.touched && !meta.valid ? "error" : undefined,
        help: meta.touched && meta.error ? meta.error : null,
      };
      return (
        <Form.Item {...formItemProps}>
          <InputMaskF
			  value={this.state.value}
			  onChange={(e) => this.setState({ value: e.target.value })}
			  placeholder={placeholder}
			  key="inputIt"
		  />
        </Form.Item>
      );
    }
}

export default InputMask;
