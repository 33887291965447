import * as types from './types';
import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";

const initialState = new Map({
    coverageGoals: []
});

export default createReducer(initialState, {
    [types.SET_COVERAGE_GOALS_V2]: (state, { payload })=>state.set('coverageGoals', payload),
});
