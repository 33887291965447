import React from 'react';
import { Button, Col, Icon, Row } from 'antd';
import { Field, reduxForm } from 'redux-form';
import validator from '@/utilities/validator';
import t from '@/utilities/translate';
import FormSkeleton from '@/components/common/form/FormSkeleton';
import TextField from "../common/form/TextFieldWrapper"

const Form = ({
	handleSubmit,
    pending
}) => {
	return (
	  <FormSkeleton onSubmit={handleSubmit}>
		  <Row gutter={15} style={{marginTop: 24}}>
			  <Col xs={24} style={{marginBottom: 16}}>
				  <Field component={TextField}
				         prefix={<Icon type="user"/>}
				         name="login"
				         type="login"
				         placeholder={t('change-credentials/email/label')}/>
			  </Col>
			  <Col xs={24} style={{marginBottom: 16}}>
				  <Field component={TextField}
				         prefix={<Icon type="lock"/>}
				         name="password"
				         type="password"
				         placeholder={t('change-credentials/password/label')}/>
			  </Col>
			  <Col xs={24}>
				  <Button type="primary"
				          loading={pending}
				          htmlType="submit"
				          style={{width: '100%'}}>{t('change-credentials/submit/button')}</Button>
			  </Col>
		  </Row>
	  </FormSkeleton>
	)
};

export default reduxForm({
	form: 'ChangeCredentials',
	validate: validator.changeCredentials
})(Form);
