export const CREATE_BASE_MACHINERY_DESCRIPTION =
  "@/base-machinery-description/CREATE_BASE_MACHINERY_DESCRIPTION";
export const EDIT_BASE_MACHINERY_DESCRIPTION =
  "@/base-machinery-description/EDIT_BASE_MACHINERY_DESCRIPTION";

export const CURRENT_BASE_MACHINERY_DESCRIPTION =
  "@/base-machinery-description/CURRENT_BASE_MACHINERY_DESCRIPTION";
export const PUT_BASE_MACHINERY_DESCRIPTION =
  "@/base-machinery-description/PUT_BASE_MACHINERY_DESCRIPTION";

export const CHANGE_STATUS_BASE_MACHINERY_DESCRIPTION =
  "@/base-machinery-description/CHANGE_STATUS_BASE_MACHINERY_DESCRIPTION";

export const PUT_BASE_MACHINERY_DESCRIPTION_ACTIVE_LIST =
  "@/base-machinery-description/PUT_BASE_MACHINERY_DESCRIPTION_ACTIVE_LIST";
export const FETCH_BASE_MACHINERY_DESCRIPTION_ACTIVE_LIST =
  "@/base-machinery-description/FETCH_BASE_MACHINERY_DESCRIPTION_ACTIVE_LIST";

export const PUT_BASE_MACHINERY_DESCRIPTION_LIST_BY_MODEL_DESCRIPTION =
  "@/base-machinery-description/PUT_BASE_MACHINERY_DESCRIPTION_LIST_BY_MODEL_DESCRIPTION";
export const FETCH_BASE_MACHINERY_DESCRIPTION_LIST_BY_MODEL_DESCRIPTION =
  "@/base-machinery-description/FETCH_BASE_MACHINERY_DESCRIPTION_LIST_BY_MODEL_DESCRIPTION";
