import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	coordinates: []
});

export default createReducer(initialState, {
	[types.SET_DISTRICTS_DATA]: (state, { payload }) => state.set('coordinates', payload)
});
