import { Map } from 'immutable';
import { pick } from 'lodash/fp';
import * as types from './types';
import { createReducer } from "@/redux/helpers";
import { apiNumberFormatter } from '../../utilities/formatter';

const initialState = new Map({
  me: {},
});

export default createReducer(initialState, {
  [types.SET_ME_PROFILE]: (state, { payload }) => {
    payload.status = String(payload.status);
    const _payload = apiNumberFormatter(pick(['phone', 'index'])(payload));

    return state.set('me', Object.assign(payload, _payload));
  }
});
