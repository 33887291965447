import React from 'react';
import {Route} from 'react-router-dom';
import BaseLayout from '@/layouts/BaseLayout';
import EmptyLayout from '@/layouts/EmptyLayout';
import PropTypes from 'prop-types';


const BaseLayoutRoute = ({component: Component, routesConfig, ...rest}) => {
    let routes = Object.values(routesConfig)
        .filter(({path}) => {
            return !!path;
        })
        .map(({path, breadcrumb}) => ({path, breadcrumb}));

    return (
        <Route {...rest} render={matchProps => (
            <BaseLayout routes={routes}>
                <Component {...matchProps} {...rest} />
            </BaseLayout>
        )}/>
    );
};

BaseLayoutRoute.propTypes = {
    component: PropTypes.any,
    routesConfig: PropTypes.object,
};

const EmptyLayoutRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <EmptyLayout>
                <Component {...matchProps} />
            </EmptyLayout>
        )}/>
    );
};

EmptyLayoutRoute.propTypes = {
    Component: PropTypes.element
};



export {BaseLayoutRoute, EmptyLayoutRoute};
