/* eslint-disable no-sequences */
import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
  // Country
  [`current@country`]: {},
  [`listOf@country`]: {},
  [`activeCountryList`]: [],
  // Region
  [`current@region`]: {},
  [`listOf@region`]: {},
  [`activeRegionListByCountry`]: new Map({}),
  [`activeRegionList`]: [],
  // Rayon
  [`current@rayon`]: {},
  [`listOf@rayon`]: {},
  [`active@rayonList`]: [],
  [`activeDistrictListByRegions`]: new Map({}),
  [`activeDistrictList`]: [],
});

export default createReducer(initialState, {
  [types.SET_CURRENT_LOCATION]: (state, { payload, _type_ }) => state.set(`current@${_type_}`, payload),
  [types.SET_LOCATION_LIST]: (state, { payload, _type_ }) => state.set(`listOf@${_type_}`, payload),
  [types.CHENGE_LOCATION_STATUS_SET]: (state, { payload, _type_ }) => state.update(`listOf@${_type_}`, (locations) => {
    locations.data = locations.data.map((location) => location.id === payload.id ? payload : location);
    return locations;
  }),
  [types.SET_ACTIVE_COUNTRY_LIST]: (state, { payload }) => state.set(`activeCountryList`, payload.sort((country) => country.id === 1 ? -1 : 1)),
  [types.SET_ACTIVE_REGION_LIST]: (state, { payload }) => state.set(`activeRegionList`, payload),
  [types.SET_ACTIVE_REGION_BY_COUNTRY_LIST]: (state, { id, payload }) => state.setIn([`activeRegionListByCountry`, id], payload),
  [types.SET_ACTIVE_DESTRICT_BY_REGIONS_LIST]: (state, { ids, payload }) => {
    const id = (ids && ids instanceof Array) ? ids.join('#') : ids;
    return id ? state.setIn(['activeDistrictListByRegions', id], payload) : state;
  },
  [types.SET_ACTIVE_DISTRICT_LIST]: (state, { payload }) => state.set('activeDistrictList', payload)
});
