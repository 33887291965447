import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { triggers } from '@/redux/change-credentials/actions';
import Form from './Form';

const ChangeCredentialsModal = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		changeCoworkerCredentials,
		changeDealerCredentials,
		changeEmployeeCredentials,
		changePssCredentials,
		changeTmCredentials,
		role,
	    initialLogin = '',
	    idForChange
	} = props;

	const closeModal = (values) => {
		switch (role) {
			case 'coworker':
				changeCoworkerCredentials({
					login: values.login,
					password: values.password,
					id: idForChange
				});
				break;
			case 'pss':
				changePssCredentials({
					login: values.login,
					password: values.password,
					id: idForChange
				});
				break;
			case 'tm':
				changeTmCredentials({
					login: values.login,
					password: values.password,
					id: idForChange
				});
				break;
			case 'employee':
				changeEmployeeCredentials({
					login: values.login,
					password: values.password,
					id: idForChange
				});
				break;
			case 'dealer':
				changeDealerCredentials({
					login: values.login,
					password: values.password,
					id: idForChange
				});
				break;
			default:
				break;
		}
		closeModalFn();
	};

	return (
	  <Modal {...modalProps} footer={null}>
		  <Form initialValues={{ login: initialLogin }} onSubmit={closeModal} />
	  </Modal>
	);
};

ChangeCredentialsModal.defaultProps = {
	modalProps: {},
	availableClients: [],
};

ChangeCredentialsModal.propTypes = {
	modalProps: PropTypes.any,
	closeModal: PropTypes.func.isRequired
};

export default connect(
  null,
  {
	  ...triggers
  }
)(ChangeCredentialsModal);
