import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { dealer } from '@/services/api';
import t from '@/utilities/translate';
import { noty } from "@/utilities/notifications";
import { history } from '@/redux/history';

//-----------------------------------------
//    Dealer triggers
//-----------------------------------------

export const triggers = {
    createDealer: (query) => ({
        type: types.CREATE_DEALER,
        query
    }),
    currentDealer: (id) => ({
        type: types.FETCH_CURRENT_DEALER,
        id
    }),
    changePasswordLoginDealer: (id) => ({
        type: types.CHANGE_DEALER_LOGIN_AND_PASSWORD,
        id
    }),
    changeStatusDealer: (id, options) => ({
        type: types.CHANGE_DEALER_STATUS,
        id,
        options
    }),
    editDealer: (id, query) => ({
        type: types.EDIT_CURRENT_DEALER,
        id,
        query
    }),
    listOfDealers: (payload) => ({
        type: types.FETCH_DEALER_LIST,
        payload
    }),
    listActiveDealers: (payload) => ({
        type: types.FETCH_DEALER_ACTIVE_LIST,
		payload
    }),
    listOfActiveDealers: () => ({
        type: types.FETCH_LIST_OF_ACTIVE_DEALERS
    })
};

//-----------------------------------------
//	Dealer saga actions
//-----------------------------------------

export function* listActiveDealers({ type, payload }) {
    try {
        const resp = yield call(dealer.listOfActive, payload);
        yield put({
            type: types.SET_DEALER_ACTIVE_LIST,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* listOfDealers({ type, payload }) {
    yield put(addPreloader(type));
    try {

        const resp = yield call(dealer.list, payload);
        yield put({
            type: types.SET_DEALER_LIST,
            payload: resp.data
        })

    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* listOfActiveDealers({ type }) {
    try {
        const { data } = yield call(dealer.listOfActive);
        yield put({
            type: types.SET_LIST_OF_ACTIVE_DEALERS,
            payload: data
        })
    } catch (e) {
        yield call(console.warn, e);
    }
}

export function* createDealer({ type, query }) {
    const haveUploadPhoto = query.photo && query.photo.constructor.name === 'Array';
    yield put(addPreloader(type));
    try {
        const {data} = yield call(dealer.create, query);
        if( haveUploadPhoto )
            yield call(dealer.uploadPhoto, data.id, query.photo);
        yield call(noty, 'success', t('dealer/create/noty/success'))
        yield call(history.push, '/dealers');
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* editDealer({ type, id, query }) {
    const haveUploadPhoto = query.photo && query.photo.constructor.name === 'Array';
    yield put(addPreloader(type));
    try {
        yield call(dealer.edit, id, query);
        if( haveUploadPhoto )
            yield call(dealer.uploadPhoto, query.id, query.photo);
        yield call(noty, 'success', t('dealer/edit/noty/success'));
        yield call(history.push, '/dealers');
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* changePasswordLoginDealer({ type, id }) {

    try {
        yield call(dealer.changePasswordLogin, id);
        yield call(noty, 'success', t('dealer/change_login_and_password/noty/success'))
    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* changeStatusDealer({ type, id, options={} }) {
    try {
        const resp = yield call(dealer.chnageStatus, id);

        if (options.type === 'list')
            yield put({
                type: types.SET_CURRENT_DEALER_IN_TABLE,
                payload: resp.data
            })
        else
            yield put({
                type: types.SET_CURRENT_DEALER,
                payload: resp.data
            })

        yield call(noty, 'success', t('dealer/change_status/noty/success'))
    } catch (e) {
        yield call(console.warn, e);
    }

}

export function* currentDealer({ type, id }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(dealer.current, id);
        yield put({
            type: types.SET_CURRENT_DEALER,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

//-----------------------------------------
//	Root dealer saga
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.FETCH_LIST_OF_ACTIVE_DEALERS, listOfActiveDealers);
    yield takeEvery(types.FETCH_DEALER_ACTIVE_LIST, listActiveDealers);
    yield takeEvery(types.FETCH_DEALER_LIST, listOfDealers);
    yield takeEvery(types.EDIT_CURRENT_DEALER, editDealer);
    yield takeEvery(types.FETCH_CURRENT_DEALER, currentDealer);
    yield takeEvery(types.CREATE_DEALER, createDealer);
    yield takeEvery(types.CHANGE_DEALER_LOGIN_AND_PASSWORD, changePasswordLoginDealer);
    yield takeEvery(types.CHANGE_DEALER_STATUS, changeStatusDealer);
}
