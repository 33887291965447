import * as types from './types';
import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";

const initialState = new Map({
    current: {},
    activeListByEquipmentGroup: []
});

export default createReducer(initialState, {
    [types.PUT_COMPOSITE_LEVEL]: (state, { payload })=>state.set('current', payload),
    [types.PUT_COMPOSITE_LEVEL_LIST_BY_EQUIPMENT_GROUP]: (state, { payload })=>state.set('activeListByEquipmentGroup', payload)
});