import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { coWorker } from '@/services/api';
import t from '@/utilities/translate';
import { noty } from "@/utilities/notifications";
import { _CREATE_NEW_USER } from '../employees/create/types';
import { USER_EDIT } from '@/redux/employees/edit/types';
import { history } from '@/redux/history';

const redirectName = '/employees';

//-----------------------------------------
//    Co-worker triggers
//-----------------------------------------

export const triggers = {};

triggers.createCoWorker = (query) => ({
  type: types.CREATE_CO_WORKER,
  query
});

triggers.currentCoWorker = (id) => ({
  type: types.FETCH_CURRENT_CO_WORKER,
  id
});

triggers.editCoWorker = (id, query) => ({
  type: types.UPDATE_CURRENT_CO_WORKER,
  id,
  query
});

triggers.changeStatusCoWorker = (id) => ({
  type: types.UPDATE_STATUS_CO_WORKER,
  id
});

triggers.sendSignCoWorker = (id) => ({
  type: types.SEND_SIGN,
  id
});

triggers.fetchCoWorkerActiveList = () => ({
  type: types.FETCH_CO_WORKER_ACTIVE_LIST
});

triggers.fetchCoWorkerByDealerList = (payload) => ({
  type: types.FETCH_CO_WORKER_BY_DEALER_LIST,
  payload
});

const setCoWorkerActiveList = (payload) => ({
  type: types.SET_CO_WORKER_ACTIVE_LIST,
  payload
});

const setCoWorkerByDealerList = (payload) => ({
  type: types.SET_CO_WORKER_BY_DEALER_LIST,
  payload
});

//-----------------------------------------
//	Co-worker saga actions
//-----------------------------------------

export function* createCoWorker({ type, query }) {
  yield put(addPreloader(_CREATE_NEW_USER));
  const havePhoto = Boolean(query.photo);

  try {
    const { data } = yield call(coWorker.create, query);
    havePhoto && (yield call(coWorker.uploadPhoto, data.id, query.photo));
    yield call(noty, 'success', t('coWorker/create/noty/success'));
    yield call(history.push, redirectName);
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(_CREATE_NEW_USER));
  }
}

export function* fetchActiveList() {
  try {
    const { data } = yield call(coWorker.activeList);
    yield put(setCoWorkerActiveList(data));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* fetchByDealerList({ payload }) {
  try {
    const { dealerId } = payload;
    const { data } = yield call(coWorker.listByDealer, { dealerId });
    yield put(setCoWorkerByDealerList(data));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* editCoWorker({ type, id, query }) {
  yield put(addPreloader(USER_EDIT));
  const havePhoto = typeof query.photo === 'object' && query.photo !== null;

  try {
    yield call(coWorker.edit, id, query);
    havePhoto && (yield call(coWorker.uploadPhoto, id, query.photo));
    yield call(noty, 'success', t('coWorker/edit/noty/success'));
    yield call(history.push, redirectName);
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(USER_EDIT));
  }
}

export function* sendSignCoWorker({ type, id }) {
  try {
    yield call(coWorker.sendSign, id);
    yield call(noty, 'success', t('coWorker/change_login_and_password/noty/success'));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* changeStatusCoWorker({ type, id }) {
  try {
    const resp = yield call(coWorker.changeStatus, id);
    yield put({
      type: types.PUT_CURRENT_CO_WORKER,
      payload: resp.data
    });
    yield call(noty, 'success', t('coWorker/change_status/noty/success'));
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* currentCoWorker({ type, id }) {
  yield put(addPreloader(type));
  try {
    const resp = yield call(coWorker.current, id);
    yield put({
      type: types.PUT_CURRENT_CO_WORKER,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

//-----------------------------------------
//	Root Co-worker saga
//-----------------------------------------

export function* saga() {
  yield takeEvery(types.FETCH_CO_WORKER_ACTIVE_LIST, fetchActiveList);
  yield takeEvery(types.FETCH_CO_WORKER_BY_DEALER_LIST, fetchByDealerList);
  yield takeEvery(types.SEND_SIGN, sendSignCoWorker);
  yield takeEvery(types.UPDATE_STATUS_CO_WORKER, changeStatusCoWorker);
  yield takeEvery(types.UPDATE_CURRENT_CO_WORKER, editCoWorker);
  yield takeEvery(types.FETCH_CURRENT_CO_WORKER, currentCoWorker);
  yield takeEvery(types.CREATE_CO_WORKER, createCoWorker);
}
