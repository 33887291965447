import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	list: [],
	defaultFilter: {},
	activeFilter: {},
});

export default createReducer(initialState, {
	[types.SET_SALES_PLANS_SUMMARY_LIST]: (state, { payload }) => state.set('list', payload),
	[types.SET_FILTER]: (state, { payload }) => state.set('defaultFilter', payload),
	[types.SET_ACTIVE_FILTER]: (state, { payload }) => state.set('activeFilter', payload),
});
