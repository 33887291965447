import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';
import { store } from '../store';

const initialState = new Map({
	mapData: [],
	manufacturers: [],
	chartData: {
		manufactures: [],
		model_descriptions: []
	},
	isLoadingChartData: false,
});

export default createReducer(initialState, {
	[types.SET_MAPS_DATA]: (state, { payload }) => state.set('mapData', payload),
	[types.SET_ACTIVE_MANUFACTURERS]: (state, { payload }) => state.set('manufacturers', (() => {
		return store.getState().manufacture.get('manufacture_list').filter((manufacturer) => payload.includes(manufacturer.id));
	})()),
	[types.SET_CHART_DATA]: (state, { payload }) => {
		return state.set('chartData', {
			manufactures: payload.manufactures.slice(0, 15),
			model_descriptions: payload.model_descriptions.slice(0, 15)
		});
	},
	[types.CLEAR_MAPS_DATA]: (state) => state.set('mapData', initialState.get('mapData')),
	[types.CLEAR_CHART_DATA]: (state) => state.set('chartData', initialState.get('chartData')),
	[types.SET_LOADING_CHART_DATA]: (state, { payload }) => state.set('isLoadingChartData', payload),
});
