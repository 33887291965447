export const FETCH_ALL_LIST_DATA_FOR_PRODUCT_CREATE = '@/product/FETCH_ALL_LIST_DATA_FOR_PRODUCT_CREATE';

export const CREATE_NEW_PRODUCT = '@/product/CREATE_NEW_PRODUCT';

export const UPDATE_PRODUCT = '@/product/UPDATE_PRODUCT';
export const CHANGE_PRODUCT_STATUS = '@/product/CHANGE_PRODUCT_STATUS';

export const UPDATE_PRODUCT_LIST = '@/product/UPDATE_PRODUCT_LIST';

export const SET_CURRENT_PRODUCT = '@/product/SET_CURRENT_PRODUCT';
export const FETCH_CURRENT_PRODUCT = '@/product/FETCH_CURRENT_PRODUCT';

export const FETCH_LIST_BY_PARAMS = '@/product/FETCH_LIST_BY_PARAMS';
export const SET_LIST_BY_PARAMS = '@/product/SET_LIST_BY_PARAMS';

export const FETCH_CURRENCIES_LIST = '@/product/FETCH_CURRENCIES_LIST';
export const SET_CURRENCIES_LIST = '@/product/SET_CURRENCIES_LIST';

export const FETCH_PRODUCT_LIST = '@/product/FETCH_PRODUCT_LIST';
export const SET_PRODUCT_LIST = '@/product/SET_PRODUCT_LIST';
export const REMOVE_PRODUCT = '@/product/REMOVE_PRODUCT';
export const CLEAR_PRODUCT_LIST = '@/product/CLEAR_PRODUCT_LIST';
