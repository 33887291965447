import * as types from './types';
import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';
import { noty } from '@/utilities/notifications';
import t from '@/utilities/translate';
import { total_country_v2 } from '@/services/api';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';

export const triggers = {
    marketPotentional_v2: (query, cb) => ({
        type: types.FETCH_TOTAL_COUNTRY_LIST_V2,
        query,
        cb
    }),
    dealerBreakdownList_v2: (query) => ({
        type: types.FETCH_DEALER_MODEL_BREAKDOWN_UNIT_V2,
        query
    }),
    dealerList_v2: (query)=>({
        type: types.FETCH_DEALER_UNIT_V2,
        query
    }),
    setMarketPotentional_v2: (query)=>({
        type: types.SET_COUNTRU_GOAL_V2,
        query
    }),
    setDealerBreakdownUnit_v2: (id, query)=>({
        type: types.SET_DEALER_MODEL_BREAKDOWN_UNIT_TO_V2,
        id,
        query
    }),
    setDealerUnit_v2: (id, query)=>({
        type: types.SET_DEALER_UNIT_TO_V2,
        id,
        query
    }),
    salesManPlanList_v2: (query)=>({
        type: types.FETCH_SALES_MAN_PLAN_V2,
        query
    }),
    setSalesManPlanUnit_v2: (id, query)=>({
        type: types.SET_SALES_MAN_PLAN_TO_V2,
        query,
        id
    }),
}

//-----------------------------------------
//	Market potential
//-----------------------------------------

export function* marketPotentional_v2({ type, query, cb }) {

    yield put(addPreloader(type));
    try {
        const resp = yield call(total_country_v2.marketPotentional_v2, query);
        yield put({
            type: types.SET_TOTAL_COUNTRY_LIST_V2,
            payload: resp.data
        })
        yield call(cb);
    } catch(e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* setMarketPotentional_v2({ query }) {

    try {
        yield call(total_country_v2.setMarketPotentional_v2, query);
    } catch(e) {
        yield call(console.warn, e);
    }

}

//-----------------------------------------
//	Manufacture unit
//-----------------------------------------

export function* dealerBrakedownList_v2({ type, query }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(total_country_v2.dealerBrakedown_v2, query);
        yield put({
            type: types.SET_DEALER_MODEL_BREAKDOWN_UNIT_V2,
            payload: resp.data
        })
    } catch(e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* setDealerBrakedown_v2({ type, id, query }) {

    try {
        yield call(total_country_v2.setDealerBrakedown_v2, id, query);
    } catch(e) {
        yield call(console.warn, e);
    }

}

//-----------------------------------------
//	Dealer unit
//-----------------------------------------

export function* dealerUnitList_v2({ type, query }) {
    try {
        const resp = yield call(total_country_v2.AORPotentional_v2, query);
        yield put({
            type: types.SET_DEALER_UNIT_V2,
            payload: resp.data
        })
    } catch(e) {
        yield call(console.warn, e);
    }
}

export function* setDealerUnit_v2({ type, id, query }) {

    try {
        yield call(total_country_v2.setAORPotentional_v2, id, query);
    } catch(e) {
        yield call(console.warn, e);
    }

}

//-----------------------------------------
//	Sales Man Plan
//-----------------------------------------

export function* salesManPlanList_v2({ type, query }) {
    yield put(addPreloader(type));
    try {
        const resp = yield call(total_country_v2.salesManPlan_v2, query);
        yield put({
            type: types.SET_SALES_MAN_PLAN_V2,
            payload: resp.data
        })
    } catch(e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }

}

export function* setSalesManPlanUnit_v2({ type, id, query }) {

    try {
        yield call(total_country_v2.setSalesManPlan_v2, id, query);
    } catch(e) {
        yield call(console.warn, e);
    }

}

export function* saga() {
    yield takeEvery(types.SET_DEALER_UNIT_TO_V2, setDealerUnit_v2);
    yield takeEvery(types.FETCH_DEALER_UNIT_V2, dealerUnitList_v2);
    yield takeEvery(types.SET_DEALER_MODEL_BREAKDOWN_UNIT_TO_V2, setDealerBrakedown_v2);
    yield takeEvery(types.FETCH_DEALER_MODEL_BREAKDOWN_UNIT_V2, dealerBrakedownList_v2);
    yield takeEvery(types.SET_COUNTRU_GOAL_V2, setMarketPotentional_v2);
    yield takeEvery(types.FETCH_TOTAL_COUNTRY_LIST_V2, marketPotentional_v2);
    yield takeEvery(types.SET_SALES_MAN_PLAN_TO_V2, setSalesManPlanUnit_v2);
    yield takeEvery(types.FETCH_SALES_MAN_PLAN_V2, salesManPlanList_v2);
}
