import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from "@/redux/helpers";

const initialState = new Map({
  current: {},
  activeListByEstimateDescription: [],
  activeListByProductLines: [],
  activeListByEquipmentGroup: [],
  activeListByManufactureWithProducts: [],
  activeListByManufactureAndEstimateDesc: [],
});

export default createReducer(initialState, {
  [types.PUT_MODEL_DESCRIPTION]: (state, { payload }) => state.set('current', payload),
  [types.PUT_MODEL_DESCRIPTION_LIST_BY_ESTIMATE_DESCRIPTION]: (state, { payload }) => state.set('activeListByEstimateDescription', payload),
  [types.PUT_MODEL_DESCRIPTION_LIST_BY_PRODUCT_LINES]: (state, { payload }) => state.set('activeListByProductLines', payload),
  [types.PUT_MODEL_DESCRIPTION_LIST_BY_EQUIPMENT_GROUP]: (state, { payload }) => state.set('activeListByEquipmentGroup', payload),
  [types.PUT_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_WITH_PRODUCTS]: (state, { payload }) => state.set('activeListByManufactureWithProducts', payload),
  [types.CLEAR_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_WITH_PRODUCTS]: (state) => state.set('activeListByManufactureWithProducts', []),
  [types.PUT_MODEL_DESCRIPTION_LIST_BY_MANUFACTURE_AND_ESTIMATE_DESC]: (state, { payload }) => state.set('activeListByManufactureAndEstimateDesc', payload)
});
