import * as React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';

import { InputMaskF } from './InputMask';

// ----------------------------------------------------------------------
// TextField Component
// ----------------------------------------------------------------------

export default (options) => class InputMaskFieldWrapper extends React.PureComponent {
    static propTypes = {
      input: PropTypes.object,
      meta: PropTypes.object,
      label: PropTypes.string,
      number: PropTypes.bool
    };

    types = {
      phone_number: '+38 (999) 999-99-99',
      international_phone_number: '?999999999999',
    }

    changeHandler = (value) => {
      this.props.input.onChange(value);
    };

    render() {
      const {
        input,
        meta,
        label,
	    placeholder,
        labelCol = {},
        wrapperCol = {},
        _validate = false,
      } = this.props;

      const formItemProps = {
        labelCol,
        wrapperCol,
        label: label ? (_validate ? (
          <span>
            {label}
            <span className="f-danger">*</span>
          </span>
        ) : label) : null,
        validateStatus: (_validate === true || isNaN(_validate)) ? (meta.touched && !meta.valid ? "error" : undefined) : undefined,
        help: (_validate === true || isNaN(_validate)) ? (meta.touched && meta.error ? meta.error : null) : null,
      };

      return (
        <Form.Item {...formItemProps} style={{ margin: '0 0 10px' }}>
          <InputMaskF
			  {...input}
			  mask={this.types[options.type]}
			  maskChar={options.type === 'international_phone_number' ? null : '_'}
			  formatChars={{
				  "9": "[0-9]",
				  "?": "[0-9\+]"
			  }}
			  placeholder={placeholder}
		  />
        </Form.Item>
      );
    }
};
