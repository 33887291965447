import React from 'react';
import { Button, Checkbox, Col, Row, Form } from 'antd';
import { Field, reduxForm } from 'redux-form';
import t from '@/utilities/translate';
import FormSkeleton from '../../common/form/FormSkeleton';
import AutoComplete from '@/components/common/form/AutoComplateWrapper';
import validator from '@/utilities/validator';

const FormWrapper = ({
	                     handleSubmit,
	                     pending,
	                     getUsersListForNotificationEmails,
	                     handleStatusChange,
	                     isEdit,
	                     status,
	                     defaultUserId
                     }) => {
	return (
	  <FormSkeleton onSubmit={handleSubmit}>
		  <Row gutter={15} style={{ marginTop: 24 }}>
			  <Col xs={24}>
				  <Field component={AutoComplete}
				         defaultActiveFirstOption={!!defaultUserId}
				         handler={(value) => {
					         if (value) {
						         getUsersListForNotificationEmails(value)
					         }
				         }}
				         data={(state) => state.notificationEmails.get('availableUsers').map((item) => ({
					         value: Number(item.id),
					         label: item.name,
				         }))}
				         name="user_id"
				         placeholder={t('notificationEmails/user_id/label')}/>
			  </Col>
			  <Col xs={24}>
				  <Field name={'status'} component={() => (
					<Form.Item name={'status'} style={{ marginBottom: 16 }}>
						<Checkbox name={'status'} checked={status} onChange={handleStatusChange}
						          style={{ width: '100%' }}>{t('notificationEmails/status/label')}</Checkbox>
					</Form.Item>
				  )}/>
			  </Col>
			  <Col xs={24}>
				  <Button type="primary"
				          loading={pending}
				          htmlType="submit"
				          style={{ width: '100%' }}>{isEdit ? t('notificationEmails/edit/button') : t('notificationEmails/create/button')}</Button>
			  </Col>
		  </Row>
	  </FormSkeleton>
	)
};

export default reduxForm({
	form: 'NotificationEmails',
	validate: validator.notificationEmails
})(FormWrapper);
