import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';
import { months } from '@/constants';

const initialState = new Map({
  activeListByTotal: [],
  activeListByDealer: [],
});

export default createReducer(initialState, {
  [types.SET_HISTORY_LIST_BY_TOTAL]: (state, { payload }) => {
    if (payload && payload.length) {
		const normalizedPayload = payload.map((el) => {
			const { estimate_description_name, id, items } = el;

			const normalizedMonths = months.map((el) => {
				return {
					...el,
					years: []
				}
			});

			normalizedMonths.forEach((month) => {
				items.forEach((year) => {
					month.years.push({
						year: year.year,
						total: year.total,
						count_in_units: year.item.filter((el) => el.month === month.month)[0].count_in_units,
					});
				})
			});

			return {
				estimate_description_name,
				id,
				months: normalizedMonths
			};
		})

		return state.set('activeListByTotal', normalizedPayload)
	}
	  return state.set('activeListByTotal', payload)
  },
  [types.SET_HISTORY_LIST_BY_DEALER]: (state, { payload }) => {
	  if (payload && payload.length) {
		  const normalizedPayload = payload.map((el) => {
			  const { estimate_description_name, id, items } = el;

			  const normalizedMonths = months.map((el) => {
				  return {
					  ...el,
					  years: []
				  }
			  });

			  normalizedMonths.forEach((month) => {
				  items.forEach((year) => {
					  month.years.push({
						  year: year.year,
						  total: year.total,
						  count_in_units: year.item.filter((el) => el.month === month.month)[0].count_in_units,
						  id: year.item.filter((el) => el.month === month.month)[0].id,
						  not_come_yet: year.item.filter((el) => el.month === month.month)[0].not_come_yet,
						  not_editable: year.item.filter((el) => el.month === month.month)[0].not_editable,
					  });
				  })
			  });

			  return {
				  estimate_description_name,
				  id,
				  months: normalizedMonths,
			  };
		  })

		  return state.set('activeListByDealer', normalizedPayload)
	  }
    return state.set('activeListByDealer', payload)
  },
  [types.SET_CHANGED_HISTORY_LIST_BY_DEALER]: (state, { payload }) => {
    const { id, count_in_units, month, not_editable, not_come_yet } = payload;
    const currentState = state.get('activeListByDealer');

    const newState = currentState.map((_estDesc) => {
      const changedValueYear = () => {
        let year;
        const changedValueMonth = _estDesc.months.filter((_month) => _month.month === month)[0];
        changedValueMonth.years.forEach((_year) => {
          if (_year.id === id ) {
            year = _year.year;
          }
        });

        return year;
      }

      const newTotal = () => {
        let total = 0;

        _estDesc.months.forEach((_month) => {
          _month.years.forEach((_year) => {
            if ( changedValueYear() === _year.year) {
              if (_year.id === id) {
                total += count_in_units
              } else {
                total += _year.count_in_units
              }
            }
          })
        })

        return total;
      }

      const newMonths = _estDesc.months.map((_month) => {

        const newYears = _month.years.map((_year) => {

          if (_year.id === id) {
            return {
              ..._year,
              total: newTotal(),
              count_in_units,
              not_editable,
              not_come_yet,
            }
          } else {
            if (_year.year === changedValueYear()) {
              return {
                ..._year,
                total: newTotal()
              }
            } else {
              return _year;
            }
          }
        })

        return {
          ..._month,
          years: newYears
        }
      })

      return {
        ..._estDesc,
        months: newMonths
      }
    });

    return state.update('activeListByDealer', () => newState)
  },
  [types.CLEAR_HISTORY_BY_TOTAL]: (state) => {
	return state.set('activeListByTotal', [])
  },
});
