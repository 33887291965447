import React from 'react';
import { Layout } from 'antd';
import PropTypes from "prop-types";
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import Lightbox from 'react-image-lightbox';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import SideLeft from '@/components/common/sideleft/SideLeft';
import Header from '@/components/common/header/Header';
import Footer from '@/components/common/footer/Footer';
import Breadcrumbs from "@/components/common/breadcrumbs/Breadcrumbs";
import Progressbar from "@/components/common/progressbar/Progressbar";
import { hidePopup } from "@/redux/common/popups";
import ModalContainer from '@/components/common/modal/ModalContainer';

const { Content } = Layout;

class BaseLayout extends React.Component {
    static propTypes = {
      isAnyPreloader: PropTypes.bool,
      children: PropTypes.any,
      routes: PropTypes.array,
      popupProps: PropTypes.object,
      isShowPopup: PropTypes.bool,
      hidePopup: PropTypes.func
    };

    render() {
      const {
        routes, isAnyPreloader, isShowPopup, popupProps, hidePopup
      } = this.props;

      const ReadyBreadcrumbs = withBreadcrumbs(routes, {
        excludePaths: [
          '/territorials/add_new_territory_manager/:distributor',
          '/territorials/add_new_territory_manager',
          '/trade-in/:deal_id/client/:client_id/vehicles/:id',
          '/trade-in/:deal_id/client/:client_id/create'
        ]
      })(Breadcrumbs);

      return (
        <Layout style={{ height: '100vh', overflow: 'hidden' }}>
          <Progressbar loading={isAnyPreloader} />

          <SideLeft style={{ height: '100%' }} />

          <Layout>
            <Header />
            <ReadyBreadcrumbs exclude={[
              '/territorials/add_new_territory_manager',
              '/trade-in/:deal_id/client/:client_id/vehicles/:id',
              '/trade-in/:deal_id/client/:client_id/create',
			  '/vehicles/:deal_id/:id'
            ]}
            />
            <Content style={{
              margin: '16px 16px', padding: 24, background: '#fff', position: 'relative'
            }}
            >
              {this.props.children}
            </Content>
            <Footer />
          </Layout>

          <ModalContainer />

          {isShowPopup && !!popupProps.mainSrc && (<Lightbox {...popupProps} onCloseRequest={hidePopup} />)}
        </Layout>
      );
    }
}

const mapStateToProps = (state) => ({
  isShowPopup: state.popups.isOpen,
  popupProps: state.popups.popupProps,
  isAnyPreloader: Object.values(state.preloaders).some((group) => group.length)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ hidePopup }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
