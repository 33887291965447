import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';
import { store } from '../store';

const initialState = new Map({
	total: [],
	manufacturers: [],
	offers: [],
	wonLost: []
});

export default createReducer(initialState, {
	[types.SET_TOTAL]: (state, { payload }) => state.set('total', (() => {
		return payload;
	})()),
	[types.SET_ACTIVE_MANUFACTURERS]: (state, { payload }) => state.set('manufacturers', (() => {
		return store.getState().manufacture.get('manufacture_list').filter((manufacturer) => payload.includes(manufacturer.id));
	})()),
	[types.CLEAR_PRODUCTS_QUANTITY]: (state) => state.set('manufacturers', initialState.get('manufacturers'))
		.set('total', initialState.get('total'))
});
