import React from 'react';

export default function ({
    loader,
    loading
}) {

    class LoadComponent extends React.Component {

        state = {
            isLoad: false,
            Component: loading
        }

        constructor(fu) {

            super(fu);

            loader().then((Component)=>{
                this.setState({
                    isLoad: true,
                    Component: Component.default
                })
            })
        }

        render() {

            const {
                Component
            } = this.state;

            return <Component {...this.props} />

        }

    }

    return LoadComponent;

}