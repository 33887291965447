import * as types from './types';
import { Map } from 'immutable';
import { createReducer } from "@/redux/helpers";
import React from 'react';
import t from '@/utilities/translate';
import { Tooltip } from 'antd';

const initialState = new Map({
  data: {
    columns: [],
	dataSource: []
  }
});

export default createReducer(initialState, {
  [types.SAVE_DATA_V2]: (state, { payload }) => state.set('data', (() => {
    const obj = {
      dataSource: payload
	};

    const arr = [
    	"user",
	    "nextActionMeeting",
	    "nextActionPhoneCall",
	    "nextActionMeetingWithClientCount",
	    "nextActionPhoneCallWithClientCount",
	    "nextActionMeetingWithClient",
	    "nextActionPhoneCallWithClient",
		"nextActionWithoutMeetingAndPhoneCall",
		"nextActionWithoutMeetingAndPhoneCallWithClientCount",
		"nextActionWithoutMeetingAndPhoneCallWithClient",
	    "nextActionWithClient",
	    "nextActionMoreTwoTimeWithClient",
	    "salesManagerLogin",
	    "salesManagerCreateDeal",
	    "salesManagerUpdateDeal",
	    "salesManagerViewDeal",
	    "clientVehiclesCreated",
	    "contactsCreated",
	    "won",
	    "gapToTheGoal",
	    "marketShareEst"
    ];

	// obj.columns = (Object.keys(payload[0]) || []).reduce((acc, key) => {
	//   if (key !== 'salesManagerId') {
	// 	acc.push({
	// 	  title: key === 'user' ? (
	// 	    <b>{t(`sales-efficiency/list/${key}`)}</b>
	// 	  ) : (
	// 	    <Tooltip title={t(`sales-efficiency/tooltip/${key}`)}>
	// 	        <b>{t(`sales-efficiency/list/${key}`)}</b>
	// 	    </Tooltip>
	// 	  ),
	// 	  key,
	// 	  dataIndex: key,
	// 	  width: 160,
	// 	  textWrap: 'word-break',
	// 	  fixed: key === 'user' ? 'left' : false
	// 	});
	//   }
	//   return acc;
	// }, []);

	obj.columns = arr.reduce((acc, key) => {
		acc.push({
			title: key === 'user' ? (
			  <b>{t(`sales-efficiency/list/${key}`)}</b>
			) : (
			  <Tooltip title={t(`sales-efficiency/tooltip/${key}`)}>
				  <b>{t(`sales-efficiency/list/${key}`)}</b>
			  </Tooltip>
			),
			key,
			align: key === 'user' ? 'left' : 'center',
			dataIndex: key,
			width: 160,
			textWrap: 'word-break',
			sorter: key === 'user' ? false : (a, b) => a[key] < b[key] ? 1 : -1,
			fixed: key === 'user' ? 'left' : false
		});
		return acc;
	}, []);

	return obj;
  })()),
});
