import * as types from './types';
import { Map } from 'immutable';
import {createReducer} from "@/redux/helpers";

const initialState = new Map({
    current: {},
	activeList: [],
    activeListByPlatform: []
});

export default createReducer(initialState, {
    [types.PUT_PRODUCT_LINE_GROUP]: (state, { payload })=>state.set('current', payload),
    [types.PUT_PRODUCT_LINE_GROUP_LIST]: (state, { payload })=>state.set('activeList', payload),
    [types.PUT_PRODUCT_LINE_GROUP_LIST_BY_PLATFORM]: (state, { payload })=>state.set('activeListByPlatform', payload)
});
