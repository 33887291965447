export const productForward = (location, props, routes) => {
    /* eslint-disable default-case */
    switch (location) {
        case routes['product/create'].link():
            return routes['product'].link();

        case routes['product/edit'].link(props.id):
            return routes['product'].link();
            
        case routes['product/current'].link(props.id):
            return routes['product'].link();
    }
}
