export const FETCH_POTENTIAL_SALES_LIST = '@/potential-sales/FETCH_POTENTIAL_SALES_LIST';
export const SET_POTENTIAL_SALES_LIST = '@/potential-sales/SET_POTENTIAL_SALES_LIST';

export const FETCH_STATUSES_LIST = '@/potential-sales/FETCH_STATUSES_LIST';
export const SET_STATUSES_LIST = '@/potential-sales/SET_STATUSES_LIST';

export const FETCH_CURRENT_POTENTIAL_SALE = '@/potential-sales/FETCH_CURRENT_POTENTIAL_SALE';
export const SET_CURRENT_POTENTIAL_SALE = '@/potential-sales/SET_CURRENT_POTENTIAL_SALE';

export const EDIT_POTENTIAL_SALE = '@/potential-sales/EDIT_POTENTIAL_SALE';
export const APPROVE_POTENTIAL_SALE = '@/potential-sales/APPROVE_POTENTIAL_SALE';
export const DECLINE_POTENTIAL_SALE = '@/potential-sales/DECLINE_POTENTIAL_SALE';
