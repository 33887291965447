import * as types from "./types";

export const loginRequest = (data) => ({ type: types.LOGIN_REQUEST, data });
export const forceLogout = () => ({ type: types.FORCE_LOGOUT_REQUEST });
export const loginSuccess = (data) => ({ type: types.LOGIN_SUCCESS, data });

export const getUserInfo = (query) => ({ type: types.GET_USER_INFO, query });
export const setUserInfo = (data) => ({ type: types.SET_USER_INFO, data });

export const refreshTokenRequest = (refresh_token) => ({ type: types.REFRESH_TOKEN, refresh_token });
export const clearToken = () => ({ type: types.CLEAR_TOKEN });

export const logoutRequest = () => ({ type: types.LOGOUT_REQUEST });

export const changeLanguage = (lang) => ({
  type: types.CHANGE_LANGUAGE,
  payload: {
    language: lang
  }
});
