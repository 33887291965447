export const formatHOF = (fns) => (val) => fns.reduce((acc, fn) => fn(acc), val);

export const detectPropery = (fn) => (target) => {
  const RCurry = detectPropery(fn);
  const targetHandlers = {
    object: (_target) => {
      Object.keys(_target).forEach((key) => (_target[key] = RCurry(_target[key])));
      return _target;
    },
    array: (_target) => {
      _target.forEach(RCurry);
      return _target;
    },
    property: (_target) => fn(_target)
  };

  const defineType = (_target) => {
    if (typeof _target !== 'object') {
      return 'property';
    }
    if (!_target) return null;
    if (!_target.constructor) return null;
    return _target.constructor.name.toLowerCase();
  };
  const type = defineType(target);

  return type ? targetHandlers[type](target) : null;
};

export const number2FormatString = (val, params = {}) => (typeof val === 'number' ? val.toLocaleString(undefined, params) : val);

export const phoneFormat = (val) => {
  const str = String(val);

  return /^(\+38)?\d{10}$/.test(str) ? normalizePhone(str) : val;
};

const normalizePhone = (value) => {
  const hasPlus = value.startsWith('+') ? value[0] : '';
  const onlyNums = value.replace(/[^\d]/g, '');
  const length = value.length;

  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
  }

  return `${hasPlus + onlyNums.slice(0, 2)} (${onlyNums.slice(2, 5)}) ${onlyNums.slice(5, 8)} ${onlyNums.slice(8, 10)} ${onlyNums.slice(10, length)}`;
};

export const apiFormatFermSizeCategory = (target, name) => {
  const formatFermSizeCategory = (target) => {
    if (target[name]) {
      const [sizeFrom, sizeTo] = target[name].split(' - ');
      return {
        ...target,
        [name]: `${number2FormatString(Number(sizeFrom))} - ${number2FormatString(Number(sizeTo))}`
      };
    }
    return target;
  };

  return Array.isArray(target) ? target.map(formatFermSizeCategory) : formatFermSizeCategory(target);
};

export const apiNumberFormatter = formatHOF([
  detectPropery(number2FormatString),
  detectPropery(phoneFormat)
]);
