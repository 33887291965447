import {createReducer} from "@/redux/helpers"

export const OPEN_MODAL = '@modals/OPEN_MODAL';
export const CLOSE_MODAL = '@modals/CLOSE_MODAL';
export const DESTROY_MODAL = '@modals/DESTROY_MODAL';

export const openModal = (name, props, modalProps) => ({type: OPEN_MODAL, name, props, modalProps});
export const closeModal = () => ({type: CLOSE_MODAL});
export const destroyModal = () => ({type: DESTROY_MODAL});

export const actions = {openModal, closeModal};

export const modal = createReducer(null, {
    [OPEN_MODAL](state, {name, props, modalProps}) {
        return {
            name,
            props,
            modalProps: {
                ...modalProps,
                visible: true
            }
        };
    },
    [CLOSE_MODAL](state) {
        return {
            ...state,
            modalProps: {
                ...state.modalProps,
                visible: false
            }
        };
    },
    [DESTROY_MODAL]() {
        return null;
    }
});