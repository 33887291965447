import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';

import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { BOED_v2 } from '@/services/api';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchList_v2 = (payload) => ({
    type: types.FETCH_BOED_LIST_V2,
    payload
});

triggers.setDisplay_v2 = (payload) => ({
    type: types.SET_BOED_DISPLAY_V2,
    payload
});

const setList_v2 = (payload)=>({
    type: types.SET_BOED_LIST_V2,
    payload
})

const setFilterValue_v2 = (payload)=>({
    type: types.SET_FILTER_VALUE_V2,
    payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchList_v2({ type, payload }) {
    yield put(addPreloader(type));

    try {
        yield put(setFilterValue_v2(payload));
        const { data } = yield call(BOED_v2.list, payload);
        yield put(setList_v2(data));
    } catch (err) {
        console.error(err);
    } finally {
        yield put(removePreloader(type));
    }
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.FETCH_BOED_LIST_V2, fetchList_v2);
}
