import * as types from './types';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {addPreloader, removePreloader} from '@/redux/common/preloaders';
import { employeesApi } from '@/services/api';

export const employeesAll = (payload)=>({
    type: types.FETCH_EMPLOYEES_ALL,
    payload
});

export const fetchSalesMenByDealerId = (id)=>({
    type: types.FETCH_SALES_MEN_BY_DEALER_ID,
    id
})

export const setSalesMenByDealerId = (payload)=>({
    type: types.SET_SALES_MEN_BY_DEALER_ID,
    payload
});

export const fetchSalesMenByQuery = (query) => ({
	type: types.FETCH_SALES_MEN_BY_QUERY,
	query
});

export const setSalesMenByQuery = (payload) => ({
	type: types.SET_SALES_MEN_BY_QUERY,
	payload
});

// ----------------------------------------------------------------------
// fetchOrdersArchiveWorker
// ----------------------------------------------------------------------


export function* fetchEmploeesAll({type, payload}) {
    yield put(addPreloader(type));

    try {
        const {data} = yield call(employeesApi.userList, payload);
        yield put({type: types.SET_EMPLOYEES_ALL, payload: data});
    } catch (e) {
        yield call(console.log, 'success');
    } finally {
        yield put(removePreloader(type));
    }
}

export function* fetchSalesMenByDealerIdSaga({type, id}) {
    try {
        const {data} = yield call(employeesApi.salesMenByDealerId, id);
        yield put(setSalesMenByDealerId(data));
    } catch (e) {
        yield call(console.log, e);
    }
}

export function* fetchSalesMenByQuerySaga({ type, query }) {
	try {
		const { data } = yield call(employeesApi.salesMenByQuery, query);
		yield put(setSalesMenByQuery(data));
	} catch (e) {
		yield call(console.log, e);
	}
}

// ----------------------------------------------------------------------
// WATCHER
// ----------------------------------------------------------------------

export function* saga() {
    yield all([
        takeEvery(types.FETCH_EMPLOYEES_ALL, fetchEmploeesAll),
        takeEvery(types.FETCH_SALES_MEN_BY_DEALER_ID, fetchSalesMenByDealerIdSaga),
        takeEvery(types.FETCH_SALES_MEN_BY_QUERY, fetchSalesMenByQuerySaga),
    ]);
}
