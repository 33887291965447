import React from 'react';
import {Icon, notification} from 'antd';

export const noty = (type = 'info', message, description, config) => {
    notification[type]({
        message: message,
        description: description,
        ...config
    });
};

export const showInDevMessage = () => notification.open({
    message: <b>В РАЗРАБОТКЕ</b>,
    description: 'Запрашиваемый функционал находится на стадии разработке, и уже совсем скоро будет реализован!',
    icon: <Icon type="tool" spin/>
});