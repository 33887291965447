export const FETCH_TRANSLATE = '@/app/FETCH_TRANSLATE';
export const SET_TRANSLATE = '@/app/SET_TRANSLATE';
export const SET_USER_LANG = '@/app/SET_USER_LANG';

//-----------------------------------------
//	Locations
//-----------------------------------------

export const SET_LOCATIONS = '@/app/SET_LOCATIONS';
export const FETCH_LOCATIONS = '@/app/FETCH_LOCATIONS';

//-----------------------------------------
//	FermsCategories
//-----------------------------------------

export const SET_FERM_CATEGORIES = '@/app/SET_FERM_CATEGORIES';
export const FETCH_FERM_CATEGORIES = '@/app/FETCH_FERM_CATEGORIES';

//-----------------------------------------
//	Roles
//-----------------------------------------

export const FETCH_ROLES = '@/app/FETCH_ROLES';
export const PUT_ROLES = '@/app/PUT_ROLES';