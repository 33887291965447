import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import T from '@/utilities/translate';
import { farmSize } from '@/services/api';
import { noty } from "@/utilities/notifications";
import { addPreloader, removePreloader } from '../common/preloaders';

//-----------------------------------------
//	Action Triggers
//-----------------------------------------

export const triggers = {};

triggers.createFarmSize = (query)=>({
    type: types.CREATE_FARM_SIZE,
    query
})

triggers.editFarmSize = (id, query)=>({
    type: types.EDIT_FARM_SIZE,
    id,
    query
})

triggers.currentFarmSize = (id)=>({
    type: types.CURRENT_FARM_SIZE,
    id
})

triggers.fetchFarmSizeList = (query)=>({
    type: types.FETCH_FARM_SIZE_LIST,
    query
})

triggers.deleteFarmSize = (id)=>({
    type: types.CHANGE_STATUS_FARM_SIZE,
    id
})

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* createFarmSize({ type, query }) {
    yield put(addPreloader(type));
    const reqSuccessNoty = T('farmSize/create/success');

    try {
        yield call(farmSize.create, query);
        yield call(noty, 'success', reqSuccessNoty);
        yield call(window._forward);
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

export function* editFarmSize({ type, id, query }) {
    yield put(addPreloader(type));
    const reqSuccessNoty = T('farmSize/edit/success');

    try {
        yield call(farmSize.edit, id, query);
        yield call(noty, 'success', reqSuccessNoty);
        yield call(window._forward, { id });
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

export function* currentFarmSize({ type, id }) {
    yield put(addPreloader(type));

    try {
        const resp = yield call(farmSize.current, id);
        yield put({
            type: types.PUT_FARM_SIZE,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

export function* fetchFarmSizeList({ type, query }) {
    yield put(addPreloader(type));

    try {
        const resp = yield call(farmSize.list, query);
        yield put({
            type: types.PUT_FARM_SIZE_LIST,
            payload: resp.data
        })
    } catch (e) {
        yield call(console.warn, e);
    } finally {
        yield put(removePreloader(type));
    }
}

export function* deleteFarmSize({ type, id }) {
    const reqSuccessNoty = T('farmSize/changeStatus/success');

    try {
        yield call(farmSize.delete, id);
        yield put({ type: types.UPDATE_FARM_SIZE_LIST, payload: id });
        yield call(noty, 'success', reqSuccessNoty);
    } catch (e) {
        yield call(console.warn, e);
    }
}

//-----------------------------------------
//	Root
//-----------------------------------------

export function* saga() {
    yield takeEvery(types.CHANGE_STATUS_FARM_SIZE, deleteFarmSize);
    yield takeEvery(types.FETCH_FARM_SIZE_LIST, fetchFarmSizeList);
    yield takeEvery(types.CURRENT_FARM_SIZE, currentFarmSize);
    yield takeEvery(types.CREATE_FARM_SIZE, createFarmSize);
    yield takeEvery(types.EDIT_FARM_SIZE, editFarmSize);
}

