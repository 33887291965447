import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { client } from '@/services/api';
import t from '@/utilities/translate';
import { noty } from "@/utilities/notifications";
// import { getRole } from '@/utilities/role';
import { history } from '@/redux/history';

//-----------------------------------------
//	Triggers
//-----------------------------------------
export const triggers = {};

triggers.clientList = (query) => ({
  type: types.FETCH_CLIENT_LIST,
  query
});

triggers.fetchContacts = (query) => ({
  type: types.FETCH_CONTACTS,
  query
});

triggers.putContacts = (payload) => ({
  type: types.PUT_CONTACTS,
  payload
});

triggers.clientCreate = (query) => ({
  type: types.CLIENT_CREATE,
  query
});

triggers.clientArray = (query) => ({
  type: types.CLIENT_ARRAY,
  query
});

triggers.currentClient = (id) => ({
  type: types.FETCH_CURRENT_CLIENT,
  id
});

triggers.editClient = (id, payload) => ({
  type: types.UPDATE_CURRENT_CLIENT,
  id,
  payload
});

triggers.clientChangeStatus = (id, _type_) => ({
  type: types.CLIENT_CHANGE_STATUS,
  id,
  _type_
});

triggers.fetchClientsByCompanyName = (company_name, except_id = null) => ({
  type: types.FETCH_CLIENTS_BY_COMPANY_NAME,
  company_name,
  except_id
});

triggers.fetchChildClientsByCompanyName = (company_name, except_id = null) => ({
  type: types.FETCH_CHILD_CLIENTS_BY_COMPANY_NAME,
  company_name,
  except_id
});

triggers.clearChildClientsByCompanyName = () => ({
  type: types.CLEAR_CHILD_CLIENTS_BY_COMPANY_NAME
});

triggers.removeContactRequest = (payload)=>({
  type: types.REMOVE_CONTACT_REQUEST,
  payload
});

triggers.contactCreateRequest = (payload, cb)=>({
  type: types.CREATE_CONTACT_REQUEST,
  payload,
  cb
});

triggers.fetchContact = (payload)=>({
  type: types.FETCH_CONTACT_REQUEST,
  payload
});

triggers.putContact = (payload)=>({
  type: types.FETCH_CONTACT_EMITTER,
  payload
});

triggers.updateContact = (payload, cb)=>({
  type: types.UPDATE_CONTACT_REQUEST,
  payload,
  cb
});

triggers.clientUpdateLocations = (id, payload)=>({
	type: types.UPDATE_LOCATIONS,
	id,
	payload
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchContact({ type, payload }) {
  yield put(addPreloader(type));
  try {
    const { data } = yield call(client.fetchContact, payload);
    yield put(triggers.putContact(data));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* fetchClientsByCompanyName({ type, company_name, except_id }) {
  try {
    const resp = yield call(client.clientsByCompanyName, company_name, except_id);
    yield put({ type: types.PUT_CLIENTS_BY_COMPANY_NAME, payload: resp.data });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* fetchChildClientsByCompanyName({ type, company_name, except_id }) {
  try {
    const resp = yield call(client.clientsByCompanyName, company_name, except_id);
    yield put({ type: types.PUT_CHILD_CLIENTS_BY_COMPANY_NAME, payload: resp.data });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* removeContact({ type, payload }) {
  const { id, query } = payload;
  try {
    yield call(client.removeContact, Number(id.replace(/\s/gi, '')));
    yield call(fetchContacts, { type, query });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* createContact({ type, payload, cb }) {
  yield put(addPreloader(type));
  try {
    yield call(client.createContact, payload);
    yield call(noty, 'success', t('contacts/create/success'));
    yield call(cb);
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* updateContact({ type, payload, cb }) {
  yield put(addPreloader(type));
  try {
    yield call(client.updateContact, payload);
    yield call(noty, 'success', t('contacts/update/success'));
    yield call(cb);
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* clientChangeStatus({ type, id, _type_ }) {
  try {
    const resp = yield call(client.changeStatus, id);
    yield call(noty, 'success', t('client/changeStatus/success'));
    if (_type_ === 'profile') {
      yield put({
        type: types.SET_CURRENT_CLIENT,
        payload: resp.data
      });
    } else {
      yield put({
        type: types.CHANGE_STATUS_FROM_LIST,
        payload: resp.data
      });
    }
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* editClient({ type, id, payload }) {
  yield put(addPreloader(type));
  try {
    const _payload = {...payload};
    _payload.farm_size = Number(_payload.farm_size);
    yield call(client.edit, id, _payload);
    yield call(noty, 'success', t('client/edit/success'));
    yield call(history.push, `/customers`);
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* currentClient({ type, id }) {
  yield put(addPreloader(type));

  try {
    const resp = yield call(client.current, id);
    yield put({
      type: types.SET_CURRENT_CLIENT,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  } finally {
	  yield put(removePreloader(type));
  }
}

export function* clientCreate({ type, query }) {
  yield put(addPreloader(type));

  try {
    yield call(client.create, query);
    yield call(noty, 'success', t('client/create/success'));
    yield call(history.push, '/customers');
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* clientList({ type, query }) {
  yield put(addPreloader(type));

  try {
    const resp = yield call(client.clientList, query);
    // resp.data.
    yield put({
      type: types.SET_CLIENT_LIST,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* fetchContacts({ type, query }) {
  yield put(addPreloader(type));

  try {
    const { data } = yield call(client.contactList, query);
    yield put(triggers.putContacts(data));
  } catch (e) {
    yield call(console.warn, e);
  } finally {
    yield put(removePreloader(type));
  }
}

export function* clientArray({ type, query }) {
  try {
    const resp = yield call(client.clientArray, query);
    yield put({
      type: types.SET_CLIENT_ARRAY,
      payload: resp.data
    });
  } catch (e) {
    yield call(console.warn, e);
  }
}

export function* updateLocations({ type, id, payload }) {
	yield put(addPreloader(type));

	try {
		yield call(client.updateLocations, id, payload);
		yield call(noty, 'success', t('client/edit/success'));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root dealer saga
//-----------------------------------------

export function* saga() {
  yield takeEvery(types.FETCH_CLIENTS_BY_COMPANY_NAME, fetchClientsByCompanyName);
  yield takeEvery(types.FETCH_CHILD_CLIENTS_BY_COMPANY_NAME, fetchChildClientsByCompanyName);
  yield takeEvery(types.CLIENT_CHANGE_STATUS, clientChangeStatus);
  yield takeEvery(types.UPDATE_CURRENT_CLIENT, editClient);
  yield takeEvery(types.FETCH_CURRENT_CLIENT, currentClient);
  yield takeEvery(types.CLIENT_ARRAY, clientArray);
  yield takeEvery(types.CLIENT_CREATE, clientCreate);
  yield takeEvery(types.FETCH_CLIENT_LIST, clientList);
  yield takeEvery(types.FETCH_CONTACTS, fetchContacts);
  yield takeEvery(types.REMOVE_CONTACT_REQUEST, removeContact);
  yield takeEvery(types.CREATE_CONTACT_REQUEST, createContact);
  yield takeEvery(types.FETCH_CONTACT_REQUEST, fetchContact);
  yield takeEvery(types.UPDATE_CONTACT_REQUEST, updateContact);
  yield takeEvery(types.UPDATE_LOCATIONS, updateLocations);
}
